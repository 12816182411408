import AddReviewComponent from './add-review';

const ConsultationSystemicReviewComponent = ( { allSymptoms, reviews, onUpdate, onDelete } ) => {

    return (
        <div>
            {
                reviews.length > 0 && <>
                    <div className="row">
                        <div className="col-4" ><strong>Symptom</strong></div>
                        <div className="col-6" ><strong>Type</strong></div>
                    </div>
                </>
            }
            {
                reviews.length > 0 && reviews.map( rev =>
                    <div className="my-1" key={ rev.recordId }>
                        <AddReviewComponent data={ rev } symptoms={ allSymptoms } onUpdate={ onUpdate } onDelete={ onDelete } />
                    </div>
                )
            }
        </div>
    );
};

export default ConsultationSystemicReviewComponent;
