import { Divider } from 'antd';
import React from 'react';
import { PaymentMethod } from './checkout';
import { useAtom } from 'jotai';
import { billPaymentsAtom, deleteBillPaymentAtom, updateBillPaymentAtom } from '../../helpers/state/edit-bill';

export default function EditPayments () {
    const [ payments, addPayment ] = useAtom( billPaymentsAtom );
    const [ , updateBill ] = useAtom( updateBillPaymentAtom );
    const [ , deleteBill ] = useAtom( deleteBillPaymentAtom );

    return (
        <div>
            <Divider orientation='left' orientationMargin={ 0 } className="mb-1">Payment Methods</Divider>
            {
                payments.map( ( pay, index ) =>
                    <PaymentMethod
                        usedMethods={ payments }
                        payment={ pay }
                        key={ index }
                        onUpdate={ updateBill }
                        onDelete={ deleteBill }
                    /> )
            }
            <button onClick={ addPayment } className="button is-small">
                <span className="bi bi-plus-circle me-2"></span>
                Add Payment
            </button>
        </div>
    );
}


