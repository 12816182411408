import { getUser, refreshToken } from "../helpers/auth";
import { Divider, Tag, message } from "antd";
import { IconChevronRight } from "@tabler/icons-react";
import { Avatar, Group, LoadingOverlay, Text } from "@mantine/core";
import { getUserBranches, putSwitchBranch } from "../helpers/api";
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import _ from "lodash";
import { PageTitle } from "../components";

const SwitchBranch = ( props ) => {
    const user = getUser();
    const nav = useNavigate();

    const { data: branches = [] } = useQuery( {
        queryFn: () => getUserBranches( user.id ),
        queryKey: [ 'userBranches' ],
    } );

    const { mutateAsync: switchUp, isLoading, isSuccess } = useMutation( ( data ) => putSwitchBranch( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                refreshToken().then( () => {
                    nav( -1, { replace: true } );
                } );
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;

            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );



    const handleSwitch = ( id ) => {
        if ( id === user.branchId ) {
            message.error( { content: 'Cannot switch to active branch' } );
            return;
        }

        switchUp( id );

    };


    return (
        <div className="mt-5">
            <PageTitle title="Switch Branch" />
            <LoadingOverlay visible={ ( isLoading || isSuccess ) } />
            <div className="row">
                <div className="col-md-4 col-12 p-4 border bokx-bg-secondary rounded mx-auto">
                    <h6>Switch Branch</h6>
                    <Divider className="mb-1" />
                    <p>
                        Below are branches assigned to you. All unsaved work will be lost when you switch. Click to switch!
                    </p>
                    <Divider className="mt-1" />
                    {
                        branches?.map( ( { branch: br } ) => {
                            return (
                                <div className="card mb-2">
                                    <Group className="p-2 hover-hand" onClick={ () => handleSwitch( br.id ) }>
                                        <Avatar radius="xl">
                                            <span className="bi bi-building"></span></Avatar>

                                        <div style={ { flex: 1 } }>
                                            <Text size="md" weight={ 500 }>
                                                { br.branchName }
                                                {
                                                    user.branchId === br.id && <Tag color="green" className="ms-3">Active Branch</Tag>
                                                }
                                            </Text>
                                        </div>
                                        <IconChevronRight size="1rem" />
                                    </Group>
                                </div>
                            );
                        } )
                    }
                    <Divider />
                    <button className="button app-btn-prim"
                        onClick={ () => nav( -1, { replace: true } ) }
                    >
                        {/* <span className="bi bi-arrow-left me-2" /> */ }
                        Exit
                    </button>
                </div>
            </div>
        </div >
    );
};

export { SwitchBranch };
