import { Divider, message } from "antd";
import _ from "lodash";
import { format } from "date-fns";
import { useState } from "react";
import { Paper, Modal, Text } from "@mantine/core";
import { DetailLineItem, RequiredIndicator } from "../../../components";
import { IconPill, IconStethoscope } from "@tabler/icons";
import { setState } from "../../../helpers/utilities";
import smalltalk from 'smalltalk';
import { putIssueMedicine, putAddPharmacyNote } from "../../../helpers/api";
import { useMutation } from "react-query";
import { openConfirmModal, closeModal } from '@mantine/modals';

const PharmacyConsultationDetail = ( { data, onReload, onClose } ) => {
  // useEffect( () => { }, [data] );
  const [ modelOpen, setOpenModal ] = useState( false );
  const [ notes, setNotes ] = useState( '' );
  // const [ price, setPrice ] = useState( 0 );
  let price = 0;

  const updatePrecriptionIssue = ( prescriptionId ) => {
    data.prescriptions.map( p => {
      if ( p.id === prescriptionId ) {
        p.isIssued = true;
        p.price = price;
      }
    } );
  };


  const { mutateAsync: issueServerMedicine, isLoading } = useMutation( ( data ) => putIssueMedicine( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        updatePrecriptionIssue( variables.prescriptionId );
        // onReload( data.code ); //after issuing drug, reload
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
    retry: true
  } );


  const { mutateAsync: addPharmacyNote, isLoading: addingNotes } = useMutation( ( data ) => putAddPharmacyNote( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        setNotes( '' );
        return;
      }
      throw data;
    },
  } );


  const handleIssueMedicine = ( prescription ) => {
    openConfirmModal( {
      modalId: 'isConfirm123',
      title: 'Confirmation',
      children: (
        <>
          <Text size="sm">
            Issuing <strong>{ prescription.medicine }</strong> cannot be undone. If you wish to proceed, please
            input the price below then click 'Proceed'.
          </Text>
          <input
            autoFocus
            tabIndex="1"
            onChange={ ( e ) => {
              // setPrice( e.target.value );
              price = parseFloat( e.target.value );
            } }
            placeholder="x.xx" min={ 0 } type="number" step="0.01" className="input mt-3" />
        </>
      ),
      labels: { confirm: 'Proceed', cancel: 'Cancel', },
      // onCancel: () => console.log( 'Cancel' ),
      closeOnConfirm: false,
      onConfirm: () => {

        if ( price <= 0 ) {
          message.error( 'invalid price' );
          return;
        }

        issueServerMedicine( {
          prescriptionId: prescription.id,  //prescriptionId
          consultationId: data.id,
          price,
          // pharmacyId: extAuth.getUser().externalId //extracted on server side
        } );
        closeModal( 'isConfirm123' );
      }
      ,
    } );


    // smalltalk.confirm(
    //   "Issue Medicine", `Issuing '${ prescription.medicine }' cannot be undone. Proceed?`, {
    //   buttons: {
    //     ok: 'YES',
    //     cancel: 'NO',
    //   },
    // }
    // ).then( go => {
    //   // message.info( 'Issuing...' );
    //   issueServerMedicine( {
    //     prescriptionId: prescription.id,  //prescriptionId
    //     consultationId: data.id,
    //     price,
    //     // pharmacyId: extAuth.getUser().externalId //extracted on server side
    //   } );
    // } ).catch( ex => {
    //   return false;
    // } );

  };




  const addNotes = () => {
    if ( _.isEmpty( notes ) ) {
      message.error( 'notes are required!' );
      return;
    }
    addPharmacyNote( { notes, consultationId: data.id } );
    // message.success( 'notes submitted' );
  };

  return (

    <div className="consultation-detail">
      <div className="row g-0">
        <div className="col-12 mb-2">
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="section-header mb-0">Consultation</h6>
              <p className="text-muted mt-0">consultation basic details</p>
            </div>
            <div>
              <button className="button "
                onClick={ () => setOpenModal( true ) }
              >
                <span className="bi bi-pen me-2"></span>
                ADD NOTES
              </button>
              <button className="button is-danger is-light p-3" onClick={ onClose }>
                <strong>Close</strong>
              </button>
            </div>
          </div>
          {/* consultation */ }
          <Paper className="p-3  border">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="layer-header">Consultation</h6>
            </div>
            <DetailLineItem icon="lock" title="Code" detail={
              <kbd>
                { data.code }
              </kbd>
            } />
            <Divider className="my-1" />
            <DetailLineItem icon="braces-asterisk" title="Type" detail={ data.type } />
            <Divider className="my-1" />
            <DetailLineItem icon="calendar" title="Date" detail={ `${ format( new Date( data.createdAt ), "EEE MMM dd, yyy" ) } @${ new Date( data.createdAt ).toLocaleTimeString() }` } />
            <Divider className="my-1" />
            <DetailLineItem icon="megaphone" title="Complaint" detail={ data.complaint } />
            <Divider className="my-1" />
            <DetailLineItem icon="file-medical" title="Management Plan (Advice)" detail={ data.doctorsAdvise } />
          </Paper>
        </div>
        <div className="col-md-6 g-0 col-12">
          {/* patient */ }
          <Paper className="p-3  border">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="layer-header">
                <IconPill className="me-1" />
                Patient</h6>
            </div>
            <DetailLineItem title="Name" detail={ `${ data?.patient?.firstName } ${ data?.patient?.lastName }` } />
            <DetailLineItem title="Contact" detail={ data?.patient?.contact } />
          </Paper>
        </div>
        <div className="col-md-6 g-0 col-12">
          {/* doctor */ }
          <Paper className="p-3 border">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="layer-header">
                <IconStethoscope className="me-1" />
                Doctor
              </h6>
            </div>
            <DetailLineItem title="Name" detail={ `${ data?.doctor?.firstName || '-' } ${ data?.doctor?.lastName || '' }` } />
            <DetailLineItem title="Contact" detail={ data?.doctor?.contact } />
          </Paper>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 mb-1">
          <h6 className="section-header mb-0">Results</h6>
          <p className="text-muted mt-0">consultation findings and recommendations for further engagement</p>
          <Paper className="p-3 border">
            {/* diagnosis */ }
            <div className="d-flex justify-content-between align-items-center">
              <span className="layer-title text-success">
                Diagnosis
              </span>
            </div>
            <div>
              {
                data.diagnosis.map( ( d, i ) => <>
                  <Divider className="my-1" />
                  <DetailLineItem title={ ++i + '.' } detail={ d.diagnosis } />
                </>
                )
              }
            </div>
          </Paper>
        </div>
        <div className="col-12 mb-1">
          <Paper className="p-3 border">
            {/* prescriptions */ }
            <div className="d-flex justify-content-between align-items-center">
              <span className="layer-title text-success">
                Prescriptions
              </span>
            </div>
            <div>
              {
                data.prescriptions.map( ( d, i ) => <>
                  <Divider className="my-1" />
                  <div className="row">
                    <div className="col-9">
                      <kbd className="bg-secondary">{ ++i }</kbd>
                      <DetailLineItem title='Medicine' detail={ d.medicine } />
                      {/* <DetailLineItem title='Dosage' detail={ d.dosage } /> */ }
                      <DetailLineItem title='Frequency' detail={ d.frequency } />
                      <DetailLineItem title='Duration' detail={ d.duration } />
                      {
                        d.isIssued &&
                        <DetailLineItem title='Price (GHS)' detail={ d.price } />
                      }
                    </div>
                    <div className="col-2">
                      {
                        !d.isIssued &&
                        <button className={ `button h6 app-btn btn-prim h-50 mt-4 ${ isLoading && ' is-loading' }` } onClick={ () => handleIssueMedicine( d ) }>
                          <span className="bi bi-capsule"></span> <br />
                          ISSUE<br />
                          MEDICINE
                        </button>
                      }
                    </div>
                  </div>
                </>
                )
              }
            </div>
          </Paper>
        </div>
      </div>
      <Modal
        size="md"
        title="Add Notes"
        opened={ modelOpen }
        onClose={ () => setOpenModal( false ) }
      >
        <label className="mb-0" htmlFor="note">
          Pharmacy Notes ({ 150 - parseInt( notes.length ) } characters remaining)
          <RequiredIndicator />
        </label>
        <textarea className="textarea"
          value={ notes }
          rows={ 6 }
          maxLength={ 150 }
          onChange={ e => setState( setNotes, e.target.value ) }
          required id="notes" name="notes" placeholder="add notes here"></textarea>
        <button onClick={ addNotes } className={ `button h6 app-btn btn-prim mt-3 ${ addingNotes && ' is-loading' }` }>Submit</button>
      </Modal>
    </div>
  );
};

export default PharmacyConsultationDetail;
