import { useAtom } from 'jotai';
import React from 'react';
import { billAtom } from '../../helpers/state/bill';

export default function AddNoteComponent () {

  const [ bill, setBill ] = useAtom( billAtom );

  return (
    <div>
      <textarea
        name="note" id="note"
        className='textarea'
        cols="30" rows="10"
        placeholder='start typing notes here'
        value={ bill.note }
        onChange={ ( e ) => setBill( { note: e.target.value } ) }
      ></textarea>
    </div>
  );
}
