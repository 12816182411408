import { BackTop, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import PrescriptionsFilterBar from '../../../components/filter-bars/prescriptions-report-filters';
import { cedisLocale, exportToExcel } from '../../../helpers/utilities';

export default function PrescriptionsReport ( { data, isLoading } ) {

    const [ filteredData, setFilteredData ] = useState( [] );

    const handleReset = () => {
        setFilteredData( data );
    };

    const handleApplyFilter = ( newData ) => {
        setFilteredData( newData );
    };

    useEffect( () => {
        setFilteredData( data );
    }, [ data ] );


    const columns = [
        {
            title: "#",
            // sorter: ( a, b ) => a.insuranceNumber > b.insuranceNumber,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => ++index

        },
        {
            title: "Date - Time",
            sorter: ( a, b ) => a?.createdAt > b?.createdAt,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => new Date( record?.createdAt ).toUTCString()
        },
        {
            title: "Patient Name",
            sorter: ( a, b ) => a?.consultation?.patient?.firstName > b?.consultation?.patient?.firstName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.consultation.patient.firstName }  ${ record?.consultation?.patient?.lastName }`,
        },
        {
            title: "Insurance Provider",
            sorter: ( a, b ) => a.gender > b.gender,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record?.consultation?.patient?.insuranceProvider?.providerName
        },
        {
            title: "Insurance #",
            dataIndex: [ 'consultation', 'patient', 'insuranceNumber' ],
            sorter: ( a, b ) => a?.consultation?.patient?.insuranceNumber > b?.consultation?.patient?.insuranceNumber,
            sortDirections: [ "descend", "ascend" ],
        },

        {
            title: "Doctor",
            sorter: ( a, b ) => a?.consultation?.doctor?.firstName > b?.consultation?.doctor?.firstName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.consultation?.doctor?.firstName }  ${ record?.consultation?.doctor?.lastName }`,
        },
        {
            title: "Drug",
            sorter: ( a, b ) => a?.medicine > b?.medicine,
            dataIndex: "medicine",
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Dosage",
            sorter: ( a, b ) => a?.dosage > b?.dosage,
            dataIndex: "dosage",
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Status",
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record?.isIssued ? "Issued" : "Not Issued"
        },
        {
            title: "Price",
            sorter: ( a, b ) => a.price > b.price,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record?.isIssued && cedisLocale.format( record.price )
        },
        {
            title: "Pharmacy",
            sorter: ( a, b ) => a?.pharmacy?.pharmacyName > b?.pharmacy?.pharmacyName,
            dataIndex: [ 'pharmacy', 'pharmacyName' ],
            sortDirections: [ "descend", "ascend" ],
        },
        // {
        //     title: "Issuer",
        //     sorter: ( a, b ) => a.totalConsultations > b.totalConsultations,
        //     dataIndex: "totalConsultations",
        //     sortDirections: [ "descend", "ascend" ],
        // },
        {
            title: "Date Issued",
            sorter: ( a, b ) => a?.updatedAt > b?.updatedAt,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record?.isIssued && new Date( record?.updatedAt ).toUTCString(),
        },

    ];


    return (
        <div>
            <PrescriptionsFilterBar data={ data } onReset={ handleReset }
                onFilter={ ( d ) => handleApplyFilter( d ) }
                excelExport={ () => exportToExcel( "prescriptionsReportTable", "rivia-prescriptions-report" ) }
            />

            {/* advanced filters
            
            -> date range
            -> doctor
            -> status
            -> age
            
            */}
            <Table
                className="border mt-3"
                id='prescriptionsReportTable'
                rowKey={ ( record ) => record.id }
                dataSource={ filteredData }
                columns={ columns }
                loading={ isLoading }
                bordered
                tableLayout='auto'
            />
            <BackTop />
        </div>
    );
}
