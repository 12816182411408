import { userEncryptionKey, roleEncryptionKey, tokenEncryptionKey } from "./config";
import { decryptString, encryptString } from "./utilities";


class ExternalSecurity {
    // #isAuthenticated = false; //private class member
    #token = ''; //private class member
    #role = '';
    #user = {};

    authenticate ( token, user ) {
        if ( !token || !user ) throw "token or user not found";

        // const eToken = encryptString( token, tokenEncryptionKey );
        // const eUser = encryptString( user, userEncryptionKey );

        this.#token = token;
        this.#user = user;
    };

    getToken () {
        if ( !this.#token ) return;
        // return decryptString( this.#token, tokenEncryptionKey );
        return this.#token;
    };

    getUser () {
        if ( !this.#user ) return;
        // return decryptString( this.#user, userEncryptionKey );
        return this.#user;
    };

    logOut () {
        this.#user = {};
        this.#token = '';
        this.removeRole();
        window.location = "/ext/login"; //temp
    };

    isAuthenticated () {
        return this.#token ? true : false;
    };

    setRole ( role ) {
        if ( !role ) return;

        // const r = encryptString( role, roleEncryptionKey );
        this.#role = role;
    };

    removeRole () {
        this.#role = '';
    };

    getRole () {
        const r = this.#role;
        if ( !r ) return;

        // return decryptString( r, roleEncryptionKey );
        return r;
    };

}


export default new ExternalSecurity();