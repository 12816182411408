import { Table, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { setState } from "../../../helpers/utilities";
import { format, isToday, isYesterday } from "date-fns";
import { getExtConsultations } from "../../../helpers/api";
import { useQuery } from "react-query";
import extAuth from "../../../helpers/extAuth";
import { Alert } from "@mantine/core";
import { IconCheck, IconLoader } from "@tabler/icons";

const PortalHistory = () => {
  const [ consultations, setConsultations ] = useState( [] );
  const [ filteredData, setFilteredData ] = useState( [] );

  const { Search } = Input;
  const { Option } = Select;

  const { isFetching, refetch } = useQuery( {
    queryFn: () => getExtConsultations(),
    queryKey: [ `${ extAuth.getUser().role }-consultations` ],
    onSuccess: data => {
      setConsultations( data );
      setFilteredData( data );
    }
  } );


  // const fetchConsultations = () => {
  //   getExtConsultations().then( res => {
  //     if ( res === 200 ) {
  //       setConsultations( res.data.data );
  //       setFilteredData( res.data.data );
  //     }
  //   } );

  // };

  // useEffect( () => {
  //   fetchConsultations();
  // }, [] );

  // sales table
  const LabsColumns = [
    {
      title: "Status",
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => r.laboratories[ 0 ].isComplete ?
        <Alert title="done" color="green" icon={ <IconCheck /> } className='p-1 mb-0' /> :
        <Alert title="waiting..." color="orange" icon={ <IconLoader /> } className='p-1 mb-0' />,
    },
    {
      title: "Date",
      sorter: ( a, b ) => new Date( a.createdAt ) - new Date( b.createdAt ),
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        isToday( new Date( record.createdAt ) ) ? "Today " :
          isYesterday( new Date( record.createdAt ) ) ? "Yesterday " :
            format( new Date( record.createdAt ), "MMM dd, YYY" ),
    },
    {
      title: "code",
      sorter: ( a, b ) => a.code > b.code,
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => r?.code //r.laboratories[ 0 ].isComplete ?
      // r.code : "..."
    },
    {
      title: "Patient",
      sorter: ( a, b ) => a.patient.firstName > b.patient.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        `${ record.patient.firstName } ${ record.patient.lastName } `,
    },
    {
      title: "Type",
      sorter: ( a, b ) => a.type > b.type,
      dataIndex: "type",
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Lab Request",
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => r.laboratories[ 0 ].requestType,
    },
    // {
    //   title: "Action",
    //   sortDirections: [ "descend", "ascend" ],
    //   render: ( t, r, i ) =>
    //     r.laboratories[ 0 ].isComplete &&
    //     <button className="app-btn btn-text">open</button>
    //   ,
    // },
  ];


  const PharmaColumns = [
    // {
    //   title: "Status",
    //   sortDirections: [ "descend", "ascend" ],
    //   render: ( t, r, i ) => r.isComplete ?
    //     <Alert title="done" color="green" icon={ <IconCheck /> } className='p-1 mb-0' /> :
    //     <Alert title="waiting..." color="orange" icon={ <IconLoader /> } className='p-1 mb-0' />,
    // },
    {
      title: "Date",
      sorter: ( a, b ) => new Date( a.createdAt ) - new Date( b.createdAt ),
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        isToday( new Date( record.createdAt ) ) ? "Today " :
          isYesterday( new Date( record.createdAt ) ) ? "Yesterday " :
            format( new Date( record.createdAt ), "MMM dd, YYY" ),
    },
    {
      title: "code",
      sorter: ( a, b ) => a.code > b.code,
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => r?.code
      // r.isComplete ?
      // r.code : "..."
      //TODO: FIGURE OUT A WAY TO INDIVIDUALLY IDENTIFY WHEN A PHARMACY IS DONE
      // PROVIDING STATED PRESCRIPTIONS
      // FOR NOW, THE CODE IS HIDDEN UNTIL THE ENTIRE CONSULTATION IS SET TO COMPLETE
      // SAME WITH STATUE ABOVE...
    },
    {
      title: "Patient",
      sorter: ( a, b ) => a.patient.firstName > b.patient.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        `${ record.patient.firstName } ${ record.patient.lastName } `,
    },
    {
      title: "Type",
      dataIndex: "type",
      sortDirections: [ "descend", "ascend" ],
    },
    // {
    //   title: "Action",
    //   sortDirections: [ "descend", "ascend" ],
    //   render: ( t, r, i ) =>
    //     r.pharmacies[ 0 ].isComplete &&
    //     <button className="app-btn btn-text">open</button>
    //   ,
    // },
  ];



  return (
    <section>
      <>
        <p>
          <strong>{ filteredData?.length }</strong>{ " " }
          { filteredData?.length > 1 ? " consultations" : "consultation" }
        </p>

        <div className="my-1 d-flex justify-content-between">
          <Search
            onChange={ ( e ) =>
              setFilteredData(
                consultations?.filter(
                  ( p ) =>
                    p.code
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    p.type
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    p.patient.firstName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    p.patient.lastName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() )
                )
              )
            }
            title="search by code, type or patient"
            placeholder="search by code, type or patient"
          />

          <Select
            style={ { minWidth: "150px" } }
            name="call type"
            className="mx-2"
            onChange={ ( v ) =>
              setFilteredData( consultations?.filter( ( d ) => d.type === v ) )
            }
            defaultValue="consultation type"
          >
            { [ ...new Set( consultations?.map( ( d ) => d.type ) ) ].map( ( v ) => (
              <Option value={ v } key={ v }>
                { v }
              </Option>
            ) ) }
          </Select>

          <div className="d-flex">
            <button
              onClick={ () => setState( setFilteredData, consultations ) }
              className="button h6 app-btn">
              <span className="bi bi-arrow-clockwise me-2"></span>
              Reset
            </button>
            <button
              onClick={ refetch }
              className="button h6 app-btn">
              <span className="bi bi-progress me-2"></span>
              Refresh
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <Table
              className="border"
              rowKey={ ( record ) => record.id }
              dataSource={ filteredData }
              columns={ extAuth.getRole() === "laboratory" ? LabsColumns : PharmaColumns }
              loading={ isFetching }
              bordered
            />
          </div>
        </div>
      </>
    </section>
  );
};

export default PortalHistory;
