import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";

// styles
import "bulma/css/bulma.css"; //styling removes basic styles for headers etc
import "./static/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "antd/dist/antd.css";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { putLogOut } from "./helpers/api";

import posthog from 'posthog-js';
import * as Sentry from "@sentry/react";

// initializations
const queryClient = new QueryClient();
posthog.init( process.env.REACT_APP_POSTHOG_KEY,
	{
		api_host: 'https://us.i.posthog.com',
		person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
	}
);

// TODO: review and choose between sentryio n posthog
Sentry.init( {
	dsn: process.env.REACT_APP_SENTRY_DNS,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration( {
			maskAllInputs: false,
			maskAllText: false,
			blockAllMedia: false,
		} ),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	tracePropagationTargets: [ "localhost", process.env.REACT_APP_URL, process.env.REACT_APP_CLIENT_URL ],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
} );


// app
function App () {
	window.onclose = () => putLogOut();

	return (
		<BrowserRouter>
			<QueryClientProvider client={ queryClient }>
				<AppRoutes />
				<ReactQueryDevtools initialIsOpen={ false } />
			</QueryClientProvider>
		</BrowserRouter>
	);
};

export default App;
