import { Avatar, Divider, Tabs, Tag } from "antd";
import { PageHeader } from "../components";
import { Paper } from "@mantine/core";
import { getUser } from "../helpers/auth";
import { useQuery } from "react-query";
import { getUserProfile } from "../helpers/api";
import { AttendanceComponent } from "./attendance";


const ProfilePage = ( props ) => {

  const { data: user = {} } = useQuery( {
    queryFn: () => getUserProfile( getUser().id ),
    queryKey: [ 'profile' ],
  } );


  return (
    <section className="user-profile">
      <PageHeader title="Profile" hasBackButton description="your profile">
        {/* <button class="button app-btn btn-text">Edit</button> */ }
      </PageHeader>
      <Divider className="mt-1" />
      <div className="row mt-4">
        <div className="col-md-8 col-12 mx-auto p-4  text-center">
          <Paper className="p-4">
            <div>
              <Avatar className="user-avatar" size="large" />
            </div>
            <h5 className="mt-3 mb-0">{ `${ user?.staff?.firstName } ${ user?.staff?.lastName }` }</h5>
            <Tag className="m-0">{ user?.role }</Tag>
            <div></div>
            {/* <Tag color="blue" className="me-0 d-inline-block mt-3 p-1">
              specialization
            </Tag> */}

            <p className="mt-3">
              { `${ user?.email?.slice( 0, 1 ) }${ "*".repeat( user?.email?.length / 3 ) + user?.email?.slice( user?.email?.length / 2 ) }` }
              {/* <button className="ms-2 button is-small btn-text">
                <IconPencil />
              </button> */}
            </p>

            <AttendanceComponent />

            <div className="mx-auto has-addons">
              {/* <button className="button" disabled><strong className="ms-1">{ user?.consultations?.length || 0 } Consultations</strong></button> */ }
              {/* <button className="button" disabled><strong>{ user?.calls?.length }</strong>: Calls Received</button> */ }
            </div>

            {/* <Tabs defaultActiveKey="1" centered className="mt-5">
              <TabPane tab="Call History" key="1">
                <div>Calls</div>
              </TabPane>
              <TabPane tab="Consultation History" key="2">
                <div>Consultations</div>
              </TabPane>
            </Tabs> */}
          </Paper>
        </div>
      </div>
    </section>
  );
};

export { ProfilePage };
