import { useMutation, useQuery } from "react-query";
import { closeBranch, getFacilityBranches, putMakeHQ } from "../../helpers/api";
import { getUser, resouces } from "../../helpers/auth";
import { DetailTile } from "../../components";
import { hasPermission } from "../../helpers/utilities";
import { Loader, Modal } from "@mantine/core";
import _ from "lodash";
import { Divider, message } from "antd";
import smallTalk from 'smalltalk';
import { useState } from "react";
import NewBranchForm from './components/new-branch';
import EditBranchForm from './components/edit-branch';

const BranchesPage = ( props ) => {

    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null
    } );


    const { data, isFetching, refetch } = useQuery( {
        queryFn: () => getFacilityBranches( getUser().facility.id ),
        queryKey: [ 'branches' ],
    } );


    const { mutateAsync: makeHQ, isLoading: makingHQ } = useMutation( ( id ) => putMakeHQ( id ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                refetch();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );


    const { mutateAsync: closeDown, isLoading: closingDown } = useMutation( ( id ) => closeBranch( id ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 204 ) {
                message.success( data.data.message );
                refetch();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );


    const handleCloseDown = ( id ) => {
        smallTalk.confirm( "Close Branch?", "Closing a branch cannot be reversed. Do you wish to continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        } ).then( () => closeDown( id ) );
    };

    // ${ isFetching && 'is-loading'; }

    return (
        <section>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                zIndex={ 50 }
            >
                { modal.content }
            </Modal>

            {/* <Divider /> */ }
            <button
                className={ `button app-btn btn-prim mb-3` }
                onClick={ () => setModal( {
                    isOpen: true,
                    title: 'Add Branch',
                    content: <NewBranchForm onUpdate={ refetch } />,

                } ) }
            >
                <span className="bi bi-plus-circle me-2"></span>
                Add
            </button>
            {
                isFetching && <div className="mt-3"><Loader /> Fetching branches ...</div>
            }
            {
                data?.map( br => {
                    return <div className="row align-items-center border hover-hand mx-1" key={ br.id }>
                        <div className="col-7">
                            <DetailTile
                                className="py-4"
                                firstCol="col-md-4"
                                secondCol="col-md-7"
                                title={ br.branchName }
                                detail={
                                    <>
                                        { br.isMain && <kbd>HQ</kbd> } { br.location }
                                    </>
                                }
                                icon="shop" />
                        </div>
                        <div className="col-5">
                            <button
                                className="button is-ghost"
                                onClick={ () => setModal( {
                                    isOpen: true,
                                    title: 'View Branch',
                                    content: <EditBranchForm id={ br.id } onUpdate={ refetch } />
                                } ) }
                            >
                                <span className="bi bi-eye me-2"></span>
                                view
                            </button>
                            <button
                                onClick={ () => handleCloseDown( br.id ) }
                                className={ `button is-ghost text-danger ${ closingDown && ' is-loading' }` }
                            >
                                <span className="bi bi-power me-2"></span>
                                close down
                            </button>
                            {
                                !br.isMain && <button
                                    onClick={ () => makeHQ( br.id ) }
                                    className={ `button bokx-btn ${ makingHQ && ' is-loading' }` }
                                >
                                    <span span className="bi bi-shop me-2"></span>
                                    Make HQ
                                </button>
                            }
                        </div>
                    </div>;
                } )
            }
        </section >
    );
};

export { BranchesPage };;