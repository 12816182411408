import { Select, Tabs, message } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  CharmBar,
  IndicatorInfo,
  RequiredIndicator,
} from "../../../components";
import {
  getAllInsuranceProviders,
  postNewPatient,
} from "../../../helpers/api";
import { Regions } from "../../../helpers/config";
import { dropDownSet } from "../../../helpers/utilities";
import _ from "lodash";


const dropTemplates = {
  insuranceProviderId: null,
  insuranceTypeId: null,
  region: "",
  gender: "",
};

const NewPatientForm = ( { onSuccess, onError, onExit, showCharm = true, showBottomButtons = false } ) => {
  const { handleSubmit, register, reset } = useForm();
  const [ dropValue, setDropValues ] = useState( dropTemplates );

  const { data: insuranceProviders } = useQuery( {
    queryFn: getAllInsuranceProviders,
    queryKey: [ 'insurances' ],
  } );

  const { Option } = Select;
  const { TabPane } = Tabs;

  const { mutateAsync: createPatient, isLoading } = useMutation( ( data ) => postNewPatient( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onSuccess();
        setDropValues( dropTemplates );
        reset();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error?.response?.data?.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
  } );

  const handleCreate = async ( data ) => {
    createPatient( { ...data, ...dropValue } );
  };

  const handleError = ( err ) => {
    message.error( 'error saving patient. check required fields' );
  };

  return (
    <div>
      <form onSubmit={ handleSubmit( handleCreate, handleError ) }>
        { showCharm && (
          <CharmBar>
            <button className={ `button app-btn btn-prim mx-2 ${ isLoading && ' is-loading' }` } type="submit">
              Save
            </button>
            <button className="button app-btn" onClick={ onExit }>
              Cancel
            </button>
          </CharmBar>
        ) }

        <IndicatorInfo />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Bio Data" key="1">
            {/* <p>image uploader here</p> */ }
            <div className="row">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="firstName">
                  First Name
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="text"
                  autoFocus
                  { ...register( "firstName", { required: true } ) }
                  id="firstName"
                  placeholder="patient's first name"
                />
                {/* <small>patient's first name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="lastName">
                  Last Name
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "lastName", { required: true } ) }
                  id="lastName"
                  placeholder="patient's last name"
                />
                {/* <small>patient's last name</small> */ }
              </div>

              {/* <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="nhisNumber">
                  NHIS Number
                </label>
                <input
                  className="input"
                  type="text"
                  {...register("nhisNumber")}
                  id="nhisNumber"
                  placeholder="Health Insurance number"
                />
               
              </div> */}
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="gender">
                  Gender
                  <RequiredIndicator />
                </label>
                <Select
                  required
                  value={ dropValue.gender }
                  allowClear
                  showSearch
                  onChange={ ( v ) =>
                    dropDownSet( "gender", v, dropValue, setDropValues )
                  }
                  size="large"
                  id="gender"
                  className="d-block"
                  placeholder="gender"
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="lastName">
                  Date of Birth
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="date"
                  { ...register( "dob", { required: true } ) }
                  id="dob"
                  placeholder="date of birth"
                />
                {/* <small>patient's last name</small> */ }
              </div>

              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="insuranceProviderId">
                  Insurance Provider
                </label>
                <Select
                  name="insuranceProviderId"
                  allowClear
                  showSearch
                  value={ dropValue.insuranceProviderId }
                  onChange={ ( v ) =>
                    dropDownSet(
                      "insuranceProviderId",
                      v,
                      dropValue,
                      setDropValues
                    )
                  }
                  size="large"
                  id="insuranceProviderId"
                  className="d-block"
                  placeholder="select insurance provider"
                >
                  { insuranceProviders && insuranceProviders.map( ( ip ) => (
                    <Option value={ ip.id } key={ ip.id }>
                      { ip.providerName }
                    </Option>
                  ) ) }
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="ghCardNumber">
                  Insurance Number
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "insuranceNumber" ) }
                  id="insuranceNumber"
                  placeholder="patient's insurance number"
                />
                {/* <small>patient's last name</small> */ }
              </div>
            </div>
          </TabPane>
          <TabPane tab="Contact & Address" key="2">
            <div className="row">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="contact">
                  Phone Number
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="tel"
                  { ...register( "contact", { required: true } ) }
                  id="contact"
                  placeholder="patient's contact number"
                />
                {/* <small>patient' last name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="email">
                  Email
                </label>
                <input
                  className="input"
                  type="email"
                  { ...register( "email" ) }
                  id="email"
                  placeholder="patient's email address"
                />
                {/* <small>patient' last name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="gpsAddress">
                  GPS Address
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "gpsAddress" ) }
                  id="gpsAddress"
                  placeholder="patient's GPS address"
                />
                {/* <small>patient' last name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="region">
                  Region
                  {/* <RequiredIndicator /> */ }
                </label>
                <Select
                  value={ dropValue.region }
                  // required
                  onChange={ ( v ) =>
                    dropDownSet( "region", v, dropValue, setDropValues )
                  }
                  name="region"
                  showSearch
                  allowClear
                  size="large"
                  id="region"
                  className="d-block"
                  placeholder="region"
                >
                  { Regions }
                </Select>
              </div>

              <div className="field col-12">
                <label className="mb-0" htmlFor="location">
                  Location
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "location" ) }
                  id="location"
                  placeholder="patient's location"
                />
                {/* <small>patient' last name</small> */ }
              </div>
            </div>
          </TabPane>
        </Tabs>
        { showBottomButtons
          &&
          <div className="row px-2">
            <button
              type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
              <span className="bi bi-save me-2"></span>
              Save
            </button>
          </div>
        }
      </form>
    </div>
  );
};

export default NewPatientForm;
