import AddDiagnosisComponent from './add-diagnosis';

const ConsultationDiagnosisComponent = ( { diagnosis, allDiagnosis, onUpdate, onDelete } ) => {
    // allDiagnosis  is the list of diagnosis pulled from the server or ICEDE

    return (
        <div>
            {
                diagnosis.length > 0 && <>
                    <div className="row">
                        <div className="col-5" ><strong>Working Diagnosis</strong></div>
                    </div>
                </>
            }
            {
                diagnosis.length > 0 && diagnosis.map( diag =>
                    <div className="my-1" key={ diag.recordId }>
                        <AddDiagnosisComponent data={ diag } allDiagnosis={ allDiagnosis } onUpdate={ onUpdate } onDelete={ onDelete } />
                    </div>
                )
            }
        </div>
    );
};

export default ConsultationDiagnosisComponent;
