import { useForm } from "react-hook-form";
import { postNewStaffGroup } from "../../../helpers/api";
import { useMutation } from "react-query";
import { message } from "antd";
// import saveAction from "../../../helpers/state";
// import { SaveButtonActions } from "../../../helpers/config";
import { RequiredIndicator } from "../../../components";

const NewStaffGroup = ( { onSuccess } ) => {
  const { handleSubmit, register, reset } = useForm();

  const createStaffGroup = useMutation( ( data ) => postNewStaffGroup( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onSuccess();
        reset();
        return;
      }

      throw Error( data );
    },
    onError: ( error, variables, context ) => {
      message.error(
        error.response.data.message || "Something went wrong. Contact Admin"
      );
    },
  } );

  const handleCreate = async ( data ) => {
    createStaffGroup.mutateAsync( data );
  };

  // event
  // saveAction.on( SaveButtonActions.ADD_STAFF_GROUP, () => {
  //   document.getElementById( "submit" ).click();
  // } );

  return (
    <div>
      <form onSubmit={ handleSubmit( handleCreate ) }>
        <div className="field">
          <label className="mb-0" htmlFor="title">
            Title
            <RequiredIndicator />
          </label>
          <input
            className="input"
            type="text"
            autoFocus
            { ...register( "title", { required: true } ) }
            id="title"
            placeholder="enter staff group title"
          />
        </div>
        <button hidden type="submit" id="submit">
          submit
        </button>
      </form>
    </div>
  );
};

export default NewStaffGroup;
