import { Divider } from "antd";
import { PageHeader, Tile } from "../../components";
import { primaryNav } from "../../helpers/config";

const FacilityIndex = ( props ) => {
    const facNav = primaryNav.filter( nav => nav.name === 'Facility' )[ 0 ];

    return (
        <section>
            <PageHeader title="Facility" description="Manage your facility here" hasBackButton />
            <Divider className="mt-2" />

            <div className="row py-5">
                { facNav.children.map( nav =>
                    <div className="mb-3 col-md-3 col-6" key={ nav.name }>
                        <Tile isAction title={ nav.name } icon={ nav.icon } url={ nav.url } />
                    </div>
                ) }
            </div>
        </section>
    );
};

export { FacilityIndex };