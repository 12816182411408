import { Badge } from "@mantine/core";
import { calcBMI } from "../../../helpers/utilities";
import { Divider } from "antd";

const ViewVitals = ( { vitals } ) => {

    return (
        <div className="row">
            <div className="col-9">
                <div className="row">
                    <div className="col-6">
                        Temperature: <strong>{ vitals?.temperature }</strong>
                        <span className="text-muted ms-1">℃</span>
                    </div>
                    <div className="col-6">
                        BP: <strong>{ vitals?.bloodPressure }</strong>
                        <span className="text-muted ms-1">mmHg</span>
                    </div>
                    <div className="col-6">
                        Pulse:
                        <strong>{ vitals?.pulse }</strong>
                        <span className="text-muted ms-1">bpm</span>
                    </div>
                    <div className="col-6">
                        Weight: <strong>{ vitals?.weight }</strong>
                        <span className="text-muted ms-1">kg</span>
                    </div>
                    <div className="col-6">
                        Height: <strong>{ vitals?.height }</strong>
                        <span className="text-muted ms-1">m</span>
                    </div>
                    <div className="col-6">
                        Oxygen: <strong>{ vitals?.oxygenSaturation }</strong>
                    </div>
                    <div className="col-6">
                        Respiration: <strong>{ vitals?.respiration }</strong>
                        <span className="text-muted ms-1">cpm</span>
                    </div>
                    <div className="col-6">
                        RBS/FBS: <strong>{ vitals?.rbs }</strong>
                        <span className="text-muted ms-1"></span>
                    </div>
                    <div className="col-6">
                        Pain: <strong>{ vitals?.pain }</strong>
                    </div>
                </div>

            </div>
            <div className="col-3">
                <Badge variant="filled" color="gray">BMI</Badge>
                <p className="h3 mt-0">
                    { calcBMI( vitals.weight, vitals.height ) || 0 }
                </p>
            </div>
            {
                vitals.comments &&
                <div className="row">
                    <div className="col-12">
                        <Divider />
                        <strong>Notes</strong>: { vitals?.comments }
                    </div>
                </div>
            }
        </div>
    );
};


export default ViewVitals;
