import { Table, message, } from "antd";

import _ from "lodash";
import { useState } from "react";
import { deleteFacilityService, getAllServices, } from "../../helpers/api";
import { Chip, Modal, Select } from "@mantine/core";
import { action, resouces } from "../../helpers/auth";
import smallTalk from 'smalltalk';
import { hasPermission } from "../../helpers/utilities";
import { useQuery } from "react-query";
import NewServiceForm from "./components/new-service";
import EditServiceForm from "./components/edit-service";

const ServicesPage = () => {

    // states
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        // zIndex
    } );

    // queries
    const { data: services, isFetching, refetch } = useQuery( {
        queryFn: getAllServices,
        queryKey: [ 'services' ],
        onSuccess: data => setFilteredData( data )
    } );

    // handlers
    const handleReset = () => setFilteredData( services );

    const handleDelete = ( id ) => {

        smallTalk.confirm( "Delete Service", "This action is irreversible. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        } ).then( () =>
            deleteFacilityService( id )
                .then( res => {
                    message.success( 'done!' );
                    refetch();
                } )
                .catch( ex => message.error( 'error deleting service' ) ) );

    };


    // consultation table
    const columns = [

        {
            title: "Code",
            width: 100,
            sorter: ( a, b ) => a?.serviceCode > b?.serviceCode,
            dataIndex: 'serviceCode',
            sortDirections: [ "descend", "ascend" ],

        },
        {
            title: "Service Name",
            sorter: ( a, b ) => a?.serviceName > b?.serviceName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                <span>
                    { record?.serviceName }
                    { !record.isActive && <span className="bi bi-exclamation-circle-fill ms-2 text-danger" /> }
                    { !record.isOnline && <span className="bi bi-wifi-off ms-2 text-warning" /> }
                </span>,
        },
        {
            title: "Base Rate",
            sorter: ( a, b ) => a?.baseRate > b?.baseRate,
            sortDirections: [ "descend", "ascend" ],
            width: 150,
            render: ( text, record, index ) => record?.baseRate
        },
        {
            title: "Category",
            width: 170,
            sorter: ( a, b ) => a?.category?.title > b?.category?.title,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => record?.category?.title,
        },
        {
            title: "Booking",
            width: 100,
            sorter: ( a, b ) => a?.isOnline > b?.isOnline,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => record?.isOnline ? 'YES' : 'NO'
        },
        {
            title: "...",
            width: 170,
            render: ( text, record, index ) => (
                <>
                    <button className="button app-btn is-ghost m-0 py-0" onClick={ () => setModal( {
                        title: 'Edit Service',
                        content: <EditServiceForm onSuccess={ refetch } id={ record.id } />,
                        isOpen: true
                    } ) }>
                        <span className="bi bi-eye me-1"></span>
                        View
                    </button>
                    <button className="button app-btn is-light text-danger pֱx-1 is-ghost my-0" onClick={ () => {
                        handleDelete( record.id );
                    } }>
                        <span className="bi bi-trash me-1"></span>
                    </button>
                </>
            ),
        },
    ];


    return (
        <>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                zIndex={ 50 }
            >
                { modal.content }
            </Modal>
            <div className="d-flex justify-content-between">
                <div className="d-flex mb-3">
                    <button
                        className={ `button app-btn btn-prim me-3` }
                        onClick={ () => setModal( {
                            title: 'Add Service',
                            content: <NewServiceForm onSuccess={ refetch } />,
                            isOpen: true
                        } ) }
                    >
                        <span className="bi bi-plus-circle me-2"></span>
                        Add
                    </button>
                    <Chip><strong>{ filteredData.length || 0 }</strong> Services</Chip>
                </div>

                <div div className="d-flex" >
                    <button
                        onClick={ handleReset }
                        className="button app-btn btn-text ms-2 d-flex align-items-center"
                    >
                        <span className="bi bi-arrow-clockwise me-2"></span>
                        <span className="d-none d-md-inline"> Reset filters</span>
                    </button>
                    <button className="button app-btn"
                        onClick={ () => refetch() }
                    >
                        <span className="bi bi-cycle"></span>
                        <span className="d-none d-md-inline">Refresh</span>
                    </button>
                </div>
            </div>
            <div className="row my-2">
                {/* <div className="col my-3 d-flex justify-content-between"> */ }
                <div className="col-md-5 col-6 field">
                    <input
                        className="input"
                        onChange={ ( e ) =>
                            setFilteredData(
                                services?.filter(
                                    ( s ) =>
                                        s?.serviceName
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() ) ||
                                        s?.category.title
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() ) ||
                                        s?.baseRate.toString()
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() )
                                )
                            )
                        }
                        // style={ { minWidth: "30vw" } }
                        title="search here"
                        placeholder="search by service name, rate or category"
                    />
                </div>
                {/* TODO: Fix this */ }
                {/* <div className="col-md-3 col-6 field">
                    <Select
                        // style={ { width: "150px" } }
                        clearable
                        searchable
                        name="byCategory"
                        onChange={ ( v ) =>
                            !v ? setFilteredData( services ) :
                                setFilteredData(
                                    services?.filter( ( d ) => d.category.id === v )
                                )
                        }
                        defaultValue="service category"
                        placeholder="filter by category"
                        data={
                            // [ ...new Set( services?.map( ( d ) => d.category.title ) ) ]
                            [
                                ...new Set( services?.map(
                                    ( cat => {
                                        return {
                                            value: cat.category.id,
                                            label: cat.category.title
                                        };
                                    } ) ) )
                            ]
                        }
                    />
                </div> */}
            </div >
            <div className="row">
                <div className="col-12">
                    <Table
                        columns={
                            columns
                        }
                        // bordered
                        className="border"
                        dataSource={ filteredData }
                        rowKey={ ( record ) => record.id }
                        loading={ isFetching }
                    />
                </div>
            </div >
        </>
    );
};

export { ServicesPage };;
