import { Divider, message } from "antd";
import { CharmBar, PageHeader } from "../../components";
import { useMutation, useQuery } from "react-query";
import { getFacilityById, putFacility } from "../../helpers/api";
import _ from "lodash";
import { useState } from "react";
import { getUser } from "../../helpers/auth";
import { Kbd, Loader } from "@mantine/core";
import smallTalk from 'smalltalk';

const FacilityPage = ( props ) => {
    const [ state, setState ] = useState( {} );
    const user = getUser();

    const { isFetching } = useQuery( {
        queryFn: () => getFacilityById( user.facility.id ),
        queryKey: [ 'clinic', user.facility.id ],
        onSuccess: data => setState( data )
    } );

    const { mutateAsync: updateClinic, isLoading, reset: qryReset } = useMutation( ( data ) => putFacility( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            qryReset();
            const err = error.response.data.message;

            if ( _.isArray( error.response.data.message ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    const updateDetails = () => {
        smallTalk.confirm(
            "Update Details", "This will update your facility information. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            updateClinic( state );
        } ).catch( ex => {
            return false;
        } );
    };


    return (
        <section>
            {/* <Divider className="mt-2" /> */ }
            {
                ( !isFetching && _.isEmpty( state ) ) ?
                    <Kbd>Nothing to show </Kbd> :
                    isFetching ?
                        <div>
                            <Loader />
                            <span className="ms-2">Fetching data...</span>
                        </div>
                        :
                        <section>
                            {/* <CharmBar> */ }
                            <button
                                className={ `button app-btn btn-prim mb-3 ${ isLoading && 'is-loading' }` }
                                onClick={ updateDetails }
                            >
                                <span className="bi bi-check-all me-2"></span>
                                Update
                            </button>
                            {/* </CharmBar> */ }
                            <div className="row">
                                <div className="col-3 text-bold">Facility Name</div>
                                <div className="col-9">
                                    <input
                                        value={ state?.facilityName }
                                        onChange={ ( e ) => { setState( { ...state, facilityName: e.target.value } ); } }
                                        className="input w-100"
                                    />
                                </div>
                            </div>
                            <Divider />
                            <div className="row">
                                <div className="col-3 text-bold">Contact</div>
                                <div className="col-9">
                                    <input
                                        value={ state?.contact }
                                        className="input w-100"
                                        maxLength={ 15 }
                                        minLength={ 10 }
                                        onChange={ ( e ) => { setState( { ...state, contact: e.target.value } ); } }
                                    />
                                </div>
                            </div>
                            <Divider />
                            <div className="row">
                                <div className="col-3 text-bold">Registration Number</div>
                                <div className="col-9">
                                    <input
                                        value={ state?.registrationNumber }
                                        onChange={ ( e ) => { setState( { ...state, registrationNumber: e.target.value } ); } }
                                        className="input w-100"
                                    />
                                </div>
                            </div>
                        </section>
            }
        </section >
    );
};

export { FacilityPage };