import { message, Select, Tabs } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getPharmacy, putUpdatePharmacy } from "../../../helpers/api";
import { Regions } from "../../../helpers/config";
import { IndicatorInfo, RequiredIndicator } from "../../../components";
import { Loader } from "@mantine/core";
import _ from "lodash";

const EditPharmacyForm = ( { pharmacyId, isEditable = true, onSuccess, onError } ) => {

  const [ pharm, setPharmacy ] = useState( {} );

  const { isError, isFetching } = useQuery( {
    queryFn: () => getPharmacy( pharmacyId ),
    queryKey: [ 'pharmacy', pharmacyId ],
    onSuccess: data =>
      setPharmacy( _.omit( data, [ 'consultations', 'totalConsultations', 'isDeleted', 'createdAt', 'updatedAt', 'userId' ] ) )
  } );

  const updateData = ( fieldName, data ) => {
    setPharmacy( { ...pharm, [ fieldName ]: data } );
  };

  const { mutateAsync: updatePharmacy, isLoading } = useMutation( ( data ) => putUpdatePharmacy( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onSuccess();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;

      if ( _.isArray( error.response.data.message ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      onError();
    },
  } );


  const { TabPane } = Tabs;

  return (

    <>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
            :
            <form onSubmit={ e => { e.preventDefault(); updatePharmacy( pharm ); } }>
              <IndicatorInfo className="p-0" />
              <div className="row p-2">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Basic" key="1">
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="pharmacyName">
                        Pharmacy Name
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        type="text"
                        value={ pharm.pharmacyName }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'pharmacyName', e.target.value ) }
                        autoFocus
                        id="pharmacyName"
                        placeholder="pharmacy name here"
                      />
                      <small>registered pharmacy name</small>
                    </div>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="contactPerson">
                        Contact Person
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        value={ pharm.contactPerson }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'contactPerson', e.target.value ) }
                        type="text"
                        id="contactPerson"
                        placeholder="name of person"
                      />
                      <small>who is the primary contact person</small>
                    </div>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="contact">
                        Contact
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        value={ pharm.contact }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'contact', e.target.value ) }
                        type="tel"
                        id="contact"
                        placeholder="contact here"
                      />
                      <small>primary contact of pharmacy</small>
                    </div>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="email">
                        Email
                      </label>
                      <input
                        value={ pharm.email }
                        className="input"
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'email', e.target.value ) }
                        type="email"
                        id="email"
                        placeholder="email here"
                      />
                      <small>email address of pharmacy</small>
                    </div>
                  </TabPane>
                  <TabPane tab="Location" key="2">
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="location">
                        Location
                      </label>
                      <input
                        className="input"
                        value={ pharm.location }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'location', e.target.value ) }
                        type="text"
                        id="location"
                        placeholder="pharmacy location here"
                      />
                      <small>physical location of pharmacy</small>
                    </div>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="region">
                        Region
                        <RequiredIndicator />
                      </label>
                      <Select
                        required
                        value={ pharm.region }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'region', e ) }
                        size="large"
                        id="region"
                        className="d-block"
                        placeholder="select region"
                      >
                        { Regions }
                      </Select>
                      <small>region where pharmacy is located</small>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              {
                isEditable && <button
                  type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                  Update Record
                </button>
              }
            </form>

      }
    </>
  );
};

export default EditPharmacyForm;
