import { Outlet, useNavigate, Link } from 'react-router-dom';
import TopNav, { DesktopNav, MobileNav } from './navigations';
import { MobileView, BrowserView } from 'react-device-detect';
import { useEffect } from 'react';
import { appLinks, constants } from '../../helpers/config';
import { isAuthenticated } from '../../helpers/auth';
import extAuth from "../../helpers/extAuth";
import { ActionButton, CopyRightTag } from '.';
import logo from '../../static/img/riviaos-logo.png';
import { message } from 'antd';


const AppLayout = ( props ) => {
    const nav = useNavigate();
    useEffect( () => {
        if ( !isAuthenticated() ) {
            nav( appLinks.login );
            return;
        }
    } );



    return (
        <>
            <section className='app-layout'>
                {/* <TopNav /> */ }
                {/* <ActionButton onClick={ () => message.success( 'clicked!' ) } /> */ }

                <div className="row px-md-2 px-0 content">
                    <div className="col-md-2 col-0">
                        <BrowserView>
                            <DesktopNav />
                        </BrowserView>
                    </div>

                    <div className="col-md-10 col-12">
                        <div className="layout-outlet">
                            {
                                isAuthenticated() ? <Outlet /> : <p>authenticate</p>
                            }
                        </div>
                    </div>
                </div>

                <MobileView>
                    <MobileNav />
                </MobileView>
            </section >
        </>
    );
};


const ExternalLayout = ( props ) => {
    const nav = useNavigate();

    useEffect( () => {
        if ( !extAuth.isAuthenticated() ) {
            nav( appLinks.exterals.login );
            return;
        }
    } );


    return (
        <div className='row portal'>
            <div className="mx-auto col-md-10 col-12">
                <div className="text-center py-3">
                    <img src={ logo } alt="rivia dokta logo" width={ 150 } />
                    {/* <kbd>{ constants.siteTitle }</kbd>
                    <Link to="#">Help</Link> */}
                </div>

                <div className="inner-layer">
                    <div className="d-flex justify-content-between p-2">
                        <h6>{ `${ extAuth.getUser()?.fullName } (${ extAuth.getUser()?.externalName })` }</h6>
                        <div>
                            <button className='button' onClick={ () => nav( appLinks.exterals.passwordReset ) }>Reset Password</button>
                            <button className='button is-danger is-light'
                                onClick={ () => extAuth.logOut() }>
                                Logout
                                <span className="bi bi-lock ms-2"></span>
                            </button>
                        </div>
                    </div>
                    <div className="content my-2">
                        {
                            extAuth.isAuthenticated() ? <Outlet /> : <p>authenticate</p>
                        }
                    </div >
                </div >
                <div className="row text-center">
                    <CopyRightTag />
                </div>
            </div >
        </div >
    );
};

export { AppLayout, ExternalLayout };


