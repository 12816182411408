import React from 'react';
import { useNavigate } from 'react-router-dom';

export const AccessDenied = () => {
    const nav = useNavigate();

    return (
        <div>
            <div className="mt-5 text-center p-5 bg-danger">
                <h1 className='text-white'>Access Denied!</h1>
                <button
                    className='button'
                    onClick={ () => nav( -1 ) }
                >Back</button>
            </div>
        </div>
    );
};
