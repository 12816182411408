import { Loader } from "@mantine/core";
import { message, Select } from "antd";
import _ from "lodash";
import { RequiredIndicator } from "../../../components";
import { clearConsultationAtom, consultationAtom } from "../../../helpers/state/consultation";
import { useQuery, useMutation } from 'react-query';
import { getConsultationOnly, putConsultationOnly } from "../../../helpers/api";
import { useAtom } from "jotai";

const EditConsultationComponent = ( { consultationId, onRefresh } ) => {
    const { Option } = Select;
    // const [ consultation, setConsultation ] = useState( {} );
    const [ consultation, setConsultation ] = useAtom( consultationAtom );
    const [ , clearConsultation ] = useAtom( clearConsultationAtom );

    const { isError, isFetching } = useQuery( {
        queryFn: () => getConsultationOnly( consultationId ),
        queryKey: [ 'consultation-only', consultationId ],
        onSuccess: data => setConsultation( data ),
        refetchOnWindowFocus: false,
    } );


    const { mutateAsync: updateConsultation, isLoading } = useMutation( ( data ) => putConsultationOnly( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <>
                            <div className="row mb-1">
                                {/* <div className="field">
                                    <label className="mb-0" htmlFor="createdAt">
                                        Consultation Date
                                    </label>
                                    <input
                                        value={ new Date( consultation.createdAt ).toISOString().slice( 0, 10 ) }
                                        type="date"
                                        className="input"
                                        onChange={ ( e ) => setConsultation( { ...consultation, createdAt: e.target.value } ) }
                                    />
                                </div> */}
                                <div className="field">
                                    <label className="mb-0" htmlFor="callType">
                                        Consultation Type
                                        <RequiredIndicator />
                                    </label>
                                    <Select
                                        required
                                        autoFocus
                                        size="large"
                                        value={ consultation.type }
                                        onChange={ ( v ) => setConsultation( { ...consultation, type: v } ) }
                                        id="type"
                                        className="d-block"
                                        placeholder="consultation type"
                                    >
                                        <Option value="Phone">Phone</Option>
                                        <Option value="Video">Video</Option>
                                        <Option value="Physical">Physical</Option>
                                    </Select>
                                </div>
                                <div className="field">
                                    <label className="mb-0" htmlFor="notes">
                                        Presenting Complaint
                                        <RequiredIndicator />
                                    </label>
                                    <textarea
                                        className="textarea"
                                        rows={ 3 }
                                        value={ consultation.complaint }
                                        onChange={ ( e ) => setConsultation( { ...consultation, complaint: e.target.value } ) }
                                        id="complaint"
                                        placeholder="presenting complain"
                                    ></textarea>
                                </div>
                                <div className="field">
                                    <label className="mb-0" htmlFor="number">
                                        Management Plan
                                        <RequiredIndicator />
                                    </label>
                                    <textarea
                                        className="textarea"
                                        id="advise"
                                        value={ consultation.doctorsAdvise }
                                        onChange={ ( e ) => setConsultation( { ...consultation, doctorsAdvise: e.target.value } ) }
                                        required
                                        rows={ 3 }
                                        placeholder="management plan (Doctor's Advice) here. Patient will see this"
                                    ></textarea>
                                </div>
                                <div className="field">
                                    <label className="mb-0" htmlFor="notes">
                                        Doctor's Notes
                                    </label>
                                    <textarea
                                        className="textarea"
                                        rows={ 3 }
                                        value={ consultation.notes }
                                        onChange={ ( e ) => setConsultation( { ...consultation, notes: e.target.value } ) }
                                        id="notes"
                                        placeholder="Clinical findings and comments. patient will not see this"
                                    ></textarea>
                                </div>
                                <div className="field">
                                    <label className="mb-0" htmlFor="number">
                                        Investigation
                                    </label>
                                    <textarea
                                        className="textarea"
                                        rows={ 3 }
                                        id="investigation"
                                        value={ consultation.investigation }
                                        onChange={ ( e ) => setConsultation( { ...consultation, investigation: e.target.value } ) }
                                        placeholder="investigation"
                                    ></textarea>
                                </div>
                            </div>

                            <button onClick={ () => updateConsultation( consultation ) }
                                id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                                Update Consultation
                            </button>
                        </>
            }
        </>
    );
};

export default EditConsultationComponent;