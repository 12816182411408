import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { RequiredIndicator } from "../../../components";
import { getAllInsuranceTypes, postNewInsurance } from "../../../helpers/api";
import { Regions } from "../../../helpers/config";
import { dropDownSet } from "../../../helpers/utilities";

const NewInsuranceProviderForm = ( { onSuccess, onError } ) => {
  const { handleSubmit, register, reset } = useForm();

  const dropTemp = {
    region: "",
    insuranceTypeId: null,
  };

  const [ dropValue, setDropValues ] = useState( dropTemp );

  // const { data: insuranceTypes } = useQuery( {
  //   queryFn: getAllInsuranceTypes,
  //   queryKey: [ 'insuranceTypes' ],
  // } );

  const { Option } = Select;

  const { mutateAsync, isLoading } = useMutation(
    ( data ) => postNewInsurance( data ),
    {
      onSuccess: ( data, variables, context ) => {
        if ( data.status === 201 ) {
          message.success( data.data.message );
          onSuccess();
          setDropValues( dropTemp );
          reset();
          return;
        }

        throw data;
      },
      onError: ( error, variables, context ) => {
        const err = error.response.data.message;

        if ( _.isArray( error.response.data.message ) ) {
          err.map( err =>
            message.error( err.message )
          );
        }
        else {
          message.error( err );
        }

        onError();
      },
    }
  );

  const handleCreate = async ( data ) => {
    mutateAsync( { ...data, ...dropValue } );
  };



  return (
    <form onSubmit={ handleSubmit( handleCreate ) }>
      <div className="row">
        <div className="field col-12">
          <label className="mb-0" htmlFor="providerName">
            Insurance Provider Name
            <RequiredIndicator />
          </label>
          <input
            className="input"
            type="text"
            autoFocus
            { ...register( "providerName", { required: true } ) }
            id="providerName"
            placeholder="provider name here"
          />
          <small>registered provider name</small>
        </div>

        {/* <div className="field col-12">
          <label className="mb-0" htmlFor="insuranceTypeId">
            Insurance Type
            <RequiredIndicator />

          </label>
          <Select
            name="insuranceTypeId"
            required
            value={ dropValue.insuranceTypeId }
            onChange={ ( v ) =>
              dropDownSet( "insuranceTypeId", v, dropValue, setDropValues )
            }
            size="large"
            id="insuranceTypeId"
            className="d-block"
            placeholder="select insurance type"
          >
            { insuranceTypes && insuranceTypes.map( ( it ) => (
              <Option value={ it.id } key={ it.id }>
                { it.title }
              </Option>
            ) ) }
          </Select>
        </div> */}
        <div className="field col-12">
          <label className="mb-0" htmlFor="contact">
            Contact
            <RequiredIndicator />

          </label>
          <input
            className="input"
            type="tel"
            { ...register( "contact", { required: true } ) }
            id="contact"
            placeholder="contact here"
          />
          <small>primary contact of provider</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="email">
            Email
            <RequiredIndicator />

          </label>
          <input
            className="input"
            type="email"
            { ...register( "email", { required: true } ) }
            id="email"
            placeholder="email here"
          />
          <small>email address of provider</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="location">
            Location Address
            <RequiredIndicator />

          </label>
          <input
            className="input"
            type="text"
            { ...register( "location", { required: true } ) }
            id="location"
            placeholder="provider location here"
          />
          <small>physical location of provider</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="region">
            Region
            <RequiredIndicator />

          </label>
          <Select
            value={ dropValue.region }
            required
            showSearch
            allowClear
            onChange={ ( v ) => dropDownSet( "region", v, dropValue, setDropValues ) }
            size="large"
            id="region"
            className="d-block"
            placeholder="select region"
          >
            { Regions }
          </Select>
          <small>region where provider is located</small>
        </div>
      </div>
      <div className="row px-2">
        <button
          type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
          <span className="bi bi-save me-2"></span>
          Save
        </button>
      </div>
    </form>
  );
};

export default NewInsuranceProviderForm;
