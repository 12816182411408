import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { format } from 'date-fns';
import _ from "lodash";
import { consultationChartDataProcessor, revenueChartDataProcessor } from "../helpers/utilities";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Consultations x Revenue Chart",
    },
  },
};


/**
 * 
 * @param {array} consultations list of consultations
 * @returns 
*/
export function ConsultationsChart ( { consultations, revenue, chartType = "bar" } ) {

  const { data } = consultationChartDataProcessor( consultations );
  const { data: revenueData } = revenueChartDataProcessor( revenue );

  const labels = data.map( d => format( new Date( d.date ), "EEE, MMM d" ) );

  const conValues = data.map( d => d.count );
  const revValues = revenueData.map( d => d.sum );

  const chartData = {
    labels,
    datasets: [
      {
        label: "Consultations",
        data: conValues,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Revenue",
        data: revValues,
        borderColor: "rgb(255, 65, 84)",
        backgroundColor: "rgba(255, 65, 84, 0.5)",
        tension: 0.2,
        borderDash: [ 3, 2 ],
      },
    ],
  };


  return chartType === 'bar' ?
    <Bar options={ options } data={ chartData } /> :
    <Line options={ options } data={ chartData } />;
  // <Chart type={ chartType } options={ options } data={ chartData } /> );

}
