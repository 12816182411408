import { message, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import EditInsuranceForm from './edit-insurance';
import smalltalk from 'smalltalk';
import { Modal } from "@mantine/core";
import { deleteInsurance } from "../../../helpers/api";
import { hasPermission } from "../../../helpers/utilities";
import { action, resouces } from "../../../helpers/auth";

const Insurance = ( { data, onReload, isloading } ) => {

    const [ modal, setModal ] = useState( {
        title: "",
        open: false,
        content: "",
    } );

    const handleDeleteInsurance = ( id ) => {
        smalltalk.confirm(
            "Delete Insurance", "This action cannot be undone. Delete Insurance?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            deleteInsurance( id ).then( res => {
                if ( res.status == 204 ) {
                    message.success( "insurance deleted" );
                    onReload();
                }
            } );

        } ).catch( ex => {
            // console.log(ex);
            return false;
        } );
    };

    useEffect( () => { }, [ data ] );

    return (
        <div>
            <Modal
                title={ modal.title }
                opened={ modal.open }
                onClose={ () => setModal( { ...modal, open: false } ) }
            >
                { modal.content }
            </Modal>
            <div className="row">
                <div className="col-12">
                    <Table
                        columns={ [
                            {
                                title: "#",
                                sorter: ( a, b ) => a.index > b.index,
                                sortDirections: [ "descend", "ascend" ],
                                render: ( text, record, index ) => ++index,
                                width: '70px'
                            },
                            {
                                title: "Provider",
                                dataIndex: 'providerName',
                                sorter: ( a, b ) => a.providerName > b.providerName,
                                sortDirections: [ "descend", "ascend" ],
                            },
                            // {
                            //     title: "Insurance Type",
                            //     dataIndex: [ 'insuranceType', 'title' ],
                            //     sorter: ( a, b ) => a.insuranceType.title > b.insuranceType.title,
                            //     sortDirections: [ "descend", "ascend" ],
                            // },
                            {
                                title: "Contact",
                                dataIndex: 'contact',
                                sorter: ( a, b ) => a.contact > b.contact,
                                sortDirections: [ "descend", "ascend" ],
                            },
                            {
                                title: "Region",
                                dataIndex: 'region',
                                sorter: ( a, b ) => a.region > b.region,
                                sortDirections: [ "descend", "ascend" ],
                            },
                            {
                                title: "Action",
                                render: ( text, record, index ) => (
                                    <Space>
                                        <div class="buttons has-addons">
                                            {
                                                hasPermission( resouces.insurances, action.canUpdate ) &&
                                                <Tooltip title="Edit Insurance">
                                                    <button
                                                        className="d-flex align-items-center button is-small"
                                                        onClick={ () => {
                                                            setModal( {
                                                                title: "Editing Insurance",
                                                                open: true,
                                                                content: <EditInsuranceForm insuranceId={ record.id } onSuccess={ onReload } />,
                                                            } );
                                                        } }>
                                                        <span className="bi bi-pencil"></span>
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                hasPermission( resouces.insurances, action.canDelete ) &&
                                                <Tooltip title="Delete Insurance">
                                                    <button className="button is-small" onClick={ () => handleDeleteInsurance( record.id ) }>
                                                        <span className="bi bi-trash"></span>
                                                    </button>
                                                </Tooltip>
                                            }
                                        </div>
                                    </Space>
                                ),
                            },

                        ] }
                        className="border"
                        dataSource={ data }
                        rowKey={ ( record ) => record.id }
                        loading={ isloading }
                        bordered
                    />
                </div>
            </div>
        </div>
    );
};

export default Insurance;