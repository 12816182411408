import { message } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { postFacilityBranch } from '../../../helpers/api';
import { getUser } from '../../../helpers/auth';
import _ from 'lodash';
import { RequiredIndicator } from '../../../components';
import smallTalk from 'smalltalk';

export default function NewBranchForm ( { onUpdate } ) {
    const { handleSubmit, register, reset } = useForm();

    const { mutateAsync, isLoading } = useMutation( ( data ) => postFacilityBranch( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                reset();
                onUpdate();
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;

            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );


    const postSubmit = ( data ) => {

        smallTalk.confirm( "Create Branch", "You are about to add a branch. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        } ).then( () => mutateAsync( data ) );
    };


    return (
        <form onSubmit={ handleSubmit( postSubmit ) }>
            <div className='field'>
                <label htmlFor="branchName">
                    Branch Name
                    <RequiredIndicator />
                </label>
                <input
                    type="text"
                    id='branchName'
                    autoFocus
                    { ...register( 'branchName', { required: true } ) }
                    className="input w-100"
                    placeholder='Outlet official name'
                />
            </div>
            <div className='field'>
                <label htmlFor="contact">
                    Contact
                    <RequiredIndicator />
                </label>
                <input
                    type="tel"
                    id='contact'
                    { ...register( 'contact', { required: true } ) }
                    className="input w-100"
                    placeholder='primary contact of branch'
                />
            </div>
            <div className='field'>
                <label htmlFor="location">
                    location
                    <RequiredIndicator />
                </label>
                <input
                    type="text"
                    id='location'
                    { ...register( 'location', { required: true } ) }
                    className="input w-100"
                    placeholder='location of branch'
                />
            </div>
            <div className='field'>
                <label htmlFor="email">
                    Email
                </label>
                <input
                    type="text"
                    id='email'
                    { ...register( 'email' ) }
                    className="input w-100"
                    placeholder='input branch email'
                />
            </div>
            {/* <div className='field'>
                <label htmlFor="receipt_abbreviation">
                    Receipt Abbreviation
                    <RequiredIndicator />
                </label>
                <input
                    type="text"
                    maxLength={ 3 }
                    id='receipt_abbreviation'
                    { ...register( 'receipt_abbreviation', { required: true } ) }
                    className="input w-100"
                    placeholder='3 unique characters for receipt number'
                />
            </div>
            <div className='field'>
                <label htmlFor="address">
                    Address
                </label>
                <input
                    type="text"
                    id='address'
                    { ...register( 'address' ) }
                    className="input w-100"
                    placeholder='Outlet physical address'
                />
            </div> */}

            <button className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading' }` } type='submit'>
                <span className="bi bi-save me-2"></span>
                Save
            </button>
        </form >
    );
}
