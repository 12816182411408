import { Tabs } from "antd";
import { PageHeader } from "../../components";
import { ExpensesPage } from "./components/expenses";
import { IncomeComponent } from "./components/income";
import { useQuery } from "react-query";
import { getAllExpenses } from "../../helpers/api";
import { Modal } from "@mantine/core";
import { useState } from "react";
import NewExpenseForm from "./components/new-expense";

const FinancePage = ( props ) => {
    const { TabPane } = Tabs;

    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null
    } );


    // expenses
    const { data: expenses = [], isFetching: expensesLoading, refetch: fetchExpenses } = useQuery( {
        queryFn: () => getAllExpenses(),
        queryKey: [ 'expenses' ],
    } );



    return (
        <section>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
            >
                { modal.content }
            </Modal>
            <PageHeader title="Finance"
                description="Manage your facility's cashflow here"
                hasBackButton>
                <button className="bokx-btn btn-prim"
                    onClick={ () => setModal( {
                        isOpen: true,
                        title: 'Add Expense',
                        content: <NewExpenseForm
                            onSuccess={ fetchExpenses }
                            showHeader={ false }
                            showFooter
                        />,
                    } ) }
                >
                    <span className="bi bi-plus-circle me-2 d-none d-md-inline"></span>
                    <strong className="text-white">ADD</strong>
                </button>
            </PageHeader>
            <Tabs defaultActiveKey="1">
                <TabPane tab={ <span> <i className="bi bi-currency-dollar me-2" />Expenses</span> } key="1">
                    <ExpensesPage
                        data={ expenses }
                        isLoading={ expensesLoading }
                        onReload={ fetchExpenses }
                    />
                </TabPane>
                <TabPane tab={ <span> <i className="bi bi-arrow-down me-2" />Income</span> } key="2">
                    {/* <IncomeComponent
                    // isLoading={ incomeLoading }
                    // data={ income }
                    /> */}
                    <kbd>Coming soon</kbd>
                </TabPane>
                {/* <TabPane tab={ <span> <i className="bi bi-bank me-2" />Capital</span> } key="3">
                    <kbd>Oh crap!</kbd>
                </TabPane> */}
            </Tabs>
        </section >
    );
};

export { FinancePage };