import { decryptString, encryptString } from "./utilities";
import {
  roleEncryptionKey,
  tokenEncryptionKey,
  userEncryptionKey,
} from "./config";
import _ from "lodash";
import { getRefreshToken } from "./api";

const authenticate = ( token, user ) => {
  if ( !token || !user ) throw "token or user not found";

  const eToken = encryptString( token, tokenEncryptionKey );
  const eUser = encryptString( JSON.stringify( user ), userEncryptionKey );

  sessionStorage.setItem( "apptkn", eToken ); //apptkn -> app token
  sessionStorage.setItem( "appusr", eUser ); //appusr -> app user
};


const getToken = () => {
  const token = sessionStorage.getItem( "apptkn" );
  if ( !token ) return;
  return decryptString( token, tokenEncryptionKey );
};

const getUser = () => {
  const user = sessionStorage.getItem( "appusr" );
  if ( !user ) return;
  // const userObj = JSON.parse(decryptString(user, userEncryptionKey));

  return JSON.parse( decryptString( user, userEncryptionKey ) );
};


const refreshToken = async () => {
  const { token, user } = await getRefreshToken();
  // logOut();
  authenticate( token, user );
};


const logOut = () => {
  sessionStorage.removeItem( "apptkn" );
  sessionStorage.removeItem( "appusr" );
  removeRole();
  // window.location = "/login"; //temp
};

const isAuthenticated = () => {
  const token = sessionStorage.getItem( "apptkn" );
  return token ? true : false;
};

const setRole = ( role ) => {
  if ( !role ) return;

  const r = encryptString( role, roleEncryptionKey );
  sessionStorage.setItem( "appRl", r );
  // appRl - app role
};

const removeRole = () => {
  sessionStorage.removeItem( "appRl" );
};

const getRole = () => {
  const r = sessionStorage.getItem( "appRl" );
  if ( !r ) return;

  return decryptString( r, roleEncryptionKey );
};

const isAdmin = ( userId ) => {
  return getUser()?.id === userId;
};

// ROLES & PERMISSIONS
const roles = {
  superuser: 'superuser',

  // staff roles
  admin: 'admin',
  doctor: 'doctor',
  nurse: 'nurse',
  frontdesk: 'frontdesk',

  // external roles
  partner: 'partner',
  pharmacy: 'pharmacy',
  laboratory: 'laboratory'
};


const resouces = {
  users: 'users',
  admin: 'admin',
  patients: 'patients',
  consultations: 'consultations',
  appointment: 'appointment',
  expense: 'expense',
  facility: 'facility',
  branch: 'branch',
  staffs: 'staffs',
  reports: 'reports',
  calls: 'calls',
  pharmacies: 'pharmacies',
  laboratories: 'laboratories',
  insurances: 'insurances',
  serviceProviders: 'serviceProviders',
};

const action = {
  canCreate: 'canCreate',
  canRead: 'canRead',
  canUpdate: 'canUpdate',
  canDelete: 'canDelete'
};


export {
  authenticate,
  isAuthenticated,
  getToken,
  logOut,
  setRole,
  getRole,
  removeRole,
  getUser,
  isAdmin,
  refreshToken,
  resouces,
  action,
  roles,
};
