import { Loader } from "@mantine/core";
import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { RequiredIndicator } from "../../../components";
import { getAllInsuranceTypes, getInsuranceProvider, putUpdateInsurance } from "../../../helpers/api";
import { Regions } from "../../../helpers/config";


const EditInsuranceForm = ( { insuranceId, isEditable = true, onSuccess, onError } ) => {
  const [ insur, setInsur ] = useState( {} );

  const { isError, isFetching } = useQuery( {
    queryFn: () => getInsuranceProvider( insuranceId ),
    queryKey: [ 'insurance', insuranceId ],
    onSuccess: data =>
      setInsur( data )
  } );

  // const { data: insuranceTypes = [] } = useQuery( {
  //   queryFn: getAllInsuranceTypes,
  //   queryKey: [ 'insuranceTypes' ],
  // } );


  const updateData = ( fieldName, data ) => {
    setInsur( { ...insur, [ fieldName ]: data } );
  };

  const { Option } = Select;

  const { mutateAsync: updateInsurance, isLoading } = useMutation(
    ( data ) => putUpdateInsurance( data ),
    {
      onSuccess: ( data, variables, context ) => {
        if ( data.status === 200 ) {
          message.success( data.data.message );
          onSuccess();
          return;
        }

        throw data;
      },
      onError: ( error, variables, context ) => {
        const err = error.response.data.message;

        if ( _.isArray( error.response.data.message ) ) {
          err.map( err =>
            message.error( err.message )
          );
        }
        else {
          message.error( err );
        }

        onError();
      },
    }
  );




  return (
    <div>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
            :
            <form onSubmit={ e => { e.preventDefault(); updateInsurance( insur ); } }>
              <div className="row">
                {/* <small>all fields are required. seek intensive medical care</small> */ }
                <div className="field col-12">
                  <label className="mb-0" htmlFor="providerName">
                    Insurance Provider Name
                    <RequiredIndicator />
                  </label>
                  <input
                    className="input"
                    type="text"
                    value={ insur.providerName }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'providerName', e.target.value ) }
                    autoFocus
                    id="providerName"
                    placeholder="provider name here"
                  />
                  <small>registered provider name</small>
                </div>
                {/* <div className="field col-12">
                  <label className="mb-0" htmlFor="insuranceTypeId">
                    Insurance Type
                    <RequiredIndicator />
                  </label>
                  <Select
                    name="insuranceTypeId"
                    required
                    value={ insur.insuranceTypeId }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'insuranceTypeId', e ) }
                    size="large"
                    id="insuranceTypeId"
                    className="d-block"
                    placeholder="select insurance type"
                  >
                    { insuranceTypes?.length > 0 && insuranceTypes?.map( ( it ) => (
                      <Option value={ it.id } key={ it.id }>
                        { it.title }
                      </Option>
                    ) ) }
                  </Select>
                </div> */}
                <div className="field col-12">
                  <label className="mb-0" htmlFor="contact">
                    Contact
                    <RequiredIndicator />

                  </label>
                  <input
                    className="input"
                    value={ insur.contact }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'contact', e.target.value ) }
                    type="tel"
                    id="contact"
                    placeholder="contact here"
                  />
                  <small>primary contact of provider</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="email">
                    Email
                    <RequiredIndicator />

                  </label>
                  <input
                    className="input"
                    type="email"
                    value={ insur.email }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'email', e.target.value ) }
                    id="email"
                    placeholder="email here"
                  />
                  <small>email address of provider</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="location">
                    Location Address
                    <RequiredIndicator />

                  </label>
                  <input
                    className="input"
                    type="text"
                    value={ insur.location }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'location', e.target.value ) }
                    id="location"
                    placeholder="provider location here"
                  />
                  <small>physical location of provider</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="region">
                    Region
                    <RequiredIndicator />

                  </label>
                  <Select
                    required
                    size="large"
                    id="region"
                    value={ insur.region }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'region', e ) }
                    className="d-block"
                    placeholder="select region"
                  >
                    { Regions }
                  </Select>
                  <small>region where provider is located</small>
                </div>
              </div>
              {
                isEditable && <button
                  type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                  Update Record
                </button>
              }
            </form>
      }
    </div>
  );
};

export default EditInsuranceForm;
