import { DatePicker, message, Select, Tabs } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  CharmBar,
  IndicatorInfo,
  RequiredIndicator,
} from "../../../components";
import {
  getAllStaffGroups,
  postNewStaff,
} from "../../../helpers/api";
import { dropDownSet } from "../../../helpers/utilities";


const dropTemp = {
  serviceId: null,
  staffGroupId: null,
  gender: "",
};


const NewStaffForm = ( { onSuccess, onError, onExit } ) => {
  const { handleSubmit, register, reset } = useForm();
  const [ dropValue, setDropValues ] = useState( dropTemp );

  const { Option } = Select;
  const { TabPane } = Tabs;

  const { data: groups = [] } = useQuery( {
    queryFn: () => getAllStaffGroups(),
    queryKey: [ 'staffGroups' ],
  } );



  const { mutateAsync, isLoading } = useMutation( ( data ) => postNewStaff( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        // message.success( 'staff added successfully' );
        onSuccess();
        reset();
        setDropValues( dropTemp );
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      onError();

    },
  } );

  const handleCreate = async ( data ) => {
    mutateAsync( { ...data, ...dropValue } );
  };

  return (
    <div>
      <form onSubmit={ handleSubmit( handleCreate ) }>
        <CharmBar className="py-0 my-0">
          <button className={ `button ${ isLoading && ' is-loading ' } app-btn btn-prim me-2` } type="submit">
            Save
          </button>
          <button className={ `button ${ isLoading && ' is-loading ' } app-btn` } onClick={ onExit }>
            Cancel
          </button>
        </CharmBar>
        <IndicatorInfo />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Basic Info" key="1">
            {/* <p>image uploader here</p> */ }
            <div className="row">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="firstName">
                  First Name
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="text"
                  autoFocus
                  { ...register( "firstName", { required: true } ) }
                  id="firstName"
                  placeholder="staff's first name"
                />
                {/* <small>staff' first name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="lastName">
                  Last Name
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "lastName", { required: true } ) }
                  id="lastName"
                  placeholder="staff's last name"
                />
                {/* <small>staff' last name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="birthdate">
                  Date of Birth
                  <RequiredIndicator />
                </label>
                <input
                  type="date"
                  className="input"
                  placeholder="select date"
                  { ...register( "dob", { required: true } ) }
                />
                {/* <DatePicker size="large" format={ "dddd - MMMM DD, YYYY" } className="d-block" /> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="gender">
                  Gender
                  <RequiredIndicator />
                </label>
                <Select
                  size="large"
                  id="gender"
                  required
                  value={ dropValue.gender }
                  onChange={ ( v ) =>
                    dropDownSet( "gender", v, dropValue, setDropValues )
                  }
                  className="d-block"
                  placeholder="gender"
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="staffGroup">
                  Staff Group
                  <RequiredIndicator />
                </label>
                <Select
                  size="large"
                  id="staffGroup"
                  required
                  value={ dropValue.staffGroupId }
                  onChange={ ( v ) =>
                    dropDownSet( "staffGroupId", v, dropValue, setDropValues )
                  }
                  className="d-block"
                  placeholder="select group"
                >
                  { groups.map( ( g ) => (
                    <Option value={ g.id } key={ g.id }>
                      { g.title }
                    </Option>
                  ) ) }
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="startWorkDate">
                  Work Start Date
                  <RequiredIndicator />
                </label>
                <input
                  type="date"
                  className="input"
                  placeholder="select date"
                  { ...register( "startWorkDate", { required: true } ) }
                />
                {/* <DatePicker size="large"
                                    required
                                    value={ dropValue.startWorkDate }
                                    onChange={ ( v ) => dropDownSet( 'startWorkDate', v, dropValue, setDropValues ) }
                                    id="startWorkDate" format={ "dddd - MMMM DD, YYYY" } className="d-block" /> */}
              </div>
            </div>
          </TabPane>
          {/* <TabPane tab="Profession" key="2">
            <div className="row">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="serviceId">
                  Specialization
                </label>
                <Select
                  size="large"
                  id="serviceId"
                  className="d-block"
                  required
                  value={dropValue.serviceId}
                  onChange={(v) =>
                    dropDownSet("serviceId", v, dropValue, setDropValues)
                  }
                  placeholder="area of specialization"
                >
                  {
                    services.map((service) => (
                      <Option value={service.id} key={service.id}>
                        {service.serviceName}
                      </Option>
                    ))
                    services.map( s =>
                    <Option value={ s.id } key={ s.id }>{ `${ s.serviceName } (${ s.specialistTitle })` }</Option>
                    )
                  }
                </Select>
              </div>

              <div className="field col-12">
                <label className="mb-0" htmlFor="bio">
                  Biography
                </label>
                <textarea
                  className="textarea"
                  {...register("bio")}
                  id="bio"
                  placeholder="staff's personal brief"
                ></textarea>
              </div>
            </div>
          </TabPane> */}
          <TabPane tab="Contact & Address" key="3">
            <div className="row">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="contact">
                  Phone Number
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="tel"
                  { ...register( "contact", { required: true } ) }
                  id="contact"
                  placeholder="staff's contact number"
                />
                {/* <small>staff' last name</small> */ }
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="email">
                  Email
                </label>
                <input
                  className="input"
                  type="email"
                  { ...register( "email" ) }
                  id="email"
                  placeholder="staff's email address"
                />
                {/* <small>staff' last name</small> */ }
              </div>
              <div className="field col-12">
                <label className="mb-0" htmlFor="residentialAddress">
                  Residential Address
                </label>
                <textarea
                  className="textarea"
                  { ...register( "residentialAddress" ) }
                  id="residentialAddress"
                  placeholder="staff's house address"
                ></textarea>
                {/* <small>staff' last name</small> */ }
              </div>
            </div>
          </TabPane>
        </Tabs>
      </form>
    </div>
  );
};

export default NewStaffForm;
