import { Skeleton } from "@mantine/core";
import { Select } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

const AddLaboratoryComponent = ( { data, allLaboratories, allActivities, onUpdate, onDelete } ) => {
    const { Option } = Select;
    const [ region, setRegion ] = useState( "" );

    useEffect( () => setRegion( data.region ), [] );

    return (
        <>
            {/* <div className="row mb-1">
                <div className="col-3">
                    Region:
                </div>
                <div className="col-9">
                    <Select
                        required
                        allowClear
                        size="medium"
                        showSearch
                        value={ data.region }
                        optionFilterProp="children"
                        filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                        filterSort={ ( optionA, optionB ) =>
                            optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                        }
                        onChange={ ( v ) => setRegion( v ) }
                        id="region" className="w-100" placeholder="Region"
                    >
                        { Regions }
                    </Select>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">
                    Lab Type:
                </div>
                <div className="col-9">
                    <Select
                        required
                        allowClear
                        size="medium"
                        showSearch
                        optionFilterProp="children"
                        filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                        filterSort={ ( optionA, optionB ) =>
                            optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                        }
                        value={ data.labType }
                        onChange={ ( v ) => onUpdate( data.recordId, "labType", v ) }
                        id="type" className="w-100" placeholder="Lab type"
                    >
                        {
                            [ ...new Set( allActivities.map( a => a.type ) ) ]?.map( at =>
                                <Option value={ at }>{ at }</Option>
                            )
                        }
                    </Select >
                </div>
            </div> */}
            <div className="row mb-1">
                <div className="col-3">
                    Lab Request:
                </div>
                <div className="col-9">
                    {
                        _.isEmpty( allActivities ) ?
                            <Skeleton>loading..</Skeleton> :
                            <Select
                                required
                                allowClear
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                                filterSort={ ( optionA, optionB ) =>
                                    optionA.children?.toLowerCase()?.localeCompare( optionB.children.toLowerCase() )
                                }
                                value={ data.requestType }
                                onChange={ ( v ) => onUpdate( data.recordId, "requestType", v ) }
                                id="laboratoryId" className="w-100" placeholder="laboratory request type"
                            >
                                {
                                    allActivities?.map( ac =>
                                        // ac.type === data.labType &&
                                        <Option value={ ac.description }>{ ac.description }</Option>
                                    )
                                }
                            </Select>
                    }
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">
                    Partner Lab:
                </div>
                <div className="col-9">
                    {
                        _.isEmpty( allLaboratories ) ?
                            <Skeleton>loading..</Skeleton> :
                            <Select
                                required
                                allowClear
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                                filterSort={ ( optionA, optionB ) =>
                                    optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                                }
                                value={ data.laboratoryId }
                                onChange={ ( v ) => onUpdate( data.recordId, "laboratoryId", v ) }
                                id="laboratoryId" className="w-100" placeholder="select laboratory"
                            >
                                {
                                    allLaboratories
                                        // .filter( lab => lab.region === region )
                                        // .filter( lab => lab.type === data.labType )
                                        .map( lab =>
                                            <Option value={ lab.id } key={ lab.id }>{ `${ lab.laboratoryName }  (${ lab.location })` }</Option>
                                        )
                                }
                            </Select>
                    }
                </div>
            </div>
            <div className="col-1 g-0">
                <button className='button is-small text-danger'
                    onClick={ () => onDelete( data.recordId ) }
                >
                    <strong>Remove Lab</strong> <span className="bi bi-trash"></span>
                </button>
            </div>
        </>
    );
};

export default AddLaboratoryComponent;
