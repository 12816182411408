import { Menu, Modal, Paper } from "@mantine/core";
import { useEffect, useState } from "react";
import EditExpenseForm from "./edit-expense";
import NewExpenseForm from "./new-expense";
import smalltalk from 'smalltalk';
import { cedisLocale } from "../../../helpers/utilities";
import { deleteExpense } from "../../../helpers/api";
import { Table, message } from "antd";


const columns = ( onOpen, onDelete ) => [
    {
        dataIndex: 'id',
        title: 'SN',
        sortable: true,
        width: 80,
        render: ( text, record, index ) => ++index,
    },
    {
        sorter: ( a, b ) => a.date > b.date,
        title: "Date",
        dataIndex: 'date',
        sortDirections: [ "descend", "ascend" ],
        width: 160,
        render: ( text, record, index ) => new Date( record.date ).toDateString(),
    },
    {
        sorter: ( a, b ) => a.description > b.description,
        title: "Description",
        dataIndex: 'description',
        sortDirections: [ "descend", "ascend" ],
        width: 300,
    },
    {
        sorter: ( a, b ) => a.amount > b.amount,
        title: "Amount",
        dataIndex: 'amount',
        sortDirections: [ "descend", "ascend" ],
        width: 150,
        render: ( text, record, index ) => cedisLocale.format( record.amount ),
    },
    {
        sorter: ( a, b ) => a.categoryId > b.categoryId,
        title: "Category",
        dataIndex: 'categoryId',
        sortDirections: [ "descend", "ascend" ],
        flex: 1,
        render: ( text, record, index ) => record.category.title,
    },
    {
        width: 50,
        render: ( text, record, index ) => {
            return (
                <div className="d-flex">
                    {/* <Menu>
                        <Menu.Target>
                            <button className="button is-small is-ghost px-2">
                                <span className="bi bi-list me-2"></span>
                            </button>
                        </Menu.Target >

                        <Menu.Dropdown>
                            <Menu.Item
                                color="blue"
                                onClick={ () => onOpen( record.id ) }
                                icon={ <span className="bi bi-arrow-up-right-square" /> }>
                                Open
                            </Menu.Item>
                            <Menu.Item
                                className="text-danger"
                                color="red"
                                onClick={ () => onDelete( record.id ) }
                                icon={ <span className="bi bi-trash" /> }>
                                Delete
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu > */}
                    <button
                        className="button app-btn is-ghost" onClick={ () => onOpen( record.id ) }>
                        <span className="bi bi-eye me-1"></span>
                        View
                    </button>
                    <button className="button app-btn is-ghost text-danger" onClick={ () => onDelete( record.id ) }>
                        <span className="bi bi-trash"></span>
                    </button>
                </div >
            );
        }
    }
];




const ExpensesPage = ( { data: expenses, isLoading, onReload } ) => {

    const [ filteredData, setFilteredData ] = useState( [] );

    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null
    } );


    const handleOpen = ( id ) => setModal( {
        title: 'View Expense',
        isOpen: true,
        content: <EditExpenseForm
            canEdit={ true } //use permission here
            id={ id }
            onUpdate={ onReload }
            showHeader={ false }
        />
    } );

    const handleDelete = ( id ) => {

        smalltalk.confirm(
            "Delete Expense", "This action cannot be undone. Do you wish to continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            deleteExpense( id ).then(
                () => {
                    message.success( "expense deleted successfully" );
                    onReload();
                }
            );
        } ).catch( ex => {
            return false;
        } );
    };


    useEffect( () => {
        setFilteredData( expenses );
    }, [ expenses ] );


    return (
        <section className="mt-4 pb-4">
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
            >
                { modal.content }
            </Modal>
            {/* <button className="bokx-btn btn-prim"
                                onClick={ () => setMode( { new: true } ) }>
                                <span className="bi bi-currency-dollar me-2 d-none d-md-inline"></span>
                                Spend
                            </button> */}
            {/* buttons */ }
            {/* <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <button className="bokx-btn btn-prim"
                                            onClick={ () => setMode( { new: true } ) }>
                                            <span className="bi bi-currency-dollar me-2 d-none d-md-inline"></span>
                                            Spend
                                        </button>
                                    </div>
                                    <SearchInput
                                        autoFocus
                                        onChange={ value =>
                                            setFilteredData(
                                                expenses.filter( fd =>
                                                    fd.description.toLowerCase().includes( value.toLowerCase() ) ||
                                                    fd.category.title.toLowerCase().includes( value.toLowerCase() ) ||
                                                    fd.amount.toString().toLowerCase().includes( value.toLowerCase() )
                                                ) )
                                        }
                                        placeholder="search by description, outlet,amount or category" />
                                </div>

                            </div> */}
            {/* <span className="ms-2">expenses cumulative summaries here. maybe a chart -> go into reports</span> */ }
            <Table
                dataSource={ filteredData }
                columns={ columns( handleOpen, handleDelete ) }
                loading={ isLoading }
                className="border"
                rowKey={ ( record ) => record.id }
                bordered
            />
        </section >
    );
};

export { ExpensesPage };