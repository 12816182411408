const RequiredIndicator = () => {
  return (
    <span className="mx-2">
      <strong className="text-danger ">*</strong>
    </span>
  );
};

const IndicatorInfo = ( { className } ) => {
  return (
    <small className={ `my-2 ${ className }` }>
      fields with <kbd className="mx-2">*</kbd>
      are required fields
    </small>
  );
};

export { RequiredIndicator, IndicatorInfo };
