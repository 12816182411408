import { Skeleton } from "@mantine/core";
import { Select } from "antd";
import _ from "lodash";


const AddDiagnosisComponent = ( { data, allDiagnosis, onUpdate, onDelete } ) => {
    // list  is the list of pharmacies pulled from the server
    const { Option } = Select;

    return (
        <div className="row">
            <div className="field col-9">
                {/* <input className="input w-100"
                    required
                    disabled={ data?.consultation?.isComplete }
                    autoFocus
                    value={ data.diagnosis }
                    onChange={ ( e ) => onUpdate( data.recordId, e.target.value ) }
                    id="id" /> */}
                {
                    _.isEmpty( allDiagnosis ) ?
                        <Skeleton>loading..</Skeleton> :
                        <Select
                            required
                            allowClear
                            size="medium"
                            showSearch
                            disabled={ data?.consultation?.isComplete }
                            value={ data.diagnosis }
                            optionFilterProp="children"
                            filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                            filterSort={ ( optionA, optionB ) =>
                                optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                            }
                            onChange={ ( v ) => onUpdate( data.recordId, v ) }
                            placeholder="select diagnosis"
                            id="diagnosis" className="w-100"
                        >
                            {
                                allDiagnosis?.map( dg =>
                                    <Option value={ `(${ dg.code }) - ${ dg.title }` } key={ dg.id }>{ `(${ dg.code }) - ${ dg.title }` }</Option>
                                )
                            }
                        </Select>
                }
            </div>
            {
                !data?.consultation?.isComplete &&
                <div className="col-1 g-0">
                    <button className='button is-small text-danger'
                        onClick={ () => onDelete( data.recordId ) }
                    >
                        <span className="bi bi-trash"></span>
                    </button>
                </div>
            }
        </div>
    );
};

export default AddDiagnosisComponent;
