import { message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getAllCategories, getService, putFacilityService } from '../../../helpers/api';
import _ from 'lodash';
import { RequiredIndicator } from '../../../components';
import smallTalk from 'smalltalk';
import { Loader, Modal, Switch } from '@mantine/core';
import { NewCategoryForm } from '../../../components/shared/new-category';
import { CATEGORY_TYPES } from '../../../helpers/config';

export default function EditServiceForm ( { onSuccess, id } ) {

    const [ state, setState ] = useState( {} );

    const { data: categories = [], refetch } = useQuery( {
        queryFn: () => getAllCategories( 'service' ),
        queryKey: [ 'service-categories' ],
    } );

    const { data, isFetching } = useQuery( {
        queryFn: () => getService( id ),
        queryKey: [ 'service', id ],
        onSuccess: data => setState( data )
    } );


    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        zIndex: 1000,
    } );

    const { mutateAsync, isLoading } = useMutation( ( data ) => putFacilityService( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onSuccess();
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;

            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );


    const updateSubmit = () => {
        smallTalk.confirm( "Update Service", "Do you wish to continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        } ).then( () => mutateAsync( state ) );
    };


    return (
        <>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                zIndex={ modal.zIndex }
            >
                { modal.content }
            </Modal>
            {
                isFetching ?
                    <p><Loader /> Fetching data...</p> :
                    <div>
                        <div className='field'>
                            <label htmlFor="serviceCode">
                                Service Code
                                <RequiredIndicator />
                            </label>
                            <input
                                type="text"
                                id='serviceCode'
                                autoFocus
                                required
                                className="input w-100"
                                placeholder='unique identification service code'
                                value={ state?.serviceCode }
                                onChange={ e => setState( { ...state, serviceCode: e.target.value } ) }
                            />
                        </div>
                        <div className='field'>
                            <label htmlFor="serviceName">
                                Service Name
                                <RequiredIndicator />
                            </label>
                            <input
                                type="text"
                                id='serviceName'
                                autoFocus
                                className="input w-100"
                                placeholder='official service name'
                                value={ state?.serviceName }
                                onChange={ e => setState( { ...state, serviceName: e.target.value } ) }
                            />
                        </div>
                        <div className='field'>
                            <label htmlFor="baseRate">
                                Price
                                <RequiredIndicator />
                            </label>
                            <input
                                type="number"
                                step="0.01"
                                id='baseRate'
                                className="input w-100"
                                placeholder='service base rate'
                                value={ state?.baseRate }
                                onChange={ e => setState( { ...state, baseRate: e.target.value } ) }
                            />
                        </div>
                        <div className='field'>
                            <label htmlFor="categories">
                                Category
                                <RequiredIndicator />
                            </label>
                            <select
                                size='large'
                                name="categories"
                                id="categories"
                                className="d-block w-100 input"
                                placeholder='select expense category'
                                value={ state?.categoryId }
                                onChange={ e => setState( { ...state, categoryId: e.target.value } ) }
                            >
                                <option value="" selected disabled>select</option>
                                {
                                    categories.map( cat =>
                                        <option value={ cat.id } id={ cat.id }>{ cat.title }</option> )
                                }
                            </select>
                            <p
                                onClick={ () => setModal( {
                                    title: 'Add Category',
                                    isOpen: true,
                                    content: <NewCategoryForm
                                        onSuccess={ refetch }
                                        type={ CATEGORY_TYPES.find( cat => cat.value === 'service' ).value } />,
                                    zIndex: 1200
                                } ) }
                                className="text-secondary hover-hand d-inline-block p-1 mb-0">
                                <span className="bi bi-plus-circle me-2"></span>
                                add category
                            </p>
                        </div>
                        <div className='field'>
                            <label htmlFor="description">
                                Description
                            </label>
                            <textarea
                                type="text"
                                step="0.01"
                                rows="3"
                                value={ state?.description }
                                onChange={ e => setState( { ...state, description: e.target.value } ) }
                                id='description'
                                className="textarea w-100"
                                placeholder='service description here'
                            />
                        </div>
                        <div className='field'>
                            <Switch
                                checked={ state?.isActive }
                                onChange={ e => setState( { ...state, isActive: !state?.isActive } ) }
                                color="teal"
                                size="md"
                                className='mb-2'
                                label="Service Enabled?"
                                onLabel='YES'
                                offLabel='NO'
                            />
                        </div>
                        <div className='field'>
                            <Switch
                                checked={ state?.isOnline }
                                onChange={ e => setState( { ...state, isOnline: !state?.isOnline } ) }
                                color="teal"
                                size="md"
                                label="Online Booking Enabled?"
                                onLabel='YES'
                                offLabel='NO'
                            />
                        </div>
                        {
                            state?.isOnline &&
                            <div className='field'>
                                <label htmlFor="onlineRate">
                                    Online Price
                                    <RequiredIndicator />
                                </label>
                                <input
                                    type="number"
                                    step="0.01"
                                    id='onlineRate'
                                    value={ state?.onlineRate }
                                    onChange={ e => setState( { ...state, onlineRate: e.target.value } ) }
                                    className="input w-100"
                                    placeholder='service online rate'
                                />
                            </div>
                        }

                        <button onClick={ updateSubmit } className={ `button h6 btn-prim mt-3 ${ isLoading && ' is-loading' }` } type='submit'>
                            <span className="bi bi-save me-2"></span>
                            Save
                        </button>
                    </div >
            }
        </>
    );
}
