import _ from 'lodash';
import { Loader } from '@mantine/core';
import ConsultationDetail from '../../pages/schedule/components/consultation-detail';
import { getConsultationsByPatient } from '../../helpers/api';
import { TimeLine } from '../cards';
import { useQuery } from 'react-query';
import { useState } from 'react';

export default function PatientJourney ( { patientId } ) {

    const [ selected, setSelected ] = useState( null );

    const { data: history = [], refetch: fetchJourney, isFetching } = useQuery( {
        queryFn: () => getConsultationsByPatient( patientId ),
        queryKey: [ 'patient-consultation', patientId ],
    } );


    // const [ history, setHistory ] = useState( [] );
    // const [ selected, setSelected ] = useState( {} );
    // const [ isBusy, setIsBusy ] = useState( false );

    // const fetchJourney = ( patient ) => {
    //     // fetch call here
    //     setIsBusy( true );
    //     getConsultationsByPatient( patient ).then( res => {
    //         if ( res.status === 200 ) {
    //             setHistory( res.data.data );
    //         }
    //     } ).catch( ex => message.error( 'an error occurred!' ) )
    //         .finally( () => setIsBusy( false ) );
    // };

    // useEffect( () => {
    //     fetchJourney( patientId );
    // }, [ patientId ] );


    return (
        <>
            {
                isFetching ?
                    <div className="text-center">
                        <Loader /> please wait...
                    </div>
                    :
                    <div className='row'>
                        <div className="col-md-4 col-12">
                            {
                                _.isEmpty( history ) ?
                                    <kbd>No previous consultations found!</kbd>
                                    :
                                    <TimeLine selectedId={ selected } data={ history } onSelect={ id => setSelected( id ) } />
                            }
                        </div>
                        {
                            !_.isEmpty( history ) &&
                            <div className="col-md-8 col-12">
                                {
                                    _.isEmpty( selected ) ?
                                        <kbd className='m-4 bg-success'>Select a history to view details</kbd> :
                                        <ConsultationDetail
                                            consultationId={ selected }
                                            refresh={ fetchJourney } />
                                }
                            </div>
                        }
                    </div>
            }
        </>
    );
}
