import { Avatar, Divider, Tabs, Tag } from "antd";
import { AppVersion, CopyRightTag, PageHeader } from "../components";
import { Paper } from "@mantine/core";
import { getUser } from "../helpers/auth";
import { useQuery } from "react-query";
import { getUserProfile } from "../helpers/api";
import { AttendanceComponent } from "./attendance";
import { Link } from "react-router-dom";


const AboutPage = ( props ) => {

  const { data: user = {} } = useQuery( {
    queryFn: () => getUserProfile( getUser().id ),
    queryKey: [ 'profile' ],
  } );


  return (
    <section className="user-profile">
      <div className="row mt-4">
        <div className="col-md-5 col-12 mx-auto p-4 text-center">
          <Paper className="p-4">
            <h2>About RiviaOS</h2>
            <p className="text-secondary">
              RiviaOS is a proprietory software application designed by Rivia LLC
              to facilitate excellent healthcare services delivery and business
              administration of healthcare facilities.
            </p>
            <p className="text-secondary">
              Useful Links:
              <div><Link to="#">User Manual</Link></div>
              <div><Link to="#">Release Notes</Link></div>
              <div><Link to="#">Feature request</Link></div>
            </p>

            <Divider>
              <small className="d-flex">
                <AppVersion showTitle={ false } className="me-1" />
                <CopyRightTag />
              </small>
            </Divider>
          </Paper>
        </div>
      </div>
    </section>
  );
};

export { AboutPage };
