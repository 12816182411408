import { Divider } from "antd";

const CharmBar = ( { children, className } ) => {
    return (
        <div className={ `charm-bar ${ className }` }>
            <div className="charm-content">
                { children }
                <Divider />
            </div>
        </div>
    );
};

export { CharmBar };