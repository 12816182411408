import { Divider, Input, Card, Typography } from 'antd';
import { Badge, Paper } from '@mantine/core';
import { calcBMI } from '../../../helpers/utilities';

const AddVitals = ( { data, onUpdate, isEditable = true, showBMI = true } ) => {

    const { Title } = Typography;

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='folderNumber'>Folder Number:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            autoFocus
                            value={ data?.folderNumber }
                            className='input'
                            type="text"
                            onChange={ ( e ) => onUpdate( 'folderNumber', e.target.value ) }
                            id="folderNumber"
                            placeholder="patient folder number"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='temperature'>Temperature:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.temperature }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'temperature', e.target.value ) }
                            required
                            id="temperature"
                            placeholder="℃"
                        />
                    </div>
                </div>

                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='bloodPressure'>Blood Pressure:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.bloodPressure }
                            disabled={ !isEditable }
                            type="text"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'bloodPressure', e.target.value ) }
                            required
                            id="bloodPressure"
                            placeholder="mmHg"
                        />
                    </div>
                </div>

                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='pulse'>Pulse:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.pulse }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'pulse', e.target.value ) }
                            required
                            id="pulse"
                            placeholder="bpm"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">

                    <div className="col-3">
                        <label htmlFor='weight'>Weight:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.weight }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'weight', e.target.value ) }
                            required
                            id="weight"
                            placeholder="kg"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">

                    <div className="col-3">
                        <label htmlFor='height'>Height:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.height }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'height', e.target.value ) }
                            required
                            id="height"
                            placeholder="meters"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='oxygenSaturation'>Oxygen Saturation:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.oxygenSaturation }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'oxygenSaturation', e.target.value ) }
                            required
                            id="oxygenSaturation"
                            placeholder="os"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='respiration'>Respiration:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.respiration }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'respiration', e.target.value ) }
                            required
                            id="respiration"
                            placeholder="cpm"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='rbs'>RBS/FBS:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.rbs }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            onChange={ ( e ) => onUpdate( 'rbs', e.target.value ) }
                            required
                            id="rbs"
                            placeholder="rbs"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <div className="col-3">
                        <label htmlFor='pain'>Pain:</label>
                    </div>
                    <div className="col-9">
                        <Input
                            size='large'
                            value={ data?.pain }
                            disabled={ !isEditable }
                            type="number"
                            className='input'
                            max={ 10 }
                            onChange={ ( e ) => onUpdate( 'pain', e.target.value ) }
                            required
                            id="pain"
                            placeholder="pain score: 1-10"
                        />
                    </div>
                </div>
                <Divider className='my-2' />
                <div className="col-12 col-md-6 d-flex my-3">
                    <div className="col-3">
                        <label htmlFor='comments'>Notes:</label>
                    </div>
                    <div className="col-9">
                        <textarea
                            value={ data?.comments }
                            rows="4"
                            className="textarea"
                            onChange={ ( e ) => onUpdate( 'comments', e.target.value ) }
                            id="comments"
                            placeholder="input your notes or comments here"
                        />
                    </div>
                </div>
                <div className="col-9 col-md-3 offset-md-2 offset-3 d-flex my-3">
                    {
                        showBMI &&
                        <div>
                            <p className='mb-0 pb-0'> Body Mass Index</p>
                            <h1 style={ { fontSize: '5em' } } className='p-0 m-0'>{ calcBMI( data.weight, data.height ) || 0 }</h1>
                        </div>
                    }
                </div>
            </div >
        </>
    );
};

export default AddVitals;