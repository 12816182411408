import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Modal,
} from "@mantine/core";
import { Divider, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppVersion, CopyRightTag, PageTitle } from "../../components";
import { postLogin } from "../../helpers/api";
import { authenticate, setRole } from "../../helpers/auth";
import { appLinks, termsandConditions } from "../../helpers/config";
import riviaOS from '../../static/img/riviaos-icon.png';

export function LoginPage () {
  const [ login, setLogin ] = useState( {
    email: "",
    password: "",
    tnc: false,
  } );
  const [ busy, setBusy ] = useState( false );
  const [ modelOpen, setOpenModal ] = useState( false );

  const nav = useNavigate();

  const handleLogin = ( e ) => {
    e.preventDefault();

    if ( !login.password || !login.email ) {
      message.info( "all fields are required" );
      return;
    }

    if ( !login.tnc ) {
      message.error( "You must accept the terms and conditions" );
      return;
    }

    setBusy( true );
    postLogin( login )
      .then( ( res ) => {
        if ( res.status === 200 ) {
          authenticate( res.data.token, res.data.data );
          setRole( res.data.data.role );
          nav( appLinks.home.index );
        }
        else
          throw res;
      } )
      .catch( ( ex ) => {
        message.error( ex.response.data.message );
        const attps = ex.response.data.loginAttempts;
        attps && message.info( `${ 4 - attps } attempts remaining...` );
      } )
      .finally( () => {
        setBusy( false );
      } );
  };

  return (
    <Container size={ 420 } mt={ 40 }>
      <PageTitle title="Login" />
      <div className="text-center">
        <img src={ riviaOS } alt="RiviaOS Logo" width="100" />
      </div>
      {/* <Title
        align="center"
        sx={ ( theme ) => ( {
          fontFamily: `Greycliff CF, ${ theme.fontFamily }`,
          fontWeight: 900,
        } ) }
      >
        Welcome!
      </Title> */}

      <Paper withBorder shadow="md" p={ 30 } mt={ 20 } radius="md">
        <h6 className="text-center">Login to continue</h6>
        <form onSubmit={ handleLogin } method="POST">
          <TextInput
            size="md"
            autoFocus
            onChange={ ( e ) => setLogin( { ...login, email: e.target.value } ) }
            value={ login.email }
            label="Email"
            placeholder="email here"
            required
          />
          <PasswordInput
            size="md"
            onChange={ ( e ) => setLogin( { ...login, password: e.target.value } ) }
            value={ login.password }
            label="Password"
            placeholder="Your password"
            required
            mt="md"
          />

          <button
            onClick={ handleLogin }
            className={ `button mt-3 h6 app-btn btn-prim py-3 px-2 w-100  ${ busy && "is-loading"
              }` }
          >
            Sign in
          </button>
          <Checkbox
            value={ login.tnc }
            onChange={ () => setLogin( { ...login, tnc: !login.tnc } ) }
            className="mt-3" label={ <>
              I Accept the
              <a className="pt-2" onClick={ () => setOpenModal( true ) }> terms and conditions</a>
            </> } />
        </form>
        <Divider className="my-3" />
        <div className="d-flex justify-content-between">
          <Anchor href={ appLinks.forgotPassword } size="sm">
            Forgot password?
          </Anchor>
          <Anchor href={ appLinks.exterals.login } size="sm">
            Login as Pharma/Labs
          </Anchor>
        </div>
      </Paper>
      {/* <div className="text-center"> */ }
      <div className="text-center mt-2">RiviaOS <AppVersion className="d-inline" showTitle={ false } /></div>
      {/* <CopyRightTag /> */ }
      {/* </div> */ }
      <Modal
        title={ <strong>Rivia Terms and Conditions</strong> }
        opened={ modelOpen }
        onClose={ () => setOpenModal( false ) }>
        <p>
          { termsandConditions }
        </p>
      </Modal>
    </Container>
  );
}
