import {
  Table,
  message,
} from "antd";

import { format, isToday, isYesterday } from "date-fns";
import _, { size } from "lodash";
import { useEffect, useState } from "react";
import { hasPermission } from "../../../helpers/utilities";
import { Modal, Menu } from "@mantine/core";
import { action, resouces } from "../../../helpers/auth";
import smalltalk from 'smalltalk';
import { IconDotsCircleHorizontal, IconEye, IconFirstAidKit, IconLink, IconPrinter, IconTrash } from "@tabler/icons";
import { deleteBill, putLinkBillToConsultation } from "../../../helpers/api";
import ConsultationDetail from "./consultation-detail";
import EditBillForm from "../../../components/billing/edit-bill";
import BillReceipt from "../../../components/billing/receipt";
import { LinkBillToConsultation } from "../../../components";
import { useMutation } from "react-query";

const BillsComponent = ( { bills, isLoading, onReload } ) => {

  // states
  const [ filteredData, setFilteredData ] = useState( [] );
  const [ modal, setModal ] = useState( {
    isOpen: false,
    title: '',
    content: null,
    size: 500,
    zIndex: 50,
  } );


  const { mutateAsync: handleLinkConsultation, isLoading: isLinking } = useMutation(
    ( { billId, code } ) => putLinkBillToConsultation( billId, code ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onReload();
        setModal( { ...modal, isOpen: false } );
        return;
      }
      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
  } );


  // handlers
  const handleDeleteBill = ( id ) => {
    smalltalk.confirm(
      "Delete Bill", "This action is irreversible. Continue?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {
      message.info( 'deleting...' );
      deleteBill( id ).then( res => {
        if ( res.status === 204 ) {
          message.success( 'done!' );
          onReload();
          return;
        }
      } ).catch( ex => {
        message.error( ex.data.message );
      } );
    } )
      .catch( ex => {
        return false;
      } );
  };


  // table columns
  const columns = [
    {
      title: "Date",
      width: 180,
      sorter: ( a, b ) => a?.createdAt > b?.createdAt,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        <p>
          {
            isToday( new Date( record?.createdAt ) ) ? "Today " :
              isYesterday( new Date( record?.createdAt ) ) ? "Yesterday " :
                format( new Date( record?.createdAt ), "MMM dd, YYY" )
          }
        </p>,
    },
    {
      title: "Code",
      width: 100,
      sorter: ( a, b ) => a?.consultation?.code > b?.consultation?.code,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) => record?.consultation?.code ?? '-'
    },
    {
      title: "Bill No.",
      dataIndex: "billNumber",
      width: 150,
      sorter: ( a, b ) => a?.billNumber > b?.billNumber,
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Client Name",
      sorter: ( a, b ) => a?.patient?.firstName > b?.patient?.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => `${ r?.patient?.firstName } ${ r?.patient?.lastName }`
    },
    {
      title: "Paid",
      width: 150,
      // sorter: ( a, b ) => a?.sumAmount > b?.sumAmount,
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => {
        let subtotal = 0;
        r.payments?.map( p => subtotal += parseFloat( p.amount ) );
        return parseFloat( subtotal );
      }
    },
    {
      title: "-",
      width: 50,
      render: ( text, record, index ) => (
        <div>
          <Menu shadow="md" width={ 170 } >
            <Menu.Target>
              <button className="button app-btn is-ghost">
                <IconDotsCircleHorizontal />
              </button>
            </Menu.Target>

            <Menu.Dropdown>
              {/* {
                hasPermission( resouces.patients, action.canRead ) && */}
              <>
                <Menu.Item
                  onClick={ () => setModal( {
                    title: 'Bill Detail',
                    isOpen: true,
                    size: 1200,
                    content: <EditBillForm billId={ record.id } onUpdate={ onReload } />,
                    zIndex: 50,
                  } ) }

                  color="blue">
                  <IconEye size={ 20 } color="teal" className="me-2" />
                  Open
                </Menu.Item>
                <Menu.Item
                  onClick={ () => setModal( {
                    title: 'Receipt',
                    isOpen: true,
                    size: 300,
                    content: <BillReceipt query={ record.id } param="billId" />,
                    zIndex: 100,
                  } ) }
                >
                  <IconPrinter size={ 20 } color="grey" className="me-2" />
                  Print Receipt
                </Menu.Item>
                <Menu.Item
                  disabled={ !record?.consultation?.id }
                  onClick={ () => setModal( {
                    title: 'Consultation Detail',
                    isOpen: true,
                    size: 900,
                    content: <ConsultationDetail consultationId={ record?.consultation?.id } refresh={ onReload } />,
                  } ) }>
                  <IconFirstAidKit size={ 20 } color="grey" className="me-2" />
                  Consultation
                </Menu.Item>
                <Menu.Item
                  // disabled={ record?.consultation?.id }
                  onClick={ () => setModal( {
                    title: 'Link Consultation',
                    isOpen: true,
                    size: 400,
                    content: <LinkBillToConsultation
                      // isLoading={ isLinking }
                      onLink={ code => handleLinkConsultation( { billId: record.id, code } ) } />,
                  } ) }>
                  <IconLink size={ 20 } color="grey" className="me-2" />
                  Link Consultation
                </Menu.Item>
                <Menu.Item color="red"
                  onClick={ () => handleDeleteBill( record.id ) }
                >
                  <IconTrash size={ 20 } color="red" className="me-2" />
                  Delete
                </Menu.Item>
              </>
              {/* } */ }

            </Menu.Dropdown>
          </Menu>
        </div>
      ),
    },
  ];


  useEffect( () => {
    setFilteredData( bills );
  }, [ bills ] );

  return (
    <>
      <Modal
        onClose={ () => setModal( { ...modal, isOpen: false } ) }
        opened={ modal.isOpen }
        title={ modal.title }
        size={ modal.size }
        zIndex={ modal.zIndex }
      >
        { modal.content }
      </Modal>
      {/* <button className="button app-btn btn-prim"
        onClick={ () => setModal( {
          title: 'New Bill',
          content: <BillComponent billId={ null } />,
          isOpen: true,
          size: 1000
        } ) }
      >Open Bill</button> */}
      <div className="my-3 d-flex justify-content-between">
        <div className="">
          <input
            className="input"
            onChange={ ( e ) =>
              setFilteredData(
                bills.filter(
                  ( bill ) =>
                    bill?.patient?.firstName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    bill?.patient?.lastName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    bill?.consultation?.code
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    bill?.billNumber
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() )
                )
              )
            }
            style={ { minWidth: "30vw" } }
            title="find bill"
            placeholder="search by client name, code or receipt number"
          />
        </div>
        <div className="d-flex">
          <button
            onClick={ () => setFilteredData( bills ) }
            className="app-btn btn-text ms-2 d-flex align-items-center"
          >
            <span className="bi bi-arrow-clockwise me-2"></span>
            <span className="d-none d-md-inline"> Reset filters</span>
          </button>
          <button className="app-btn"
            onClick={ onReload }
          >
            <span className="bi bi-cycle"></span>
            <span className="d-none d-md-inline">Refresh</span>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Table
            columns={ columns }
            className="border"
            dataSource={ filteredData }
            rowKey={ ( record ) => record.id }
            loading={ isLoading } />
        </div>
      </div >
    </>
  );
};

export default BillsComponent;;
