import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'; // if using DnD
// import FullCalendar from '@fullcalendar/react' // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

const CalendarComponent = ( { events = [], onSelect } ) => {
    const localizer = momentLocalizer( moment );
    // const myEventsList = [ 
    //     // {
    //     //         Event {
    //     //         title: string,
    //     //         start: Date,
    //     //         end: Date,
    //     //         allDay?: boolean
    //     //   resource?: any,
    //     // } 
    // ];
    return (
        <div>
            {/* <div className="row">
                <div className="col-12">
                </div>
            </div> */}

            <div className="row">
                <div className="col-12">
                    <Calendar
                        localizer={ localizer }
                        events={ events }
                        startAccessor="start"
                        endAccessor="end"
                        style={ { height: 500 } }
                        onSelectEvent={ ( event ) => onSelect( event ) }
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarComponent;