import { Select } from "antd";
// import { roles } from "./auth";

const { Option } = Select;

export const constants = {
  siteTitle: "RiviaOS",
};

export const tokenEncryptionKey = "xY090P3rfect";
export const userEncryptionKey = "15comIngitsG0in?";
export const roleEncryptionKey = "p3rfect!onist";

export const fileTypes = {
  image: [ 'jpg', 'jpeg', 'jfif', 'png' ],
  pdf: [ 'pdf' ]
};

export const paymentMethods = [
  { title: 'Bank', value: 'bank' },
  { title: 'Card', value: 'card' },
  { title: 'Cash', value: 'cash' },
  { title: 'MoMo', value: 'momo' },
  { title: 'Cheque', value: 'cheque' },
  { title: 'Other', value: 'other' },
];

export const consultationStates = {
  open: { description: 'consultation is started and available for review' },
  done: { description: 'consultation is marked complete and closed' },
  pending: { description: 'an open consultation with unresolved tasks (prescriptions and labs)' }
};


export const cancelReasons = [
  'Patient asked to cancel', 'Spam', 'Consultation done',
  'Duplicate appointment', 'Doctor asked to cancel', 'Conflicting schedules'
];


export const appointmentStates = [
  {
    value: "scheduled",
    label: 'Scheduled'
  },
  {
    value: "completed",
    label: 'Completed'
  },
  {
    value: "cancelled",
    label: 'Cancelled'
  },
  {
    value: 'declined',
    label: 'Declined'
  },
  {
    value: 'no-show',
    label: 'No Show'
  },
  {
    value: 'rescheduled',
    label: 'Rescheduled'
  },
];



export const consultationCodeLength = 6;

export const urls = {
  app_url: process.env.REACT_APP_URL,
  // stagingAPI: "https://ahotopa-service-api-6twzg.ondigitalocean.app/api",
  // prodApi: "https://teledokta_api_service-local.medireformist.org/api",
};

export const appLinks = {
  // focus-mode
  inboundCall: {
    index: "/",
    // index: "/inbound-call",
  },
  findPatient: {
    index: "/",
    // index: "/find-patient",
  },
  startConsultation: {
    index: "/start",
  },
  video: {
    room: ( roomId ) => `/video-call/${ roomId }`,
  },
  patientVideo: {
    room: ( roomId ) => `/vc/${ roomId }`,
  },

  // about
  about: "/about",

  // feedback
  feedback: "/feedback",

  attendance: "/attendance",

  // about
  switchBranch: "/switch-branch-2",

  // Home
  home: {
    index: "/",
  },

  // facility
  facility: {
    index: "/facility",
  },

  // care
  care: {
    index: "/care",
    new: "/care/new",
    // status: ( key ) => `/care/${ key }`,
  },

  // catalog : admin only
  catalog: {
    index: "/catalog",

    // doctors
    // staffs: "/staffs",

    // patients
    patients: "/patients",
    patientPage: ( slug ) => `/patients/${ slug }`,

    // reports
    reports: "/reports",
  },


  facility: {
    index: "/facility",

    admin: "/facility/admin",

    serviceProviders: "/facility/service-providers",

    hr: "/facility/human-resource",

    finance: "/facility/finance",

    staffPage: ( slug ) => `/staffs/${ slug }`,


  },

  // setup
  setup: {
    index: "/setup",

    // appSettings
    appSettings: "/setup/app",

    // services
    // services: "/setup/services",
    // servicePage: (slug) => `/services/${slug}`,

    // accounts
    accounts: "/setup/accounts",

    // roles
    roles: "/setup/roles",

    // roles
    settings: "/setup/settings",

    // logs
    logs: "/setup/logs",

    // serviceProviders
    // serviceProviders: "/setup/service-providers",

    // hospital
    // hospital: "/setup/hospital",
  },

  // externals

  exterals: {
    index: '/ext',
    laboratory: '/ext/laboratory',
    pharmacy: `/ext/pharmacy`,
    login: `/ext/login`,
    passwordReset: `/ext/password-reset`,
    patientPortal: ( hash ) => `/ext/status/${ hash }`,
  },

  // profile
  profile: {
    index: "/me",
  },

  logs: "/activity-logs",

  // auth
  login: "/login",
  signup: "/signup",
  changePassword: ( hash ) => `/change-password/${ hash }`,
  forgotPassword: "/forgot-password",

  // Search
  search: "/search",

  // not found
  catchAll: "*",
};

export const primaryNav = [
  // params
  // device: - desktop, mobile, all
  // roles: - doctor, patient, admin, all (list)

  // {
  // 	name: "video",
  // 	icon: "bi-camera-video",
  // 	url: appLinks.home.index,
  // 	view: "all",
  // 	roles: ["all"],
  // },
  {
    name: "Home",
    icon: "bi-house",
    url: appLinks.home.index,
    view: "all",
    roles: [ 'superuser', 'admin', 'doctor', 'nurse', 'frontdesk', 'partner' ]
  },
  {
    name: "Care",
    icon: "bi-clipboard2-pulse",
    url: appLinks.care.index,
    view: "all",
    roles: [ 'superuser', 'admin', 'doctor', 'nurse' ]
  },
  {
    name: "Patients",
    icon: "bi-people",
    url: appLinks.catalog.patients,
    view: "all",
    roles: [ 'superuser', 'admin', 'doctor', 'nurse' ]
  },
  {
    name: "Reports",
    icon: "bi-file-text",
    url: appLinks.catalog.reports,
    view: "desktop",
    roles: [ 'superuser', 'admin', 'doctor', 'partner' ]
  },
  {
    name: "Facility",
    icon: "bi-hospital",
    url: appLinks.facility.index,
    view: "all",
    roles: [ 'superuser', 'admin', ],
    children: [
      {
        name: "Admin",
        icon: "bi-building",
        url: appLinks.facility.admin,
        view: "desktop",
      },
      {
        name: "Finance",
        icon: "bi-wallet2",
        url: appLinks.facility.finance,
        view: "desktop",
      },
      {
        name: "Human Resource",
        icon: "bi-people",
        url: appLinks.facility.hr,
        view: "desktop",
      },
      {
        name: "Service providers",
        icon: "bi-briefcase",
        url: appLinks.facility.serviceProviders,
        view: "desktop",
      },
      {
        name: "Inventory",
        icon: "bi-archive",
        url: "#",
        view: "desktop",
      },
    ]
  },
  {
    name: "Setup",
    icon: "bi-gear",
    url: appLinks.setup.index,
    view: "desktop",
    roles: [ 'superuser', 'admin', 'doctor' ],
    children: [
      {
        name: "Accounts",
        icon: "bi-shield",
        url: appLinks.setup.accounts,
        view: "desktop",
      },
      {
        name: "Logs",
        icon: "bi-body-text",
        url: appLinks.setup.logs,
        view: "desktop",
      },
      {
        name: "Settings",
        icon: "bi-gear",
        url: appLinks.setup.settings,
        view: "desktop",
      },
      // {
      //   name: "Service providers",
      //   icon: "bi-briefcase",
      //   url: appLinks.setup.serviceProviders,
      //   view: "desktop",
      // },
      // {
      //   name: "Roles",
      //   icon: "bi-lock",
      //   url: appLinks.setup.roles,
      //   view: "desktop",
      // role: [ roles.superuser, roles.admin ],
      // },
      // {
    ],
  },
  {
    name: "Profile",
    icon: "bi-person",
    url: appLinks.profile.index,
    view: "mobile",
    roles: [ 'superuser', 'admin', 'doctor', 'nurse', 'partner', 'frontdesk' ]
  },
  // {
  //   name: "About",
  //   icon: "bi-info",
  //   url: appLinks.profile.index,
  //   view: "desktop",
  //   roles: [ 'superuser', 'admin', 'doctor', 'nurse', 'partner', 'frontdesk' ]
  // },
];

export const Regions = (
  <>
    <Option value="Ahafo">Ahafo</Option>
    <Option value="Ashanti">Ashanti</Option>
    <Option value="Bono East">Bono East</Option>
    <Option value="Brong Ahafo">Brong Ahafo</Option>
    <Option value="Central">Central</Option>
    <Option value="Eastern">Eastern</Option>
    <Option value="Greater Accra">Greater Accra</Option>
    <Option value="North East">North East</Option>
    <Option value="Northern">Northern</Option>
    <Option value="Oti">Oti</Option>
    <Option value="Savannah">Savannah</Option>
    <Option value="Upper East">Upper East</Option>
    <Option value="Upper West">Upper West</Option>
    <Option value="Western">Western</Option>
    <Option value="Western North">Western North</Option>
    <Option value="Volta">Volta</Option>
  </>
);

export const laboratoryCategories = (
  <>
    <Option value="Chemical Pathology">Chemical Pathology</Option>
    <Option value="Haematology">Haematology</Option>
    <Option value="Immunology">Immunology</Option>
    <Option value="Microbiology">Microbiology</Option>
    <Option value="Radiology">Radiology</Option>
    <Option value="Biochemistry">Biochemistry</Option>
  </>
);

export const medicalHistoryIllnesses = (
  <>
    <Option value="Hypertension">Hypertension</Option>
    <Option value="Diabetes Mellitus">Diabetes Mellitus</Option>
    <Option value="Bronchial Asthma">Bronchial Asthma</Option>
    <Option value="Heart Failure">Heart Failure</Option>
    <Option value="Stroke">Stroke</Option>
    <Option value="Seizure Disorder">Seizure Disorder</Option>
    <Option value="Sickle Cell Disease">Sickle Cell Disease</Option>
    <Option value="Liver Cirrhosis">Liver Cirrhosis</Option>
    <Option value="Renal Failure">Renal Failure</Option>
    <Option value="Cancer">Cancer</Option>
  </>
);


export const drugList = (
  <>
    <Option value="21ST CENT APPLE CIDER VINEGAR TABS">21ST CENT APPLE CIDER VINEGAR TABS</Option>
    <Option value="1ST AID ELBOW SUPPORT">1ST AID ELBOW SUPPORT</Option>
    <Option value="A.P.C TAB 4'S">A.P.C TAB 4'S</Option>
    <Option value="ABONIKI  BALM">ABONIKI  BALM</Option>
    <Option value="ADOM KOOKO CAPS">ADOM KOOKO CAPS</Option>
    <Option value="ALAXIN SUSP">ALAXIN SUSP</Option>
    <Option value="ASPIRIN TAB 300MG 16'S">ASPIRIN TAB 300MG 16'S</Option>
    <Option value="AXACEF SUSP">AXACEF SUSP</Option>
    <Option value="BACTIFLOX  500MG /TAB">BACTIFLOX  500MG /TAB</Option>
    <Option value="BELLA OINTMENT">BELLA OINTMENT</Option>
    <Option value="BLUSOPT EYE DROP">BLUSOPT EYE DROP</Option>
    <Option value="ROCEPHIN 1G/ IV">ROCEPHIN 1G/ IV</Option>
    <Option value="SUNDOWN CALCIUM + VIT D3 120'S">SUNDOWN CALCIUM + VIT D3 120'S</Option>
    <Option value="TIGER CAPSULES PK">TIGER CAPSULES PK</Option>
    <Option value="TOP FEVER SYRUP">TOP FEVER SYRUP</Option>
    <Option value="TRIGEL  SACHET 2'S">TRIGEL  SACHET 2'S</Option>
    <Option value="TUMS TABS 96'S">TUMS TABS 96'S</Option>
    <Option value="VAL. B'CO 60'S">VAL. B'CO 60'S</Option>
    <Option value="VENTOLIN SYRUP">VENTOLIN SYRUP</Option>
    <Option value="VITA-SLIM CAP">VITA-SLIM CAP</Option>
    <Option value="WELLMAN DRINK">WELLMAN DRINK</Option>
    <Option value="XYLOCAIN INJ">XYLOCAIN INJ</Option>
    <Option value="ZZOWIN TAB 20'S">ZZOWIN TAB 20'S</Option>
  </>
);

export const SaveButtonActions = {
  ADD_PATIENT: "add_patient",
  ADD_CALL: "add_call",
  ADD_CONSULTATION: "add_consultation",
  ADD_DOCTOR: "add_doctor",
  ADD_STAFF_GROUP: "add_staff_group",
  ADD_PHARMACY: "add_pharmacy",
  ADD_LABORATORY: "add_laboratory",
  ADD_INSURANCE: "add_insurance",
  ADD_INSURANCETYPE: "add_insurance_type",
  ADD_SERVICE_GROUP: "add_service_group",
  // 
  ADD_PRESCRIPTION: "add_prescription",
  ADD_DIAGNOSIS: "add_diagnosis",
};

export const CATEGORY_TYPES = [
  { value: 'expense', label: 'Expenditure' },
  { value: 'income', label: 'Income' },
  { value: 'support', label: 'Support' },
  { value: 'service', label: 'Service' },
];


export const ATTENDANCE_PURPOSE = [
  { value: 'care', label: 'For Care' },
  { value: 'appointment', label: 'Appointment' },
  { value: 'patient-visit', label: 'Patient Visit' },
  { value: 'official', label: 'Official' },
  { value: 'enquiry', label: 'Enquiry' },
  { value: 'other', label: 'Other' },
];


export const FEEDBACK = {
  ratings: [ 1, 2, 3, 4, 5 ],//[0.5, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5 ],
  reasons: [
    { reason: 'Long Wait Hours', value: 'long_wait_hours', rate: 1 },
    { reason: 'Bad facility', value: 'bad_facility', rate: 1 },
    { reason: "Staff didn't care", value: 'staff_didnt_care', rate: 1 },
    { reason: "Expensive services", value: 'expensive_services', rate: 1 },
    { reason: 'I expected more', value: 'i_expected_more', rate: 2 },
    { reason: 'Disorganized', value: 'disorganized', rate: 2 },
    { reason: 'Smooth and fast', value: 'smooth_and_fast', rate: 3 },
    { reason: 'Professional staff and care', value: 'professional_staff_and_care', rate: 3 },
    { reason: 'Modern infrastructure', value: 'modern_infrastructure', rate: 3 },
    { reason: 'Fast and reliable', value: 'fast_and_reliable', rate: 4 },
    { reason: 'Great Customer Service', value: '', rate: 4 },
    { reason: 'World class', value: 'world_class', rate: 5 },
    { reason: 'Friendly staff', value: 'friendly_staff', rate: 5 },
    { reason: 'Frictionless process', value: 'frictionless_process', rate: 5 },
    { reason: 'Great communication', value: 'great_communication', rate: 5 },
  ],
};



export const termsandConditions = `
          This system is for the use of authorised users only in accordance with
          Rivia security policies and procedures. Individuals using this application without
          authorisation or in excess of their authority are subject to sanctioning
          procedures by Rivia authorities and/or law enforcement officials.
          Rivia will not be responsible for any misuse or personal use of any kind,
          in its information systems, and reserves the right for monitoring systems usage
          to control abusive situations or security policy violations.
          Your continuous use of this system signifies acceptance of these terms and conditions
`;