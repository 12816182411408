import { Skeleton } from "@mantine/core";
import { Select } from "antd";
import _ from "lodash";

const AddReviewComponent = ( { symptoms = [], data, onUpdate, onDelete } ) => {

    const { Option } = Select;


    return (
        <div className="row mb-2">
            <div className="col-4">
                {
                    _.isEmpty( symptoms ) ?
                        <Skeleton>loading</Skeleton> :
                        <Select
                            required
                            allowClear
                            size="medium"
                            showSearch
                            optionFilterProp="children"
                            filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                            filterSort={ ( optionA, optionB ) =>
                                optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                            }
                            value={ data.symptom }
                            onChange={ ( v ) => onUpdate( data.recordId, 'symptom', v ) }
                            id="symptom" className="w-100" placeholder="select symptom"
                        >
                            {
                                [ ...new Set( symptoms.map( s => s.symptom ) ) ]?.map( st =>
                                    <Option value={ st }>{ st }</Option>
                                )
                            }
                        </Select>
                }
            </div>
            <div className="col-6">
                {
                    _.isEmpty( symptoms ) ?
                        <Skeleton>loading..</Skeleton> :
                        <Select
                            required
                            allowClear
                            size="medium"
                            showSearch
                            optionFilterProp="children"
                            filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                            filterSort={ ( optionA, optionB ) =>
                                optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                            }
                            value={ data.type }
                            onChange={ ( v ) => onUpdate( data.recordId, 'type', v ) }
                            id="type" className="w-100" placeholder="select type"
                        >
                            {
                                symptoms.map( sp =>
                                    sp.symptom === data.symptom &&
                                    <Option value={ sp.type }>{ sp.type }</Option>
                                )
                            }
                        </Select>
                }
            </div>
            <div className="col-1 g-0">
                <button className='button is-small text-danger'
                    onClick={ () => onDelete( data.recordId ) }
                >
                    <span className="bi bi-trash"></span>
                </button>
            </div>
        </div >
    );
};

export default AddReviewComponent;