import DailyFrame from "@daily-co/daily-js";
import { useEffect } from "react";
import { PageTitle } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { deleteExitVideoCall, getVideoCall } from "../../helpers/api";
import { isAuthenticated } from "../../helpers/auth";
import { appLinks } from "../../helpers/config";
import { NewConsultation } from "./components/new-consultation";
import { nanoid } from 'nanoid';
import { message } from "antd";

const VideoCallPage = ( props ) => {

  const params = useParams();
  const meetingId = `${ params.roomId }-${ nanoid( 9 ) }`;
  const nav = useNavigate();

  // const videoFrame = document.getElementById( 'video-iframe' );
  const videoFrame = document.createElement( 'iframe' );
  videoFrame.setAttribute( "allow", "camera;microphone" );


  // the agent goes into his meeting room but the meeting id
  // is a combination of agent's room number plus a random
  // 9 digit code (with a leading dash) automatically used to create unique meeting room
  // this is a security measure.

  // This is to ensure that all meetings regardless if its the same agent, are unique
  // from each other. The system automatically ensures this


  const initiateCall = () => {
    getVideoCall( meetingId )
      .then( ( res ) => {
        if ( res.status === 200 ) {
          DailyFrame.wrap( videoFrame, {
            activeSpeakerMode: true,
            showFullscreenButton: true,
            showLeaveButton: true,
            url: `https://wafflellc.daily.co/${ meetingId }`,
            userName: `${ res.data.staff.firstName } ${ res.data.staff.lastName }`,
            showParticipantsBar: true,
            showLocalVideo: true,

          } ).join();

        }
        throw res;
      } )
      .catch( ( ex ) => {
        // message.error( "Oops... We encountered an error" );
        console.log( ex );
      } );
  };


  const handleExitCall = () => {
    deleteExitVideoCall( meetingId );
  };


  // authenticate user
  useEffect( () => {
    if ( !isAuthenticated() ) {
      nav( appLinks.login );
      return;
    }
  }, [] );


  useEffect( () => {
    document.getElementById( 'videoFrame' ).appendChild( videoFrame );
    initiateCall();

    return () => {
      message.info( 'cleaning up after you...' );
      handleExitCall();
    };
  }, [] );

  return (
    <div className="video-consultation">
      <PageTitle title="Video Consultation" />
      <div className="row">
        <div className="col-md-8 g-0 col-12">
          <div id="videoFrame">
            {/* the error was a chrome update which required the allow attribute on iframe */ }
            {/* for camera and mic to work */ }
            {/* <iframe id="video-iframe" allow="camera;microphone"></iframe> */ }
          </div>
        </div>
        <div className="col-md-4 g-0 col-12">
          <div className="me-5 my-3 consultation-comp">
            <NewConsultation meetingId={ meetingId } />
          </div>
        </div>
      </div>
    </div>
  );
};

export { VideoCallPage };
