
export const rbac = {

    // admin role
    'superuser': {
        // --> permissions
        // resources
        'admin': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'users': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'patients': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'consultations': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'appointment': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'expense': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'facility': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'branch': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'staffs': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'reports': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'calls': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'pharmacies': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'laboratories': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'insurances': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'serviceProviders': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },

    },


    // admin role
    'admin': {
        // --> permissions
        // resources
        'admin': { //meta
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'users': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'patients': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'consultations': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'facility': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'branch': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'expense': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'appointment': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'staffs': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'reports': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'calls': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'pharmacies': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'laboratories': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'insurances': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'serviceProviders': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
    },

    // doctor role
    'doctor': {
        // --> permissions
        // resources
        'users': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'patients': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'consultations': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'appointment': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'facility': {
            'canRead': true,
        },
        'branch': {
            'canRead': true,
        },
        'expense': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'staffs': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'reports': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'calls': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'pharmacies': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'laboratories': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'insurances': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'serviceProviders': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
    },

    // nurse role
    'nurse': {
        // --> permissions
        // resources
        'patients': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'consultations': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'appointment': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'calls': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'pharmacies': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'expense': {
            'canCreate': true,
            'canRead': false,
            'canUpdate': false,
            'canDelete': false,
        },
        'laboratories': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'insurances': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'serviceProviders': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
    },


    // frontdesk role
    'frontdesk': {
        // --> permissions
        // resources
        'patients': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'consultations': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'appointment': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': true,
        },
        'expense': {
            'canCreate': true,
            'canRead': false,
            'canUpdate': false,
            'canDelete': false,
        },
        'calls': {
            'canCreate': true,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },
        'pharmacies': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'laboratories': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'insurances': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'serviceProviders': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
    },


    // partner role
    'partner': {
        // --> permissions
        // resources

        'patients': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },
        'reports': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': false,
            'canDelete': false,
        },

    },

    // laboratory role
    'laboratory': {
        // --> permissions
        // resources
        'consultations': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },

    },

    // pharmacy role
    'pharmacy': {
        // --> permissions
        // resources

        'consultations': {
            'canCreate': false,
            'canRead': true,
            'canUpdate': true,
            'canDelete': false,
        },

    },

};
