import { useState } from "react";
import { RequiredIndicator } from '..';
import { useMutation, useQuery } from "react-query";
import _ from "lodash";
import { ATTENDANCE_PURPOSE } from "../../helpers/config";
import { Select, Skeleton } from "@mantine/core";
import { IconArrowLeft, IconHomeDown, IconHomeUp } from "@tabler/icons";
import { getAllPublicFacilities, getFacilitySettings, postPublicAttendance, putPublicAttendance } from "../../helpers/api";
import { message } from "antd";

let attendanceStateTemplate = {
    firstName: '',
    lastName: '',
    contact: '',
    email: '',
    purpose: "",
    attendanceType: "",
    timeIn: null,
    timeOut: null,
    facilitySlug: null,
};

const GuestAttendanceRegistration = ( props ) => {

    const [ state, setState ] = useState( attendanceStateTemplate );
    // const [ outState, setOutState ] = useState( { contact: '', lastName: '', firstName: '', notes: '' } );

    const { data: facilities = [], isFetching } = useQuery( {
        queryFn: getAllPublicFacilities,
        queryKey: [ 'all-facilities' ],
    } );

    const { mutateAsync: ClockIn, isLoading } = useMutation( ( data ) => postPublicAttendance( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                setState( attendanceStateTemplate );
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    const { mutateAsync: ClockOut, isLoading: isClockingOut } = useMutation( ( data ) => putPublicAttendance( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                setState( attendanceStateTemplate );
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    return (
        <div>
            {
                !state?.attendanceType
                &&
                <>
                    <button
                        onClick={ () => setState( { ...state, attendanceType: 'checkin' } ) }
                        className="button d-block w-100 mb-3 is-large app-btn">
                        <IconHomeUp className="me-3" />
                        Check In
                    </button>
                    <button
                        onClick={ () => setState( { ...state, attendanceType: 'checkout' } ) }
                        className="button d-block w-100 is-large app-btn">
                        <IconHomeDown className="me-3" />
                        Check Out
                    </button>
                </>
            }
            {
                state?.attendanceType === 'checkin' &&
                <>
                    <button
                        onClick={ () => setState( { attendanceType: '' } ) }
                        className="button mb-4 app-btn h5"><IconArrowLeft className="me-3" />
                        Go Back
                    </button>
                    <CheckInForm
                        state={ state }
                        setState={ ( { field, value } ) => setState( { ...state, [ field ]: value } ) }
                        onSubmit={ () => ClockIn( state ) }
                        isSubmitting={ isLoading }
                        isFetching={ isFetching }
                        facilities={ facilities }
                    />
                </>
            }
            {
                state?.attendanceType === 'checkout' &&
                <>
                    <button
                        onClick={ () => setState( { attendanceType: '' } ) }
                        className="button mb-4 app-btn h5"><IconArrowLeft className="me-3" /> Go Back</button>
                    <CheckOutForm
                        state={ state }
                        setState={ ( { field, value } ) => setState( { ...state, [ field ]: value } ) }
                        onSubmit={ () => ClockOut( state ) }
                        isSubmitting={ isClockingOut }
                        isFetching={ false }
                    />
                </>
            }
        </div>
    );
};

export default GuestAttendanceRegistration;




const CheckInForm = ( { state, setState, facilities = [], onSubmit, isSubmitting, isFetching } ) => {
    return (
        <div autoComplete="off" className="row">
            <input class="gLFyf gsfi" jsaction="paste:puy29d;" hidden
                maxlength="2048" name="q"
                type="text" aria-autocomplete="both" aria-haspopup="false" autocapitalize="off" autocomplete="off"
                autocorrect="off" autofocus="" role="combobox" spellcheck="false" title="Pesquisar" value=""
                aria-label="Pesquisar" data-ved="0ahUKEwjw0svW6brxAhWdqJUCHXoYDRsQ39UDCAQ"></input>

            <div className="col-12 mb-3">
                {
                    isFetching ?
                        <Skeleton>Loading</Skeleton> :
                        <div className="field">
                            <label className="mb-0 label" htmlFor="facility">
                                Where are you?
                                <RequiredIndicator />
                            </label>
                            <Select
                                id='facility'
                                nothingFound="No match"
                                size="xl"
                                clearable
                                searchable
                                value={ state?.facilitySlug }
                                onChange={ value => setState( { field: 'facilitySlug', value } ) }
                                placeholder="name of Rivia Clinic"
                                data={
                                    facilities?.map( d => {
                                        return {
                                            value: d.slug,
                                            label: d.facilityName
                                        };
                                    } )
                                }
                            />
                        </div>
                }
            </div>
            {
                state?.facilitySlug &&
                <>
                    <div className="col-12 mb-3">
                        <div className="field">
                            <label className="mb-0 label" htmlFor="firstName">
                                First Name
                                <RequiredIndicator />
                            </label>
                            <input
                                required
                                id="firstName"
                                autoComplete="new"
                                placeholder="first name"
                                value={ state?.firstName }
                                onChange={ e => setState( { field: 'firstName', value: e.target.value } ) }
                                type="text"
                                className="input is-large" />
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="field">
                            <label className="mb-0 label" htmlFor="lastName">
                                Last Name
                                <RequiredIndicator />
                            </label>
                            <input
                                required
                                id="lastName"
                                placeholder="last name"
                                autoComplete="new-pas"
                                value={ state?.lastName }
                                onChange={ e => setState( { field: 'lastName', value: e.target.value } ) }
                                type="text"
                                className="input is-large" />
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="field">
                            <label className="mb-0 label" htmlFor="contact">
                                Phone Number
                                <RequiredIndicator />
                            </label>
                            <input
                                required
                                id="contact"
                                placeholder="active phone number"
                                autoComplete="new-pass"
                                type="tel"
                                value={ state?.contact }
                                onChange={ e => setState( { field: 'contact', value: e.target.value } ) }
                                className="input is-large" />
                        </div>
                    </div>

                    <div className="col-12 mb-3">
                        <div className="field">
                            <label className="mb-0 label" htmlFor="purpose">
                                Purpose
                                <RequiredIndicator />
                            </label>
                            <Select
                                id='purpose'
                                nothingFound="No match"
                                size="xl"
                                clearable
                                searchable
                                value={ state?.purpose }
                                onChange={ value => setState( { field: 'purpose', value } ) }
                                placeholder="what's the visit's purpose?"
                                data={
                                    ATTENDANCE_PURPOSE.map( d => {
                                        return {
                                            value: d.value,
                                            label: d.label
                                        };
                                    } )
                                }
                            />
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="field">
                            <label className="mb-0 label" htmlFor="email">
                                Email Address
                            </label>
                            <input
                                id="email"
                                type="email"
                                autoComplete="new-passw"
                                value={ state?.email }
                                onChange={ e => setState( { field: 'email', value: e.target.value } ) }
                                placeholder="active email address (optional)"
                                className="input is-large" />
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="field">
                            <label className="mb-0 label" htmlFor="notes">
                                Notes
                            </label>
                            <textarea
                                id="notes"
                                value={ state?.notes }
                                // autoComplete="new"
                                onChange={ e => setState( { field: 'notes', value: e.target.value } ) }
                                placeholder="share additional information (optional)"
                                className="textarea is-large" />
                        </div>
                    </div>

                    <div className="col-12">
                        <button
                            onClick={ onSubmit }
                            className={ `button h6 is-large app-btn btn-prim mt-3 ${ isSubmitting && 'is-loading' }` }
                        >
                            <IconHomeUp className="me-3" />
                            Check-In
                        </button>
                    </div>

                </>
            }
        </div>
    );
};


const CheckOutForm = ( { state, setState, onSubmit, isSubmitting, isFetching } ) => {
    return (
        <div>
            <div autoComplete="off" className="row">
                <div className="col-12 mb-3">
                    <div className="field">
                        <label className="mb-0 label" htmlFor="lastName">
                            Last Name
                            <RequiredIndicator />
                        </label>
                        <input
                            required
                            id="lastName"
                            placeholder="last name"
                            autoComplete="new-p"
                            value={ state?.lastName }
                            onChange={ e => setState( { field: 'lastName', value: e.target.value } ) }
                            type="text"
                            className="input is-large" />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="field">
                        <label className="mb-0 label" htmlFor="contact">
                            Phone Number
                            <RequiredIndicator />
                        </label>
                        <input
                            required
                            id="contact"
                            placeholder="active phone number"
                            autoComplete="new"
                            type="tel"
                            value={ state?.contact }
                            onChange={ e => setState( { field: 'contact', value: e.target.value } ) }
                            className="input is-large" />
                    </div>
                </div>
                {/* <>
                <div className="col-12 mb-3">
                    <div className="field">
                        <label className="mb-0 label" htmlFor="notes">
                            Notes
                        </label>
                        <textarea
                            id="notes"
                            // autoComplete="new"
                            value={ state?.notes }
                            onChange={ e => setState( { ...state, notes: e.target.value } ) }
                            placeholder="share additional information (optional)"
                            className="textarea is-large" />
                    </div>
                </div>
            </> */}
                <div className="col-12 text-center">
                    <button
                        onClick={ onSubmit }
                        className={ `button h6 is-large app-btn btn-prim mt-3 ${ isSubmitting && 'is-loading' }` }
                    >
                        <IconHomeDown className="me-3" />
                        Correct, Check-Out
                    </button>
                </div>
            </div>
        </div>
    );
};
