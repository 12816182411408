import DailyFrame from "@daily-co/daily-js";
import { createRef, useEffect } from "react";
import { PageTitle } from "../../components";
import { useParams } from "react-router-dom";

const PatientVideoPage = ( props ) => {
  const params = useParams();
  const videoContainer = createRef();

  useEffect( () => {
    const videoFrame = DailyFrame.createFrame( {
      activeSpeakerMode: true,
      showFullscreenButton: true,
      showLeaveButton: true,
      showParticipantsBar: true,
      iframeStyle: {
        position: "relative",
        width: "100%",
        height: "100%",
        border: "0",
        zIndex: 9999,
      },
    } ).join( {
      url: `https://wafflellc.daily.co/${ params.roomId }`,
    } );
    // videoContainer.current.appendChild(videoFrame);
  }, [ params.roomId ] );

  return (
    <div className="schedule-status">
      <PageTitle title="Video Consultation" />
      <div id="meeting-container" ref={ videoContainer }></div>
    </div>
  );
};

export { PatientVideoPage };
