import { Paper } from '@mantine/core';
import { Dropdown, Input, Menu, Select } from 'antd';
import React from 'react';

export default function ConsultationFilterBar ( { data, onFilter, onReset, excelExport } ) {
    const { Search } = Input;
    const { Option } = Select;


    const onMenuClick = ( e ) => {
        if ( e.key === "1" ) excelExport();
        // if ( e.key === "2" ) alert( 'pdf' );
        // if ( e.key === "3" ) alert( 'email' );
    };


    const menu = (
        <Menu
            onClick={ onMenuClick }
            items={ [
                {
                    key: "1",
                    label: <>
                        <span className='bi bi-file-excel me-2'></span>
                        Excel
                    </>
                },
                // {
                //     key: "2",
                //     label: <>
                //         <span className='bi bi-file-pdf me-2'></span>
                //         PDF
                //     </>
                // },
                // {
                //     key: "3",
                //     label: <>
                //         <span className='bi bi-envelope me-2'></span>
                //         Email
                //     </>
                // },

            ] }
        />
    );


    return (
        <Paper className="border p-3 d-flex justify-content-between">
            <div>
                <Search
                    style={ { maxWidth: "200px" } }
                    onChange={ ( e ) =>
                        onFilter(
                            data?.filter(
                                ( c ) =>
                                    c.patient.firstName
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.patient.lastName
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.patient.gender
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.doctor.firstName
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.doctor.lastName
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.doctor.gender
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.type
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() ) ||
                                    c.code
                                        .toLowerCase()
                                        .includes( e.target.value.toLowerCase() )
                            )
                        )
                    }
                    title="search here"
                    placeholder="search here"
                />
                <Select
                    style={ { maxWidth: "150px" } }
                    name="bygender"
                    className="mx-2"
                    onChange={ ( v ) =>
                        onFilter( data?.filter( ( d ) => d.patient.gender === v ) )
                    }
                    defaultValue="patient gender">
                    <Option value="Male" key="1">Male</Option>
                    <Option value="Female" key="2">Female</Option>
                </Select>
                <Select
                    style={ { maxWidth: "130px" } }
                    name="byType"
                    onChange={ ( v ) =>
                        onFilter( data?.filter( ( d ) => d.type === v ) )
                    }
                    defaultValue="select type">
                    { [ ...new Set( data?.map( ( d ) => d.type ) ) ].map( ( v ) => (
                        <Option value={ v } key={ v }>
                            { v }
                        </Option>
                    ) ) }
                </Select>
                {/* <Select
                    style={ { maxWidth: "130px" } }
                    name="byStatus"
                    className="ms-2"
                    onChange={ ( v ) =>
                        onFilter( data?.filter( ( d ) => d.status === v ) )
                    }
                    defaultValue="select status">
                    { [ ...new Set( data?.map( ( d ) => d.status ) ) ].map( ( v ) => (
                        <Option value={ v } key={ v }>
                            { v }
                        </Option>
                    ) ) }
                </Select> */}
            </div>
            <div className='d-flex'>
                <Dropdown overlay={ menu }>
                    <button className="app-btn btn-prim d-flex align-items-center">
                        <span className="bi bi-file-earmark-arrow-down me-2"></span>
                        Export to
                    </button>
                </Dropdown>
                {/* <button
                    className="app-btn btn-prim d-flex mx-2 align-items-center">
                    <span className="bi bi-list me-2"></span>
                    More Filters
                </button> */}
                <button
                    onClick={ onReset }
                    className="app-btn btn-prim d-flex align-items-center ms-2">
                    <span className="bi bi-arrow-clockwise me-2"></span>
                    Reset filters
                </button>
            </div>
        </Paper>
    );
}