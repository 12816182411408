import { Loader, Skeleton } from "@mantine/core";
import { Divider, message } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAllDrugs, getPrescriptions, putPrescriptions } from "../../../helpers/api";
import AddPrescriptionComponent from "./add-prescription";
import { ErrorBoundary } from "../../../components";

const EditPrescriptionComponent = ( { consultationId, onRefresh } ) => {

    const [ prescriptions, setPres ] = useState( [] );
    const [ consultation, setConsultation ] = useState( {} );

    const { data: serverDrugs = [] } = useQuery( {
        queryFn: () => getAllDrugs(),
        queryKey: [ 'drugs' ],
    } );

    const { isError, isFetching, refetch } = useQuery( {
        queryFn: () => getPrescriptions( consultationId ),
        queryKey: [ 'prescriptions', consultationId ],
        onSuccess: data => {

            // add the recordId field to fetched data to enable tracking
            const newSet = data.map( ( d, i ) => {
                return { ...d, recordId: ++i };
            } );
            setPres( newSet );
            setConsultation( data[ 0 ]?.consultation );
        }
    } );


    const { mutateAsync: updatePrescriptions, isLoading } = useMutation( ( data ) => putPrescriptions( consultationId, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    // prescriptions
    const handleDeletePrescription = ( recordId ) => {
        const updated = prescriptions.filter( pres => pres.recordId !== recordId );
        setPres( updated );
    };

    const handleUpdatePrescription = ( recordId, field, value ) => {
        let currentPres = prescriptions.filter( pres => pres.recordId === recordId )[ 0 ];
        currentPres[ field ] = value;
        setPres( [
            ...prescriptions.filter( pres => pres.recordId !== recordId ),
            currentPres
        ] );
    };

    const handleAddPrescription = () => {
        setPres( [
            ...prescriptions,
            {
                recordId: prescriptions.length + 1,
                medicine: '',
                dosage: '',
                strength: '',
                frequency: '',
                duration: ''
            }
        ] );
    };


    return (
        <>

            { isFetching ?
                <div className="text-center"><Loader /> please wait...</div>
                :
                isError ?
                    <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                    :
                    <div className="row mb-1">
                        {
                            prescriptions?.map( ( pres, index ) =>
                                <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd> }>

                                    <div className="col-1"><kbd>{ ++index }</kbd></div>
                                    <div className="col-11">
                                        {
                                            _.isEmpty( serverDrugs ) ?
                                                <Skeleton>loading..</Skeleton> :
                                                <AddPrescriptionComponent drugs={ serverDrugs } key={ pres.id } data={ pres } onUpdate={ handleUpdatePrescription } onDelete={ handleDeletePrescription } />
                                        }
                                    </div>
                                    <Divider />
                                </ErrorBoundary>
                            )
                        }
                    </div>
            }

            {
                !consultation?.isComplete &&
                <>
                    <div>
                        <button className={ `button is-small mt-2 ${ isLoading && ' is-loading ' }` }
                            onClick={ handleAddPrescription }>Add Prescription</button>
                    </div>
                    <button onClick={ () => updatePrescriptions( prescriptions ) }
                        id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                        Update Prescriptions
                    </button>
                </>
            }
        </>
    );
};


export default EditPrescriptionComponent;
