import { CopyRightTag, PageTitle } from '../../components';
import PatientPortalSearch from "./components/patient-portal-search";
import logo from '../../static/img/riviaos-logo.png';

const PatientPortal = ( props ) => {

    return (

        <div className='row portal'>
            <PageTitle title="Consultation Status" />
            <div className="mx-auto col-md-10 col-12">
                <div className="text-center py-3">
                    <img src={ logo } alt="riviaos logo" width={ 200 } />
                </div>
                <div className="inner-layer border">
                    <div className="content my-2">
                        <div className="schedule-status">
                            <div className="row">
                                <div className="mx-auto col-12 content p-md-5 p-2">
                                    <PatientPortalSearch />
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                <div className="row text-center py-3">
                    <CopyRightTag />
                </div>
            </div >
        </div >

    );
};

export { PatientPortal };


