import { Select, Input } from 'antd';
import { drugList } from '../../../helpers/config';
import _ from 'lodash';
import { Skeleton } from '@mantine/core';

const AddPrescriptionComponent = ( { data, drugs, onDelete, onUpdate } ) => {

    const { Option } = Select;

    return (
        <>
            <div className="row mb-1">
                <div className="col-3">Drug Name:</div>
                <div className="col-9">
                    {
                        _.isEmpty( drugs ) ?
                            <Skeleton>loading...</Skeleton> :
                            <Select
                                required
                                showSearch
                                allowClear
                                value={ data.medicine }
                                disabled={ data?.isIssued }
                                autoFocus
                                id="medicine"
                                onChange={ ( v ) => onUpdate( data.recordId, 'medicine', v ) }
                                className="d-block"
                                placeholder="generic / trade name"
                            >
                                {/* { drugList } */ }
                                {
                                    drugs?.map( dr =>
                                        <Option key={ dr.id } value={ dr.drugName }>{ dr.drugName }</Option>
                                    )
                                }
                            </Select>
                    }
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">Strength:</div>
                <div className="col-9">
                    <Input
                        className=" is-small"
                        value={ data.strength }
                        disabled={ data?.isIssued }
                        type="text"
                        onChange={ ( e ) => onUpdate( data.recordId, 'strength', e.target.value ) }
                        required
                        id="strength"
                        placeholder="strength"
                    />
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">Frequency:</div>
                <div className="col-9">
                    <Select
                        required
                        id="frequency"
                        className="d-block"
                        placeholder="frequency"
                        value={ data.frequency }
                        disabled={ data?.isIssued }
                        onChange={ ( v ) => onUpdate( data.recordId, 'frequency', v ) }
                        allowClear
                    >
                        <Option value="1x day">1x day (OD)</Option>
                        <Option value="2x day">2x day (BD)</Option>
                        <Option value="3x day">3x day (TDS)</Option>
                        <Option value="4x day">4x day (QID)</Option>
                        <Option value="5x day">5x day</Option>
                        <Option value="MANE">MORNING (MANE)</Option>
                        <Option value="STAT">STAT</Option>
                        <Option value="PRN">PRN</Option>
                        <Option value="COS">COS</Option>
                        <Option value="NOCTE">NOCTE</Option>
                        <Option value="WEEKLY">WEEKLY</Option>
                        <Option value="EVERY 1 HOUR">EVERY 1 HOUR</Option>
                        <Option value="EVERY 2 HOURS">EVERY 2 HOURS</Option>
                        <Option value="EVERY 3 HOURS">EVERY 3 HOURS</Option>
                        <Option value="EVERY 4 HOURS">EVERY 4 HOURS</Option>
                        <Option value="EVERY 5 HOURS">EVERY 5 HOURS</Option>
                    </Select>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">Duration:</div>
                <div className="col-9">
                    <Input
                        className=" is-small"
                        value={ data.duration }
                        disabled={ data?.isIssued }
                        type="text"
                        onChange={ ( e ) => onUpdate( data.recordId, 'duration', e.target.value ) }
                        required
                        id="duration"
                        placeholder="days"
                    />
                </div>
            </div>
            {/* <div className="row mb-1">
                <div className="col-3">Quantity:</div>
                <div className="col-9">
                    <Input
                        className="is-small"
                        value={ data.quantity }
                        disabled={ data?.isIssued }
                        type="text"
                        onChange={ ( e ) => onUpdate( data.recordId, 'quantity', e.target.value ) }
                        required
                        id="quantity"
                        placeholder="quantity"
                    />
                </div>
            </div> */}
            {/* <div className="row mb-1">
                <div className="col-3">Quantity:</div>
                <div className="col-9">
                    <kbd>coming soon!</kbd>
                    <Input
                        className="is-small"
                        value={ data.dosage }
                        disabled={ data?.isIssued }
                        type="text"
                        onChange={ ( e ) => onUpdate( data.recordId, 'dosage', e.target.value ) }
                        required
                        id="dosage"
                        placeholder="dosage"
                    />
                </div>
            </div> */}
            {
                !data?.isIssued &&
                <div>
                    <button className='button is-small text-danger'
                        onClick={ () => onDelete( data.recordId ) }
                    >
                        <strong>Remove</strong> <span className="bi bi-trash"></span>
                    </button>
                </div>
            }

        </>
    );
};

export default AddPrescriptionComponent;