const PayRollPage = ( props ) => {


    return (
        <section className="pt-3">
            <kbd>coming soon</kbd>
        </section>
    );
};

export { PayRollPage };