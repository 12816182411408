import { Divider, message } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { getExcuseDuties } from '../../helpers/api';
import { useQuery } from 'react-query';
import { ExcuseDutyCard } from '../cards';
import { Modal } from '@mantine/core';
import { ExcuseDutyDisplay } from './excuse-duty-display';
import { consultationCodeLength } from '../../helpers/config';

export default function ExcuseDutySearch () {
    const [ duties, setDuties ] = useState( [] );
    const [ code, setCode ] = useState( '' );
    const [ open, setOpen ] = useState( {
        id: '',
        code: '',
        isOpen: false
    } );


    const { isFetching, refetch: searchDuties } = useQuery( {
        queryFn: () => getExcuseDuties( code ),
        queryKey: [ 'excuseDuties', code ],
        enabled: false,
        onSuccess: data => {
            if ( !_.isEmpty( data ) )
                setDuties( data );
            else
                message.error( 'excuse duty not found' );
        }
    } );


    const findDuties = ( e ) => {
        e.preventDefault();

        if ( !code ) {
            message.error( 'no code found' );
            return;
        }
        if ( code.length !== consultationCodeLength ) {
            message.error( 'invalid code' );
            return;
        }

        searchDuties();
    };



    return (
        <div>
            <Modal
                title="Excuse Duty"
                size={ 900 }
                opened={ open.isOpen }
                onClose={ () => setOpen( { ...open, isOpen: false } ) }
            >
                <ExcuseDutyDisplay code={ open.code } excuseDutyId={ open.id } />
            </Modal>
            {
                _.isEmpty( duties ) ?
                    <div className="row">
                        <div className="col-12 mx-auto mt-3">
                            <form onSubmit={ findDuties } className="text-center">
                                <label className="mb-0" htmlFor="title">
                                    Enter consultation code
                                </label>
                                <div className="field">
                                    <input
                                        className="w-50 input text-center"
                                        type="text"
                                        required
                                        value={ code }
                                        onChange={ ( e ) => setCode( e.target.value ) }
                                        autoFocus
                                        id="title"
                                        placeholder="code here"
                                    />
                                </div>
                                <button type='submit' className={ `button h6 app-btn btn-prim ${ isFetching && ' is-loading' }` }>Search</button>
                            </form>
                        </div>
                    </div>
                    :
                    <>
                        <div className="app-bg-primary p-2 w-100 mb-3 text-white rounded">
                            <div className="d-flex justify-content-between">
                                <span className='ms-2'>search results for code: { code }</span>
                                <button className='app-btn me-1 p-0' onClick={ () => {
                                    setCode( '' );
                                    setDuties( [] );
                                } }>
                                    <span className='bi bi-x-circle'></span>
                                </button>
                            </div>
                        </div>
                        {
                            duties.map( d =>
                                <>
                                    <ExcuseDutyCard
                                        data={ d }
                                        openDuty={ ( code, id ) => setOpen( { code, id, isOpen: true } ) }
                                    />
                                    <Divider className='my-2' />
                                </> )
                        }
                    </>

            }
        </div >
    );
}
