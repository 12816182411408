import { Divider, Tabs } from "antd";
import { PageHeader } from "../../components";
import Laboratories from "./components/laboratories";
import Pharmacies from "./components/pharmacies";
import Insurance from "./components/insurance";
import NewPharmacyForm from "./components/new-pharmacy";
import NewLaboratoryForm from "./components/new-laboratory";
import NewInsuranceProviderForm from "./components/new-insurance-provider";
import {
  getAllInsuranceProviders,
  getAllLaboratories,
  getAllPharmacies,
} from "../../helpers/api";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Modal, Menu } from "@mantine/core";
import { hasPermission } from "../../helpers/utilities";
import { action, resouces } from "../../helpers/auth";
import { IconMedicineSyrup, IconMicroscope, IconShieldCheck } from "@tabler/icons";

const ServiceProviders = ( props ) => {

  const [ modal, setModal ] = useState( {
    title: "",
    open: false,
    content: "",
  } );

  const { data: pharms, isFetching: fetchingPharms, refetch: fetchPharmacies } = useQuery( {
    queryFn: getAllPharmacies,
    enabled: false,
    queryKey: [ 'pharmacies' ],
  } );

  const { data: labs, isFetching: fetchingLabs, refetch: fetchLaboratories } = useQuery( {
    queryFn: getAllLaboratories,
    enabled: false,
    queryKey: [ 'laboratories' ],
  } );

  const { data: insurances, isFetching: fetchingInsurances, refetch: fetchInsurances } = useQuery( {
    queryFn: getAllInsuranceProviders,
    enabled: false,
    queryKey: [ 'insurances' ],
  } );

  // const { data: insuranceTypes, isFetching: fetchingInsurTypes, refetch: fetchInsuranceTypes } = useQuery( {
  //   queryFn: getAllInsuranceTypes,
  //   enabled: false,
  //   queryKey: [ 'insuranceTypes' ],
  // } );


  const { TabPane } = Tabs;

  // const onMenuClick = ( e ) => {
  //   if ( e.key === "1" && hasPermission( resouces.pharmacies, action.canCreate ) )
  //     setModal( {
  //       content: <NewPharmacyForm onSuccess={ fetchPharmacies } />,
  //       title: "New Pharmacy",
  //       open: true
  //     } );

  //   if ( e.key === "2" && hasPermission( resouces.laboratories, action.canCreate ) )
  //     setModal( {
  //       content: <NewLaboratoryForm onSuccess={ fetchLaboratories } />,
  //       title: "New Laboratory",
  //       open: true
  //     } );;

  //   if ( e.key === "3" && hasPermission( resouces.insurances, action.canCreate ) )
  //     setModal( {
  //       content: <NewInsuranceProviderForm onSuccess={ fetchInsurances } />,
  //       title: "New Insurance Provider",
  //       open: true
  //     } );;

  //   // if ( e.key === "4" && hasPermission( resouces.insurances, action.canCreate ) )
  //   //   setModal( {
  //   //     content: <NewInsuranceType onSuccess={ fetchInsuranceTypes } />,
  //   //     title: "New Insurance Type",
  //   //     open: true
  //   //   } );;
  // };

  // const menu = (
  //   <Menu
  //     onClick={ onMenuClick }
  //     items={ [
  //       {
  //         key: "1",
  //         label: "Pharmacy",
  //       },
  //       {
  //         key: "2",
  //         label: "Laboratory",
  //       },
  //       {
  //         key: "3",
  //         label: "Insurance Provider",
  //       },
  //       // {
  //       //   key: "4",
  //       //   label: "Insurance Type",
  //       // },

  //     ] }
  //   />
  // );


  const handleTabChange = ( tab ) => {
    if ( tab === "2" ) fetchLaboratories();

    if ( tab === "3" ) fetchInsurances();

    // if ( tab === "4" ) fetchInsuranceTypes();
  };

  useEffect( () => {
    fetchPharmacies();
  }, [] );

  return (
    <section>
      <PageHeader title="Service Providers" hasBackButton>

        <Menu
          trigger="hover"
          withArrow
          transition="scale-y"
          shadow="md"
          width={ 180 }
          // position="bottom-right"
          className="d-block"
        >
          <Menu.Target>
            {
              <button className="app-btn btn-prim fw-bold">
                <span className="bi bi-plus-circle me-2"></span>
                NEW
              </button>
            }
          </Menu.Target>

          <Menu.Dropdown mr={ 90 } >
            <Menu.Item
              onClick={ () =>
                hasPermission( resouces.pharmacies, action.canCreate ) &&
                setModal( {
                  content: <NewPharmacyForm onSuccess={ fetchPharmacies } />,
                  title: "New Pharmacy",
                  open: true
                } )
              }
            >
              <IconMedicineSyrup size={ 20 } color="grey" className="me-2" />
              Pharmacy
            </Menu.Item>
            <Menu.Item
              onClick={ () =>
                hasPermission( resouces.laboratories, action.canCreate ) &&
                setModal( {
                  content: <NewLaboratoryForm onSuccess={ fetchLaboratories } />,
                  title: "New Laboratory",
                  open: true
                } )
              }
            >
              <IconMicroscope size={ 20 } color="grey" className="me-2" />
              Laboratory
            </Menu.Item>
            <Menu.Item
              onClick={ () =>
                hasPermission( resouces.insurances, action.canCreate ) &&
                setModal( {
                  content: <NewInsuranceProviderForm onSuccess={ fetchInsurances } />,
                  title: "New Insurance Provider",
                  open: true
                } )
              }
            >
              <IconShieldCheck size={ 20 } color="grey" className="me-2" />
              Insurance Provider
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

      </PageHeader>
      <Divider className="my-2" />
      <Modal
        title={ modal.title }
        opened={ modal.open }
        onClose={ () => setModal( { ...modal, open: false } ) }
      >
        { modal.content }
      </Modal>

      <Tabs defaultActiveKey="1" onChange={ handleTabChange }>
        <TabPane tab={ <span><i className="bi bi-capsule me-2" /> Pharmacies</span> } key="1">
          <Pharmacies data={ pharms } isLoading={ fetchingPharms } onReload={ fetchPharmacies } />
        </TabPane>
        <TabPane tab={ <span><i className="bi bi-virus me-2" /> Laboratories</span> } key="2">
          <Laboratories data={ labs } isloading={ fetchingLabs } onReload={ fetchLaboratories } />
        </TabPane>
        <TabPane tab={ <span><i className="bi bi-cone-striped me-2" /> Insurance Providers</span> } key="3">
          <Insurance data={ insurances } isloading={ fetchingInsurances } onReload={ fetchInsurances } />
        </TabPane>
        {/* <TabPane tab="Insurance Types" key="4">
          <InsuranceTypes data={ insuranceTypes } isloading={ fetchingInsurTypes } onReload={ fetchInsuranceTypes } />
        </TabPane> */}
      </Tabs>
    </section >
  );
};

export { ServiceProviders };
