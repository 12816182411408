import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { billAtom } from '../../helpers/state/bill';

export default function AddDiscountComponent () {

    const [ bill, setBill ] = useAtom( billAtom );

    return (
        <div>
            {/* Percentage or Figure */ }
            {/* TODO -> update: Make it optional for user to select either percentage or numeric figure */ }
            <input
                type="number"
                step="0.01"
                autoFocus
                value={ bill.discount }
                className="input w-100"
                placeholder='input discount here (e.g 20)'
                onChange={ ( e ) =>
                    setBill( {
                        discount: parseFloat( e.target.value ),
                    } )
                }
            />

        </div>
    );
}
