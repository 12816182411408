import { Loader } from '@mantine/core';
import { Divider, Input, message } from 'antd';
import { getVitals, putVitals } from '../../../helpers/api';
import { updateData } from '../../../helpers/utilities';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useState } from 'react';

const EditVitals = ( { consultationId, isComplete, onRefresh } ) => {

    const [ vitals, setVitals ] = useState( {} );

    const { isError, isFetching, refetch } = useQuery( {
        queryFn: () => getVitals( consultationId ),
        queryKey: [ 'vitals', consultationId ],
        onSuccess: data => setVitals( data )
    } );


    const { mutateAsync: updateVitals, isLoading } = useMutation( ( data ) => putVitals( consultationId, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <>
                            <div className="row mb-1">
                                <div className="col-3">Temperature:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.temperature }
                                        type="number"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'temperature', e.target.value ) }
                                        required
                                        id="temperature"
                                        placeholder="℃"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Blood Pressure:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.bloodPressure }
                                        type="text"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'bloodPressure', e.target.value ) }
                                        required
                                        id="bloodPressure"
                                        placeholder="mmHg"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Pulse:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.pulse }
                                        type="text"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'pulse', e.target.value ) }
                                        required
                                        id="pulse"
                                        placeholder="bpm"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Weight:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.weight }
                                        type="number"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'weight', e.target.value ) }
                                        required
                                        id="weight"
                                        placeholder="kg"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Height:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.height }
                                        type="number"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'height', e.target.value ) }
                                        required
                                        id="height"
                                        placeholder="meters"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Oxygen Saturation:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.oxygenSaturation }
                                        type="text"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'oxygenSaturation', e.target.value ) }
                                        required
                                        id="oxygenSaturation"
                                        placeholder="os"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Respiration:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.respiration }
                                        type="text"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'respiration', e.target.value ) }
                                        required
                                        id="respiration"
                                        placeholder="cpm"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">RBS/FBS:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.rbs }
                                        max={ 10 }
                                        type="text"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'rbs', e.target.value ) }
                                        id="rbs"
                                        placeholder="random/fasting blood sugar"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row mb-1">
                                <div className="col-3">Pain:</div>
                                <div className="col-9">
                                    <Input
                                        size='large'
                                        value={ vitals?.pain }
                                        max={ 10 }
                                        type="number"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'pain', e.target.value ) }
                                        required
                                        id="pain"
                                        placeholder="pain score: 1-10"
                                    />
                                </div>
                            </div>
                            <Divider className='my-2' />
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor='comments'>Notes:</label>
                                </div>
                                <div className="col-9">
                                    <textarea
                                        value={ vitals?.comments }
                                        rows="4"
                                        className="textarea"
                                        onChange={ ( e ) => updateData( vitals, setVitals, 'comments', e.target.value ) }
                                        id="comments"
                                        placeholder="input your notes or comments here"
                                    />
                                </div>
                            </div>
                        </>
            }
            {
                !isComplete &&
                <button onClick={ () => updateVitals( vitals ) }
                    id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                    Update Vitals
                </button>
            }
        </>
    );
};

export default EditVitals;;;;