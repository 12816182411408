import { message } from 'antd';
import { atom } from 'jotai';
import { nanoid } from 'nanoid';


// state
const _payments = atom( [] );

export const getTotalBillPaymentsAtom = atom(
    get => {
        let total = 0;
        get( _payments ).map( c => {
            total += parseFloat( c.amount || 0 );
        } );
        return total;
    }
);


// atoms
export const billPaymentsAtom = atom(
    get => get( _payments ),
    ( get, set, payment ) => {
        set( _payments, [ ...get( _payments ), { recordId: nanoid(), paymentMethod: '', amount: 0 } ] );
    }
);



// payments handler
export const deleteBillPaymentAtom = atom(
    null,
    ( get, set, recordId ) => {
        set( _payments, get( _payments ).filter( pay => pay.recordId !== recordId ) );
    } );


export const updateBillPaymentAtom = atom(
    null,
    ( get, set, params ) => {
        const { recordId, field, value } = params;

        // prevent duplicate methods
        if ( ( field === 'paymentMethod' ) && get( _payments ).find( pay => pay.paymentMethod === value ) ) {
            message.error( 'method already selected!' );
            return;
        }

        let updatedPayment = get( _payments ).find( pay => pay.recordId === recordId );
        updatedPayment[ field ] = value;

        // update payments
        set( _payments,
            get( _payments )
                .map( pay => {
                    if ( pay.recordId === recordId )
                        return updatedPayment;
                    return pay;
                } )
        );
    } );


export const setBillPayments = atom(
    null,
    ( get, set, payments ) => {
        set( _payments, payments );
    } );
