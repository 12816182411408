import { Divider, Table, Button, Space, Alert, Input, Select, Tooltip, message } from "antd";
import { useState } from "react";
import { PageHeader } from "../../components";
import NewUserForm from './components/new-user';
import {
    EditOutlined,
    FileSearchOutlined,
    LockTwoTone,
    StopFilled,
    UnlockOutlined,
    DeleteFilled
} from "@ant-design/icons";
import { hasPermission, openNotification, setState } from "../../helpers/utilities";
import { getAllUsers, putActivateUser, putSuspendUser, postForgotPassword, deleteUser } from "../../helpers/api";
import smalltalk from 'smalltalk';
import { action, getRole, getUser, resouces } from "../../helpers/auth";
import { Modal } from "@mantine/core";
import EditUserForm from './components/edit-user';
import { useQuery } from "react-query";


const AccountsPage = ( props ) => {
    const [ isNewObject, setIsNewObject ] = useState( false ); //newObject
    const [ users, setUsers ] = useState( [] );
    const [ filteredData, setFilteredData ] = useState( [] );

    const [ modal, setModal ] = useState( {
        title: "",
        open: false,
        content: "",
    } );

    const { refetch: fetchUsers, isFetching } = useQuery( {
        queryFn: getAllUsers,
        queryKey: [ 'users' ],
        onSuccess: data => {

            switch ( getRole() ) {
                case "superuser":
                    setUsers( data );
                    setFilteredData( data );
                    break;

                case "admin":
                    setUsers( () => data.filter( u => u?.role?.toLowerCase() !== "superuser" ) );
                    setFilteredData( () => data.filter( u => u?.role?.toLowerCase() !== "superuser" ) );
                    break;

                default:
                    setUsers( () => data.filter( u => u?.role?.toLowerCase() !== "superuser"
                        && u?.role?.toLowerCase() !== "admin" ) );
                    setFilteredData( () => data.filter( u => u?.role?.toLowerCase() !== "superuser"
                        && u?.role?.toLowerCase() !== "admin" ) );
            }
        }
    } );


    const { Search } = Input;
    const { Option } = Select;

    const handleActivateUser = ( userId ) => {
        if ( !hasPermission( resouces.users, action.canUpdate ) ) {
            message.error( "permission denied" );
            openNotification( 'Error', "permission denied", 'error' );
            return;
        }

        putActivateUser( userId ).then( res => {
            if ( res.status === 200 ) {
                message.success( "Operation successful" );
                fetchUsers();
            }
        } ).catch( ex => {
            message.error( "Operation failed" );
        } );

    };


    const handleSuspendUser = ( userId ) => {
        if ( !hasPermission( resouces.users, action.canUpdate ) ) {
            message.error( "permission denied" );
            openNotification( 'Error', "permission denied", 'error' );
            return;
        }

        putSuspendUser( userId ).then( res => {
            if ( res.status === 200 ) {
                message.success( "Operation successful" );
                fetchUsers();
            }
        } ).catch( ex => {
            message.error( "Operation failed" );
        } );

    };


    const handleDeleteUser = ( userId ) => {
        if ( !hasPermission( resouces.users, action.canDelete ) ) {
            message.error( "permission denied" );
            openNotification( 'Error', "permission denied", 'error' );
            return;
        }

        smalltalk.confirm(
            "Delete Account", "This action cannot be undone. Delete User Account?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            deleteUser( userId ).then( res => {
                if ( res.status === 204 ) {
                    message.success( "Operation successful" );
                    fetchUsers();
                }
            } ).catch( ex => {
                message.error( "Operation failed" );
            } );
        } ).catch( ex => {
            return false;
        } );

    };


    const handlePasswordReset = ( email ) => {
        if ( !hasPermission( resouces.users, action.canUpdate ) ) {
            // message.error( "permission denied" );
            openNotification( 'Error', "permission denied", 'error' );
            return;
        }

        if ( getUser().email === email ) {
            // message.error( "Use the 'reset password' option instead" );
            openNotification( 'password reset error', "you cannot reset your password. Use the 'change password' instead", 'error' );
            return;
        }

        smalltalk.confirm(
            "Password Reset", "This will generate a random password for user. Do you wish to continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            postForgotPassword( { email } )
                .then( ( res ) => {
                    if ( res.status === 200 ) {
                        // message.success( "Password reset successful. SMS and Email sent" );
                        openNotification( 'Success', "Password reset successful. SMS & Email sent", 'success' );
                        return;
                    }

                    // message.error( "Error! Try activating user and try again" );
                    openNotification( 'error', "Error! Try activating user and try again.", 'error' );
                } );
        } ).catch( ex => {
            // console.log(ex);
            return false;
        } );
    };

    return (
        <section>
            <PageHeader title="Accounts" hasBackButton description="User accounts used to access app">
                <button class="app-btn btn-prim" onClick={ () => setIsNewObject( true ) }>
                    <span className="bi bi-plus-circle me-2"></span>
                    <span className="fw-bold">Add User</span>
                </button>
            </PageHeader>
            <Modal
                title={ modal.title }
                opened={ modal.open }
                onClose={ () => setModal( { ...modal, open: false } ) }
            >
                { modal.content }
            </Modal>
            <Divider className="mt-1" />
            {
                !isNewObject &&
                <>
                    {/* <FilterCard filter="filter" className="d-inline-block me-2" /> */ }
                    <p>
                        Showing <strong>{ filteredData?.length }</strong>{ " " }
                        { filteredData?.length > 1 ? " users" : "user" }
                    </p>

                    <div className="my-1 d-flex justify-content-between">
                        <Search
                            onChange={ ( e ) =>
                                setFilteredData(
                                    users.filter(
                                        ( u ) =>
                                            u.email
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            u.role
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            u.staff?.firstName
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            u.staff?.lastName
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            u.pharmacy?.pharmacyName
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            u.laboratory?.laboratoryName
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() )
                                    )
                                )
                            }
                            title="search by user, email or role"
                            placeholder="search by user, email or role"
                        />
                        <Select
                            style={ { minWidth: "150px" } }
                            name="call type"
                            className="mx-2"
                            onChange={ ( v ) =>
                                setFilteredData( users.filter( ( d ) => d.role === v ) )
                            }
                            defaultValue="role"
                        >
                            { [ ...new Set( users.map( ( d ) => d.role ) ) ].map( ( v ) => (
                                <Option value={ v } key={ v }>
                                    { v }
                                </Option>
                            ) ) }
                        </Select>
                        <button
                            onClick={ () => setState( setFilteredData, users ) }
                            className="app-btn btn-text d-flex align-items-center"
                        >
                            <span className="bi bi-arrow-clockwise me-2"></span>
                            Reset
                        </button>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <Table
                                columns={ [
                                    {
                                        title: "User",
                                        render: ( text, record, index ) =>
                                            record.category === "internal" ?
                                                `${ record?.staff?.firstName } ${ record?.staff?.lastName }` :
                                                `${ record.fullName } (${ record.role === "pharmacy" ? record?.pharmacy?.pharmacyName :
                                                    record?.laboratory?.laboratoryName
                                                })`,
                                    },
                                    {
                                        title: "Email",
                                        dataIndex: "email",
                                    },
                                    // {
                                    //     title: "Email",
                                    //     dataIndex: "email",
                                    // },
                                    {
                                        title: "Role",
                                        dataIndex: "role",
                                    },
                                    {
                                        title: "Status",
                                        render: ( t, r, i ) => {
                                            return r.isSuspended ? (
                                                <Alert message="suspended" type="error" showIcon />
                                            ) : (
                                                <Alert message="active" type="success" showIcon />
                                            );
                                        },
                                    },
                                    {
                                        title: "Tasks",
                                        render: ( text, record, index ) => (
                                            <Space>
                                                {
                                                    hasPermission( resouces.users, action.canUpdate ) &&
                                                    <Tooltip title="Edit User">
                                                        <Button
                                                            className="d-flex align-items-center"
                                                            onClick={ () => setModal( {
                                                                title: "Edit User",
                                                                open: true,
                                                                content: <EditUserForm userId={ record.id }
                                                                    onSuccess={ fetchUsers } showCharm={ false } />
                                                            } ) }
                                                        >
                                                            <EditOutlined />
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                {/* <Button
                                                    title="view user activity logs"
                                                    className="d-flex align-items-center"
                                                    onClick={ () => {
                                                        showModal(
                                                            record.username + "'s Logs",
                                                            <UserLogs userId={ record.id } />,
                                                            "500px"
                                                        );
                                                    } }
                                                >
                                                    <FileSearchOutlined />
                                                    logs
                                                </Button> */}
                                                {/* <DeleteOutlined className="text-danger" /> */ }
                                                {
                                                    record.isSuspended ? (
                                                        <Tooltip title="activate user account">
                                                            <Button
                                                                className="d-flex align-items-center"
                                                                onClick={ () => handleActivateUser( record.id ) }>
                                                                <UnlockOutlined className="text-success" />
                                                                {/* activate */ }
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="suspend user account">
                                                            <Button
                                                                className="d-flex align-items-center"
                                                                title="suspend user account"
                                                                onClick={ () => handleSuspendUser( record.id ) }>
                                                                <StopFilled className="text-danger" />
                                                                {/* suspend */ }
                                                            </Button>
                                                        </Tooltip>
                                                    )
                                                }
                                                < Tooltip title="reset password" >
                                                    <Button
                                                        // title="reset password"
                                                        className="d-flex align-items-center"
                                                        onClick={ () => handlePasswordReset( record.email ) }>
                                                        <LockTwoTone />
                                                        {/* reset pwd */ }
                                                    </Button>
                                                </Tooltip>
                                                {
                                                    hasPermission( resouces.users, action.canDelete ) &&
                                                    <Tooltip title="delete user account">
                                                        <Button
                                                            className="d-flex align-items-center"
                                                            // title="delete user account"
                                                            onClick={ () => handleDeleteUser( record.id ) }>
                                                            <DeleteFilled className="text-danger" />
                                                            {/* delete */ }
                                                        </Button>
                                                    </Tooltip>
                                                }
                                            </Space>
                                        ),
                                    },
                                ] }
                                className="border"
                                dataSource={ filteredData }
                                rowKey={ ( record ) => record.id }
                                bordered
                                loading={ isFetching }
                            />
                        </div>
                    </div>
                </>
            }

            {
                isNewObject &&
                <div className="row">
                    <div className="col-md-8 col-12">
                        <NewUserForm onSuccess={ fetchUsers } onExit={ () => setIsNewObject( false ) } />
                    </div>
                </div>
            }

        </section >
    );
};

export { AccountsPage };