import { atom } from 'jotai';

// template
// let consultationTemplate = {
//     type: '',
//     doctorsAdvise: '',
//     investigation: '',
//     notes: '',
//     date: null,
//     consultationId: null
// };

// state - atom
const _consultation = atom( {} );



// atoms
export const consultationAtom = atom(
    ( get ) => get( _consultation ),
    ( get, set, cons ) => {
        set( _consultation, { ...get( _consultation ), ...cons } );
    }
);

export const clearConsultationAtom = atom(
    null,
    ( get, set, cons ) => {
        set( _consultation, {} );
    }
);

