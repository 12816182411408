import { BackTop, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import DiagnosisFilterBar from '../../../components/filter-bars/diagnosis-report-filters';
import { calcAge, exportToExcel } from '../../../helpers/utilities';

export default function DiagnosisReport ( { data, isLoading } ) {

    const [ filteredData, setFilteredData ] = useState( [] );

    const handleReset = () => {
        setFilteredData( data );
    };

    const handleApplyFilter = ( newData ) => {
        setFilteredData( newData );
    };

    useEffect( () => {
        setFilteredData( data );
    }, [ data ] );


    const columns = [
        {
            title: "#",
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => ++index
        },
        {
            title: "Date - Time",
            sorter: ( a, b ) => a?.createdAt > b?.createdAt,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => new Date( record?.createdAt ).toUTCString()
        },
        {
            title: "Consultation",
            sorter: ( a, b ) => a?.consultation?.type > b?.consultation?.type,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.consultation?.type } - (${ record?.consultation?.code })`
        },
        {
            title: "Doctor",
            sorter: ( a, b ) => a?.consultation?.doctor?.firstName > b?.consultation?.doctor?.firstName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.consultation?.doctor?.firstName } ${ record?.consultation?.doctor?.lastName }`
        },
        {
            title: "Diagnosis",
            sorter: ( a, b ) => a?.diagnosis > b?.diagnosis,
            dataIndex: "diagnosis",
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Patient Name",
            sorter: ( a, b ) => a?.consultation?.patient?.firstName > b?.consultation?.patient?.firstName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.consultation?.patient?.firstName }  ${ record?.consultation?.patient?.lastName }`,
        },
        {
            title: "Gender",
            sorter: ( a, b ) => a?.consultation?.patient?.gender > b?.consultation?.patient?.gender,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => record?.consultation?.patient?.gender,
        },
        {
            title: "Age",
            sorter: ( a, b ) => a.calcAge( a?.consultation?.patient?.dob ).age,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                calcAge( record?.consultation?.patient?.dob ).age
        },
        {
            title: "Insurance Provider",
            dataIndex: "gender",
            sorter: ( a, b ) => a?.consultation?.patient?.insuranceProvider?.providerName
                > b.consultation?.patient?.insuranceProvider?.providerName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record?.consultation?.patient?.insuranceProvider?.providerName,

        },

        // {
        //     title: "Attachments",
        //     sorter: ( a, b ) => a.totalConsultations > b.totalConsultations,
        //     dataIndex: "totalConsultations",
        //     sortDirections: [ "descend", "ascend" ],
        // },

    ];


    return (
        <div>
            <DiagnosisFilterBar data={ data } onReset={ handleReset }
                onFilter={ ( d ) => handleApplyFilter( d ) }
                excelExport={ () => exportToExcel( "diagnosisReportTable", "rivia-diagnosis-report" ) }
            />

            {/* advanced filters
            
            -> date range
            -> age
            -> agent
            
            */}
            <Table
                className="border mt-3"
                id='diagnosisReportTable'
                rowKey={ ( record ) => record.id }
                dataSource={ filteredData }
                columns={ columns }
                loading={ isLoading }
                bordered
            />
            <BackTop />
        </div>
    );
}
