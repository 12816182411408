import { Divider, Select, Tabs, message } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageHeader, RequiredIndicator } from "../../../components";
import { appLinks } from "../../../helpers/config";
import { Badge, Menu, Modal, Notification } from "@mantine/core";
import ConsultationPrescriptionsComponent from './prescriptions';
import ConsultationPharmaciesComponent from './pharmacies';
import ConsultationLaboratoriesComponent from './laboratories';
import ConsultationDiagnosisComponent from './diagnosis';
import { getAllDiagnosis, getAllDrugs, getAllLabActivities, getAllLaboratories, getAllPharmacies, getAllSymptoms, getVideoCallInvite, postNewConsultation } from "../../../helpers/api";
// import { nanoid } from 'nanoid';
import { calcAge, calcBMI, copyToClipboard, dropDownSet, hasPermission, updateData } from './../../../helpers/utilities';
import { consultationSchema, vitalsSchema } from '../../../helpers/schemas';
import { action, getUser, resouces } from "../../../helpers/auth";
import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import smalltalk from 'smalltalk';
import { IconCopy, IconMail, IconMessage } from "@tabler/icons";
import { EditPatientForm } from "../../catalog/components/edit-patient";
import PatientJourney from "../../../components/shared/patient-journey";
import MedicalHistory from "../../../components/shared/medical-history";
import ConsultationSystemicReviewComponent from "./review";
import AddVitals from "./add-vitals";

let consultationTemplate = {
  type: 'Physical',
  doctorsAdvise: '',
  investigation: '',
  notes: '',
  complaint: '',
  patientId: null,
  appointmentId: null
};

const NewConsultation = ( { meetingId } ) => {
  //

  // selections
  const [ laboratories, setLabs ] = useState( [], );
  const [ diagnosis, setDiag ] = useState( [] );
  const [ reviews, setReviews ] = useState( [] );
  const [ vitals, setVitals ] = useState( [] );
  const [ pharmacies, setPharms ] = useState( [] );
  const [ prescriptions, setPres ] = useState( [] );
  const [ consultation, setConsultation ] = useState( consultationTemplate );

  // fetching data
  const { data: serverPharmas = [] } = useQuery( {
    queryFn: () => getAllPharmacies(),
    queryKey: [ 'pharmacies' ],
    // enabled: false
  } );

  const { data: serverLabs = [] } = useQuery( {
    queryFn: () => getAllLaboratories(),
    queryKey: [ 'laboratories' ],
  } );

  const { data: serverDrugs = [] } = useQuery( {
    queryFn: () => getAllDrugs(),
    queryKey: [ 'drugs' ],
  } );

  const { data: serverSymptoms = [] } = useQuery( {
    queryFn: () => getAllSymptoms(),
    queryKey: [ 'symptoms' ],
  } );

  const { data: serverLabActivities = [] } = useQuery( {
    queryFn: () => getAllLabActivities(),
    queryKey: [ 'lab-activities' ],
  } );

  const { data: serverDiagnosis = [] } = useQuery( {
    queryFn: () => getAllDiagnosis(),
    queryKey: [ 'diagnosis' ],
    refetchOnReconnect: false,
    retryOnMount: false,
    keepPreviousData: true,
  } );


  //
  const [ modal, setModal ] = useState( {
    open: false,
    title: '',
    size: 700,
    child: null
  } );

  const openModal = ( title, child, size = 700 ) => {
    setModal( {
      title,
      child,
      size,
      open: true
    } );
  };


  const [ patient, setPatient ] = useState( {} );

  const navigate = useNavigate();
  const location = useLocation();

  const isVideoMode = location.pathname !== appLinks.care.new;
  const { Option } = Select;
  const { TabPane } = Tabs;

  // methods

  // prescriptions
  const handleDeletePrescription = ( recordId ) => {
    const updated = prescriptions.filter( pres => pres.recordId !== recordId );
    setPres( updated );
  };

  const handleUpdatePrescription = ( recordId, field, value ) => {
    let currentPres = prescriptions.filter( pres => pres.recordId === recordId )[ 0 ];
    currentPres[ field ] = value;
    setPres( [
      ...prescriptions.filter( pres => pres.recordId !== recordId ),
      currentPres
    ] );
  };

  const handleAddPrescription = () => {
    setPres( [
      ...prescriptions,
      {
        recordId: prescriptions.length + 1,
        medicine: '',
        dosage: '',
        strength: '',
        frequency: '',
        duration: ''
      }
    ] );
  };

  // pharmacy
  const handleAddPharmacy = () => {
    setPharms( [
      ...pharmacies,
      {
        recordId: pharmacies.length + 1,
        pharmacyId: null,
      }
    ] );
  };

  const handleUpdatePharmacy = ( recordId, value ) => {
    let currentPharma = pharmacies.filter( pharma => pharma.recordId === recordId )[ 0 ];
    currentPharma.pharmacyId = value;
    setPharms( [
      ...pharmacies.filter( pharma => pharma.recordId !== recordId ),
      currentPharma
    ] );
  };

  const handleDeletePharmacy = ( recordId ) => {
    const updated = pharmacies.filter( pharm => pharm.recordId !== recordId );
    setPharms( updated );
  };


  // laboratory
  const handleAddLaboratory = () => {
    setLabs( [
      ...laboratories,
      {
        recordId: laboratories.length + 1,
        laboratoryId: null,
        labType: "",
        requestType: "",
      }
    ] );
  };

  const handleUpdateLaboratory = ( recordId, field, value ) => {
    let currentLab = laboratories.filter( lab => lab.recordId === recordId )[ 0 ];
    currentLab[ field ] = value;
    setLabs( [
      ...laboratories.filter( lab => lab.recordId !== recordId ),
      currentLab
    ] );
  };

  const handleDeleteLaboratory = ( recordId ) => {
    const updated = laboratories.filter( lab => lab.recordId !== recordId );
    setLabs( updated );
  };


  // diagnosis
  const handleAddDiagnosis = () => {
    setDiag( [
      ...diagnosis,
      {
        recordId: diagnosis.length + 1,
        diagnosis: ''
      }
    ] );
  };

  const handleUpdateDiagnosis = ( recordId, value ) => {
    let currentDiag = diagnosis.filter( diag => diag.recordId === recordId )[ 0 ];
    currentDiag.diagnosis = value;
    setDiag( [
      ...diagnosis.filter( diag => diag.recordId !== recordId ),
      currentDiag
    ] );
  };

  const handleDeleteDiagnosis = ( recordId ) => {
    const updated = diagnosis.filter( diag => diag.recordId !== recordId );
    setDiag( updated );
  };


  // reviews
  const handleAddReview = () => {
    setReviews( [
      ...reviews,
      {
        recordId: reviews.length + 1,
        type: '',
        symptom: ''
      }
    ] );
  };

  const handleUpdateReview = ( recordId, field, value ) => {
    let curReview = reviews.filter( rev => rev.recordId === recordId )[ 0 ];
    curReview[ field ] = value;

    setReviews( [
      ...reviews.filter( rev => rev.recordId !== recordId ),
      curReview
    ] );
  };

  const handleDeleteReview = ( recordId ) => {
    const updatedReviews = reviews.filter( rev => rev.recordId !== recordId );
    setReviews( updatedReviews );
  };


  // api
  // const fetchPharmacies = () => {

  //   getAllPharmacies().then( res => {
  //     if ( res.status === 200 ) {
  //       setServerPharmas( res.data?.data );
  //     }
  //   } );

  // };

  // const fetchLabs = () => {

  //   getAllLaboratories().then( res => {
  //     if ( res.status === 200 ) {
  //       setServerLaboratories( res.data?.data );
  //     }
  //   } );

  // };

  // const fetchMedicines = () => {

  // };

  // const fetchDiagnosis = () => {

  // };


  const handleSwitchToVideo = () => {
    smalltalk.confirm(
      "Switch to Video", "Your progress made will be lost. Do you wish to continue?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {
      navigate( appLinks.video.room( getUser().roomId ), {
        state: {
          patient: location.state?.patient,
          type: "Video"
        }
      } );
    } ).catch( ex => {
      return false;
    } );
  };

  const handleConsultationSubmit = () => {
    smalltalk.confirm(
      "Create Consultation", "This action cannot be undone. Do you wish to continue?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {

      consultationSchema.validate( consultation, {
        abortEarly: true,
        stripUnknown: true
      } )
        .then( valid => {
          try {
            // validate vitals, prescriptions, labs, pharmacies & diagnosis

            if ( prescriptions.length > 0 ) {
              prescriptions.map( pres => {
                if ( ( pres.medicine === "" || pres.frequency === "" || pres.duration === "" ) ) {
                  throw Error( 'Prescription has an incomplete entry' );
                }
              } );
            }
            if ( laboratories.length > 0 ) {
              laboratories.map( lab => {
                if ( lab.id === null ) {
                  throw Error( 'Laboratories has an incomplete entry' );
                }
              } );
            }
            if ( pharmacies.length > 0 ) {
              pharmacies.map( pharm => {
                if ( pharm.id === null ) {
                  throw Error( 'Pharmacies has an incomplete entry' );
                }
              } );
            }
            if ( diagnosis.length > 0 ) {
              diagnosis.map( diag => {
                if ( diag.diagnosis === "" ) {
                  throw Error( 'Diagnosis has an incomplete entry' );
                }
              } );
            }
            if ( reviews.length > 0 ) {
              reviews.map( rev => {
                if ( rev.type === "" || rev.symptom === "" ) {
                  throw Error( 'Reviews has an incomplete entry' );
                }
              } );
            }
          } catch ( ex ) {
            message.error( ex.message );
            return;
          }

          // now post to server
          // console.log( 'consultation to be processed ', { consultation, prescriptions, laboratories, pharmacies, diagnosis, reviews } );
          const cons = { ...consultation, isInstant: true };
          createConsultation( { consultation: cons, prescriptions, laboratories, pharmacies, diagnosis, reviews, vitals } );
          // message.success( 'saved' );
        } )
        .catch( ( err ) => {
          if ( _.isArray( err.errors ) ) {
            message.error( err.errors[ 0 ] );
          } else {
            message.error( err );
          }
        } );

    } ).catch( ex => {
      return false;
    } );

  };

  const reset = () => {
    consultationTemplate.patientId = location.state.patient.id;
    consultationTemplate.appointmentId = null;

    setVitals( {} );
    setConsultation( consultationTemplate );
    setLabs( [] );
    setPres( [] );
    setPharms( [] );
    setDiag( [] );
    setReviews( [] );
  };



  const { mutateAsync: createConsultation, isLoading } = useMutation( ( data ) => postNewConsultation( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        reset();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
    retry: true
  } );

  // invites

  // send
  const handleSendInvite = () => {
    getVideoCallInvite( meetingId, consultation.patientId ).then( res => {
      if ( res.status === 200 ) {
        message.success( res.data.message );
      }
    } ).catch( ex => console.log( "" ) );
  };

  // copy
  const handleCopyInvite = () => {
    const msg = `${ getUser().staff.name } of ${ getUser().facility.facilityName } is inviting you to an ongoing video consultation. Join now at ${ process.env.REACT_APP_CLIENT_URL }/vc/${ meetingId }`;
    copyToClipboard( msg ).then( done =>
      message.success( 'invite copied!' )
    );
  };

  // 
  useEffect( () => {
    // fetchPharmacies();
    // fetchLabs();

    setVitals( location.state.vitals || {} );

    if ( location.state?.patient?.id ) {
      setConsultation( {
        ...consultation,
        patientId: location.state?.patient?.id,
        type: location.state?.type || "Physical",
        appointmentId: location.state?.appointmentId
      } );
      setPatient( location.state?.patient );
    }
    // // fetchMedicines();
  }, [] );


  useEffect( () => {
    if ( !location.state?.patient?.id )
      return navigate( appLinks.findPatient.index );
  }, [] );


  return (
    <div className="row">
      <div className="mx-auto col-12">
        {
          !isVideoMode && <>
            <PageHeader
              title="New Consultation"
              description="Create and save an ongoing / scheduled consultation"
              hasBackButton
            >
              <button
                className="button app-btn btn-text bg-danger text-white px-2"
                onClick={ () => navigate( appLinks.home.index ) }
              >
                <span className="bi bi-x h5 m-0"></span>
                Close
              </button>
            </PageHeader>

            < Divider />
          </>
        }
        <div className="row">
          <div className={ isVideoMode ? 'col-12' : 'col-md-5  col-12' } >
            <button className={ `button app-btn btn-prim mb-4 py-4 ${ isLoading && ' is-loading' }` }
              onClick={ handleConsultationSubmit }

            >
              <span className="bi bi-save text-white me-2"></span>
              <span className="h5 mt-1">Save</span>
            </button>
            {
              ( isVideoMode && !isLoading ) &&
              <>
                <Menu shadow="md" width={ 200 }>
                  <Menu.Target>
                    <button className="button app-btn py-4 mx-5">
                      <span className="h6 mt-1">Invite</span>
                    </button>
                  </Menu.Target>
                  <Menu.Dropdown >
                    <Menu.Item icon={ <IconMessage size={ 14 } /> }
                      onClick={ handleSendInvite }
                    >Send Invite</Menu.Item>
                    {/* <Menu.Item icon={ <IconMail size={ 14 } /> }>Send by Email</Menu.Item> */ }
                    <Menu.Item icon={ <IconCopy size={ 14 } /> }
                      onClick={ handleCopyInvite }
                    >Copy Invitation</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                <button className={ `button is-danger py-4` }
                  onClick={ () => navigate( appLinks.home.index ) }>
                  <span className="h5 mt-1">Exit</span>
                </button>
              </>
            }
            {
              !isVideoMode &&
              <button className={ `button app-btn  mb-4 py-4 ms-2 ${ isLoading && 'is-loading' }` }
                onClick={ handleSwitchToVideo }
              >
                <span className="bi bi-camera-video me-2"></span>
                <span className="h6 mt-2">Switch to Video</span>
              </button>
            }
            <p className="m-0"><i>Patient</i></p>
            <div className="d-flex justify-content-between align-items-center my-0">
              <div>
                <h4 className="m-0">{ `${ location.state?.patient?.firstName } ${ location.state?.patient?.lastName }` }</h4>
                <p className="mt-0">{ `${ calcAge( patient.dob ).age } yrs old - ${ patient.gender }` }</p>

              </div>

            </div>
            <Divider className="my-1" />

            {/* <div className="field">
              <label className="mb-0" htmlFor="callType">
                Consultation Type
                <RequiredIndicator />
              </label>
              <Select
                required
                autoFocus
                size="large"
                value={ consultation.type }
                onChange={ ( v ) => dropDownSet( 'type', v, consultation, setConsultation ) }
                id="type"
                className="d-block"
                placeholder="consultation type"
              >
                <Option value="Phone">Phone</Option>
                <Option value="Video">Video</Option>
                <Option value="Physical">Physical</Option>
              </Select>
            </div> */}
            <div className="field">
              <label className="mb-0" htmlFor="complaint">
                Presenting Complaint
                <RequiredIndicator />
              </label>
              <textarea
                className="textarea"
                rows={ 3 }
                value={ consultation.complaint }
                onChange={ ( e ) => dropDownSet( 'complaint', e.target.value, consultation, setConsultation ) }
                id="complaint"
                placeholder="presenting complaint"
              ></textarea>
            </div>
            <div className="field">
              <label className="mb-0" htmlFor="notes">
                Doctor's Notes
              </label>
              <textarea
                className="textarea"
                rows={ 3 }
                value={ consultation.notes }
                onChange={ ( e ) => dropDownSet( 'notes', e.target.value, consultation, setConsultation ) }
                id="notes"
                placeholder="Clinical findings and comments. patient will not see this"
              ></textarea>
            </div>

            <div className="field">
              <label className="mb-0" htmlFor="number">
                Investigation
              </label>
              <textarea
                className="textarea"
                rows={ 3 }
                id="investigation"
                value={ consultation.investigation }
                onChange={ ( e ) => dropDownSet( 'investigation', e.target.value, consultation, setConsultation ) }
                placeholder="investigation"
              ></textarea>
            </div>
            <div className="field">
              <label className="mb-0" htmlFor="number">
                Management Plan
                <RequiredIndicator />
              </label>
              <textarea
                className="textarea mb-3"
                id="advise"
                value={ consultation.doctorsAdvise }
                onChange={ ( e ) => dropDownSet( 'doctorsAdvise', e.target.value, consultation, setConsultation ) }
                required
                rows={ 3 }
                placeholder="management plan (doctor's advice) here. Patient will see this"
              ></textarea>
            </div>
          </div>
          <div className={ isVideoMode ? 'col-12' : 'col-md-7  col-12 mt-md-0 mt-3 pb-5 px-3' } >
            <div className="buttons has-addons">
              <button className="button" onClick={ () =>
                openModal( "Patient Details",
                  <EditPatientForm
                    patientId={ consultation.patientId }
                    isEditable={ hasPermission( resouces.patients, action.canUpdate ) }
                    showCharm={ false } /> ) }>
                <span className="bi bi-person-circle me-2"></span>
                Patient Details
              </button>
              <button className="button" onClick={ () =>
                openModal( "Medical History",
                  <MedicalHistory
                    isEditable={ hasPermission( resouces.patients, action.canUpdate ) }
                    patientId={ consultation.patientId } /> ) }>
                <span className="bi bi-clock-history me-2"></span>
                Medical History
              </button>
              <button className="button" onClick={ () =>
                openModal( "Patient Journey", <PatientJourney patientId={ consultation.patientId } />, 1000 ) }>
                <span className="bi bi-file-medical me-2"></span>
                Patient Journey
              </button>
            </div>
            <Tabs defaultActiveKey="0">
              <TabPane tab={
                <span>
                  Vitals
                </span> } key="0">
                <div>
                  <AddVitals
                    onUpdate={ ( field, data ) => updateData( vitals, setVitals, field, data ) }
                    data={ vitals } />
                </div>

              </TabPane>
              <TabPane tab={ <span>Systemic Reviews <Badge variant="filled" color={ reviews.length ? "teal" : "dark" }>{ reviews.length }</Badge> </span> } key="1">
                <div>
                  <ConsultationSystemicReviewComponent allSymptoms={ serverSymptoms } reviews={ reviews } onUpdate={ handleUpdateReview } onDelete={ handleDeleteReview } />
                  <button className="button is-small mt-2" onClick={ handleAddReview }>Add Review</button>
                </div>
              </TabPane>
              <TabPane tab={ <span>Diagnosis <Badge variant="filled" color={ diagnosis.length ? "teal" : "dark" }>{ diagnosis.length }</Badge> </span> } key="2">
                <div>
                  <ConsultationDiagnosisComponent allDiagnosis={ serverDiagnosis } diagnosis={ diagnosis } onUpdate={ handleUpdateDiagnosis } onDelete={ handleDeleteDiagnosis } />
                  <button className="button is-small mt-2" onClick={ handleAddDiagnosis }>Add Diagnosis</button>
                </div>
              </TabPane>
              <TabPane tab={ <span>Prescriptions <Badge variant="filled" color={ prescriptions.length ? "teal" : "dark" }>{ prescriptions.length }</Badge></span> } key="3">
                <div className="row">
                  <div className="col-12">
                    {/* <small className="text-danger">make sure medicine isnt selected twice</small> */ }
                    <ConsultationPrescriptionsComponent drugs={ serverDrugs } prescriptions={ prescriptions } onUpdate={ handleUpdatePrescription } onDelete={ handleDeletePrescription } />
                    <button className="button is-small mt-2" onClick={ handleAddPrescription }>Add Prescription</button>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={ <span>Labs <Badge variant="filled" color={ laboratories.length ? "teal" : "dark" }>{ laboratories.length }</Badge> </span> } key="4">
                <div>
                  <ConsultationLaboratoriesComponent allActivities={ serverLabActivities } allLaboratories={ serverLabs } labs={ laboratories } onUpdate={ handleUpdateLaboratory } onDelete={ handleDeleteLaboratory } />
                  <button className="button is-small mt-2" onClick={ handleAddLaboratory }>Add Laboratory</button>
                </div>
              </TabPane>
              <TabPane tab={ <span>Pharmacies <Badge variant="filled" color={ pharmacies.length ? "teal" : "dark" }>{ pharmacies.length }</Badge></span> } key="5">
                <div>
                  <ConsultationPharmaciesComponent
                    allPharmacies={ serverPharmas }
                    pharmacies={ pharmacies } onUpdate={ handleUpdatePharmacy }
                    onDelete={ handleDeletePharmacy } />
                  <button className="button is-small mt-2" onClick={ handleAddPharmacy }>Add Pharmacy</button>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div >

      {/* modals */ }
      <Modal
        title={ modal.title }
        size={ modal.size }
        opened={ modal.open }
        onClose={ () => setModal( { ...modal, open: false } ) }
      >
        { modal.child }
      </Modal>
    </div >
  );
};

export { NewConsultation };
