import { Menu, Modal, Paper } from "@mantine/core";
import { BackTop, Divider, Table, Input, Space, Select, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components";
import { getAllPatients, deletPatient } from "../../helpers/api";
import NewPatientForm from "./components/new-patient";
import { calcAge, hasPermission, setState } from "../../helpers/utilities";
import { EditPatientForm } from '../index';
import smalltalk from 'smalltalk';
import { useQuery } from "react-query";
import MedicalHistory from "../../components/shared/medical-history";
import PatientJourney from "../../components/shared/patient-journey";
import { action, resouces } from "../../helpers/auth";
import { IconDotsCircleHorizontal, IconEye, IconTimeline } from "@tabler/icons";
import { IconProgressCheck } from "@tabler/icons-react";

const PatientsIndex = ( props ) => {
  const [ isNewObject, setIsNewObject ] = useState( false ); //newObject
  const [ filteredData, setFilteredData ] = useState( [] );

  const [ modal, setModal ] = useState( {
    title: "",
    open: false,
    content: "",
    size: 700
  } );

  const { data: patients, isFetching, refetch: fetchPatients } = useQuery( {
    queryFn: getAllPatients,
    enabled: false,
    queryKey: [ 'patients' ],
    onSuccess: ( data ) => {
      setFilteredData( data );
    }
  } );


  const { Search } = Input;
  const { Option } = Select;


  const handleDeletePatient = ( id ) => {
    smalltalk.confirm(
      "Delete Patient ", "This action cannot be undone. Delete Patient?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {
      // delete patient api call
      deletPatient( id ).then( res => {
        if ( res.status === 204 ) {
          message.success( 'Patient deleted' );
          fetchPatients();
          return;
        }
      } );
    } ).catch( ex => {
      // console.log(ex);
      message.error( 'error deleting patient' );
      return false;
    } );
  };

  useEffect( () => {
    fetchPatients();
  }, [] );

  // sales table
  const columns = [
    {
      title: "Name",
      sorter: ( a, b ) => a?.firstName > b?.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        `${ record?.firstName }  ${ record?.lastName }`,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: ( a, b ) => a?.gender > b?.gender,
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Age",
      sorter: ( a, b ) => new Date( a?.dob ).getFullYear() > new Date( b?.dob ).getFullYear(),
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) => calcAge( r?.dob ).age
    },
    {
      title: "Contact",
      dataIndex: "contact",
      sorter: ( a, b ) => a?.contact > b?.contact,
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Insurance Provider",
      sorter: ( a, b ) => a?.insuranceProvider?.providerName > b?.insuranceProvider?.providerName,
      dataIndex: [ 'insuranceProvider', 'providerName' ],
      sortDirections: [ "descend", "ascend" ],
    },
    // {
    //   title: "Calls",
    //   sorter: ( a, b ) => a.totalCalls > b.totalCalls,
    //   dataIndex: "totalCalls",
    //   sortDirections: [ "descend", "ascend" ],
    // },
    {
      title: "Consultations",
      sorter: ( a, b ) => parseInt( a?.totalConsultations ) > parseInt( b?.totalConsultations ),
      dataIndex: "totalConsultations",
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "-",
      render: ( text, record, index ) => (
        <Menu shadow="md" width={ 160 } >
          <Menu.Target>
            {/* <button className="button is-small is-ghost px-2">
              <span className="bi bi-list me-2"></span>
            </button> */}
            <button className="button app-btn is-ghost">
              <IconDotsCircleHorizontal />
            </button>
          </Menu.Target>

          <Menu.Dropdown>
            {
              hasPermission( resouces.patients, action.canUpdate ) &&
              <Menu.Item
                color="blue"
                onClick={ () => {
                  setModal( {
                    title: "Editing " + record.firstName + " " + record.lastName,
                    open: true,
                    content: < EditPatientForm patientId={ record.id } onSuccess={ fetchPatients } showCharm={ false } />,

                  } );
                } }>
                <IconEye color="grey" size={ 10 } />
                <span className="ms-1">Open</span>
              </Menu.Item>
            }
            {
              hasPermission( resouces.patients, action.canRead ) &&
              <>
                <Menu.Item
                  onClick={ () => setModal( {
                    title: "Journey",
                    open: true,
                    content: <PatientJourney patientId={ record.id } />,
                    size: 1200
                  } ) }>
                  <IconProgressCheck color="grey" />
                  <span className="ms-1">Journey</span>
                </Menu.Item>
                <Menu.Item
                  onClick={ () => setModal( {
                    title: "Medical History",
                    open: true,
                    content: <MedicalHistory patientId={ record.id } isEditable={ false } />
                  } ) }>
                  <IconTimeline size={ 10 } color="grey" />
                  <span className="ms-1">Medical History</span>
                </Menu.Item>
              </>
            }

            {
              hasPermission( resouces.patients, action.canDelete ) &&
              <Menu.Item
                icon={ <span className="bi bi-trash" /> }
                color="red"
                onClick={ () => handleDeletePatient( record.id ) }>
                Delete
              </Menu.Item>
            }
          </Menu.Dropdown>
        </Menu>
      ),
    }
  ];

  return (
    <section>
      <Modal
        title={ modal.title }
        opened={ modal.open }
        size={ modal.size }
        onClose={ () => setModal( { ...modal, open: false } ) }
      >
        { modal.content }
      </Modal>
      <PageHeader
        title="Patients"
        hasBackButton
        description="List of all patients attended to"
      >
        {
          hasPermission( resouces.patients, action.canCreate ) &&
          <button
            class="app-btn btn-prim"
            onClick={ () => setIsNewObject( true ) }
          >
            <span className="bi bi-plus-circle me-2"></span>
            <strong className="text-white">NEW</strong>
          </button>
        }
      </PageHeader>
      <Divider className="mt-1" />

      { !isNewObject && (
        <>
          {/* <FilterCard filter="filter" className="d-inline-block me-2" /> */ }
          <p>
            Showing <strong>{ filteredData.length }</strong>{ " " }
            { filteredData.length > 1 ? " records" : "record" }
          </p>
          <div className="my-3 d-flex justify-content-between">
            <Space className="ms-0">
              <input
                className="input"
                onChange={ ( e ) =>
                  setFilteredData(
                    patients.filter(
                      ( p ) =>
                        p.firstName
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() ) ||
                        p.lastName
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() ) ||
                        p.gender
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() ) ||
                        p.contact
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() ) ||
                        p.insuranceProvider?.providerName
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() )
                    )
                  )
                }
                title="search here"
                placeholder="search here"
                style={ { minWidth: "30vw" } }
              />
              <Select
                style={ { minWidth: "100px" } }
                size="large"
                name="byType"
                allowClear
                showSearch
                onChange={ ( v ) =>
                  v != null ?
                    setFilteredData( filteredData.filter( ( d ) => d.gender === v ) ) :
                    setFilteredData( patients )
                }
                defaultValue="select gender"
              >
                { [ ...new Set( filteredData.map( ( d ) => d.gender ) ) ].map( ( v ) => (
                  <Option value={ v } key={ v }>
                    { v }
                  </Option>
                ) ) }
              </Select>
            </Space>
            <button
              onClick={ () => setState( setFilteredData, patients ) }
              className="app-btn btn-text ms-5 d-flex align-items-center"
            >
              <span className="bi bi-arrow-clockwise me-2"></span>
              Reset filters
            </button>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Table
                className="border"
                rowKey={ ( record ) => record.id }
                dataSource={ filteredData }
                columns={ columns }
                loading={ isFetching }
                bordered
              />
            </div>
          </div>
          <BackTop />
        </>
      ) }

      { isNewObject && (
        <div className="row">
          <div className="col-md-8 col-12">
            <NewPatientForm
              onSuccess={ fetchPatients }
              onExit={ () => setIsNewObject( false ) }
            />
          </div>
        </div>
      ) }

    </section>
  );
};

export { PatientsIndex };
