import { Paper, Progress, Timeline, Text, Modal } from "@mantine/core";
import { Avatar, Typography } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { appLinks } from "../helpers/config";
import { consultationColorCoding, percentage } from "../helpers/utilities";
import { IconCheck, IconHistoryToggle } from '@tabler/icons';
import { differenceInDays } from "date-fns";
import { useState } from "react";
import ConsultationDetail from "../pages/schedule/components/consultation-detail";


const ShortDateCard = ( { date, className } ) => {
  return (
    <div className={ `app-card hover short-date-card ${ className }` }>
      <div className="date">20</div>
      <div className="day">Fri</div>
    </div>
  );
};

const DoctorProfileCard = ( { doctor, className } ) => {
  return (
    <div className={ `app-card hover doctor-profile-card ${ className }` }>
      <div className="row">
        <div className="col-3">
          <Avatar size="large" />
        </div>
        <div className="col-9">
          <h5>name</h5>
          <p>specialization</p>
          <p>rating stars (number)</p>
          <p>reviews</p>
        </div>
      </div>
    </div>
  );
};

const DoctorAppointmentCard = ( { hasAvatar = false, doctor, className } ) => {
  return (
    <div className={ `app-card hover doctor-appointment-card ${ className }` }>
      <div className="row">
        <div className="col-3">
          { hasAvatar ? <Avatar size="large" /> : <ShortDateCard /> }
        </div>
        <div className="col-9">
          {/* <p>duration (time)</p> */ }
          <h5>Dr. Name</h5>
          <p>specialization</p>
        </div>
      </div>
      <div className="row">
        <div className="col-4">date</div>
        <div className="col-4">time</div>
        <div className="col-4">channel</div>
      </div>
    </div>
  );
};
const PatientCard = ( { patient, className } ) => {
  return (
    <div className={ `app-card hover doctor-appointment-card ${ className }` }>
      <div className="row">
        <div className="col-3">
          <Avatar size="large" />
        </div>
        <div className="col-9">
          {/* <p>duration (time)</p> */ }
          <h5>patient name</h5>
          <p>type</p>
        </div>
      </div>
    </div>
  );
};

const PatientAppointmentCard = ( { patient, className } ) => {
  return (
    <div className={ `app-card hover doctor-appointment-card ${ className }` }>
      <div className="row">
        <div className="col-3">
          <Avatar size="large" />
        </div>
        <div className="col-9">
          {/* <p>duration (time)</p> */ }
          <h5>patient name</h5>
          <p>cold (problem summary)</p>
          <div className="row">
            {/* <div className="col-4">date</div>
                        <div className="col-4">time</div> */}
            <div className="col-4">channel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceCard = ( { service, className } ) => {
  return (
    <div className={ `app-card hover service-card ${ className }` }>
      <Link to="#">
        <div className="row">
          <div className="col-md-3 col-12">
            <Avatar />
          </div>
          <div className="col-md-9 col-12">service name</div>
        </div>
      </Link>
    </div>
  );
};

const FilterCard = ( { filter, className, isActive, onClick } ) => {
  return (
    <div
      className={ `app-card hover filter-card ${ className } ${ isActive && "is-selected"
        }` }
      onClick={ onClick }
    >
      { filter }
    </div>
  );
};

const AppointmentTypeCard = ( {
  title,
  icon = "",
  isActive = false,
  className,
  onClick,
} ) => {
  return (
    <div
      className={ `app-tile action-tile hover d-block ${ className } ${ isActive && " tile-active"
        }` }
      onClick={ onClick }
    >
      <span className={ `bi bi-${ icon } me-2 mb-0` }></span>
      <span>{ title }</span>
    </div>
  );
};

const Tile = ( {
  title,
  icon,
  url,
  className,
  isLink = true,
  isAction = false,
  isActive = false,
  onClick,
} ) => {
  const content = (
    <div className="py-4">
      <span className={ `h5 bi ${ icon } mb-3` }></span>
      <p className="m-0">{ title }</p>
    </div>
  );
  return (
    <div
      className={ `app-tile ${ isAction && " action-tile " } ${ isActive && "tile-active"
        } ${ className }` }
    >
      { isLink ? <Link to={ url || "#" }>{ content }</Link> : <>{ content }</> }
    </div>
  );
};

const AvailabilityCard = ( { day, times = [] } ) => {
  return (
    <div className="d-flex justify-content-between">
      <div>
        <h4>{ day }</h4>
        { times.map( ( time ) => (
          <p>{ time }</p>
        ) ) }
      </div>
      <button className="button btn-text">
        <span className="bi bi-pencil"></span>
      </button>
    </div>
  );
};

const SummaryCard = ( { className, label, value, icon } ) => {
  return (
    <Paper className={ `summary-card ${ className }` }>
      <h5 className="data mt-2">
        { value }
      </h5>
      <p className="card-label">
        { label }
      </p>
      {/* <div className="row">
        <div className="col-3">
          <span className={ `bi bi-${ icon }` } />
        </div>
        <div className="col-9">
          <h4>{ value }</h4>
        </div>
      </div>
      <div className="row m-0">
        <small className="text-secondary text-right">{ label }</small>
      </div> */}
    </Paper>
  );
};

const PrescriptionCard = ( { data, onDelete } ) => {
  return (
    <div className="row bg-white p-2">
      <div className="col-4" title={ data?.medicine }>{ data?.medicine }</div>
      <div className="col-2" title={ data?.dosage }>{ data?.dosage }</div>
      <div className="col-2" title={ data?.frequency }>{ data?.frequency }</div>
      <div className="col-3" title={ data?.duration }>{ data?.duration }</div>
      <div className="col-1">
        <button className='button is-small text-danger'
          onClick={ () => onDelete( data.id ) }
        >
          <span className="bi bi-trash"></span>
        </button>
      </div>
    </div>
  );
};


const PatientFinderResultCard = ( { patient, className, onSelect } ) => {
  const navigate = useNavigate();

  return (
    <Paper className={ `d-flex justify-content-between finder-card p-3 ${ className }` }>
      <div>
        <div className="d-flex">
          {/* <small className="me-2">
            <kbd className="bg-danger text-small">ATD</kbd>
          </small> */}
          <h5 className="m-0">
            { `${ patient.firstName } ${ patient.lastName }` }
          </h5>
        </div>
        {
          patient.insuranceNumber && <small className="me-3">#: { patient.insuranceNumber }</small>
        }
        { patient.contact && <small>Phone: { patient.contact }</small> }
      </div>
      <button className="app-btn "
        onClick={ () => onSelect( patient ) }>Select</button>
      {/* <div class="buttons has-addons">
        <button className="button is-light border is-link"
          onClick={ () => {
            navigate( appLinks.inboundCall.index, {
              state: {
                patient
              }
            } );
          } }
        >Call</button>
        <button className="button is-light border is-success"
          onClick={ () => {
            navigate( appLinks.consultations.new, {
              state: {
                patient
              }
            } );
          } }
        >Consult</button>
      </div> */}
    </Paper >
  );
};



const ConsultationProgressCard = ( { consultation } ) => {
  const { Paragraph } = Typography;
  const [ modelOpen, setOpenModal ] = useState( false );

  return (
    <>
      <div onClick={ () => setOpenModal( true ) }>
        <div className="row m-0 align-items-center g-0" key={ consultation.code }>
          <div className="col-6 g-0">
            <Paragraph ellipsis className="m-0">
              { `${ consultation.patient.firstName } ${ consultation.patient.lastName }` }
            </Paragraph>
          </div>
          <div className="col-6 g-0">
            <Progress className="m-0 p-0" value={ percentage( consultation.completedTasks, consultation.totalTasks ) } label={ `${ percentage( consultation.completedTasks, consultation.totalTasks ) }%` } size="lg" striped animate />
          </div>
        </div>
        <div className="text-muted g-0">
          <div className="g-0 d-flex align-items-center">
            <small className="me-2"><kbd
              className={ consultationColorCoding( consultation.completedTasks, consultation.totalTasks ) }
            >Code:</kbd></small> { consultation.code } <div className="span mx-2">-</div>
            { new Date( consultation.createdAt ).toDateString() }
          </div>
        </div>
      </div>
      <Modal
        title="Consultation"
        size={ 900 }
        opened={ modelOpen }
        onClose={ () => setOpenModal( false ) }
      >
        <ConsultationDetail consultationId={ consultation.id } />
      </Modal>
    </>
  );
};



const DetailLineItem = ( { icon, title, detail, firstCol, secondCol } ) => {
  return (
    <div className="row mt-3 align-items-center">
      <div className={ `${ firstCol ? firstCol : 'col-3' }` }>
        <span className="text-muted">
          <span className={ `bi bi-${ icon } me-2` } />
          { title }
        </span>
      </div>
      <div className={ `${ secondCol ? secondCol : 'col-9' }` }>
        { detail }
      </div>
    </div>
  );
};



const TimeLine = ( { data = [], onSelect, selectedId } ) => {

  return (
    <Timeline active={ 1 } bulletSize={ 24 } lineWidth={ 2 }>

      {
        data.map( dt => {

          if ( dt.isComplete )

            return <Timeline.Item className={ `rounded pb-2 hover py-1 ${ selectedId === dt.id && ' app-bg-default' }` }
              bullet={ <IconCheck size={ 12 } /> }
              onClick={ () => onSelect( dt.id ) }
              title={ new Date( dt.createdAt ).toUTCString() }
            >
              <Text color="dimmed" size="sm">{ dt.complaint }</Text>
              <Text color="dimmed" size="sm">{ dt.totalTasks } - tasks</Text>
              <Text size="xs" mt={ 4 }>
                { dt.isComplete ?
                  <kbd className="bg-success">Complete</kbd> :
                  <kbd className="bg-warning">Pending</kbd>
                }
              </Text>
            </Timeline.Item>;

          else

            return <Timeline.Item className={ `rounded pb-2 hover py-1 ${ selectedId === dt.id && ' app-bg-default' }` }
              bullet={ <IconHistoryToggle size={ 12 } /> }
              onClick={ () => onSelect( dt.id ) }
              title={ new Date( dt.createdAt ).toUTCString() }
              color="orange"
              lineVariant="dashed">

              <Text color="dimmed" size="sm">{ dt.complaint }</Text>
              <Text color="dimmed" size="sm">{ dt.totalTasks } - tasks</Text>
              <Text size="xs" mt={ 4 }>
                { dt.isComplete ?
                  <kbd className="bg-success">Complete</kbd> :
                  <kbd className="bg-warning">Pending</kbd>
                }
              </Text>
            </Timeline.Item>;

        } )
      }
    </Timeline>
  );
};



const ExcuseDutyCard = ( { data, openDuty } ) => {
  return (
    <div className="row px-2">
      <div className="col-9">
        <div>{ `${ data.patient.firstName } ${ data.patient.lastName } - 
        (${ differenceInDays( new Date( data.endDate ), new Date( data.startDate ) ) } days)
        ` }</div>
        <div>
          <small>
            <strong>Date </strong>: { new Date( data.createdAt ).toDateString() }
            <strong className="mx-2">Doctor:</strong>{ `${ data.doctor.firstName } ${ data.doctor.lastName }` }
          </small>
        </div>
      </div>
      <div className="col-3">
        <button className="app-btn btn-text"
          onClick={ () => openDuty( data.consultationCode, data.id ) }
        >open</button>
      </div>
    </div>
  );
};



export default SummaryCard;

export {
  TimeLine,
  ShortDateCard,
  DoctorProfileCard,
  DoctorAppointmentCard,
  ServiceCard,
  FilterCard,
  PatientAppointmentCard,
  PatientCard,
  AppointmentTypeCard,
  Tile,
  AvailabilityCard,
  PrescriptionCard,
  PatientFinderResultCard,
  ConsultationProgressCard,
  DetailLineItem,
  ExcuseDutyCard
};
