import { message } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation } from "react-query";
import { postNewCategory } from "../../helpers/api";
import { RequiredIndicator } from "../presentation-components";

const NewCategoryForm = ( { onSuccess, type } ) => {
    const [ state, setState ] = useState( {
        title: '',
        description: '',
        type,
    } );


    const { mutateAsync, isLoading } = useMutation( ( data ) => postNewCategory( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                onSuccess();
                setState( {
                    title: '',
                    description: '',
                    type
                } );
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );

    const handleSubmit = () => {
        if ( _.isEmpty( state.title ) || _.isEmpty( state.type ) ) {
            message.error( 'required fields missing' );
            return;
        }

        mutateAsync( state );
    };


    return (
        <div>
            <form
                onSubmit={ e => {
                    e.preventDefault();
                    handleSubmit();
                } }
            >
                <div className="field">
                    <label className="mb-0" htmlFor="title">
                        Title
                        <RequiredIndicator />
                    </label>
                    <input
                        value={ state.title }
                        required
                        onChange={ e => setState( { ...state, title: e.target.value } ) }
                        className="input" type="text"
                        autoFocus
                        id="title" placeholder="enter category title here" />
                </div>
                <div className="field">
                    <label className="mb-0" htmlFor="description">Description</label>
                    <textarea
                        value={ state.description }
                        onChange={ e => setState( { ...state, description: e.target.value } ) }
                        className="textarea" type="text"
                        rows={ 2 }
                        id="description" placeholder="describe category"></textarea>
                </div>
                <button
                    disabled={ state.title.length === 0 }
                    className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading' }` } type='submit' >
                    <span className="bi bi-save me-2"></span>
                    Save
                </button>
            </form>
        </div>
    );
};

export { NewCategoryForm };