import { Loader } from '@mantine/core';
import { BackTop, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import ConsultationFilterBar from '../../../components/filter-bars/consultations-report-filters';
import { calcAge, exportToExcel } from '../../../helpers/utilities';
import { useDownloadExcel } from 'react-export-table-to-excel';

export default function ConsultationsReport ( { data, isLoading } ) {

    const [ filteredData, setFilteredData ] = useState( [] );

    const handleReset = () => {
        setFilteredData( data );
    };

    const handleApplyFilter = ( newData ) => {
        setFilteredData( newData );
    };

    // const { onDownload: exportToEXcel } = useDownloadExcel( {
    //     currentTableRef: document.getElementById( 'consultationsReportTable' ),
    //     filename: 'Consultation Report',
    //     sheet: 'main'
    // } );




    useEffect( () => {
        setFilteredData( data );
    }, [ data ] );

    const columns = [
        {
            title: "#",
            // sorter: ( a, b ) => a.insuranceNumber > b.insuranceNumber,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => ++index
        },
        {
            title: "code",
            sorter: ( a, b ) => a?.code > b?.code,
            dataIndex: 'code',
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Date - Time",
            sorter: ( a, b ) => a?.createdAt > b?.createdAt,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => new Date( record?.createdAt ).toUTCString()

        },
        {
            title: "Patient Name",
            sorter: ( a, b ) => a?.firstName > b?.lastName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.patient?.firstName }  ${ record?.patient?.lastName }`,
        },
        {
            title: "Gender",
            dataIndex: [ 'patient', 'gender' ],
            sorter: ( a, b ) => a?.patient?.gender > b?.patient?.gender,
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Age",
            // sorter: ( a, b ) => a.contact > b.contact,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => calcAge( record?.patient?.dob ).age
        },

        {
            title: "Type",
            sorter: ( a, b ) => a?.type > b?.type,
            dataIndex: "type",
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Doctor",
            sorter: ( a, b ) => a?.doctor?.firstName > b?.doctor?.firstName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => `${ record?.doctor?.firstName } ${ record?.doctor?.lastName }`

        },
        {
            title: "Status",
            sorter: ( a, b ) => a?.isComplete > b?.isComplete,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => record?.isComplete ? "Complete" : "Pending"

        },
        {
            title: "Drugs",
            sorter: ( a, b ) => a?.prescriptions?.length > b?.prescriptions?.length,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => record?.prescriptions?.length

        },
        {
            title: "Labs",
            sorter: ( a, b ) => a?.laboratories > b?.laboratories,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => record?.laboratories?.length
        },

    ];


    return (
        <div>
            <ConsultationFilterBar data={ data }
                excelExport={ () => exportToExcel( "consultationsReportTable", "rivia-consultations-report" ) }
                onReset={ handleReset } onFilter={ ( d ) => handleApplyFilter( d ) }
            />
            {/* advanced filters
            -> date range
            -> doctor
            -> status
            -> age
            
            */}
            {/* { isLoading &&
                <div className='mt-3' >
                    <Spin /> <span>refreshing...</span>
                </div>
            } */}
            <Table
                className="border mt-3"
                rowKey={ ( record ) => record.id }
                dataSource={ filteredData }
                columns={ columns }
                loading={ isLoading }
                bordered
                id='consultationsReportTable'
            />
            <BackTop />
        </div>
    );
}
