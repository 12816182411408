import AddPharmacyComponent from './add-pharmacy';

const ConsultationPharmaciesComponent = ( { pharmacies, allPharmacies, onUpdate, onDelete } ) => {
    // allPharmacies  is the list of pharmacies pulled from the server

    return (
        <div>
            {
                pharmacies.length > 0 && <>
                    <div className="row">
                        {/* <div className="col-4" ><strong>Region</strong></div> */ }
                        <div className="col-12" ><strong>Pharmacy</strong></div>
                    </div>
                </>
            }
            {
                pharmacies.length > 0 && pharmacies.map( pharm =>
                    <div className="my-1" key={ pharm.recordId }>
                        <AddPharmacyComponent data={ pharm } allPharmacies={ allPharmacies }
                            onUpdate={ onUpdate } onDelete={ onDelete }
                        />
                    </div>
                )
            }
        </div>
    );
};

export default ConsultationPharmaciesComponent;
