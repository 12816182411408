import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { IndicatorInfo, RequiredIndicator } from "../../../components";
import { postNewLaboratory } from "../../../helpers/api";
import { laboratoryCategories, Regions } from "../../../helpers/config";
import { dropDownSet } from "../../../helpers/utilities";

const NewLaboratoryForm = ( { onSuccess, onError } ) => {
  const { handleSubmit, register, reset } = useForm();
  const [ dropValue, setDropValues ] = useState( {
    region: "",
    type: ""
  } );

  const { mutateAsync, isLoading } = useMutation( ( data ) => postNewLaboratory( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data?.message );
        onSuccess();
        reset();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {

      const err = error.response.data.message;

      if ( _.isArray( error.response.data.message ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      onError();
    },
  } );

  const handleCreate = async ( data ) => {
    mutateAsync( { ...data, ...dropValue } );
  };


  return (
    <form onSubmit={ handleSubmit( handleCreate ) }>
      <div className="row">
        <IndicatorInfo />
        <div className="field col-12">
          <label className="mb-0" htmlFor="laboratoryName">
            Laboratory Name
            <RequiredIndicator />
          </label>
          <input
            className="input"
            type="text"
            autoFocus
            { ...register( "laboratoryName", { required: true } ) }
            id="laboratoryName"
            placeholder="laboratory name here"
          />
          <small>registered laboratory name</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="type">
            Type
            <RequiredIndicator />
          </label>
          <Select
            value={ dropValue.category }
            required
            onChange={ ( v ) => dropDownSet( "type", v, dropValue, setDropValues ) }
            size="large"
            id="type"
            className="d-block"
            placeholder="select type"
          >
            { laboratoryCategories }
          </Select>
          <small>type of laboratory</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="contactPerson">
            Contact Person
            <RequiredIndicator />
          </label>
          <input
            className="input"
            type="text"
            { ...register( "contactPerson", { required: true } ) }
            id="contactPerson"
            placeholder="name of person"
          />
          <small>who is the primary contact person</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="contact">
            Contact
            <RequiredIndicator />
          </label>
          <input
            className="input"
            type="tel"
            { ...register( "contact", { required: true } ) }
            id="contact"
            placeholder="contact here"
          />
          <small>primary contact of laboratory</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="email">
            Email
          </label>
          <input
            className="input"
            type="email"
            { ...register( "email" ) }
            id="email"
            placeholder="email here"
          />
          <small>email address of laboratory</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="location">
            Location
          </label>
          <input
            className="input"
            type="text"
            { ...register( "location" ) }
            id="location"
            placeholder="laboratory location here"
          />
          <small>physical location of laboratory</small>
        </div>
        <div className="field col-12">
          <label className="mb-0" htmlFor="region">
            Region
            <RequiredIndicator />
          </label>
          <Select
            value={ dropValue.region }
            required
            onChange={ ( v ) => dropDownSet( "region", v, dropValue, setDropValues ) }
            size="large"
            id="region"
            className="d-block"
            placeholder="select region"
          >
            { Regions }
          </Select>
          <small>region where laboratory is located</small>
        </div>
      </div>
      <div className="row px-2">
        <button
          type="submit" id="submit" className={ `button app-btn h6 btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
          <span className="bi bi-save me-2" />
          Save
        </button>
      </div>
    </form>
  );
};

export default NewLaboratoryForm;
