import { message } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getExtProviderConsultation } from '../../../helpers/api';
import { consultationCodeLength } from '../../../helpers/config';
import LabConsultationDetail from './lab-details';

export default function LabPortalSearch () {
    const [ consultation, setConsultation ] = useState( {} );
    const [ code, setCode ] = useState( '' );
    const [ busy, setBusy ] = useState( false );

    const findConsultation = ( e ) => {
        e.preventDefault();

        if ( !code ) {
            message.error( 'no code found' );
            return;
        }
        if ( code.length !== consultationCodeLength ) {
            message.error( 'invalid code' );
            return;
        }

        fetchConsultation( code );
    };

    // const { isFetching: busy, refetch: fetchConsultation } = useQuery( {
    //     queryFn: getExtProviderConsultation( code ),
    //     queryKey: [ 'extLabConsultation', code ],
    //     enabled: false,
    //     onSuccess: data => {
    //         console.log( 'data returned: ', data );
    //     },
    //     onError: err => {
    //         console.log( 'error received: ', err );
    //         message.error( 'error fetching record' );
    //     }
    // } );


    const fetchConsultation = ( consultationCode ) => {
        setBusy( true );

        getExtProviderConsultation( consultationCode ).then(
            res => {
                if ( res.status === 200 ) {
                    if ( !_.isEmpty( res.data?.data ) ) {
                        setConsultation( res.data?.data );
                        // setCode( '' );
                    }
                    else
                        message.error( 'records not found' );
                } else {
                    message.error( res.response.data.message );
                }

                setBusy( false );
            }
        );
    };

    return (
        <div>
            {
                _.isEmpty( consultation ) ?
                    <div className="row">
                        <div className="col-md-4 col-8 mx-auto mt-5">
                            <form onSubmit={ findConsultation } className="text-center">
                                <label className="mb-0" htmlFor="title">
                                    Enter consultation code to search
                                </label>
                                <div className="field">
                                    <input
                                        className="input text-center"
                                        type="text"
                                        required
                                        value={ code }
                                        onChange={ ( e ) => setCode( e.target.value ) }
                                        autoFocus
                                        id="title"
                                        placeholder="enter consultation code here"
                                    />
                                </div>
                                <button onClick={ findConsultation } className={ `button mt-3 h6 app-btn btn-prim ${ busy && ' is-loading ' }` }>Search</button>
                            </form>
                        </div>
                    </div>
                    :
                    <LabConsultationDetail data={ consultation } onReload={ fetchConsultation } onClose={ () => setConsultation( {} ) } />

            }
        </div>
    );
}
