import { Loader } from "@mantine/core";
import { message, Select, Tabs } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  IndicatorInfo,
  RequiredIndicator,
} from "../../../components";
import {
  getAllStaffGroups,
  getStaff,
  putUpdateStaff,
} from "../../../helpers/api";

const EditStaffForm = ( { staffId, isEditable = true, onSuccess, onError, onExit } ) => {

  const [ staff, setStaff ] = useState( {} );

  const { Option } = Select;
  const { TabPane } = Tabs;

  const { isError, isFetching } = useQuery( {
    queryFn: () => getStaff( staffId ),
    queryKey: [ 'staff', staffId ],
    onSuccess: data =>
      setStaff( _.omit( data, [ 'avatarUrl', 'staffGroup', 'totalConsultations',
        'totalCalls', 'createdAt', 'updatedAt', 'isDeleted' ] ) )
  } );


  const { data: groups } = useQuery( {
    queryFn: () => getAllStaffGroups(),
    queryKey: [ 'staffGroups' ],
  } );

  const updateData = ( fieldName, data ) => {
    setStaff( { ...staff, [ fieldName ]: data } );
  };


  const { mutateAsync: updateStaff, isLoading } = useMutation( ( data ) => putUpdateStaff( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onSuccess();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
      onError();
    },
  } );


  return (
    <div>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
            :
            <form onSubmit={ ( e ) => { e.preventDefault(); updateStaff( staff ); } }>
              <IndicatorInfo />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Basic Info" key="1">
                  {/* <p>image uploader here</p> */ }
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="firstName">
                        First Name
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        type="text"
                        disabled={ !isEditable }
                        value={ staff.firstName }
                        onChange={ e => updateData( 'firstName', e.target.value ) }
                        autoFocus
                        id="firstName"
                        placeholder="staff's first name"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="lastName">
                        Last Name
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        type="text"
                        disabled={ !isEditable }
                        value={ staff.lastName }
                        onChange={ e => updateData( 'lastName', e.target.value ) }
                        id="lastName"
                        placeholder="staff's last name"
                      />
                      {/* <small>staff' last name</small> */ }
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="birthdate">
                        Date of Birth
                        <RequiredIndicator />
                      </label>
                      <input
                        type="date"
                        className="input"
                        disabled={ !isEditable }
                        value={ staff.dob }
                        onChange={ e => updateData( 'dob', e.target.value ) }
                        placeholder="select date"
                      />
                      {/* <DatePicker size="large" format={ "dddd - MMMM DD, YYYY" } className="d-block" /> */ }
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="gender">
                        Gender
                        <RequiredIndicator />
                      </label>
                      <Select
                        size="large"
                        id="gender"
                        disabled={ !isEditable }
                        value={ staff.gender }
                        onChange={ v => updateData( 'gender', v ) }
                        required
                        className="d-block"
                        placeholder="gender"
                      >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="staffGroup">
                        Staff Group
                        <RequiredIndicator />
                      </label>
                      <Select
                        size="large"
                        id="staffGroup"
                        disabled={ !isEditable }
                        value={ staff.staffGroupId }
                        onChange={ v => updateData( 'staffGroupId', v ) }
                        required
                        className="d-block"
                        placeholder="select group"
                      >
                        { groups?.map( ( g ) => (
                          <Option value={ g.id } key={ g.id }>
                            { g.title }
                          </Option>
                        ) ) }
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="startWorkDate">
                        Work Start Date
                        <RequiredIndicator />
                      </label>
                      <input
                        type="date"
                        disabled={ !isEditable }
                        value={ staff.startWorkDate }
                        onChange={ e => updateData( 'startWorkDate', e.target.value ) }
                        className="input"
                        placeholder="select date"
                      />

                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Contact & Address" key="3">
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="contact">
                        Phone Number
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        type="tel"
                        id="contact"
                        disabled={ !isEditable }
                        value={ staff.contact }
                        onChange={ e => updateData( 'contact', e.target.value ) }
                        placeholder="staff's contact number"
                      />
                      {/* <small>staff' last name</small> */ }
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="input"
                        type="email"
                        id="email"
                        disabled={ !isEditable }
                        value={ staff.email }
                        onChange={ e => updateData( 'email', e.target.value ) }
                        placeholder="staff's email address"
                      />
                      {/* <small>staff' last name</small> */ }
                    </div>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="residentialAddress">
                        Residential Address
                      </label>
                      <textarea
                        className="textarea"
                        disabled={ !isEditable }
                        id="residentialAddress"
                        value={ staff.residentialAddress }
                        onChange={ e => updateData( 'residentialAddress', e.target.value ) }
                        placeholder="staff's house address"
                      ></textarea>
                      {/* <small>staff' last name</small> */ }
                    </div>
                  </div>
                </TabPane>
              </Tabs>
              {
                isEditable && <button
                  type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                  Update Record
                </button>
              }
            </form>
      }

    </div>
  );
};

export default EditStaffForm;
