import { Loader } from "@mantine/core";
import { Divider, message } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAllPharmacies, getPharmacies, putPharmacies } from "../../../helpers/api";
import AddPharmacyComponent from "./add-pharmacy";
import { ErrorBoundary } from "../../../components";

const EditPharmacyComponent = ( { consultationId, onRefresh } ) => {
    const [ pharmacies, setPharms ] = useState( [] );
    const [ consultation, setConsultation ] = useState( {} );

    const { isError, isFetching, refetch } = useQuery( {
        queryFn: () => getPharmacies( consultationId ),
        queryKey: [ 'pharmacies', consultationId ],
        onSuccess: data => {
            // add the recordId field to fetched data to enable tracking
            const newSet = data.map( ( d, i ) => {
                return {
                    isComplete: d.isComplete,
                    id: d.pharmacy.id,
                    pharmacyId: d.pharmacy.id,
                    region: d.pharmacy.region,
                    // pharmacyName: d.pharmacy.pharmacyName,
                    // location: d.pharmacy.location,
                    // contact: d.pharmacy.contact,
                    recordId: ++i
                };
            } );
            setPharms( newSet );
            setConsultation( data[ 0 ]?.consultation );
        }
    } );

    const { data: serverPharmas = [] } = useQuery( {
        queryFn: () => getAllPharmacies(),
        queryKey: [ 'pharmacies' ],
    } );


    const { mutateAsync: updatePharmacies, isLoading } = useMutation( ( data ) => putPharmacies( consultationId, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    // pharmacy
    const handleAddPharmacy = () => {
        setPharms( [
            ...pharmacies,
            {
                recordId: pharmacies.length + 1,
                pharmacyId: null,
            }
        ] );
    };

    const handleUpdatePharmacy = ( recordId, value ) => {
        let currentPharma = pharmacies.filter( pharma => pharma.recordId === recordId )[ 0 ];
        currentPharma.pharmacyId = value;
        setPharms( [
            ...pharmacies.filter( pharma => pharma.recordId !== recordId ),
            currentPharma
        ] );
    };

    const handleDeletePharmacy = ( recordId ) => {
        const updated = pharmacies.filter( pharm => pharm.recordId !== recordId );
        setPharms( updated );
    };


    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <div className="row mb-1">
                            <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd> }>
                                {
                                    pharmacies.map( ( pharm, index ) =>
                                        <>
                                            <div className="col-1"><kbd>{ ++index }</kbd></div>
                                            <div className="col-11">
                                                <AddPharmacyComponent allPharmacies={ serverPharmas }
                                                    key={ pharm.id } data={ pharm } onUpdate={ handleUpdatePharmacy }
                                                    onDelete={ handleDeletePharmacy } />
                                            </div>
                                            <Divider />
                                        </>
                                    )
                                }
                            </ErrorBoundary>
                        </div>
            }
            {
                !consultation?.isComplete &&
                <>
                    <div>
                        <button className={ `button is-small mt-2 ${ isLoading && ' is-loading ' }` }
                            onClick={ handleAddPharmacy }>Add Pharmacy</button>
                    </div>
                    <button onClick={ () => updatePharmacies( pharmacies ) }
                        id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                        Update Pharmacies
                    </button>
                </>
            }
        </>
    );
};

export default EditPharmacyComponent;
