import { Loader, Skeleton } from "@mantine/core";
import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAllDiagnosis, getDiagnosis, putDiagnosis } from "../../../helpers/api";
import AddDiagnosisComponent from './add-diagnosis';
import { ErrorBoundary } from "../../../components";

const EditDiagnosisComponent = ( { consultationId, onRefresh } ) => {
    const { Option } = Select;
    const [ diagnosis, setDiag ] = useState( [] );
    const [ consultation, setConsultation ] = useState( {} );

    const { isError, isFetching, refetch } = useQuery( {
        queryFn: () => getDiagnosis( consultationId ),
        queryKey: [ 'diagnosis', consultationId ],
        onSuccess: data => {

            // add the recordId field to fetched data to enable tracking
            const newSet = data.map( ( d, i ) => {
                return { ...d, recordId: ++i };
            } );
            setDiag( newSet );
            setConsultation( data[ 0 ]?.consultation );
        }
    } );

    const { data: serverDiagnosis = [] } = useQuery( {
        queryFn: () => getAllDiagnosis(),
        queryKey: [ 'diagnosis' ],
        refetchOnReconnect: false,
        retryOnMount: false,
        keepPreviousData: true,
    } );


    const { mutateAsync: updateDiagnosis, isLoading } = useMutation( ( data ) => putDiagnosis( consultationId, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    // diagnosis
    const handleAddDiagnosis = () => {
        setDiag( [
            ...diagnosis,
            {
                recordId: diagnosis.length + 1,
                diagnosis: ''
            }
        ] );
    };

    const handleUpdateDiagnosis = ( recordId, value ) => {
        let currentDiag = diagnosis.filter( diag => diag.recordId === recordId )[ 0 ];
        currentDiag.diagnosis = value;
        setDiag( [
            ...diagnosis.filter( diag => diag.recordId !== recordId ),
            currentDiag
        ] );
    };

    const handleDeleteDiagnosis = ( recordId ) => {
        const updated = diagnosis.filter( diag => diag.recordId !== recordId );
        setDiag( updated );
    };

    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <>
                            <div className="row mb-1">
                                <div className="col-12 mb-2" ><strong>Working Diagnosis <kbd>{ diagnosis.length }</kbd></strong></div>
                                {
                                    diagnosis?.map( ( diag ) =>
                                        <div className="col-12">
                                            <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd> }>
                                                {
                                                    _.isEmpty( serverDiagnosis ) ?
                                                        <Skeleton>loading..</Skeleton> :
                                                        <AddDiagnosisComponent allDiagnosis={ serverDiagnosis } key={ diag.id } data={ diag } onUpdate={ handleUpdateDiagnosis } onDelete={ handleDeleteDiagnosis } />

                                                }
                                            </ErrorBoundary>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                !consultation?.isComplete &&
                                <>
                                    <div>
                                        <button className={ `button is-small mt-2 ${ isLoading && ' is-loading ' }` }
                                            onClick={ handleAddDiagnosis }>Add Diagnosis</button>
                                    </div>
                                    <button onClick={ () => updateDiagnosis( diagnosis ) }
                                        id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                                        Update Diagnosis
                                    </button>
                                </>
                            }
                        </>
            }
        </>
    );
};


export default EditDiagnosisComponent;
