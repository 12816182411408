import React from 'react';
import { cedisLocale } from '../../helpers/utilities';
import { Select } from '@mantine/core';

export default function BillItemsComponent ( { item, onUpdate, onDelete, services = [] } ) {
    return (
        <div className='row'>
            <div className="col-5">
                <Select
                    id='serviceId'
                    value={ item?.serviceId }
                    nothingFound="No match"
                    onChange={ ( value ) => onUpdate( { recordId: item.recordId, field: 'serviceId', value } ) }
                    size="md"
                    clearable
                    searchable
                    placeholder='select service'
                    data={
                        services?.map( serv => {
                            return {
                                label: `(${ serv.serviceCode }) ${ serv.serviceName }`,
                                value: serv.id
                            };
                        } )
                    }
                />
            </div>
            <div className="col-2">
                { cedisLocale.format( parseFloat( services?.find( s => s?.id === item?.serviceId )?.baseRate || 0 ) ) }
            </div>
            <div className="col-2">
                <input
                    type="number"
                    step="1"
                    className='input'
                    value={ item.quantity }
                    onChange={ ( e ) => onUpdate( { recordId: item.recordId, field: 'quantity', value: e.target.value } ) }
                />
            </div>
            <div className="col-2">
                { cedisLocale.format( parseFloat( parseFloat( services?.find( s => s?.id === item?.serviceId )?.baseRate || 0 )
                    * parseInt( item.quantity ) ) ) }
            </div>
            <div className="col-1 g-0">
                <button className='button is-text'
                    onClick={ () => onDelete( item.recordId ) }
                >
                    <span className="bi bi-trash text-danger"></span>
                </button>
            </div>
        </div>
    );
};;
