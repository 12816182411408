import { message } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { getExtProviderConsultation } from '../../../helpers/api';
import { consultationCodeLength } from '../../../helpers/config';
import PharmacyConsultationDetail from './pharm-details';

export default function PharmaPortalSearch () {
    const [ consultation, setConsultation ] = useState( {} );
    const [ code, setCode ] = useState( '' );
    const [ busy, setBusy ] = useState( false );

    const findConsultation = ( e ) => {
        e.preventDefault();

        if ( !code ) {
            message.error( 'no code found' );
            return;
        }
        if ( code.length !== consultationCodeLength ) {
            message.error( 'invalid code' );
            return;
        }

        fetchConsultation( code );
    };


    const fetchConsultation = ( consultationCode ) => {
        setBusy( true );

        getExtProviderConsultation( consultationCode ).then(
            res => {
                if ( res.status === 200 ) {
                    if ( !_.isEmpty( res.data?.data ) ) {
                        setConsultation( res.data?.data );
                        // setCode( '' );
                    }
                    else
                        message.error( 'records not found' );
                }
                else {
                    message.error( res.response.data.message );
                }

                setBusy( false );
            }
        );
    };

    return (
        <div>
            {
                _.isEmpty( consultation ) ?
                    <div className="row">
                        <div className="col-md-4 col-8 mx-auto mt-5">
                            <form onSubmit={ findConsultation } className="text-center">
                                <label className="mb-0" htmlFor="title">
                                    Enter consultation code to search
                                </label>
                                <div className="field">
                                    <input
                                        className="input text-center"
                                        type="text"
                                        required
                                        value={ code }
                                        onChange={ ( e ) => setCode( e.target.value ) }
                                        autoFocus
                                        id="title"
                                        placeholder="enter consultation code here"
                                    />
                                </div>
                                <button className={ `button h6 app-btn btn-prim ${ busy && ' is-loading' }` }>Search</button>
                            </form>
                        </div>
                    </div>
                    :
                    <PharmacyConsultationDetail data={ consultation } onReload={ fetchConsultation } onClose={ () => setConsultation( {} ) } />

            }
        </div>
    );
}
