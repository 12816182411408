import { Divider, message, Tag } from "antd";
import _ from "lodash";
import { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { cedisLocale } from "../../helpers/utilities";
import { useQuery } from "react-query";
import { getBillReceipt } from "../../helpers/api";
import { Loader } from "@mantine/core";
import { format } from 'date-fns';
import { IconDownload } from "@tabler/icons";
import { usePDF } from "react-to-pdf";
import riviaClinicLogo from '../../static/img/rivia-clinics-logo.png';
import { getUser } from "../../helpers/auth";

const BillReceipt = ( { query, param = "billId" || "receiptNumber", options = {
    enablePrinting: true,
    allowSizeChange: true,
    showOperator: true
} } ) => {
    // hooks
    const [ size, setSize ] = useState( localStorage.getItem( 'printerSize' ) || '58' );
    const [ receipt, setReceipt ] = useState( {} );
    const printArea = useRef();
    const { toPDF, targetRef } = usePDF( { filename: `rivia-receipt-${ receipt?.billNumber || '' }.pdf` } );

    // query
    const { data, isFetching } = useQuery( {
        queryFn: () => getBillReceipt( param, query ),
        queryKey: [ 'receipt', query ],
        cacheTime: 0,
        onSuccess: data =>
            setReceipt( {
                ...data,
                sumAmount: ( parseFloat( data?.items.reduce( ( total, c ) => total + parseFloat( ( c.rate * c.quantity ) || 0 ), 0 ) ) || 0 ),
                discount: ( parseFloat( data?.discount ) || 0 ),
                amountPaid: ( parseFloat( data?.payments.reduce( ( total, c ) => total + parseFloat( c.amount || 0 ), 0 ) ) || 0 ),
            } )
        // sum all charges --- or NOT 😊
    } );

    const balance = parseFloat( ( receipt.sumAmount - ( receipt.discount || 0 ) ) - receipt?.amountPaid );

    // handlers
    const handlePrint = useReactToPrint( {
        content: () => printArea.current,
        documentTitle: "receipt " + receipt.receipt_number,
        copyStyles: true,
        bodyClass: "sale-receipt"
    } );


    const handleSizeChange = ( size ) => {
        setSize( size );
        localStorage.setItem( 'printerSize', size.toString() );
    };


    return (
        // size58
        <>
            <div className={ `sale-receipt ${ size === '58' ? ' print-size-58' : ' print-size-80' }` }>
                {
                    isFetching ?
                        <Loader> loading...</Loader> :
                        <div ref={ targetRef }>
                            <div className="receipt-content p-2" ref={ printArea } >
                                <div className="header">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="mb-0 receipt-title">{ receipt?.branch?.facility?.facilityName }</h5>
                                            <p className="m-0 text-muted">By Rivia { receipt?.branch?.location && ` - ${ receipt?.branch?.location }` } </p>
                                        </div>
                                        <div>
                                            <img width={ 50 } src={ riviaClinicLogo } alt="Rivia Clinics Logo" />
                                        </div>
                                    </div>
                                    <p className="m-0">{ receipt?.branch?.contact }</p>
                                    <Divider className="my-2" />
                                    <div className="m-0 text-muted">
                                        {/* { size !== '58' ? 'Official Receipt' : 'Receipt' } */ }
                                        <p className="m-0">
                                            Receipt: #{ receipt?.billNumber }</p>
                                        <p className="m-0 p-0">
                                            Date:
                                            <span className="ms-2">{ `${ receipt?.createdAt && format( new Date( receipt?.createdAt ), "EEE, MMM dd, yyyy - h:mm a" ) }` }</span>
                                        </p>
                                        <p className="m-0 p-0">
                                            Bill To:
                                            <span className="ms-2">{ `${ receipt?.patient?.firstName } ${ receipt?.patient?.lastName }` }</span>
                                        </p>
                                    </div>
                                    <hr className="my-2" />
                                </div>
                                <div className="body">
                                    { receipt?.items?.map( ( s ) => (
                                        <div className="row mb-2 g-1" key={ s.serviceId }>
                                            <div className="col-8 m-0">
                                                <i className="me-1">
                                                    (<span>{ s.quantity }x</span>)
                                                </i>
                                                { s.service?.serviceName }
                                            </div>
                                            <div className="col-3 p-0 m-0" style={ { textAlign: "right" } }>
                                                { cedisLocale.format(
                                                    parseInt( s.quantity ) * parseFloat( s.rate )
                                                ) }
                                            </div>
                                            {/* <Divider className="my-1" /> */ }
                                        </div>
                                    ) ) }
                                    {
                                        !_.isEmpty( receipt?.charges ) &&
                                        receipt?.charges.map( ( ch, ind ) => (
                                            <div className="row mb-2 g-1" key={ ind }>
                                                <div className="col-8 m-0">
                                                    <i className="me-1">
                                                        (<span>{ ch.units }x</span>)
                                                    </i>
                                                    { ch.charge?.title }
                                                </div>
                                                <div className="col-3 p-0 m-0" style={ { textAlign: "right" } }>
                                                    { cedisLocale.format(
                                                        ( parseInt( ch.units ) * parseFloat( ch.amount ) )
                                                    ) }
                                                </div>
                                                {/* <Divider className="my-1" /> */ }
                                            </div>
                                        ) )
                                    }
                                </div>
                                {/* <Divider className="my-2" /> */ }
                                <hr className="my-2" />
                                <div className="receipt--footer p--2">
                                    {
                                        receipt?.discount > 0 ?
                                            <>
                                                <div className="row g-0 m-0">
                                                    <div className="col-6">
                                                        Subtotal:
                                                    </div>
                                                    <div className="col-5 m-0 p-0" style={ { textAlign: "right" } }>
                                                        { cedisLocale.format( parseFloat( receipt.sumAmount || 0 ) ) }
                                                    </div>
                                                </div>
                                                <div className="row g-0 m-0">
                                                    <div className="col-6">
                                                        Discount:
                                                    </div>
                                                    <div className="col-5 m-0 p-0" style={ { textAlign: "right" } }>
                                                        { cedisLocale.format( receipt.discount || 0 ) }
                                                    </div>
                                                </div>
                                            </> :
                                            <div className="row g-0 m-0">
                                                <div className="col-6">
                                                    Total Due (GHS):
                                                </div>
                                                <div className="col-5 m-0 p-0" style={ { textAlign: "right" } }>
                                                    <strong>
                                                        { cedisLocale.format( parseFloat( receipt.sumAmount ) ) }
                                                    </strong>
                                                </div>
                                            </div>
                                    }
                                    {/* <Divider className="my-1" /> */ }
                                    <div className="row g-0 m-0">
                                        <div className="col-6" >
                                            Amt Paid:
                                        </div>
                                        <div className="col-5 m-0 p-0" style={ { textAlign: "right" } }>
                                            { cedisLocale.format( receipt?.amountPaid ) }
                                        </div>
                                        <div className="col-6">
                                            Balance:
                                        </div>
                                        <div className="col-5 m-0 p-0" style={ { textAlign: "right" } }>
                                            <strong className="ms-1">
                                                { `${ cedisLocale.format( balance || 0 ) }` }
                                            </strong>
                                        </div>
                                    </div>
                                    <Divider className="my-1" />
                                    {
                                        options.showOperator &&
                                        <small className="text-left">
                                            <i>
                                                Operator:
                                                {
                                                    getUser() ?
                                                        ` ${ getUser()?.staff?.name }` :
                                                        ` ${ receipt?.user?.staff.firstName } ${ receipt?.user?.staff.lastName }` }
                                            </i>
                                        </small>
                                    }
                                    {/* <Divider className="my-1" /> */ }
                                    <small className="d-block">
                                        <span>
                                            Generated from RiviaOS on { format( new Date(), 'MM/dd/yy h:mm a' ) }
                                            { receipt?.consultation?.code && ` for consultation with code: ${ receipt?.consultation?.code }` }
                                        </span>
                                    </small>
                                    {/* <p className="my-3">
										---- END ----
									</p> */}
                                </div>
                                {/* insert ends */ }
                            </div>
                        </div>
                }
                <div className="p-2">
                    <div className="row buttons has-addons px-2">
                        {
                            options.enablePrinting &&
                            <button
                                className="h6 button app-btn btn-prim col"
                                onClick={ handlePrint }>
                                <span className="bi bi-printer me-2"></span>
                                Print
                            </button>
                        }
                        <button
                            onClick={ () => {
                                message.info( 'downloading...' );
                                toPDF();
                            } }
                            title="Download" className={ `h6 button app-btn ${ !options.enablePrinting ? ' btn-prim col' : 'col-2' }` }>
                            {
                                options.enablePrinting ?
                                    <IconDownload /> :
                                    <IconDownload className="me-2" />
                            }
                            { !options.enablePrinting && 'Download' }
                        </button>
                    </div>
                    {
                        options.allowSizeChange &&
                        <>
                            <select
                                placeholder="select printer size"
                                className="input mt-2 is-small mb-1"
                                value={ size } onChange={ e => handleSizeChange( e.target.value ) }>
                                <option value="58">Paper Size: 58 mm</option>
                                <option value="80">Paper Size: 80 mm</option>
                                <option value="a4">Paper Size: A4</option>
                            </select>
                            <Tag color="gold" clas>selection will be remembered</Tag>
                        </>
                    }
                </div>
            </div >

        </>
    );
};

export default BillReceipt;
