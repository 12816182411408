import { Divider, message } from "antd";
import _ from "lodash";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { consultationColorCoding, copyToClipboard, generateFileUrl, hasPermission, percentage, } from "../../../helpers/utilities";
import { Kbd, Paper, Menu, Loader, Modal, Image } from "@mantine/core";
import { DetailLineItem, FeedBackView, RatingDisplay } from "../../../components";
import { IconAlarm, IconCheck, IconForms, IconMail, IconClock, IconMessageCircle, IconPill, IconRoad, IconStethoscope, IconTrash, IconHistory, IconNews, IconLockOpen } from "@tabler/icons";
import smalltalk from 'smalltalk';
import { deleteConsultation, getConsultationById, getSendReminder, putUpdateConsultationStatus } from "../../../helpers/api";
import EditConsultationComponent from "./edit-consultation";
import { EditPatientForm } from "../../catalog/components/edit-patient";
import EditDiagnosisComponent from "./edit-diagnosis";
import EditReviewComponent from "./edit-review";
import EditPrescriptionComponent from "./edit-prescription";
import EditLaboratoryComponent from "./edit-laboratory";
import EditPharmacyComponent from "./edit-pharmacy";
import ExcuseDutyForm from "../../../components/shared/excuse-duty";
import PatientJourney from "../../../components/shared/patient-journey";
import MedicalHistory from "../../../components/shared/medical-history";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { action, resouces } from "../../../helpers/auth";
import pdfIcon from '../../../static/icons/pdf.png';
import EditVitals from "./edit-vitals";
import ViewVitals from "./view-vitals";


const ConsultationDetail = ( { consultationId, refresh } ) => {

  const { data = null, isFetching, refetch } = useQuery( {
    queryFn: () => getConsultationById( consultationId ),
    queryKey: [ 'consultation', consultationId ],
    enabled: false,
  } );



  useEffect( () => {
    if ( consultationId )
      refetch();
  }, [ consultationId ] );



  const [ modal, setModal ] = useState( {
    open: false,
    title: '',
    size: 700,
    child: null
  } );
  let totalPrice = 0;
  data?.prescriptions?.map( ( d ) =>
    totalPrice = parseFloat( totalPrice ) + parseFloat( d.price ) );


  const openModal = ( title, child, size = 700 ) => {
    setModal( {
      title,
      child,
      size,
      open: true
    } );
  };

  // methods

  const preview = ( file, index ) => {

    const fName = file.fileName;
    const ext = fName.slice( fName.indexOf( "." ) );

    return (
      <div className="col-4 border"
        title="lab report attachment"
      >
        {
          ( ext === '.pdf' ) ?
            // use a tag and etc etc
            <a href={ generateFileUrl( fName ) } target="_blank">
              <img key={ index }
                style={ {
                  height: '100px',
                  width: '100px'
                } }
                src={ pdfIcon } />
            </a>
            :
            <a href={ generateFileUrl( fName ) } className="d-block" target="_blank">
              <img
                key={ index }
                style={ {
                  height: '100px',
                  width: '130px'
                } }
                crossOrigin='anonymous' //simple trick to get crossorigin error off
                src={ generateFileUrl( fName ) }
                alt="laboratory report image"
              />
            </a>
        }
      </div>
    );
  };



  const handleMarkComplete = () => {
    smalltalk.confirm(
      "Update Status", "You are about to update the status of this consultation. Continue?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {

      message.info( 'please wait...' );
      putUpdateConsultationStatus( data.id ).then( res => {
        if ( res.status === 200 ) {
          message.success( res.data.message );
          refresh();
          refetch();
          return;
        }
      } ).catch( ex => {
        message.error( ex.data.message );
      } );

    } )
      .catch( ex => {
        return false;
      } );

  };
  const handleDelete = () => {
    smalltalk.confirm(
      "Delete Consultation", "Deleting this consultation cannot be undone. Continue?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {
      message.info( 'deleting...' );
      deleteConsultation( data.id ).then( res => {
        if ( res.status === 204 ) {
          message.success( res.data.message );
          // reload();
          refresh();
          refetch();
          return;
        }
      } ).catch( ex => {
        message.error( ex.data.message );
      } );
    } )
      .catch( ex => {
        return false;
      } );
  };

  // updates
  // const handleGenerateDutyForm = () => { };
  // const handleSendSMS = () => { };
  // const handleSendEmail = () => { };


  const handleSendReminder = () => {
    getSendReminder( data.id ).then( res => {
      if ( res.status === 200 ) {
        message.success( res.data.message );
      }
    } );
  };


  return (
    <>
      {
        isFetching &&
        <>
          <Loader className="me-3" />
          <span>Please wait...</span>
        </>
      }
      { ( _.isEmpty( data ) && !isFetching ) && (
        <p className="p-3"> select a consultation to view details</p>
      ) }
      { !_.isEmpty( data ) &&
        <div className="consultation-detail">
          <div className="row g-0">
            <div className="col-12 mb-2">
              <h6 className="section-header mb-0 d-flex">Consultation</h6>
              <p className="text-muted mt-0">basic details of selected consultation</p>
              {/* consultation */ }
              <Paper className="p-3  border">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="layer-header d-flex">
                    Consultation
                    { data?.feedback?.rating && <span className="d-flex ms-2"> - <RatingDisplay rate={ data?.feedback?.rating } />
                      <span className="ms-1 bi bi-star-fill text-warning" /></span> }</h6>
                  <div>
                    <Menu shadow="md" width={ 200 } >
                      <Menu.Target>
                        <button className="app-btn btn-text">
                          <span className="bi bi-grid me-2"></span>
                          Action
                        </button>
                      </Menu.Target>
                      <Menu.Dropdown >
                        {
                          ( hasPermission( resouces.consultations, action.canUpdate ) ||
                            hasPermission( resouces.consultations, action.canDelete )
                          ) &&
                          <Menu.Label>Consultation</Menu.Label>
                        }
                        {
                          ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) ?
                            <Menu.Item
                              color="green"
                              icon={ <IconCheck size={ 14 } /> }
                              onClick={ handleMarkComplete }
                            >Mark Complete</Menu.Item> :
                            <Menu.Item
                              color="black"
                              icon={ <IconLockOpen size={ 14 } /> }
                              onClick={ handleMarkComplete }
                            >Re-Open</Menu.Item>
                        }
                        {
                          hasPermission( resouces.consultations, action.canDelete ) &&
                          <Menu.Item color="red" icon={ <IconTrash size={ 14 } /> }
                            onClick={ handleDelete }
                          >Delete</Menu.Item>
                        }
                        {
                          ( hasPermission( resouces.consultations, action.canUpdate ) ||
                            hasPermission( resouces.consultations, action.canDelete )
                          ) &&
                          <Menu.Divider />
                        }
                        <Menu.Label>Patient</Menu.Label>
                        {
                          hasPermission( resouces.patients, action.canCreate ) &&
                          <Menu.Item icon={ <IconForms size={ 14 } /> }
                            onClick={ () => openModal( "Generate Excuse Duty", <ExcuseDutyForm code={ data.code } />, 500 ) }
                          >Excuse Duty</Menu.Item>
                        }
                        <Menu.Item icon={ <IconRoad size={ 14 } /> }
                          onClick={ () => openModal( "Patient Journey", <PatientJourney patientId={ data.patient.id } />, 1200 ) }
                        >Patient Journey</Menu.Item>
                        <Menu.Item icon={ <IconHistory size={ 14 } /> }
                          onClick={ () => openModal( "Medical History",
                            <MedicalHistory patientId={ data.patient.id }
                              isEditable={ hasPermission( resouces.patients, action.canUpdate ) }
                            /> ) }
                        >Medical History</Menu.Item>
                        <Menu.Item
                          disabled={ _.isEmpty( data.feedback ) }
                          onClick={ () => openModal( "Feedback",
                            <FeedBackView consultationId={ data.id } /> ) }
                          icon={ <IconNews size={ 14 } /> }>Feedback</Menu.Item>
                        {
                          ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                          <>
                            <Menu.Divider />
                            <Menu.Label>Messaging</Menu.Label>
                            <Menu.Item icon={ <IconAlarm size={ 14 } /> }
                              onClick={ handleSendReminder }
                            >Send Reminder</Menu.Item>
                          </>
                        }

                      </Menu.Dropdown>
                    </Menu>
                    {
                      hasPermission( resouces.consultations, action.canUpdate ) &&
                      <button className="app-btn btn-text" onClick={ () =>
                        openModal( "Edit Consultation", <EditConsultationComponent consultationId={ data.id } onRefresh={ refetch } />, 900 ) }>
                        <span className="bi bi-pencil me-2"></span>
                        Edit
                      </button>
                    }
                  </div>
                </div>
                <DetailLineItem icon="lock" title="Code" detail={
                  <>
                    <kbd className={ consultationColorCoding( data.completedTasks, data.totalTasks ) }>
                      { data.code }
                    </kbd>
                    <button className="app-btn btn-text p-1 ms-2"
                      onClick={ () => {
                        copyToClipboard( data.code ).then( done =>
                          message.success( 'code copied!' )
                        );
                      } }
                    >copy</button>
                  </>
                } />
                <Divider className="my-1" />
                <DetailLineItem icon="file-font" title="Folder Number" detail={ data?.folderNumber || '-' } />
                <Divider className="my-1" />
                <DetailLineItem icon="braces-asterisk" title="Type" detail={ data.type } />
                <Divider className="my-1" />
                <DetailLineItem icon="calendar" title="Date" detail={ `${ format( new Date( data.createdAt ), "EEE MMM dd, yyy" ) } @${ new Date( data.createdAt ).toLocaleTimeString() }` } />
                <Divider className="my-1" />
                <DetailLineItem icon="list-task" title="Tasks"
                  detail={ `Total: ${ data.totalTasks } - Complete: ${ data.completedTasks } ---> (${ percentage( data?.completedTasks, data?.totalTasks ) }% complete)` } />
                <Divider className="my-1" />
                <DetailLineItem icon="megaphone" title="Complaint" detail={ data.complaint } />
                <Divider className="my-1" />
                <DetailLineItem icon="card-text" title="Notes" detail={ data.notes } />
                <Divider className="my-1" />
                <DetailLineItem icon="incognito" title="Investigation" detail={ data.investigation } />
                <Divider className="my-1" />
                <DetailLineItem icon="file-medical" title="Management Plan" detail={ data.doctorsAdvise } />
              </Paper>
            </div>
            <div className="col-md-6 g-0 col-12">
              {/* patient */ }
              <Paper className="p-3  border">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="layer-header">
                    <IconPill className="me-1" />
                    Patient</h6>
                  <button className="app-btn btn-text m-0" onClick={ () => openModal( "View Patient",
                    <EditPatientForm patientId={ data.patient?.id } showCharm={ false }
                      isEditable={ hasPermission( resouces.patients, action.canUpdate ) } /> ) }>
                    view
                  </button>
                </div>
                <DetailLineItem title="Name" detail={ `${ data?.patient?.firstName } ${ data?.patient?.lastName }` } />
                {/* <DetailLineItem title="Gender" detail={ data.patient.gender } /> */ }
                <DetailLineItem title="Contact" detail={ data.patient?.contact } />
                {/* <DetailLineItem title="Insurance" detail={ data.patient?.insuranceNumber } /> */ }
              </Paper>
            </div>
            <div className="col-md-6 g-0 col-12">
              {/* doctor */ }
              <Paper className="p-3 border">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="layer-header">
                    <IconStethoscope className="me-1" />
                    Doctor
                  </h6>
                  {/* <button className="app-btn btn-text">
                    view
                  </button> */}
                </div>
                <DetailLineItem title="Name" detail={ `${ data?.doctor?.firstName || '-' } ${ data?.doctor?.lastName || '' }` } />
                <DetailLineItem title="Contact" detail={ data?.doctor?.contact } />
              </Paper>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12 mb-1">
              <h6 className="section-header mb-0">Results</h6>
              <p className="text-muted mt-0">findings and recommendations for further engagement</p>
              <div className="mb-1">
                <Paper className="p-3 border">
                  {/* vitals */ }
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="layer-title text-success">
                      Vitals
                    </span>

                    {
                      ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                      <button className="app-btn btn-text"
                        onClick={ () => openModal( "Patient Vitals", <EditVitals
                          consultationId={ data.id }
                          isComplete={ data.isComplete }
                          onRefresh={ refetch } /> ) }
                      >
                        <span className="bi bi-pencil me-2"></span>
                        Edit
                      </button>
                    }

                  </div>
                  <div>
                    { data?.vitals && <ViewVitals vitals={ data.vitals } /> }
                    {
                      data?.initiator &&
                      <div className="row">
                        <div className="col-12">
                          <Divider />
                          <strong>Read By</strong>: { `${ data?.initiator?.firstName } ${ data?.initiator?.lastName } (${ data?.initiator?.contact || '-' })` }
                        </div>
                      </div>
                    }
                  </div>
                </Paper>
              </div>
              <Paper className="p-3 border">
                {/* diagnosis */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Diagnosis
                  </span>
                  {
                    ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                    <button className="app-btn btn-text"
                      onClick={ () => openModal( "Diagnosis", <EditDiagnosisComponent consultationId={ data.id } onRefresh={ refetch } /> ) }
                    >
                      <span className="bi bi-pencil me-2"></span>
                      Edit
                    </button> }
                </div>
                <div>
                  {
                    data.diagnosis.map( ( d, i ) => <div key={ i }>
                      <Divider className="my-1" />
                      <DetailLineItem
                        firstCol="col-2"
                        secondCol="col-10"
                        title={ ++i + '.' } detail={ d.diagnosis } />
                    </div>
                    )
                  }
                </div>
              </Paper>
            </div>

            <div className="col-12 mb-1">
              <Paper className="p-3 border">
                {/* reviews */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Systemic Reviews
                  </span>

                  {
                    ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                    <button className="app-btn btn-text"
                      onClick={ () => openModal( "Systemic Reviews", <EditReviewComponent consultationId={ data.id } onRefresh={ refetch } /> ) }
                    >
                      <span className="bi bi-pencil me-2"></span>
                      Edit
                    </button>
                  }

                </div>
                <div>
                  {
                    data.reviews.map( ( r, i ) => <div key={ i }>
                      <Divider className="my-1" />
                      <DetailLineItem title='Type' detail={ r.type } />
                      <DetailLineItem title='Symptom' detail={ r.symptom } />
                    </div>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div className="col-12 mb-1">
              <Paper className="p-3 border">
                {/* prescriptions */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Prescriptions
                    {
                      totalPrice > 0
                      && <kbd className="ms-2">GHS { totalPrice }</kbd>
                    }
                  </span>

                  {
                    ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                    <button className="app-btn btn-text"
                      onClick={ () => openModal( "Prescriptions", <EditPrescriptionComponent consultationId={ data.id } onRefresh={ refetch } /> ) }
                    >
                      <span className="bi bi-pencil me-2"></span>
                      Edit
                    </button>
                  }

                </div>
                <div>
                  {
                    data.prescriptions.map( ( d, i ) =>
                      <div key={ i }>
                        <Divider className="my-1" />
                        <kbd className={ d.isIssued ? "bg-success" : "bg-secondary" }>{ ++i }</kbd>
                        <DetailLineItem title='Medicine' detail={ d.medicine } />
                        <DetailLineItem title='Strength' detail={ d.strength } />
                        {/* <DetailLineItem title='Dosage' detail={ d.dosage } /> */ }
                        <DetailLineItem title='Frequency' detail={ d.frequency } />
                        <DetailLineItem title='Duration' detail={ d.duration } />
                        {
                          d.isIssued &&
                          <DetailLineItem title='Price (GHS)' detail={ parseFloat( d.price ).toFixed( 2 ) } />
                        }
                        <DetailLineItem title='Is Issued'
                          detail={ d.isIssued ?
                            `YES: By ${ d.pharmacy?.pharmacyName } - (${ format( new Date( d.updatedAt ), "MMM dd, yy" ) } @ ${ new Date( d.updatedAt ).toLocaleTimeString() })`
                            : 'NO' } />
                      </div>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div className="col-12 mb-1">
              <Paper className="p-3 border">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Pharmacies
                  </span>

                  {
                    ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                    <button className="app-btn btn-text"
                      onClick={ () => openModal( "Pharmacies", <EditPharmacyComponent consultationId={ data.id } onRefresh={ refetch } /> ) }
                    >
                      <span className="bi bi-pencil me-2"></span>
                      Edit
                    </button>
                  }

                </div>
                <div>
                  {
                    data.pharmacies.map( ( d, i ) => <div key={ i }>
                      <Divider className="my-1" />
                      <DetailLineItem
                        title={ ++i + '.' } detail={ `${ d.pharmacy.pharmacyName } - (${ d.pharmacy.contact })` } />
                      {
                        d.notes && <>
                          <DetailLineItem
                            title={ <kbd>Notes: </kbd> } detail={ <i>{ d.notes }</i> } />
                        </>
                      }
                    </div>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div div className="col-12">
              <Paper className="p-3 border">
                {/* laboratories */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Laboratories
                  </span>

                  {
                    ( !data.isComplete && hasPermission( resouces.consultations, action.canUpdate ) ) &&
                    <button className="app-btn btn-text"
                      onClick={ () => openModal( "Laboratories", <EditLaboratoryComponent consultationId={ data.id } onRefresh={ refetch } /> ) }

                    >
                      <span className="bi bi-pencil me-2"></span>
                      Edit
                    </button>
                  }
                </div>
                <div>
                  {
                    data.laboratories.map( ( lab, i ) => <div key={ i }>
                      <Divider className="my-2" />
                      <DetailLineItem
                        title={ <kbd>Lab: { ++i } </kbd> }
                        detail={ `${ lab.laboratory.laboratoryName } - (${ lab.laboratory.contact })` } />
                      <DetailLineItem
                        title={ <strong>Request: </strong> }
                        detail={ lab.requestType } />
                      {
                        lab.bill > 0 && <>
                          <DetailLineItem

                            title={ <strong>Bill: </strong> } detail={ 'GHS ' + lab.bill } />
                        </>
                      }
                      {
                        lab.report && <>
                          <DetailLineItem
                            title={ <strong>Report: </strong> } detail={ <i>{ lab.report }</i> } />
                        </>
                      }
                      {
                        <div className="row mx-auto mt-3">
                          {
                            data?.attachments?.length > 0 &&
                            data.attachments.map( file =>
                              file.laboratoryId === lab.laboratoryId &&
                              <>
                                {/* <p className="mb-0">click to open attachments</p> */ }
                                { preview( file ) }
                              </>
                            )
                          }
                        </div>
                      }
                    </div>
                    )
                  }
                </div>
              </Paper>
            </div >
          </div >
          {/* modals */ }
          <Modal
            title={ modal.title }
            size={ modal.size }
            opened={ modal.open }
            onClose={ () => setModal( { ...modal, open: false } ) }
          >
            { modal.child }
          </Modal>
        </div >
      }
    </>
  );
};

export default ConsultationDetail;
