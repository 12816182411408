import { Divider } from 'antd';
import AddLaboratoryComponent from './add-laboratory';

const ConsultationLaboratoriesComponent = ( { labs, allActivities, allLaboratories, onUpdate, onDelete } ) => {
    // allLaboratories  is the list of labs pulled from the server

    return (
        <div>
            {/* {
                labs.length > 0 && <>
                    <div className="row">
                        <div className="col-3 g-0" ><strong>Region</strong></div>
                        <div className="col-3 g-0" ><strong>Type</strong></div>
                        <div className="col-4 g-0" ><strong>Laboratory</strong></div>
                    </div>
                </>
            } */}
            {
                labs.length > 0 && labs.map( ( lab, index ) =>
                    <div className="row my-1" key={ lab.recordId }>
                        <div className="col-1">
                            <kbd>{ ++index }</kbd>
                        </div>
                        <div className="col-11">
                            <AddLaboratoryComponent data={ lab } allActivities={ allActivities } allLaboratories={ allLaboratories } onUpdate={ onUpdate } onDelete={ onDelete } />
                            <Divider />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default ConsultationLaboratoriesComponent;
