import { PageHeader } from "../../components";
import { FacilityPage } from './facility';
import { BranchesPage } from "./branches";
import { ServicesPage } from "./services";
import { Tabs } from "antd";

const FacilityAdminPage = ( props ) => {

    const { TabPane } = Tabs;

    return (
        <section>
            <PageHeader title="Administration" hasBackButton />
            <Tabs defaultActiveKey="1">
                <TabPane tab={ <span><i className="bi bi-command me-2" /> General</span> } key="1">
                    <FacilityPage />
                </TabPane>
                <TabPane tab={ <span><i className="bi bi-building me-2" /> Branches</span> } key="2">
                    <BranchesPage />
                </TabPane>
                <TabPane tab={ <span><i className="bi bi-award me-2" /> Services</span> } key="3">
                    <ServicesPage />
                </TabPane>
                <TabPane tab={ <span><i className="bi bi-transparency me-2" /> Support</span> } key="4">
                    <p>moved here!</p>
                </TabPane>
            </Tabs>
        </section>
    );
};

export { FacilityAdminPage };
