import React, { useEffect, useState } from 'react';
import { getUser, refreshToken } from '../helpers/auth';
import { useMutation } from 'react-query';
import { postAttendance, putAttendance } from '../helpers/api';
import { message } from 'antd';
import _ from 'lodash';
import { IconClock } from '@tabler/icons';

export const AttendanceComponent = ( { className, style } ) => {

    const { mutateAsync: clockIn, isLoading } = useMutation( ( data ) => postAttendance( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( 'clocked in!' );
                refreshToken();
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;

            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );

    const { mutateAsync: clockOut, isLoading: clockingOut } = useMutation( ( data ) => putAttendance( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( 'clocked out!' );
                refreshToken();
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;

            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );


    return (
        <div className={ `${ className }` } style={ style }>
            {
                !getUser()?.attendanceId ?
                    <button
                        onClick={ () => clockIn( { inLocation: 'Ghana' } ) }
                        className={ `button px-3 rounded bg-danger is-small ${ isLoading && ' is-loading' }` }>
                        <strong className='text-white'>
                            <IconClock size={ 14 } />
                            <span className="ms-1"> Clock-in</span>
                        </strong>
                    </button> :
                    <button
                        onClick={ () => clockOut( 'Ghana' ) }
                        className={ `button px-3 rounded bg-success is-small ${ clockingOut && ' is-loading' }` }>
                        <strong className='text-white'>
                            <IconClock size={ 14 } />
                            <span className="ms-1"> Clock-out</span>
                        </strong>
                    </button>
            }
        </div>
    );
};
