import { Tabs } from 'antd';
import React from 'react';
import PortalHistory from './components/history';
import { PageTitle } from '../../components';
import LabPortalSearch from './components/lab-portal-search';

export function LabsPortal () {
    const { TabPane } = Tabs;

    return (
        <Tabs defaultActiveKey="1" centered >
            <PageTitle title="Laboratory Portal" />
            <TabPane tab="Search" key="1">
                <div className="row">
                    <div className='col-md-9 col-11 mx-auto'>
                        <LabPortalSearch />
                    </div>
                </div>
            </TabPane>
            <TabPane tab="History" key="2">
                <div className="row">
                    <div className="col-md-10 col-11 mx-auto">
                        <PortalHistory />
                    </div>
                </div>
            </TabPane>
        </Tabs>
    );
}
