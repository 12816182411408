import { Divider, message, Select } from "antd";
import { useState, useEffect } from "react";
import { dropDownSet } from "../../helpers/utilities";
import { RequiredIndicator } from '..';
import { useMutation, useQuery } from "react-query";
import { getAllPatients, getAllStaffs, postExcuseDuty } from "../../helpers/api";
import _ from "lodash";
import { Modal } from "@mantine/core";
import { ExcuseDutyDisplay } from './excuse-duty-display';

let excuseDutyTemplate = {
    diagnosis: '',
    comment: '',
    consultationCode: "",
    startDate: null,
    endDate: null,
    doctorId: null,
    // patientId: null,
    // excuseDutyDate: null
};

const ExcuseDutyForm = ( { code } ) => {
    const { Option } = Select;
    const [ excuseDuty, setExcuseDuty ] = useState( excuseDutyTemplate );
    const [ modalOpen, setModalOpen ] = useState( false );


    // const { data: patients = [] } = useQuery( {
    //     queryFn: () => getAllPatients(),
    //     queryKey: [ 'patients' ],
    // } );

    const { data: doctors = [] } = useQuery( {
        queryFn: () => getAllStaffs(),
        queryKey: [ 'staffs' ],
    } );

    const { mutateAsync: saveDuty, isLoading } = useMutation( ( data ) => postExcuseDuty( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                console.log( data );
                setExcuseDuty( { ...excuseDuty, id: data.data.data.id } );
                message.success( data.data.message );
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    useEffect( () => {
        setExcuseDuty( {
            ...excuseDuty,
            consultationCode: code
        } );
    }, [] );

    return (
        <form onSubmit={ ( e ) => { e.preventDefault(); saveDuty( excuseDuty ); } }>
            {/* <h3>Generate Excuse Duty</h3> */ }
            {/* <Divider /> */ }
            <div className="row">
                <div className="col-12 mb-3">
                    <kbd>consultation code: { excuseDuty.consultationCode }</kbd>
                </div>
                {/* <div className="col-md-6 col-12">
                    <div className="field">
                        <label className="mb-0" htmlFor="patientId">
                            Patient
                            <RequiredIndicator />
                        </label>
                        <Select
                            required
                            autoFocus
                            size="large"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                            filterSort={ ( optionA, optionB ) =>
                                optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                            }
                            value={ excuseDuty.patientId }
                            onChange={ ( v ) => dropDownSet( 'patientId', v, excuseDuty, setExcuseDuty ) }
                            id="patientId"
                            className="d-block"
                            placeholder="Patient's Name"
                        >
                            {
                                patients.map( p => {
                                    return (
                                        <Option value={ p.id } key={ p.id }>{ `${ p.firstName } ${ p.lastName }` }</Option>
                                    );
                                } )
                            }
                        </Select>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="field">
                        <label className="mb-0" htmlFor="doctorId">
                            Doctor-In-Charge
                            <RequiredIndicator />
                        </label>
                        <Select
                            required
                            autoFocus
                            size="large"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                            filterSort={ ( optionA, optionB ) =>
                                optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                            }
                            value={ excuseDuty.doctorId }
                            onChange={ ( v ) => setExcuseDuty( { ...excuseDuty, doctorId: v } ) }
                            id="doctorId"
                            className="d-block"
                            placeholder="Doctor's Name"
                        >
                            {
                                doctors.map( d => {
                                    return (
                                        <Option value={ d.id } key={ d.id }>{ `${ d.firstName } ${ d.lastName }` }</Option>
                                    );
                                } )
                            }
                        </Select>
                    </div>

                </div>

            </div>

            <div className="row my-3">
                <div className="col-md-6 col-12">
                    <div className="field">
                        <label className="mb-0" htmlFor="startDate">
                            Start Date
                            <RequiredIndicator />
                        </label>
                        <input id="startDate" value={ excuseDuty.startDate }
                            onChange={ ( e ) => setExcuseDuty( { ...excuseDuty, startDate: e.target.value } ) }
                            type="date" className="input" />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="field">
                        <label className="mb-0" htmlFor="endDate">
                            End Date
                            <RequiredIndicator />
                        </label>
                        <input id="endDate" value={ excuseDuty.endDate }
                            onChange={ ( e ) => setExcuseDuty( { ...excuseDuty, endDate: e.target.value } ) }
                            type="date" className="input" />
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="mb-0" htmlFor="diagnosis">
                    Diagnosis
                    <RequiredIndicator />
                </label>
                <textarea
                    className="textarea"
                    rows={ 3 }
                    value={ excuseDuty.diagnosis }
                    onChange={ ( e ) => setExcuseDuty( { ...excuseDuty, diagnosis: e.target.value } ) }
                    id="diagnosis"
                    placeholder="diagnosis"
                ></textarea>
            </div>
            <div className="field">
                <label className="mb-0" htmlFor="comment">
                    Comment
                </label>
                <textarea
                    className="textarea"
                    rows={ 3 }
                    value={ excuseDuty.comment }
                    onChange={ ( e ) => setExcuseDuty( { ...excuseDuty, comment: e.target.value } ) }
                    id="comment"
                    placeholder="doctor's comment"
                ></textarea>
            </div>

            <div className="buttons has-addons mt-3">
                <button type="submit" id="submit" className={ `button app-btn btn-prim h6 ${ isLoading && 'is-loading' }` }>
                    <span className="bi bi-save me-2"></span>
                    Save
                </button>
                {
                    excuseDuty.id &&
                    <button type="button" id="submit" className="button app-btn h6" onClick={ () => setModalOpen( true ) }>
                        <span className="bi bi-arrow-right me-2"></span>
                        View
                    </button>
                }
            </div>
            <Modal
                title="Excuse Duty - Display Mode"
                size={ 900 }
                opened={ modalOpen }
                onClose={ () => setModalOpen( false ) }
            >
                <ExcuseDutyDisplay code={ excuseDuty.consultationCode } excuseDutyId={ excuseDuty.id } />
            </Modal>
        </form>
    );
};

export default ExcuseDutyForm;
