import { atom } from 'jotai';

// templates
const carePlanTemplate = {
    assessment: "",
    diagnosis: "",
    plan: "",
    evaluation: "",
    intervention: ""
};


// state
const _carePlan = atom( carePlanTemplate );



// atoms

// CAREPLAN
export const carePlanAtom = atom(
    get => get( _carePlan ),
    ( get, set, plan ) => {
        set( _carePlan, { ...get( _carePlan ), ...plan } );
    }
);

export const clearCarePlanAtom = atom(
    null,
    ( get, set, plan ) => {
        set( _carePlan, carePlanTemplate );
    }
);


// CONSULTATION


