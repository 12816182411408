import { Select, Tabs, message } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  CharmBar,
  IndicatorInfo,
  RequiredIndicator,
} from "../../../components";
import {
  getAllInsuranceProviders,
  getPatient,
  putUpdatePatient,
} from "../../../helpers/api";
import { Regions } from "../../../helpers/config";
import _ from "lodash";
import { Loader } from "@mantine/core";


const EditPatientForm = ( { patientId, onSuccess, onExit, showCharm = true, isEditable = true } ) => {

  const [ patient, setPatient ] = useState( {} );

  const { Option } = Select;
  const { TabPane } = Tabs;

  const { isError, isFetching } = useQuery( {
    queryFn: () => getPatient( patientId ),
    queryKey: [ 'patient', patientId ],
    onSuccess: data => setPatient( _.omit( data, "avatarUrl", "lastConsultationDate" ) )
  } );

  const { data: insuranceProviders = [] } = useQuery( {
    queryFn: getAllInsuranceProviders,
    queryKey: [ 'insurances' ],
  } );


  const updateData = ( fieldName, data ) => {
    setPatient( { ...patient, [ fieldName ]: data } );
  };


  const { mutateAsync: updatePatient, isLoading } = useMutation( ( data ) => putUpdatePatient( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onSuccess();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
  } );

  // const handleCreate = async ( data ) => {
  //   updatePatient( patient );
  // };


  return (
    <div>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
            :
            <form onSubmit={ e => { e.preventDefault(); updatePatient( patient ); } } >
              { showCharm && (
                <CharmBar>
                  <button className={ `button app-btn btn-prim mx-2 ${ isLoading && ' is-loading' }` } type="submit">
                    Save
                  </button>
                  <button className="button app-btn" onClick={ onExit }>
                    Cancel
                  </button>
                </CharmBar>
              ) }

              <IndicatorInfo />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Bio Data" key="1">
                  {/* <p>image uploader here</p> */ }
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="firstName">
                        First Name
                        <RequiredIndicator />
                      </label>
                      <input
                        disabled={ !isEditable }
                        value={ patient.firstName }
                        onChange={ e => updateData( 'firstName', e.target.value ) }
                        className="input"
                        type="text"
                        autoFocus
                        id="firstName"
                        placeholder="patient's first name"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="lastName">
                        Last Name
                        <RequiredIndicator />
                      </label>
                      <input
                        disabled={ !isEditable }
                        value={ patient.lastName }
                        onChange={ e => updateData( 'lastName', e.target.value ) }
                        className="input"
                        type="text"
                        id="lastName"
                        placeholder="patient's last name"
                      />
                    </div>


                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="gender">
                        Gender
                        <RequiredIndicator />
                      </label>
                      <Select
                        required
                        disabled={ !isEditable }
                        value={ patient.gender }
                        onChange={ v => updateData( 'gender', v ) }
                        size="large"
                        id="gender"
                        className="d-block"
                        placeholder="gender"
                      >
                        <Option value="Male" >Male</Option>
                        <Option value="Female" >Female</Option>
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="lastName">
                        Date of Birth
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        value={ patient.dob }
                        onChange={ e => updateData( 'dob', e.target.value ) }
                        disabled={ !isEditable }
                        required
                        type="date"
                        id="dob"
                        placeholder="date of birth"
                      />
                    </div>

                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="insuranceProviderId">
                        Insurance Provider
                      </label>
                      <Select
                        name="insuranceProviderId"
                        value={ patient.insuranceProviderId }
                        disabled={ !isEditable }
                        allowClear
                        showSearch
                        onChange={ v => updateData( 'insuranceProviderId', v ) }
                        size="large"
                        id="insuranceProviderId"
                        className="d-block"
                        placeholder="select insurance provider"
                      >
                        {/* <Option value="None" key="none">None</Option> */ }
                        { insuranceProviders?.map( ( ip ) => (
                          <Option value={ ip.id } key={ ip.id } selected={ ip.id === patient.insuranceProviderId }>
                            { ip.providerName }
                          </Option>
                        ) ) }
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="ghCardNumber">
                        Insurance Number
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.insuranceNumber }
                        onChange={ e => updateData( 'insuranceNumber', e.target.value ) }
                        type="text"
                        id="insuranceNumber"
                        placeholder="patient's insurance number"
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Contact & Address" key="2">
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="contact">
                        Phone Number
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.contact }
                        onChange={ e => updateData( 'contact', e.target.value ) }
                        type="tel"
                        id="contact"
                        placeholder="patient's contact number"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.email }
                        onChange={ e => updateData( 'email', e.target.value ) }
                        type="email"
                        id="email"
                        placeholder="patient's email address"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="gpsAddress">
                        GPS Address
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.gpsAddress }
                        onChange={ e => updateData( 'gpsAddress', e.target.value ) }
                        type="text"
                        id="gpsAddress"
                        placeholder="patient's GPS address"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="region">
                        Region
                        {/* <RequiredIndicator /> */ }
                      </label>
                      <Select
                        value={ patient.region }
                        disabled={ !isEditable }
                        // required
                        onChange={ v => updateData( 'region', v ) }
                        name="region"
                        size="large"
                        id="region"
                        className="d-block"
                        placeholder="region"
                      >
                        { Regions }
                      </Select>
                    </div>

                    <div className="field col-12">
                      <label className="mb-0" htmlFor="location">
                        Location
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.location }
                        onChange={ e => updateData( 'location', e.target.value ) }
                        type="text"
                        id="location"
                        placeholder="patient's location"
                      />
                    </div>
                  </div>
                </TabPane>

              </Tabs>
              {
                isEditable && <button
                  type="submit" id="submit" className={ `button app-btn btn-prim h6 mt-3 ${ isLoading && ' is-loading ' }` }>
                  <span className="bi bi-check-all me-2"></span>
                  Update Record
                </button>
              }
            </form>
      }

    </div>
  );
};

export { EditPatientForm };