import * as yup from "yup";

export const emailSchema = yup.object( {
	email: yup.string().email().required(),
} );

export const loginSchema = yup.object( {
	email: yup.string().email().required(),
	password: yup.string().required(),
} );

export const signupSchema = yup.object( {
	firstName: yup.string().required( "first name is required" ),
	lastName: yup.string().required( "last name is required" ),
	email: yup.string().email().required(),
	phone: yup.string().required(),
	gender: yup.string().required(),
	// username: yup.string().required(),
	password: yup
		.string()
		.min( 4, "password must be 4 or more characters long" )
		.required(),
	confirmPassword: yup
		.string()
		.min( 4, "password confirmation below minimum (4) characters allowed" )
		.matches( yup.ref( 'password' ) )
		.required( "please confirm the password" ),
} );


export const consultationSchema = yup.object( {
	type: yup.string().required().label( "consultation type" ),
	doctorsAdvise: yup.string().required().label( "doctor's advise" ),
	complaint: yup.string().required().label( "presenting complaint" ),
	notes: yup.string().notRequired(),
	investigation: yup.string().notRequired(),
	patientId: yup.string().uuid().required( 'patient not found' ).label( "patient" ),
	isInstant: yup.boolean().default( false ).label( 'is instant' )
} );

export const vitalsSchema = yup.object( {
	temperature: yup.number().required().label( 'temperature' ),
	bloodPressure: yup.string().required().label( 'blood pressure' ),
	pulse: yup.number().required(),
	weight: yup.number().required(),
	height: yup.number().required(),
	oxygenSaturation: yup.number().required().label( 'oxygen saturation' ),
	respiration: yup.number().required(),
	pain: yup.number().max( 10, 'pain cannot be more than 10' ).lessThan( 11, 'invalid input. pain cannot be more than 10' ).notRequired(),

	folderNumber: yup.string().notRequired(),
	rbs: yup.string().notRequired(),
	comments: yup.string().notRequired(),
} );


export const callSchema = yup.object( {
	summary: yup.string(),
	callType: yup.string().max( 14 ).required().label( "call type" ),
	callerNumber: yup.string().min( 10 ).max( 15 ).required().label( "caller number" ),
	callerLocation: yup.string().required().label( "caller location" ),
	isProxyCall: yup.boolean().default( false ).label( "proxy call?" ),
	patientId: yup.string().uuid().required().label( "patient" ),
} );