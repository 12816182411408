import AddPrescriptionComponent from './add-prescription';
import { useEffect } from 'react';
import { Divider } from 'antd';

const ConsultationPrescriptionsComponent = ( { prescriptions, drugs, onDelete, onUpdate } ) => {



    useEffect( () => { }, [ prescriptions ] );

    return (
        <div>
            {/* {
                prescriptions.length > 0 && <>
                    <div className="row">
                        <div className="col-4" ><strong>Drug Name</strong></div>
                        <div className="col-2" ><strong>Strength</strong></div>
                        <div className="col-2" ><strong>Frequency</strong></div>
                        <div className="col-2" ><strong>Duration</strong></div>
                        <div className="col-2" ><strong>Dose</strong></div>
                    </div>
                </>
            } */}
            {
                prescriptions.length > 0 && prescriptions.map( ( pres, index ) =>
                    <div className="row my-3" key={ pres.recordId }>
                        <div className="col-1">
                            <kbd>{ ++index }</kbd>
                        </div>
                        <div className="col-11">
                            <AddPrescriptionComponent data={ pres } drugs={ drugs } onUpdate={ onUpdate } onDelete={ onDelete } />
                            <Divider />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default ConsultationPrescriptionsComponent;