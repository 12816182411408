import { Loader, Skeleton } from "@mantine/core";
import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAllSymptoms, getReviews, putReviews } from "../../../helpers/api";
import AddReviewComponent from './add-review';
import { ErrorBoundary } from "../../../components";

const EditReviewComponent = ( { consultationId, onRefresh } ) => {
    const [ reviews, setReviews ] = useState( [] );
    const [ consultation, setConsultation ] = useState( {} );

    const { data: serverSymptoms = [] } = useQuery( {
        queryFn: () => getAllSymptoms(),
        queryKey: [ 'symptoms' ],
    } );


    const { isError, isFetching, refetch } = useQuery( {
        queryFn: () => getReviews( consultationId ),
        queryKey: [ 'reviews', consultationId ],
        onSuccess: data => {

            // add the recordId field to fetched data to enable tracking
            const newSet = data.map( ( d, i ) => {
                return { ...d, recordId: ++i };
            } );
            setReviews( newSet );
            setConsultation( data[ 0 ]?.consultation );
        }
    } );


    const { mutateAsync: updateReviews, isLoading } = useMutation( ( data ) => putReviews( consultationId, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    // reviews
    const handleAddReview = () => {
        setReviews( [
            ...reviews,
            {
                recordId: reviews.length + 1,
                type: '',
                symptom: ''
            }
        ] );
    };

    const handleUpdateReview = ( recordId, field, value ) => {
        let curReview = reviews.filter( rev => rev.recordId === recordId )[ 0 ];
        curReview[ field ] = value;

        setReviews( [
            ...reviews.filter( rev => rev.recordId !== recordId ),
            curReview
        ] );
    };

    const handleDeleteReview = ( recordId ) => {
        const updatedReviews = reviews.filter( rev => rev.recordId !== recordId );
        setReviews( updatedReviews );
    };


    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <>
                            { reviews?.length > 0 && <>
                                <div className="row">
                                    <div className="col-4" ><strong>Type</strong></div>
                                    <div className="col-6" ><strong>Symptom</strong></div>
                                </div>
                            </> }
                            <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd> }>
                                { reviews?.map( ( rev ) =>
                                    <div className="col-12">
                                        {
                                            _.isEmpty( serverSymptoms ) ?
                                                <Skeleton>loading..</Skeleton> :
                                                <AddReviewComponent symptoms={ serverSymptoms } key={ rev.id } data={ rev } onUpdate={ handleUpdateReview } onDelete={ handleDeleteReview } />
                                        }
                                    </div>
                                ) }
                            </ErrorBoundary>
                        </>
            }

            {
                !consultation?.isComplete &&
                <>
                    <div>
                        <button className={ `button is-small mt-2 ${ isLoading && ' is-loading ' }` }
                            onClick={ handleAddReview }>Add Review</button>
                    </div>
                    <button onClick={ () => updateReviews( reviews ) }
                        id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                        Update Reviews
                    </button>
                </>
            }
        </>
    );
};


export default EditReviewComponent;
