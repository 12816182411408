import { BackTop, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import LaboratoriesFilterBar from '../../../components/filter-bars/laboratories-report-filters';
import { cedisLocale, exportToExcel } from '../../../helpers/utilities';

export default function LaboratoriesReport ( { data, isLoading } ) {

    const [ filteredData, setFilteredData ] = useState( [] );

    const handleReset = () => {
        setFilteredData( data );
    };

    const handleApplyFilter = ( newData ) => {
        setFilteredData( newData );
    };

    useEffect( () => {
        setFilteredData( data );
    }, [ data ] );


    const columns = [
        {
            title: "#",
            sortDirections: [ "descend", "ascend" ],
            render: ( t, r, i ) => ++i
        },
        {
            title: "Date - Time",
            sorter: ( a, b ) => a?.createdAt > b?.createdAt,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) => new Date( record?.createdAt ).toUTCString()
        },
        {
            title: "Patient Name",
            sorter: ( a, b ) => a?.firstName > b?.lastName,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                `${ record?.consultation?.patient?.firstName }  ${ record?.consultation?.patient?.lastName }`,
        },
        {
            title: "Insurance Provider",
            sorter: ( a, b ) => a?.consultation?.patient?.insuranceProvider?.providerName
                > b?.consultation?.patient?.insuranceProvider?.providerName,
            render: ( text, record, index ) =>
                record?.consultation?.patient?.insuranceProvider?.providerName

        },
        {
            title: "Investigation",
            sorter: ( a, b ) => a?.record?.consultation?.investigation > b?.record?.consultation?.investigation,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record.consultation.investigation
        },

        {
            title: "Laboratory Name",
            sorter: ( a, b ) => a?.laboratory?.laboratoryName > b?.laboratory?.laboratoryName,
            dataIndex: [ 'laboratory', 'laboratoryName' ],
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Lab Type",
            sorter: ( a, b ) => a?.labType > b?.labType,
            dataIndex: "labType",
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Lab Request",
            sorter: ( a, b ) => a?.requestType > b?.requestType,
            dataIndex: "requestType",
            sortDirections: [ "descend", "ascend" ],
        },
        {
            title: "Status",
            sorter: ( a, b ) => a?.isComplete > b?.isComplete,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                record.isComplete ? "Complete" : "Pending"
        },
        {
            title: "Bill",
            sorter: ( a, b ) => a?.bill > b?.bill,
            sortDirections: [ "descend", "ascend" ],
            render: ( text, record, index ) =>
                cedisLocale.format( record.bill )
        },

    ];


    return (
        <div>
            <LaboratoriesFilterBar data={ filteredData } onReset={ handleReset }
                onFilter={ ( d ) => handleApplyFilter( d ) }
                excelExport={ () => exportToExcel( "laboratoriesReportTable", "rivia-laboratories-report" ) }
            />

            {/* advanced filters
            
            -> date range
            -> cost
            -> status
            -> gender
            
            */}
            <Table
                className="border mt-3"
                id='laboratoriesReportTable'
                rowKey={ ( record ) => record.id }
                dataSource={ filteredData }
                columns={ columns }
                loading={ isLoading }
                bordered
            />
            <BackTop />
        </div>
    );
}
