import { AppointmentTypeCard } from "../../../components";
import { dateFormat, dropDownSet } from "../../../helpers/utilities";
import { Stepper, Group, Paper, Chip, Modal } from "@mantine/core";
import { DatePicker, Divider, message, Select, Tag, TimePicker } from "antd";
import { useEffect, useState } from "react";
import { getAllPatients, getAllStaffs, postNewAppointment } from "../../../helpers/api";
import NewPatientForm from "../../catalog/components/new-patient";
import SharedFields from "./shared-fields";
import PharmacyAppointment from "./pharmacy-appointment";
import LaboratoryAppointment from "./laboratory-appointment";
import DoctorAppointment from "./doctor-appointment";
import { format, isPast, isToday } from "date-fns";
import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { IconArrowLeft, IconArrowRight, IconCamera, IconCheck, IconHomeUp } from "@tabler/icons";
import { EditPatientForm } from "../../catalog/components/edit-patient";

const appoinmentState = {
  patientId: null,
  staffId: null,
  appointmentType: "", //virtual, in-person
  startDate: null,
  startTime: null,
  complain: "",
};


const NewAppointmentForm = ( { onSuccess: onDone, onExit, onError } ) => {
  const [ state, setState ] = useState( appoinmentState );
  const [ active, setActive ] = useState( 0 );

  // stepper
  const finalStep = 2;
  const nextStep = () =>
    setActive( ( current ) => ( current < 3 ? current + 1 : current ) );
  const prevStep = () =>
    setActive( ( current ) => ( current > 0 ? current - 1 : current ) );

  const { Option } = Select;

  const { data: patients = [], isFetching: fetchingPatients, refetch: fetchPatients } = useQuery( {
    queryFn: getAllPatients,
    queryKey: [ 'patients' ],
  } );

  const { data: doctors = [], isFetching: fetchingDoctors, refetch: fetchDoctors } = useQuery( {
    queryFn: getAllStaffs,
    queryKey: [ 'doctors' ],
  } );


  const [ modal, setModal ] = useState( {
    isOpen: false,
    title: '',
    content: null,
    size: "md"
  } );


  const { mutateAsync: createAppointment, isLoading } = useMutation( ( data ) => postNewAppointment( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onDone();
        setState( appoinmentState );
        // nextStep();
        setActive( 0 );
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error?.response?.data?.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err?.message )
        );
      }
      else {
        message.error( err );
      }
    },
  } );


  const handleSubmitAppointment = async ( data ) => {
    if ( isPast( state.startDate ) ) {
      message.error( 'Date cannot be in the past' );
      return;
    }

    if ( isToday( state.startDate ) && isPast( state.startTime ) ) {
      message.error( "invalid time" );
      return;
    }

    createAppointment( state );
  };



  return (
    <section>
      <Modal
        onClose={ () => setModal( { ...modal, isOpen: false } ) }
        opened={ modal.isOpen }
        title={ modal.title }
        size={ modal.size }
      >
        { modal.content }
      </Modal>
      <div className="row">
        <div className="col-12 mt-1 mb-2">
          <Stepper active={ active } onStepClick={ setActive } breakpoint="sm">
            <Stepper.Step
              label="Basic"
              description="requirements"
              allowStepSelect={ active > 0 }
            >
              <div className="row">
                <label className="mb-0" htmlFor="patientId">
                  Patient
                </label>
                <div className="d-flex">
                  <Select
                    size="large"
                    required
                    allowClear
                    showSearch
                    autoFocus
                    id="patientId"
                    className="w-100"
                    placeholder="select patient"
                    filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                    filterSort={ ( optionA, optionB ) =>
                      optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                    }
                    value={ state.patientId }
                    onChange={ ( v ) =>
                      dropDownSet( "patientId", v, state, setState )
                    }
                  >
                    { patients.map( ( dc ) => (
                      <Option
                        value={ dc.id }
                        key={ dc.id }
                      >{ `${ dc.firstName } ${ dc.lastName } (${ dc.contact })` }</Option>
                    ) ) }
                  </Select>
                  <button
                    className="button btn-text"
                    onClick={ () => setModal( {
                      title: 'Add Patient',
                      isOpen: true,
                      content: <NewPatientForm onSuccess={ fetchPatients } showCharm={ false } showBottomButtons />
                    } ) }
                  >
                    <span className="bi bi-plus-circle me-2"></span>
                    add
                  </button>
                </div>
                <small>select/add patient</small>

                <div className="field col-12 mt-4">
                  <label className="mb-0" htmlFor="complain">
                    Complain
                  </label>
                  <textarea
                    className="textarea"
                    value={ state.complain }
                    onChange={ e => dropDownSet( "complain", e.target.value, state, setState ) }
                    required
                    id="complain"
                    placeholder="patient's problem"
                  ></textarea>
                  <small>what is the patient's problem</small>
                </div>
              </div>
            </Stepper.Step>
            <Stepper.Step
              label="Schedule"
              description="appointment"
              allowStepSelect={ active > 1 }
            >
              <div className="row">
                <p>
                  What type of appointment is this?
                </p>
                <div className="col-4">
                  <button
                    className={ `w-100 app-btn ${ state.appointmentType === 'virtual' && 'btn-prim' } px-5` }
                    onClick={ () =>
                      dropDownSet( "appointmentType", "virtual", state, setState )
                    }
                  >
                    <IconCamera />
                    <p className="mt-1 mb-0">Virtual</p>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className={ `w-100 app-btn ${ state.appointmentType === 'in-person' && 'btn-prim' } px-5` }
                    onClick={ () =>
                      dropDownSet( "appointmentType", "in-person", state, setState )
                    }
                  >
                    <IconHomeUp />
                    <p className="mt-1 mb-0">In-Person</p>
                  </button>
                </div>
                <div className="field col-12 mt-4">
                  <label className="mb-0" htmlFor="staffId">Doctor In-Charge</label>
                  <Select size="large"
                    required
                    allowClear
                    showSearch
                    value={ state.staffId }
                    onChange={ ( v ) => dropDownSet( 'staffId', v, state, setState ) }
                    filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                    filterSort={ ( optionA, optionB ) =>
                      optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                    }
                    id="staffId" className="w-100" placeholder="select doctor"
                  >
                    {
                      doctors.map( dc =>
                        <Option value={ dc.id } key={ dc.id }>{ `${ dc.firstName } ${ dc.lastName }` }</Option>
                      )
                    }
                  </Select>
                  <small>who is meeting { patients.find( p => p.id === state.patientId )?.firstName || ' the patient' }?</small>
                </div>
              </div>

              {/* <DoctorAppointment state={ state } setState={ setState } /> */ }
              {/* { state.appointmentType === "doctor" && (
              ) } */}
              {/* { state.appointmentType === "laboratory" && (
                <LaboratoryAppointment state={ state } setState={ setState } />
              ) }
              { state.appointmentType === "pharmacy" && (
                <PharmacyAppointment state={ state } setState={ setState } />
              ) } */}
              {/* <SharedFields state={ state } setState={ setState } /> */ }

              <div className="my-3">
                <p>Set the schedule (start date and time)?</p>
                <div className="d-flex">
                  <DatePicker
                    onChange={ data => dropDownSet( "startDate", data, state, setState ) }
                    id="startDate"
                    size="large"
                    value={ state.startDate }
                    format={ dateFormat }
                  // className="w-75"
                  />
                  <TimePicker
                    onChange={ data => dropDownSet( "startTime", data, state, setState ) }
                    id="startTime"
                    size="large"
                    allowClear
                    value={ state.startTime }
                  />
                </div>
              </div>
            </Stepper.Step>
            <Stepper.Step
              label="Confirm"
              description="check & submit"
              allowStepSelect={ active > 2 }
            >
              <div className="row">
                <div className="col-12 ">
                  <Paper>
                    <div className="p-3 px-md-5">
                      <h3 className="mb-0">
                        { _.capitalize( state.appointmentType ) } Consultation
                      </h3>
                      <kbd className="app-bg-primary">for { doctors?.find( d => d.id === state.staffId )?.firstName }</kbd>
                      <Divider />
                      <Chip disabled>Patient</Chip>
                      <h5 className="mb-0">
                        { patients &&
                          patients.find( ( p ) => p.id === state.patientId )
                            ?.firstName }
                        { patients &&
                          patients.find( ( p ) => p.id === state.patientId )
                            ?.lastName }
                        <span
                          className="ms-3 hover-hand"
                          onClick={ () => setModal( {
                            title: 'Patient Details',
                            isOpen: true,
                            content: <EditPatientForm
                              patientId={ state.patientId }
                              isEditable={ false }
                              showCharm={ false }
                            />
                          } ) }
                        >
                          <span className="bi bi-arrow-up-right-square"></span>
                        </span>
                      </h5>
                      <small>
                        <div>{ patients?.find( p => p.id === state.patientId )?.contact }</div>
                        <div>{ patients?.find( p => p.id === state.patientId )?.gender }</div>
                      </small>
                      {/* <p className="text-secondary mt-0">
                        { _.capitalize( state.appointmentType ) }
                      </p> */}
                      <Chip disabled className="mt-4">Date & Time</Chip>
                      <h6 className="mb-0">
                        { isToday( new Date( state.startDate ) ) ? 'Today' : format( new Date( state.startDate ), "EE MMM dd, yyy" ) }
                      </h6>
                      <p className="text-secondary mt-0">
                        {
                          new Date( state.startTime ).toLocaleTimeString()
                        }
                      </p>
                      <Divider />
                      <p className="mb-0">
                        OK?
                        <span className="bi bi-patch-check-fill text-success ms-2"></span>
                      </p>
                      <small className="text-secondary">
                        Click Submit button
                      </small>
                    </div>
                  </Paper>
                </div>
              </div>
            </Stepper.Step>
            <Stepper.Completed>
              <div className="mb-0 text-success text-center mt-4">
                <span className="h1 bi bi-patch-check-fill"></span>
                <h1 className="mt-0">Submitted !</h1>
              </div>
            </Stepper.Completed>
          </Stepper>

          <Group position="center" mt="xl" className="mt-3">
            { ( active > 0 && !isLoading ) && (
              <button className="button h6 app-btn" variant="default" onClick={ prevStep }>
                {/* Back */ }
                <IconArrowLeft />
                <span className="ms-2">Back</span>
              </button>
            ) }
            { active < finalStep && !isLoading && (
              <button className="button h6 app-btn" onClick={ nextStep }>
                {/* Next */ }
                <span className="me-2">Next</span>
                <IconArrowRight />
              </button>
            ) }

            {/*  */ }
            { active > finalStep && !isLoading && (
              <button
                className="button h6 app-btn btn-prim"
                variant="default"
                onClick={ onExit }
              >
                <span className="me-2">Done</span>
                <IconCheck />
              </button>
            ) }
            { active === finalStep && (
              <button
                onClick={ () => handleSubmitAppointment( state ) }
                className={ `button h6 app-btn btn-prim ${ isLoading && " is-loading" }` }
              >
                <span className="me-2">Submit</span>
                <IconCheck />
              </button>
            ) }
          </Group>
        </div>
      </div>
    </section>
  );
};

export default NewAppointmentForm;
