import {
  createStyles,
  Paper,
  Title,
  Text,
  TextInput,
  PasswordInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  Progress,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../components";
import { postChangePassword } from "../../helpers/api";
import { authenticate, setRole } from "../../helpers/auth";
import { appLinks } from "../../helpers/config";
import { passwordStrength } from "../../helpers/utilities";


const useStyles = createStyles( ( theme ) => ( {
  title: {
    fontSize: 26,
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${ theme.fontFamily }`,
  },

  controls: {
    [ theme.fn.smallerThan( "xs" ) ]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [ theme.fn.smallerThan( "xs" ) ]: {
      width: "100%",
      textAlign: "center",
    },
  },
} ) );

export function ChangePasswordPage () {
  const { classes } = useStyles();
  const [ state, setPwdState ] = useState( {
    email: "",
    // currentPassword: "",
    newPassword: "",
    token: ""
  } );
  const [ busy, setBusy ] = useState( false );
  const [ pwdStr, setPwdStr ] = useState( 0 );
  const nav = useNavigate();
  const params = useParams();

  const handleSubmit = ( e ) => {
    e.preventDefault();

    if ( !state.email || !state.newPassword ) {
      message.info( "All fields are required" );
      return;
    }

    if ( pwdStr < 60 ) {
      message.error( "Weak Password" );
      return;
    }

    setPwdState( {
      ...state,
      token: params.token
    } );

    setBusy( true );
    postChangePassword( state )
      .then( ( res ) => {
        if ( res.status === 200 ) {
          message.success( "Password changed successfully" );
          // nav( appLinks.login ); 
        }
        else
          throw res;
      } )
      .catch( ( ex ) => {
        message.error(
          ex.response.data.message || "Something went wrong, contact Admin"
        );
      } )
      .finally( () => {
        setBusy( false );
      } );
  };

  return (
    <Container size={ 460 } my={ 30 }>
      <PageTitle title="Change Password" />
      <Title className={ classes.title } align="center">
        Change password
      </Title>
      <Text color="dimmed" size="sm" align="center">
        provide new passwords to change
      </Text>

      <form onSubmit={ handleSubmit } method="POST">
        <Paper withBorder shadow="md" p={ 30 } radius="md" mt="xl">
          <TextInput
            label="Your email"
            onChange={ ( e ) => setPwdState( { ...state, email: e.target.value } ) }
            value={ state.email }
            placeholder="you@mail.com"
            size="md"
            required
          />
          {/* <PasswordInput
            onChange={ ( e ) =>
              setPwdState( { ...state, currentPassword: e.target.value } )
            }
            value={ state.currentPassword }
            label="Current Password"
            placeholder="Your current password"
            required
            mt="md"
          /> */}
          <PasswordInput
            onChange={ ( e ) => {
              setPwdState( { ...state, newPassword: e.target.value } );
              setPwdStr( passwordStrength( e.target.value ) );
            } }
            value={ state.newPassword }
            label="New Password"
            placeholder="Your New password"
            required
            size="md"
            mt="md"
          />
          <Progress size={ 100 } animate className="mt-2" style={ { height: "30px" } } color={ pwdStr > 60 ? "green" : "red" } value={ pwdStr } />
          {/* <p>{ pwdStr }</p> */ }
          <p className="mb-0">A strong password has:</p>
          <ol>
            <li>Minimum characters: 6</li>
            <li>At least 1 upper case</li>
            <li>At least 1 lower case</li>
            <li>At least 1 symbol character</li>
          </ol>


          <Group position="apart" mt="lg" className={ classes.controls }>
            <Anchor
              color="dimmed"
              size="sm"
              className={ classes.control }
              onClick={ () => nav( -1 ) }
            >
              <Center inline>
                <IconArrowLeft size={ 12 } stroke={ 1.5 } />
                <Box ml={ 5 }>Cancel</Box>
              </Center>
            </Anchor>
            <button
              disabled={ pwdStr < 60 }
              className={ `button h6 app-btn btn-prim ${ busy && "is-loading" } ${ classes.control
                }` }
            >
              Change password
            </button>
          </Group>
        </Paper>
      </form>
    </Container>
  );
}
