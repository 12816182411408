import { Modal, Paper } from "@mantine/core";
import { BackTop, Divider, Drawer, Table, Tabs, Input, Space, Tooltip, message } from "antd";
import { useState } from "react";
import { PageHeader, SaveButton } from "../../components";
import { deleteStaff, getAllStaffGroups, getAllStaffs } from "../../helpers/api";
import { calcAge, hasPermission, setState } from "../../helpers/utilities";
import NewStaffForm from "./components/new-staff";
import NewStaffGroup from "./components/new-staff-group";
import { SaveButtonActions } from "../../helpers/config";
import EditStaffForm from './components/edit-staff';
import smalltalk from 'smalltalk';
import { useQuery } from "react-query";
import { action, resouces } from "../../helpers/auth";
import { PayRollPage } from './payroll';

const HRIndex = ( props ) => {
  const [ isNewObject, setIsNewObject ] = useState( false ); //newObject
  const [ visible, setVisible ] = useState( false ); //drawer
  const [ filteredStaffs, setFilteredStaffs ] = useState( [] );
  const [ filteredGroups, setFilteredGroups ] = useState( [] );


  const { TabPane } = Tabs;
  const { Search } = Input;

  const [ modal, setModal ] = useState( {
    title: "",
    open: false,
    content: "",
  } );

  const { data: staffs, isFetching, refetch: fetchStaffs } = useQuery( {
    queryFn: () => getAllStaffs(),
    queryKey: [ 'staffs' ],
    onSuccess: data => setFilteredStaffs( data )
  } );

  const { data: staffGroups, refetch: fetchStaffGroups } = useQuery( {
    queryFn: () => getAllStaffGroups(),
    enabled: false,
    queryKey: [ 'staffGroups' ],
    onSuccess: data => setFilteredGroups( data )
  } );


  const handleDeleteStaff = ( id ) => {
    smalltalk.confirm(
      "Delete Staff", "This action cannot be undone. Delete Staff?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {
      deleteStaff( id ).then( res => {
        if ( res.status === 204 ) {
          message.success( 'staff deleted' );
          fetchStaffs();
        }
      } );

    } ).catch( ex => {
      // console.log(ex);
      return false;
    } );
  };

  const handleTabChange = ( tab ) => {
    if ( tab === "2" && filteredGroups.length === 0 ) fetchStaffGroups();
  };


  // staffs table
  const staffColumns = [
    {
      title: "Name",
      sorter: ( a, b ) => a.firstName > b.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        `${ record.firstName }  ${ record.lastName }`,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: ( a, b ) => a.gender > b.gender,
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Contact",
      sorter: ( a, b ) => a.contact > b.contact,
      dataIndex: "contact",
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Group",
      sorter: ( a, b ) => a.staffGroup.title > b.staffGroup.title,
      dataIndex: [ "staffGroup", "title" ],
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Age",
      sorter: ( a, b ) => new Date( a.dob ) > new Date( b.dob ),
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) => {
        const { age, interval } = calcAge( record.dob );
        return `${ age } ${ interval }`;
      }
    },
    {
      title: "-",
      width: 150,
      render: ( text, record, index ) => (
        <Space>
          <div class="d-flex">
            {/* {
              hasPermission( resouces.staffs, action.canUpdate ) &&
              <Tooltip title="Edit Staff">
                <button
                  className="d-flex align-items-center button is-small"
                  onClick={ () => {
                    setModal( {
                      title: "Edit Staff",
                      open: true,
                      content: <EditStaffForm staffId={ record.id } onSuccess={ fetchStaffs } showCharm={ false } />,
                    } );
                  } }>
                  <span className="bi bi-pencil"></span>
                </button>
              </Tooltip>
            }
            {
              hasPermission( resouces.staffs, action.canDelete ) &&
              <Tooltip title="Delete Staff">
                <button className="button is-small" onClick={ () => handleDeleteStaff( record.id ) }>
                  <span className="bi bi-trash"></span>
                </button>
              </Tooltip>
            } */}

            <button
              className="button app-btn is-ghost" onClick={ () =>
                setModal( {
                  title: "Edit Staff",
                  open: true,
                  content: <EditStaffForm isEditable={ hasPermission( resouces.staffs, action.canUpdate ) } staffId={ record.id } onSuccess={ fetchStaffs } showCharm={ false } />,
                } )
              }>
              <span className="bi bi-eye me-1"></span>
              View
            </button>
            <button className="button app-btn is-ghost text-danger" onClick={ () => handleDeleteStaff( record.id ) }>
              <span className="bi bi-trash"></span>
            </button>
          </div>

        </Space >
      ),
    },
  ];

  // staffs groups table
  const staffGroupsColumns = [
    {
      title: "#",
      sorter: ( a, b ) => a.index > b.index,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) => ++index,
      width: "70px",
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: ( a, b ) => a.title > b.title,
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Staffs",
      sorter: ( a, b ) => a.staffs > b.staffs,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) => record.staffs.length,
    },
  ];

  return (
    <section>
      <PageHeader title="HR" hasBackButton description="Manage your workforce">
        {
          hasPermission( resouces.staffs, action.canCreate ) &&
          <>
            <button
              class="app-btn btn-text me-2"
              onClick={ () => setVisible( true ) }
            >
              Add Group
            </button>
            <button
              class="app-btn btn-prim"
              onClick={ () => setIsNewObject( true ) }
            >
              <span className="bi bi-plus-circle me-2"></span>
              <strong className="text-white">NEW</strong>
            </button>
          </>
        }
      </PageHeader>
      <Modal
        title={ modal.title }
        opened={ modal.open }
        size={ 700 }
        onClose={ () => setModal( { ...modal, open: false } ) }
      >
        { modal.content }
      </Modal>
      <Divider className="mt-1" />
      { !isNewObject && (
        <Tabs defaultActiveKey="1" onChange={ handleTabChange }>
          <TabPane tab={ <span><i className="bi bi-person-check me-2" /> Staffs</span> } key="1">
            <p>
              Showing <strong>{ filteredStaffs.length }</strong>{ " " }
              { filteredStaffs.length > 1 ? " records" : "record" }
            </p>

            <Paper className="border bg-white p-3 my-1 d-flex justify-content-between">
              <Search
                className="w-50"
                onChange={ ( e ) =>
                  setFilteredStaffs(
                    staffs.filter(
                      ( s ) =>
                        s.firstName
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() ) ||
                        s.lastName
                          .toLowerCase()
                          .includes( e.target.value.toLowerCase() )
                    )
                  )
                }
                title="search by staff name"
                placeholder="search by staff names"
              />
              <button
                onClick={ () => setState( setFilteredStaffs, staffs ) }
                className="app-btn btn-text ms-5 d-flex align-items-center"
              >
                <span className="bi bi-reload me-2"></span>
                Reset filters
              </button>
            </Paper>
            <div className="row mt-2">
              <div className="col-12">
                <Table
                  className="border"
                  rowKey={ ( record ) => record.id }
                  dataSource={ filteredStaffs }
                  columns={ staffColumns }
                  loading={ isFetching }
                  bordered
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab={ <span><i className="bi bi-people me-2" /> Staff Groups</span> } key="2">
            <p>
              Showing <strong>{ filteredGroups.length }</strong>{ " " }
              { filteredGroups.length > 1 ? " records" : "record" }
            </p>
            <Paper className="border bg-white p-3 my-1 d-flex justify-content-between">
              {/* <Space className="ms-3"> */ }

              <Search
                className="w-50"
                onChange={ ( e ) =>
                  setFilteredGroups(
                    staffGroups.filter( ( s ) =>
                      s.title
                        .toLowerCase()
                        .includes( e.target.value.toLowerCase() )
                    )
                  )
                }
                title="search by group title"
                placeholder="search by group title"
              />
              {/* </Space> */ }
              <button
                onClick={ () => setState( setFilteredGroups, staffGroups ) }
                className="app-btn btn-text ms-5 d-flex align-items-center"
              >
                <span className="bi bi-arrow-clockwise me-2"></span>
                Reset filters
              </button>
            </Paper>
            <div className="col-12">
              <Table
                className="border"
                rowKey={ ( record ) => record.id }
                dataSource={ filteredGroups }
                columns={ staffGroupsColumns }
                bordered
              />
            </div>
          </TabPane>
          <TabPane tab={ <span><i className="bi bi-receipt me-2" /> Payroll</span> } key="3">
            <PayRollPage />
          </TabPane>
        </Tabs>
      ) }

      { isNewObject && (
        <div className="row">
          <div className="col-md-8 col-12">
            <NewStaffForm
              onSuccess={ fetchStaffs }
              onExit={ () => setIsNewObject( false ) }
            />
          </div>
        </div>
      ) }
      <BackTop />

      <Drawer
        key={ Math.random() }
        footer={
          <div className="ms-2">
            <SaveButton action={ SaveButtonActions.ADD_STAFF_GROUP } />
          </div>
        }
        title="Add Staff Group"
        placement="right"
        onClose={ () => setVisible( false ) }
        visible={ visible }
      >
        <NewStaffGroup onSuccess={ fetchStaffGroups } />
      </Drawer>
    </section>
  );
};

export { HRIndex };
