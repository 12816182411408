import { Divider, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { Loader } from '@mantine/core';
import { RequiredIndicator } from '../../../components';
import { getAllCategories, getExpenseById, putExpense } from '../../../helpers/api';
import _ from 'lodash';
import { useState } from 'react';


const EditExpenseForm = ( { id, canEdit, showHeader = true, showFooter = true, onClose, onUpdate } ) => {

    const [ state, setState ] = useState( {} );

    // const { handleSubmit, register, getValues } = useForm( {
    //     defaultValues:

    //         async () => getExpenseById( id ).then( res => {
    //             return {
    //                 ...res,
    //                 date: new Date( res.date ).toISOString().substring( 0, 10 )
    //             };
    //         } )
    // } );

    const { data: categories = [], isFetching } = useQuery( {
        queryFn: () => getAllCategories( 'expense' ),
        queryKey: [ 'expense-categories' ],
    } );

    const { isFetching: fetchingExpense } = useQuery( {
        queryFn: () => getExpenseById( id ),
        queryKey: [ 'expense', id ],
        onSuccess: data => setState( {
            ...data,
            date: new Date( data.date ).toISOString().substring( 0, 10 )
        } )
    } );



    const { mutateAsync, isLoading } = useMutation( ( data ) => putExpense( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onUpdate();
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;

            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );

    const handleSubmit = () => mutateAsync( state );


    return (

        <div className="pt-1">
            {
                showHeader &&
                <>
                    <div className="d-flex justify-content-between align-items-center sticky-top bg-white pt-1 px-4">
                        <div>
                            <h5>Expense Details</h5>
                        </div>
                        <div className='buttons has-addons'>
                            {
                                canEdit &&
                                <button
                                    onClick={
                                        handleSubmit
                                        //             () => {
                                        // document.getElementById( 'submit_btn' ).click();
                                        //     } 
                                    }
                                    className={ `button btn-prim ${ isLoading && ' is-loading' }` }
                                >
                                    <span className="bi bi-check-all me-2"></span>
                                    Update
                                </button>
                            }
                            <button className="button bokx-btn" onClick={ onClose }>
                                <span className="bi bi-x-circle me-2"></span>
                                Close
                            </button>
                        </div>
                    </div>
                    <Divider />
                </>
            }
            {
                fetchingExpense ?
                    <Loader color="success" className="mb-2" /> :
                    <div
                        // onSubmit={ handleSubmit( mutateAsync ) }
                        className="p-2">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <label htmlFor="description">
                                        Description
                                        <RequiredIndicator />
                                    </label>
                                    <input
                                        type="text"
                                        id='description'
                                        autoFocus
                                        className="input"
                                        placeholder="describe expenditure"
                                        value={ state.description }
                                        onChange={ e => setState( { ...state, description: e.target.value } ) }
                                    // { ...register( "description", { required: true } ) }
                                    />
                                </div>
                                <div className="my-3">
                                    <label htmlFor="amount">
                                        Amount
                                        <RequiredIndicator />
                                    </label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        id='amount'
                                        className="input"
                                        placeholder="amount spent"
                                        value={ state.amount }
                                        onChange={ e => setState( { ...state, amount: e.target.value } ) }
                                    // { ...register( "amount", { required: true } ) }
                                    />
                                </div>
                                <div>
                                    <label htmlFor="categories">
                                        Category
                                        <RequiredIndicator />
                                    </label>
                                    <select
                                        size='large'
                                        name="categories"
                                        id="categories"
                                        className="d-block w-100 input"
                                        placeholder='select expense category'
                                        value={ state.categoryId }
                                        onChange={ e => setState( { ...state, categoryId: e.target.value } ) }
                                    // { ...register( "categoryId", { required: true } ) }
                                    >
                                        {
                                            categories.map( cat =>
                                                <option value={ cat.id } id={ cat.id }>{ cat.title }</option> )
                                        }
                                    </select>
                                </div>
                                <div className="my-3">
                                    <label htmlFor="date">
                                        Date
                                        <RequiredIndicator />
                                    </label>
                                    <input
                                        type="date"
                                        id='date'
                                        className="input"
                                        placeholder="input expenditure date"
                                        value={ state.date }
                                        onChange={ e => setState( { ...state, date: e.target.value } ) }
                                    // { ...register( "date", { required: true } ) }
                                    />
                                    <small className="text-muted">{ state.date ? new Date( state.date ).toDateString() : 'no date' }</small>
                                </div>

                            </div>
                        </div>
                        <button hidden type='submit' id='submit_btn'></button>
                        {
                            showFooter &&
                            <>
                                <Divider />
                                <button
                                    onClick={ handleSubmit }
                                    className={ `button h6 app-btn btn-prim me-3 ${ isLoading && ' is-loading' }` }>
                                    <span className="bi bi-check-all me-2"></span>
                                    Update
                                </button>
                            </>
                        }
                    </div>
            }

        </div >
    );
};

export default EditExpenseForm;