import { Divider, Tabs, message } from "antd";
import { ErrorBoundary, PageHeader, RequiredIndicator } from "../../components";
import { useMutation, useQuery } from "react-query";
import { getAllServices, getFacilitySettings, putFacilitySettings } from "../../helpers/api";
import { getUser } from "../../helpers/auth";
import { Modal, Select, Skeleton, Switch } from "@mantine/core";
import { useState } from "react";
import _ from "lodash";
import { IconInfoCircle } from "@tabler/icons";
import smalltalk from 'smalltalk';
import { format } from "date-fns";

const SettingsPage = ( props ) => {

    // states
    const [ state, setState ] = useState( {} );
    const [ modal, setModal ] = useState( {
        title: "",
        open: false,
        content: "",
        size: 500,
        zIndex: 55
    } );

    // consts
    const { TabPane } = Tabs;

    // queries
    const { isFetching, refetch } = useQuery( {
        queryFn: () => getFacilitySettings( getUser().facility.id ),
        queryKey: [ 'settings' ],
        onSuccess: data => data && setState( data )
    } );

    const { data: services, isFetching: fetchingServices } = useQuery( {
        queryFn: getAllServices,
        queryKey: [ 'services' ],
    } );

    const { mutateAsync, isLoading } = useMutation( ( data ) => putFacilitySettings( getUser().facility.id, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                refetch();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    // handlers
    const handleUpdateSettings = () => {
        smalltalk.confirm(
            "Update Settings", "You are about to change RiviaOS settings for your facility. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            mutateAsync( state );
        } ).catch( ex => {
            message.error( ex.data.message );
        } );
        return false;
    };

    //others
    const folderNumberPatternTemplates = (
        <>
            <p><kbd>[year]</kbd>  - current year</p>
            <p><kbd>[count]</kbd>  - consultation sequence number</p>
            <p><kbd>[month]</kbd> - current month</p>
            <p><kbd>[day]</kbd> - current day of month</p>
            <Divider />
            <p className="text-muted">RiviaOS will replace these template patterns with the corresponding actual data</p>
            <p>for instance, this pattern 'RCM/[count]/[month]/[year]' transforms to 'RCM/20/12/24' (the 20th consultation in the 12th month of 2024)</p>
        </>
    );

    return (
        <section>
            <PageHeader title="Settings"
                description='Manage and configure application behaviour'
                hasBackButton>
                <button onClick={ handleUpdateSettings } class={ `button app-btn h6 btn-prim ${ isLoading && ' is-loading' }` } >
                    <span className="bi bi-save me-2"></span>
                    UPDATE
                </button>
            </PageHeader>
            <Modal
                title={ modal.title }
                opened={ modal.open }
                size={ modal.size }
                onClose={ () => setModal( { ...modal, open: false } ) }
                zIndex={ modal.zIndex }
            >
                { modal.content }
            </Modal>
            <Divider className="my-2" />
            {
                isFetching ?
                    <Skeleton>loading settings...</Skeleton> :
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={ <span><i className="bi bi-bricks me-2" /> General</span> } key="1">
                            <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Sorry, we encountered an error fetching settings</kbd> }>
                                { state?.updatedAt && <p className="text-muted">Last Updated: { format( new Date( state?.updatedAt ), 'dd MMM yyyy, hh:mm a' ) }</p> }
                                <div className="row mt-3">
                                    <div className="col-3">
                                        <label htmlFor="autoGenerateFolderNumbers">
                                            Auto Generate Folder Numbers?
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <Switch
                                            id="autoGenerateFolderNumbers"
                                            size="lg"
                                            checked={ state?.autoGenerateFolderNumbers }
                                            onChange={ () => setState( {
                                                ...state,
                                                autoGenerateFolderNumbers: state ? !state.autoGenerateFolderNumbers : true
                                            } ) }
                                            onLabel="YES"
                                            offLabel="NO"
                                        />
                                        <small className="text-muted">should RiviaOS automatically generate folder numbers based on provided pattern?</small>
                                    </div>
                                </div>
                                {
                                    state?.autoGenerateFolderNumbers &&
                                    <div className="row mt-3">
                                        <div className="col-3">
                                            <label htmlFor="folderNumberPattern">
                                                Folder Number Pattern
                                                <span className="hover-hand ms-2" onClick={ () => setModal( {
                                                    title: 'Folder Number Pattern Templates',
                                                    open: true,
                                                    content: folderNumberPatternTemplates
                                                } ) }><IconInfoCircle color="teal" size={ 10 } /></span>
                                                <RequiredIndicator />
                                                <small className="d-block text-primary hover-hand fw-bold" onClick={ () => setModal( {
                                                    title: 'Folder Number Pattern Templates',
                                                    open: true,
                                                    content: folderNumberPatternTemplates
                                                } ) }>View all templates</small>
                                            </label>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                id="folderNumberPattern"
                                                className="input"
                                                value={ state?.folderNumberPattern }
                                                onChange={ e => setState( {
                                                    ...state,
                                                    folderNumberPattern: e.target.value
                                                } ) }
                                            />
                                            <small className="text-muted">pattern to use when auto-generating consultation 'folder numbers'. example RCM/[count]/[month]/[year]</small>

                                        </div>
                                    </div>
                                }
                                <div className="row mt-3">
                                    <div className="col-3">
                                        <label htmlFor="autoBillNewConsultations">
                                            Auto Bill New Consultations?
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <Switch
                                            id="autoBillNewConsultations"
                                            size="lg"
                                            checked={ state?.autoBillNewConsultations }
                                            onChange={ () => setState( {
                                                ...state,
                                                autoBillNewConsultations: state ? !state.autoBillNewConsultations : true
                                            } ) }
                                            onLabel="YES"
                                            offLabel="NO"
                                        />
                                        <small className="text-muted">should RiviaOS automatically generate a bill for patient when a consultation is created?</small>
                                    </div>
                                </div>
                                {
                                    state?.autoBillNewConsultations &&
                                    <div className="row mt-3">
                                        <div className="col-3">
                                            <label htmlFor="defaultBillingServiceId">
                                                Default Billing Service
                                                { state?.autoBillNewConsultations && <RequiredIndicator /> }
                                            </label>
                                        </div>
                                        {
                                            fetchingServices ?
                                                <Skeleton>loading services...</Skeleton> :
                                                <div className="col-8">
                                                    <Select
                                                        id='defaultBillingServiceId'
                                                        value={ state?.defaultBillingServiceId }
                                                        nothingFound="No match"
                                                        onChange={ ( value ) =>
                                                            setState( {
                                                                ...state,
                                                                defaultBillingServiceId: value
                                                            } ) }
                                                        size="md"
                                                        clearable
                                                        searchable
                                                        placeholder='select service'
                                                        data={
                                                            services && services?.map( service => {
                                                                return {
                                                                    label: service?.serviceName,
                                                                    value: service?.id
                                                                };
                                                            } )
                                                        }
                                                    />
                                                    <small className="text-muted">service to use if consultation auto-billing is enabled</small>
                                                </div>
                                        }
                                    </div>
                                }
                                <div className="row mt-3">
                                    <div className="col-3">
                                        <label htmlFor="receiptAbbreviation">
                                            Receipt Abbreviation
                                            <RequiredIndicator />
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            id="receiptAbbreviation"
                                            className="input"
                                            maxLength={ 3 }
                                            value={ state?.receiptAbbreviation }
                                            onChange={ e => setState( {
                                                ...state,
                                                receiptAbbreviation: e.target.value
                                            } ) }
                                        />
                                        <small className="text-muted">3 character combinations that will be prepended to bill/receipt numbers. example - ABC</small>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-3">
                                        <label htmlFor="momoNumber">
                                            Mobile Money Number
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            id="momoNumber"
                                            className="input"
                                            value={ state?.momoNumber }
                                            onChange={ e => setState( {
                                                ...state,
                                                momoNumber: e.target.value
                                            } ) }
                                        />
                                        <small className="text-muted">active and registered mobile money number for transactions</small>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-3">
                                        <label htmlFor="maxAppointmentDuration">
                                            Maximum Appointment Duration
                                            <RequiredIndicator />
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            type="number"
                                            id="maxAppointmentDuration"
                                            className="input"
                                            value={ state?.maxAppointmentDuration }
                                            onChange={ e => setState( {
                                                ...state,
                                                maxAppointmentDuration: e.target.value
                                            } ) }
                                        />
                                        <small className="text-muted">appointments upper ceiling duration in minutes</small>
                                    </div>
                                </div>
                            </ErrorBoundary>
                        </TabPane>
                        {/* <TabPane tab={ <span><i className="bi bi-clipboard2-pulse-fill me-2" /> Consultations</span> } key="2">
                        </TabPane>
                        <TabPane tab={ <span><i className="bi bi-building-fill me-2" /> Facility</span> } key="3">
                        </TabPane> */}
                    </Tabs>
            }

            {/* <ol>
                <li>default consultation billing service</li>
                <li>max scheduling periods</li>
                <li>receipt abbreviations</li>
                <li>momo number</li>
                <li>folder number generation pattern</li>
                <li></li>
                <li>make these default settings when onboarding a facility</li>
            </ol> */}
        </section>
    );
};

export { SettingsPage };