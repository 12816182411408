import { useQuery } from "react-query";
import { deleteCarePlan, getCareplans } from "../../../helpers/api";
import { Loader, Modal } from "@mantine/core";
import EditCarePlan from "./edit-careplan";
import { useState } from "react";
import { format } from "date-fns";
import { message, Typography } from "antd";
import smalltalk from 'smalltalk';


const CarePlansComponent = ( { consultationId } ) => {

    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        size: 500,
        zIndex: 50,
    } );

    const { data: plans, isFetching, refetch } = useQuery( {
        queryFn: () => getCareplans( consultationId ),
        queryKey: [ 'careplans' ],
    } );

    const handleDeletePlan = ( id ) => {

        if ( !id ) {
            return;
        }

        smalltalk.confirm(
            "Delete Plan", "Deleting this care plan cannot be undone. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            message.info( 'deleting...' );
            deleteCarePlan( id ).then( res => {
                if ( res.status === 204 ) {
                    message.success( 'done!' );
                    refetch();
                    return;
                }
            } ).catch( ex => {
                message.error( ex.data.message );
            } );
        } )
            .catch( ex => {
                return false;
            } );
    };

    const { Paragraph } = Typography;

    return (
        <>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                size={ modal.size }
                zIndex={ modal.zIndex }
            >
                { modal.content }
            </Modal>
            {/* <div className="d-flex justify-content-between"> */ }
            {/* <h6>Care Plan</h6> */ }
            <button
                onClick={ () =>
                    setModal( {
                        isOpen: true, title: "Add Care Plan",
                        content: <EditCarePlan isNewPlan
                            onRefresh={ refetch }
                            onPost={ () => setModal( { isOpen: false } ) } consultationId={ consultationId } />,
                        size: 900
                    } ) }
                className="button app-btn btn-prim h6">
                <span className="bi bi-plus-circle me-2" />
                Add Plan
            </button>
            {/* </div> */ }
            { isFetching && <p className="mt-3"><Loader className="me-2" />  Fetching plans...</p> }
            { plans &&
                plans?.sort( ( a, b ) => new Date( b.createdAt ) - new Date( a.createdAt ) ).map( ( pl, index ) =>
                    <div className="border rounded mt-2 p-3">
                        <div className="row">
                            <div className="col-1">
                                <h5>#{ ++index }</h5>
                            </div>
                            <div className="col-8">
                                <div>
                                    <h6>{ `${ pl?.user?.staff?.firstName } ${ pl?.user?.staff?.lastName }` }</h6>
                                    <Paragraph ellipsis>{ pl.assessment }</Paragraph>
                                </div>
                                <small className="text-muted">
                                    { format( new Date( pl.createdAt ), 'MMMM dd, yyyy hh:mm a' ) }
                                    { pl?.updatedAt !== pl.createdAt &&
                                        ' - Updated on: ' + format( new Date( pl.updatedAt ), 'MMMM dd, yyyy hh:mm a' ) }
                                </small>
                            </div>
                            <div className="col-3 g-0">
                                <button
                                    onClick={ () => setModal( {
                                        title: 'Edit Care Plan',
                                        isOpen: true,
                                        size: 900,
                                        content: <EditCarePlan key={ pl.id } carePlanId={ pl.id } onRefresh={ refetch } />
                                    } ) }
                                    className="button fw-bold">
                                    <span className="bi bi-eye me-2" />
                                    Open
                                </button>
                                <button
                                    onClick={ () => handleDeletePlan( pl.id ) }
                                    className="button fw-bold text-danger">
                                    <span className="bi bi-trash" />
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default CarePlansComponent;