import { Divider } from "antd";
import _ from "lodash";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { Kbd, Paper, Modal } from "@mantine/core";
import { DetailLineItem } from "../../../components";
import { IconAlarm, IconCheck, IconMail, IconMessageCircle, IconPhoto, IconPill, IconStethoscope, IconTrash } from "@tabler/icons";
import LabReportForm from "./lab-report";
import extAuth from "../../../helpers/extAuth";

const LabConsultationDetail = ( { data, onReload, onClose } ) => {
  const [ lab, setLab ] = useState( {} );

  useEffect( () => {
    // const labs = []; //useful if one lab can be given multiple lab requests per consultation

    data.laboratories?.map( lab => {
      if ( lab.laboratoryId === extAuth.getUser().externalId ) {
        setLab( lab );
        // console.log( 'lab ', lab );
        // labs.push( lab );
      }
    } );

    // setLab( labs );
    // console.log( 'user data ', extAuth.getUser() );
  }, [ data ] );

  const [ modelOpen, setOpenModal ] = useState( false );

  const handleReportSubmitSuccess = ( report, bill ) => {

    setLab( {
      ...lab,
      isComplete: true,
      report,
      bill
    } );

  };


  return (

    <div className="consultation-detail">
      <div className="row g-0">
        <div className="col-12 mb-2">
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="section-header mb-0">Consultation</h6>
              <p className="text-muted mt-0">consultation basic details</p>
            </div>
            <div>
              {
                !lab.isComplete &&
                <button className="button is-link"
                  onClick={ () => setOpenModal( true ) }
                >
                  SUBMIT REPORT
                  <span className="bi bi-plus-circle ms-2"></span>
                </button>
              }
              <button className="button is-danger is-light p-3" onClick={ onClose }>
                <strong>Close</strong>
                <span className="bi bi-x ms-2"></span>
              </button>
            </div>
          </div>
          {/* consultation */ }
          <Paper className="p-3  border">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="layer-header">Consultation</h6>
            </div>
            <DetailLineItem icon="lock" title="Code" detail={
              <kbd>
                { data.code }
              </kbd>
            } />
            <Divider className="my-1" />
            <DetailLineItem icon="braces-asterisk" title="Type" detail={ data.type } />
            <Divider className="my-1" />
            <DetailLineItem icon="calendar" title="Date" detail={ `${ format( new Date( data.createdAt ), "EEE MMM dd, yyy" ) } @${ new Date( data.createdAt ).toLocaleTimeString() }` } />
            <Divider className="my-1" />
            <DetailLineItem icon="megaphone" title="Complaint" detail={ data.complaint } />
            <Divider className="my-1" />
            <DetailLineItem icon="incognito" title="Investigation" detail={ data.investigation } />
            <Divider className="my-1" />
            <DetailLineItem icon="virus" title="Lab Request" detail={ lab.requestType } />
            <Divider className="my-1" />
            <DetailLineItem icon="file-medical" title="Management Plan (Advice)" detail={ data.doctorsAdvise } />
          </Paper>
        </div>
        <div className="col-md-6 g-0 col-12">
          {/* patient */ }
          <Paper className="p-3  border">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="layer-header">
                <IconPill className="me-1" />
                Patient</h6>
            </div>
            <DetailLineItem title="Name" detail={ `${ data?.patient?.firstName } ${ data?.patient?.lastName }` } />
            <DetailLineItem title="Contact" detail={ data?.patient?.contact } />
          </Paper>
        </div>
        <div className="col-md-6 g-0 col-12">
          {/* doctor */ }
          <Paper className="p-3 border">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="layer-header">
                <IconStethoscope className="me-1" />
                Doctor
              </h6>
            </div>
            <DetailLineItem title="Name" detail={ `${ data?.doctor?.firstName || '-' } ${ data?.doctor?.lastName || '' }` } />
            <DetailLineItem title="Contact" detail={ data?.doctor?.contact } />
          </Paper>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 mb-1">
          <h6 className="section-header mb-0">Results</h6>
          <p className="text-muted mt-0">consultation findings and recommendations for further engagement</p>
          <Paper className="p-3 border">
            {/* diagnosis */ }
            <div className="d-flex justify-content-between align-items-center">
              <span className="layer-title text-success">
                Diagnosis
              </span>
            </div>
            <div>
              {
                data.diagnosis.map( ( d, i ) => <>
                  <Divider className="my-1" />
                  <DetailLineItem title={ ++i + '.' } detail={ d.diagnosis } />
                </>
                )
              }
            </div>
          </Paper>
        </div>
        <div className="col-12 mb-1">
          <Paper className="p-3 border">
            {/* prescriptions */ }
            <div className="d-flex justify-content-between align-items-center">
              <span className="layer-title text-success">
                Prescriptions
              </span>
            </div>
            <div>
              {
                data.prescriptions.map( ( d, i ) => <>
                  <Divider className="my-1" />
                  <kbd className="bg-secondary">{ ++i }</kbd>
                  <DetailLineItem title='Medicine' detail={ d.medicine } />
                  <DetailLineItem title='Strength' detail={ d.strength } />
                  {/* <DetailLineItem title='Dosage' detail={ d.dosage } /> */ }
                  <DetailLineItem title='Frequency' detail={ d.frequency } />
                  <DetailLineItem title='Duration' detail={ d.duration } />
                </>
                )
              }
            </div>
          </Paper>
        </div>
        <div className="col-12">
          <Paper className="p-3 border">
            <div className="d-flex justify-content-between align-items-center">
              <span className="layer-title text-success">
                Laboratory Report
              </span>
            </div>
            <div className="mt-2">
              <Divider />
              {
                lab.bill > 0 &&
                <div className="d-flex">
                  <kbd className="me-2">Bill:</kbd>
                  <p className="m-0"><strong>GHS { lab.bill }</strong></p>
                </div>
              }
              {
                lab.report &&
                <div className="d-flex mt-2">
                  <kbd className="me-2">Report:</kbd>
                  <p className="m-0">{ lab.report }</p>
                </div>
              }
            </div>
          </Paper>
        </div>
      </div>
      <Modal
        size="xl"
        title="Laboratory Report"
        zIndex={ 10 }
        opened={ modelOpen }
        onClose={ () => setOpenModal( false ) }
      >
        <LabReportForm consultation={ data } onDone={ handleReportSubmitSuccess } />
      </Modal>
    </div>
  );
};

export default LabConsultationDetail;
