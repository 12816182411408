import { Divider } from "antd";
import { useState } from "react";
import { PageHeader } from "../../components";
import ConsultationsReport from "./components/consultations-report";
import CallsReport from "./components/calls-report";
import PrescriptionsReport from "./components/prescriptions-report";
import LaboratoriesReport from "./components/laboratories-report";
import DiagnosisReport from "./components/diagnosis-report";
import { useQuery } from "react-query";
import {
	getCallsReport, getConsultationsReport, getDiagnosisReport,
	getLaboratoriesReport, getPrescriptionsReport
} from "../../helpers/api";



const ReportingIndex = ( props ) => {
	const [ page, setPage ] = useState( 1 );

	const { data: consultationsReport, isFetching: conFetching } = useQuery( {
		queryFn: getConsultationsReport,
		queryKey: [ 'consultationsReport' ],
	} );

	const { data: callsReport, isFetching: callsFetching } = useQuery( {
		queryFn: getCallsReport,
		queryKey: [ 'callsReport' ],
		// enabled: page === 2
		enabled: false,
	} );

	const { data: prescriptionsReport, isFetching: presFetching } = useQuery( {
		queryFn: getPrescriptionsReport,
		queryKey: [ 'prescriptionsReport' ],
		enabled: page === 3
	} );

	const { data: laboratoriesReport, isFetching: labsFetching } = useQuery( {
		queryFn: getLaboratoriesReport,
		queryKey: [ 'laboratoriesReport' ],
		enabled: page === 4
	} );

	const { data: diagnosisReport, isFetching: diagFetching } = useQuery( {
		queryFn: getDiagnosisReport,
		queryKey: [ 'diagnosisReport' ],
		enabled: page === 5
	} );


	return (
		<section>
			<PageHeader title="Reports" description="Perform analysis and draw insights from data" hasBackButton />
			<Divider className="mt-2" />
			<div className="buttons has-addons">
				<button className={ `button ${ page === 1 && ' is-selected' }` }
					onClick={ () => setPage( 1 ) }>Consultations</button>
				{/* <button className={ `button ${ page === 2 && ' is-selected' }` }
					onClick={ () => setPage( 2 ) }>Calls</button> */}
				<button className={ `button ${ page === 3 && ' is-selected' }` }
					onClick={ () => setPage( 3 ) }>Prescriptions</button>
				<button className={ `button ${ page === 4 && ' is-selected' }` }
					onClick={ () => setPage( 4 ) }>Laboratories</button>
				<button className={ `button ${ page === 5 && ' is-selected' }` }
					onClick={ () => setPage( 5 ) }>Diagnosis</button>
			</div>

			{ page === 1 && <ConsultationsReport data={ consultationsReport } isLoading={ conFetching } /> }
			{/* { page === 2 && <CallsReport data={ callsReport } isLoading={ callsFetching } /> } */ }
			{ page === 3 && <PrescriptionsReport data={ prescriptionsReport } isLoading={ presFetching } /> }
			{ page === 4 && <LaboratoriesReport data={ laboratoriesReport } isLoading={ labsFetching } /> }
			{ page === 5 && <DiagnosisReport data={ diagnosisReport } isLoading={ diagFetching } /> }

			{/* labs

	-> patient
	-> insurance provider
	-> investigation
	-> lab name
	-> status
	-> cost
	-> attachments
	-> date
	->

            Diagnosis

	-> diagnosis
	-> date
	-> patient
	-> insurance
	-> gender
	-> age
	->


            prescriptions

	-> patient name
	-> insurance provider
	-> insurance number
	-> doctor
	-> drug
	-> dosage
	-> strength
	-> price
	-> pharmacy
	-> issuer
	-> date
	-> status -> issued | pending


            calls

	-> call name, age, gender, number
	-> caller location 
	-> caller region
	-> call purpose
	-> led to consultation
	-> agent
	-> date



            consultations

	-> patient name
	-> patient gender
	-> patient age
	-> date
	-> type
	-> doctor
	-> status -> complete | pending
	-> #ofdrugs
	-> #oflabs */}


		</section>
	);
};

export { ReportingIndex };