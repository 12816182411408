import { Loader } from "@mantine/core";
import { Divider, message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAllPatients, getPatientMedicalHistory, putUpdatePatientMedicalHistory } from "../../helpers/api";
import { medicalHistoryIllnesses } from "../../helpers/config";
import { setState } from "../../helpers/utilities";


// const illnessTemplate = {
//     id: medicalHistory.illnesses.length + 1,
//     illness: "",
//     history: ""
// };


const MedicalHistory = ( { patientId, onSuccess, onError, isEditable = true } ) => {

    const { Option } = Select;

    const [ medicalHistory, setMedicalHistory ] = useState( {
        drugAllergy: "",
        surgicalHistory: "",
        illnesses: []
    } );


    const { isFetching, isError } = useQuery( {
        queryFn: () => getPatientMedicalHistory( patientId ),
        queryKey: [ 'patientMedicalHistory', patientId ],
        onSuccess: data => setMedicalHistory( data )
    } );


    const updateData = ( fieldName, data ) => {
        setMedicalHistory( { ...medicalHistory, [ fieldName ]: data } );
    };


    const { mutateAsync: updateMedicalHistory, isLoading } = useMutation( ( data ) => putUpdatePatientMedicalHistory( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onSuccess();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
            onError();
        },
    } );


    const handleAddIllness = () => {
        let tempIllness = {
            recordId: medicalHistory.illnesses.length + 1,
            illness: "",
            history: ""
        };
        tempIllness.recordId = medicalHistory.illnesses.length + 1;

        const newIllness = [
            ...medicalHistory.illnesses,
            tempIllness
        ];

        setMedicalHistory( {
            ...medicalHistory,
            illnesses: newIllness
        } );
    };


    const handleUpdateIllness = ( id, field, data ) => {
        const illnesses = medicalHistory.illnesses;
        illnesses.map( il => {
            if ( il.recordId === id ) {
                il[ field ] = data;
            }
        } );

        setMedicalHistory( {
            ...medicalHistory,
            illnesses: illnesses
        } );

    };


    const handleDeleteIllness = ( id ) => {
        const updatedIllnesses = medicalHistory.illnesses.filter( illness => illness.recordId !== id );

        setMedicalHistory( {
            ...medicalHistory,
            illnesses: updatedIllnesses
        } );
    };


    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <>
                            {
                                medicalHistory.illnesses.length > 0 && <>
                                    <div className="row">
                                        <div className="col-md-1">
                                            <strong>#</strong>
                                        </div>
                                        <div className="col-md-6">
                                            <strong>Illness</strong>
                                        </div>
                                        <div className="col-md-4">
                                            <strong>History</strong>
                                        </div>
                                    </div>
                                    { medicalHistory.illnesses.map( ( il, index ) =>
                                        <div className="row mb-2">
                                            <div className="col-1">{ ++index }</div>
                                            <div className="col-6">
                                                <Select
                                                    required
                                                    allowClear
                                                    size="medium"
                                                    disabled={ !isEditable }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                                                    filterSort={ ( optionA, optionB ) =>
                                                        optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                                                    }
                                                    value={ il.illness }
                                                    onChange={ ( v ) => handleUpdateIllness( il.recordId, "illness", v ) }
                                                    id="illness" className="w-100" placeholder="illness"
                                                >
                                                    { medicalHistoryIllnesses }
                                                </Select>
                                            </div>
                                            <div className="col-4">
                                                <Select
                                                    required
                                                    allowClear
                                                    size="medium"
                                                    disabled={ !isEditable }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                                                    filterSort={ ( optionA, optionB ) =>
                                                        optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                                                    }
                                                    value={ il.history }
                                                    onChange={ ( v ) => handleUpdateIllness( il.recordId, "history", v ) }
                                                    id="history" className="w-100" placeholder="history"
                                                >
                                                    <Option value="Personal">Personal</Option>
                                                    <Option value="Family">Family</Option>
                                                    <Option value="Family & Personal">Family & Personal</Option>
                                                </Select>
                                            </div>
                                            {
                                                isEditable &&
                                                <div className="col-1 g-0">
                                                    <button className="button is-small" onClick={ ( e ) => handleDeleteIllness( il.recordId ) }>
                                                        <span className="bi bi-trash text-danger"></span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ) }
                                </>
                            }

                            {
                                isEditable &&
                                <button className="button is-small is-link is-light mt-2" onClick={ handleAddIllness }>
                                    <strong>
                                        <span className="bi bi-plus-circle me-2"></span>
                                        Add illness
                                    </strong>
                                </button>
                            }
                            <Divider />
                            <div className="field">
                                <label className="mb-0" htmlFor="drugAllergy">
                                    Drug Allergy
                                </label>
                                <textarea
                                    className="textarea"
                                    rows={ 3 }
                                    disabled={ !isEditable }
                                    onChange={ ( e ) => updateData( "drugAllergy", e.target.value ) }
                                    value={ medicalHistory.drugAllergy }
                                    id="drugAllergy"
                                    placeholder="drug allergy of patient"
                                ></textarea>
                            </div>
                            <div className="field">
                                <label className="mb-0" htmlFor="surgicalHistory">
                                    Surgical History
                                </label>
                                <textarea
                                    className="textarea"
                                    rows={ 3 }
                                    disabled={ !isEditable }
                                    onChange={ ( e ) => updateData( "surgicalHistory", e.target.value ) }
                                    value={ medicalHistory.surgicalHistory }
                                    id="surgicalHistory"
                                    placeholder="previous surgical operations"
                                ></textarea>
                            </div>

                            {
                                isEditable &&
                                <div className="buttons has-addons mt-3">
                                    <button id="submit" className="button is-link" onClick={ () => updateMedicalHistory( medicalHistory ) }>
                                        Update
                                        <span className="bi bi-save ms-2"></span>
                                    </button>
                                </div>
                            }
                        </>

            }
        </>
    );
};

export default MedicalHistory;
