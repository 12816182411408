import { Divider, message, Tag } from "antd";
import _ from "lodash";
import { format } from "date-fns";
import { Chip, createStyles, Modal, Paper } from "@mantine/core";
import { DetailLineItem, ErrorBoundary, FeedBackForm } from "../../../components";
import { IconPill, IconPrinter, IconReceipt, IconStethoscope, } from "@tabler/icons";
import { consultationColorCoding, percentage } from "../../../helpers/utilities";
import { useMutation } from "react-query";
import { postExtPatientFeedback } from "../../../helpers/api";
import { useState } from "react";
import { consultationStates } from "../../../helpers/config";
import BillReceipt from "../../../components/billing/receipt";


const PatientPortalDetails = ( { data, onClose, onRefresh } ) => {
  const [ modal, setModal ] = useState( {
    isOpen: false,
    title: '',
    content: null,
    size: 'md'
  } );


  const code = data.code;
  const hash = data.hash;

  let totalPrice = 0;
  data.prescriptions?.map( ( d ) =>
    totalPrice = parseFloat( totalPrice ) + parseFloat( d.price ) );

  const { mutateAsync, isLoading } = useMutation( ( data ) => postExtPatientFeedback( code, hash, data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onRefresh();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
    retry: true
  } );


  return (
    <div className="mx-3">
      <span className="mb-3">

        {
          ( data.isComplete ) ?
            <span className="d-flex"><Tag color="teal" className="fw-bold" >Done</Tag><span className="ms-1 text-muted">{ consultationStates.done.description }</span></span> :
            ( data?.totalTasks === data?.completedTasks && !data?.isComplete ) ?
              <span className="d-flex"><Tag color="black" className='fw-bold'>Open</Tag> <span className="ms-1 text-muted">{ consultationStates.open.description }</span></span> :
              <span className="d-flex"><Tag color="orange" className='fw-bold'>Pending</Tag> <span className="ms-1 text-muted">{ consultationStates.pending.description }</span></span>
        }
      </span>
      <Modal
        title={ modal.title }
        opened={ modal.isOpen }
        onClose={ () => setModal( { ...modal, isOpen: false } ) }
        size={ modal.size }
      >
        { modal.content }
      </Modal>
      { _.isEmpty( data ) && (
        <h5 className="p-3">something is broken!</h5>
      ) }
      { !_.isEmpty( data ) &&
        <div className="consultation-detail">
          <div className="buttons has-addons mt-3">
            {
              ( _.isEmpty( data?.feedback ) && data.code && data.hash ) &&
              <button className="button h6 app-btn btn-prim"
                onClick={ () => setModal( {
                  isOpen: true,
                  title: "Submit Feedback",
                  size: 'lg',
                  content: <FeedBackForm onSubmit={ ( data ) => mutateAsync( data ) } isLoading={ isLoading } />
                } ) }
              >Submit Feedback</button>
            }
            {
              !_.isEmpty( data?.bill ) &&
              <button className="button h6 app-btn"
                onClick={ () => setModal( {
                  isOpen: true,
                  title: 'Receipt',
                  size: 'sm',
                  content: <BillReceipt query={ data.bill.id } param="billId"
                    options={ { enablePrinting: false, showOperator: true, allowSizeChange: false } } />
                } ) }
              >
                <IconReceipt className="me-2" />
                Receipt
              </button>
            }
          </div>
          <div className="row g-0 mt-0">

            <div className="col-md-6 g-0 col-12">
              {/* patient */ }
              <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Error rendering patient info</kbd> }>
                <Paper className="p-3  border">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="layer-header">
                      <IconPill className="me-1" />
                      Patient</h6>
                  </div>
                  <DetailLineItem title="Name" detail={ `${ data.patient.firstName } ${ data.patient.lastName }` } />
                  <DetailLineItem title="Gender" detail={ data.patient.gender } />
                  <DetailLineItem title="Contact" detail={ data.patient.contact } />
                  <DetailLineItem title="Insurance" detail={ data.patient.insuranceNumber } />
                </Paper>
              </ErrorBoundary>
            </div>
            <div className="col-md-6 g-0 col-12">
              {/* doctor */ }
              <Paper className="p-3 border">
                <ErrorBoundary fallback={ <kbd className="bg-danger p-2">Error rendering doctor info</kbd> }>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="layer-header">
                      <IconStethoscope className="me-1" />
                      Doctor
                    </h6>
                  </div>
                  <DetailLineItem title="Name" detail={ `${ data?.doctor?.firstName || '-' } ${ data?.doctor?.lastName || '-' }` } />
                  <DetailLineItem title="Gender" detail={ `${ data?.doctor?.gender || '' } ` } />
                </ErrorBoundary>
              </Paper>
            </div>
            <div className="col-12 mt-2">
              <div className="p-md-0 ps-3">
                <h6 className="section-header mt-3 mb-0">Consultation</h6>
                <p className="text-muted mt-0">consultaion summary</p>
              </div>
              {/* consultation */ }
              <Paper className="p-3  border">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="layer-header">Consultation</h6>
                </div>
                <DetailLineItem icon="lock" title="Code" detail={
                  <kbd className={ consultationColorCoding( data.completedTasks, data.totalTasks ) }>
                    { data.code }
                  </kbd>
                } />
                <Divider className="my-1" />
                <DetailLineItem icon="braces-asterisk" title="Type" detail={ data.type } />
                <Divider className="my-1" />
                <DetailLineItem icon="calendar" title="Date" detail={ `${ format( new Date( data?.createdAt ), "EEE MMM dd, yyy" ) } @${ new Date( data.createdAt ).toLocaleTimeString() }` } />
                <Divider className="my-1" />
                <DetailLineItem icon="megaphone" title="Complaint" detail={ data.complaint } />
                <Divider className="my-1" />
                <DetailLineItem icon="list-task" title="Tasks"
                  detail={ `Total: ${ data.totalTasks } - Complete: ${ ( data.completedTasks || 0 ) } ---> (${ percentage( ( data.completedTasks || 0 ), ( data.totalTasks || 0 ) ) }% complete)` } />
                <Divider className="my-1" />
                <DetailLineItem icon="file-medical" title="Doctor's Advice" detail={ data.doctorsAdvise } />
              </Paper>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 mb-1">
              <div className="p-md-0 ps-3">
                <h6 className="section-header mb-0">Results</h6>
                <p className="text-muted mt-0">findings and recommended service providers for further engagement</p>
              </div>
              <Paper className="p-3 border">
                {/* diagnosis */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Diagnosis
                  </span>
                </div>
                <div>
                  {
                    data.diagnosis.map( ( d, i ) => <div key={ i }>
                      <Divider className="my-1" />
                      <DetailLineItem title={ ++i + '.' } detail={ d.diagnosis } />
                    </div>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div className="col-12 mb-1">
              <Paper className="p-3 border">
                {/* reviews */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Systemic Reviews
                  </span>
                </div>
                <div>
                  {
                    data.reviews?.map( ( r, i ) => <>
                      <Divider className="my-1" />
                      <DetailLineItem title={ r.type } detail={ r.symptom } />
                    </>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div className="col-12 mb-1">
              <Paper className="p-3 border">
                {/* prescriptions */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Prescriptions
                    <kbd className="ms-2">GHS { totalPrice }</kbd>
                  </span>

                </div>
                <div>
                  {
                    data.prescriptions?.map( ( d, i ) => <>
                      <Divider className="my-1" />
                      <kbd className={ d.isIssued ? 'bg-success' : 'bg-secondary' }>{ ++i }</kbd>
                      <DetailLineItem title='Medicine' detail={ d.medicine } />
                      <DetailLineItem title='Strength' detail={ d.strength } />
                      {/* <DetailLineItem title='Dosage' detail={ d.dosage } /> */ }
                      <DetailLineItem title='Frequency' detail={ d.frequency } />
                      <DetailLineItem title='Duration' detail={ d.duration } />
                      {
                        d.isIssued &&
                        <DetailLineItem title='Price (GHS)' detail={ d.price } />
                      }
                      <DetailLineItem title='Is Issued'
                        detail={ d.isIssued ?
                          `YES: By ${ d.pharmacy?.pharmacyName } - (${ format( new Date( d.updatedAt ), "MMM dd, yy" ) } @ ${ new Date( d.updatedAt ).toLocaleTimeString() })`
                          : 'NO' } />
                    </>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div className="col-12 mb-1">
              <Paper className="p-3 border">
                {/* pharmacies */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Pharmacies
                  </span>
                </div>
                <div>
                  {
                    data.pharmacies?.map( ( d, i ) => <>
                      <Divider className="my-1" />
                      <DetailLineItem title={ ++i + '.' } detail={ `${ d.pharmacy.pharmacyName } - (${ d.pharmacy.contact })` } />
                    </>
                    )
                  }
                </div>
              </Paper>
            </div>
            <div className="col-12">
              <Paper className="p-3 border">
                {/* laboratories */ }
                <div className="d-flex justify-content-between align-items-center">
                  <span className="layer-title text-success">
                    Laboratories
                  </span>

                </div>
                <div>
                  {
                    data.laboratories?.map( ( lab, i ) => <>
                      <Divider className="my-1" />
                      <DetailLineItem title={ ++i + '.' } detail={ `${ lab.laboratory.laboratoryName } - (${ lab.laboratory.contact })` } />
                    </>
                    )
                  }
                </div>
              </Paper>
            </div>
          </div>
        </div >
      }
    </div >
  );
};

export default PatientPortalDetails;
