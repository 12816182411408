import { Loader } from "@mantine/core";
import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { IndicatorInfo, RequiredIndicator } from "../../../components";
import { getLaboratory, putUpdateLaboratory } from "../../../helpers/api";
import { laboratoryCategories, Regions } from "../../../helpers/config";

const EditLaboratoryForm = ( { laboratoryId, isEditable = true, onSuccess, onError } ) => {

  const [ lab, setLab ] = useState( {} );

  const { isError, isFetching } = useQuery( {
    queryFn: () => getLaboratory( laboratoryId ),
    queryKey: [ 'laboratory', laboratoryId ],
    onSuccess: data =>
      setLab( _.omit( data, [ 'consultations', 'totalConsultations', 'isDeleted', 'createdAt', 'updatedAt', 'userId' ] ) )
  } );

  const updateData = ( fieldName, data ) => {
    setLab( { ...lab, [ fieldName ]: data } );
  };

  const { mutateAsync: updateLab, isLoading } = useMutation( ( data ) => putUpdateLaboratory( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onSuccess();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;

      if ( _.isArray( error.response.data.message ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      onError();
    },
  } );




  return (
    <>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
            :
            <form onSubmit={ e => { e.preventDefault(); updateLab( lab ); } }>
              <div className="row">
                <IndicatorInfo />
                <div className="field col-12">
                  <label className="mb-0" htmlFor="laboratoryName">
                    Laboratory Name
                    <RequiredIndicator />
                  </label>
                  <input
                    className="input"
                    type="text"
                    value={ lab.laboratoryName }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'laboratoryName', e.target.value ) }
                    autoFocus
                    id="laboratoryName"
                    placeholder="laboratory name here"
                  />
                  <small>registered laboratory name</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="type">
                    Type
                    <RequiredIndicator />
                  </label>
                  <Select
                    required
                    size="large"
                    id="type"
                    className="d-block"
                    value={ lab.type }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'type', e ) }
                    placeholder="select type"
                  >
                    { laboratoryCategories }
                  </Select>
                  <small>type of laboratory</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="contactPerson">
                    Contact Person
                    <RequiredIndicator />
                  </label>
                  <input
                    className="input"
                    type="text"
                    id="contactPerson"
                    value={ lab.contactPerson }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'contactPerson', e.target.value ) }
                    placeholder="name of person"
                  />
                  <small>who is the primary contact person</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="contact">
                    Contact
                    <RequiredIndicator />
                  </label>
                  <input
                    className="input"
                    type="tel"
                    id="contact"
                    value={ lab.contact }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'contact', e.target.value ) }
                    placeholder="contact here"
                  />
                  <small>primary contact of laboratory</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="input"
                    type="email"
                    value={ lab.email }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'email', e.target.value ) }
                    id="email"
                    placeholder="email here"
                  />
                  <small>email address of laboratory</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="location">
                    Location
                  </label>
                  <input
                    className="input"
                    value={ lab.location }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'location', e.target.value ) }
                    type="text"
                    id="location"
                    placeholder="laboratory location here"
                  />
                  <small>physical location of laboratory</small>
                </div>
                <div className="field col-12">
                  <label className="mb-0" htmlFor="region">
                    Region
                    <RequiredIndicator />
                  </label>
                  <Select
                    required
                    size="large"
                    value={ lab.region }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'region', e ) }
                    id="region"
                    className="d-block"
                    placeholder="select region"
                  >
                    { Regions }
                  </Select>
                  <small>region where laboratory is located</small>
                </div>
              </div>
              {
                isEditable && <button
                  type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                  Update Record
                </button>
              }
            </form>
      }

    </>
  );
};

export default EditLaboratoryForm;
