import { Loader, MultiSelect } from "@mantine/core";
import { message, Select } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getFacilityBranches, getUser, putUpdateUser } from "../../../helpers/api";
import { action, getUser as authUser, resouces } from "../../../helpers/auth";
import { hasPermission } from "../../../helpers/utilities";

const EditUserForm = ( { userId, isEditable = true, onSuccess, onError } ) => {
  const [ user, setUser ] = useState( {} );

  const { Option } = Select;

  const { isError, isFetching } = useQuery( {
    queryFn: () => getUser( userId ),
    queryKey: [ 'user', userId ],
    onSuccess: data => setUser( {
      ...data,
      branches: data.branches.map( br => br.facilityBranchId )
    } )
  } );

  const { data: branches = [] } = useQuery( {
    queryFn: () => getFacilityBranches( authUser().facility.id ),
    queryKey: [ 'branches' ],
  } );

  const updateData = ( fieldName, data ) => {
    setUser( { ...user, [ fieldName ]: data } );
  };


  const { mutateAsync: updateUser, isLoading } = useMutation( ( data ) => putUpdateUser( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onSuccess();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;

      if ( _.isArray( error.response.data.message ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      // onError();
    },
  } );


  const handleUpdateUser = () => {
    if ( user?.category === "internal" && _.isEmpty( user.branches ) ) {
      message.error( 'assign a branch to user' );
      return;
    }

    updateUser( user );
  };




  return (
    <div>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <>
              <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request.
              </kbd>
              <kbd className="d-block mt-2">
                IF THE USER IS SUSPENDED, ACTIVATE THE ACCOUNT AND TRY AGAIN
              </kbd>
            </>
            :
            <div>
              <div className="row">
                {
                  user.category === "internal" &&
                  <div className="field col-12">
                    <label className="mb-0" >
                      <strong>Staff</strong>
                    </label>
                    <input
                      value={ `${ user?.staff?.firstName } ${ user?.staff?.lastName }` }
                      size="large"
                      disabled
                      className="d-block input"
                    />
                    <small>staff associated with account</small>
                  </div>
                }
                {
                  ( user?.category === "external" && user?.role === "pharmacy" ) &&
                  <div className="field col-12">
                    <label className="mb-0" >
                      <strong>Pharmacy</strong>
                    </label>
                    <input
                      size="large"
                      disabled
                      value={ `${ user?.pharmacy?.pharmacyName } - ${ user?.pharmacy?.location }` }
                      className="d-block input"
                    />
                    <small>pharmacy associated with account</small>
                  </div>
                }
                {
                  ( user.category === "external" && user.role === "laboratory" ) &&
                  <div className="field col-12">
                    <label className="mb-0" >
                      <strong>Laboratory</strong>
                    </label>
                    <input
                      size="large"
                      disabled
                      value={ `${ user?.laboratory?.laboratoryName } - ${ user?.laboratory?.location }` }
                      className="d-block input"
                    />
                    <small>laboratory associated with account</small>
                  </div>

                }
                {
                  user?.category === "internal" &&
                  <div className="field col-12">
                    <label className="mb-0" htmlFor="role">
                      <strong>Role</strong>
                    </label>
                    <Select
                      required
                      size="large"
                      disabled={ !isEditable }
                      value={ user.role }
                      onChange={ e => updateData( 'role', e ) }
                      id="role"
                      className="d-block"
                      placeholder="select staff"
                    >
                      {/* <Option value="admin" key="admin">
                        Admin
                      </Option> */}
                      {
                        hasPermission( resouces.admin, action.canCreate ) &&
                        <Option value="admin" key="admin">
                          Admin
                        </Option>
                      }
                      <Option value="doctor" key="doctor">
                        Medical Doctor
                      </Option>
                      <Option value="nurse" key="nurse">
                        Nurse
                      </Option>
                      <Option value="frontdesk" key="frontdesk">
                        Front Desk
                      </Option>
                      {/* <Option value="agent" key="agent">
                        Agent
                      </Option> */}
                      <Option value="partner" key="partner">
                        Partner
                      </Option>
                    </Select>
                    <small>Role for user</small>
                  </div>
                }
                {
                  user?.category !== "internal" &&
                  <>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="fullname">
                        <strong> Full Name</strong>
                      </label>
                      <input
                        className="input"
                        type="text"
                        id="fullname"
                        value={ user?.fullName }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'fullName', e.target.value ) }
                        placeholder="fullname"
                      />
                      <small>Fullname of account owner (staff)</small>
                    </div>
                    <div className="field col-12">
                      <label className="mb-0" htmlFor="contact">
                        <strong>Phone Number</strong>
                      </label>
                      <input
                        className="input"
                        type="tel"
                        id="contact"
                        value={ user.contact }
                        disabled={ !isEditable }
                        onChange={ e => updateData( 'contact', e.target.value ) }
                        placeholder="contact"
                      />
                      <small>phone number of staff without +233 & spaces (e.g. 0200000000)</small>
                    </div>
                  </>

                }
                <div className="field col-12">
                  <label className="mb-0" htmlFor="email">
                    <strong>Account Email</strong>
                  </label>
                  <input
                    className="input"
                    type="email"
                    value={ user.email }
                    disabled={ !isEditable }
                    onChange={ e => updateData( 'email', e.target.value ) }
                    id="email"
                    placeholder="account email address"
                  />
                  <small>
                    password reset, update and other details will be sent here
                  </small>
                </div>
                {
                  user?.category === "internal" &&
                  <div className="field col-12">
                    <label htmlFor="branches">
                      <strong>Assigned Branches</strong>
                    </label>
                    <MultiSelect
                      id='branches'
                      size="md"
                      required
                      onChange={ ( v ) => updateData( 'branches', v ) }
                      value={ user.branches }
                      placeholder='select branches user will have access to'
                      data={
                        branches.map( ol => {
                          return {
                            value: ol.id,
                            label: ol.branchName
                          };
                        } )
                      }
                    />
                    <small>user will have access to the assigned branche's data</small>
                  </div>
                }
              </div>
              {
                isEditable && <button
                  onClick={ handleUpdateUser }
                  id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                  <span className="bi bi-check-all me-2" />
                  Update Record
                </button>
              }
            </div>
      }
    </div >
  );
};

export default EditUserForm;
