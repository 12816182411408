import { Group, useMantineTheme, Text, SimpleGrid, Image } from "@mantine/core";
import { IconFile, IconPhoto, IconTrash } from '@tabler/icons';
// import { Dropzone } from '@mantine/dropzone';
import { Divider, message } from "antd";
import { useState, createRef } from "react";
import { setState } from "../../../helpers/utilities";
import { RequiredIndicator } from "../../../components";
import pdfIcon from '../../../static/icons/pdf.png';
import _ from "lodash";
import { putLabReport } from "../../../helpers/api";
import { useMutation } from 'react-query';
import smalltalk from 'smalltalk';

export default function LabReportForm ( { consultation, onDone, props } ) {
    const [ report, setReport ] = useState( '' );
    const [ bill, setBill ] = useState( 0 );
    const [ files, setFiles ] = useState( [] );
    const theme = useMantineTheme();
    const reportRef = createRef();


    const { mutateAsync: createReport, isLoading } = useMutation( ( data ) => putLabReport( data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    } ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                setFiles( [] );
                setReport( '' );
                setBill( 0 );
                onDone( report, bill );
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );


    const submitReport = () => {


        if ( bill <= 0 )
            setBill( 0 );
        // message.error( 'bill is required' );
        // return;

        if ( _.isEmpty( report ) ) {
            message.error( 'report is required!' );
            // reportRef.current.focus = true;
            return;
        }

        smalltalk.confirm(
            "Submit Report", "Submitting a laboratory report cannot be undone. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            const formData = new FormData();
            files.map( ( file, i ) => formData.append( "attachments", file ) );
            formData.append( 'report', report );
            formData.append( 'bill', parseFloat( bill ) );
            formData.append( 'consultationId', consultation.id );

            createReport( formData );
            // createReport( { files: formData, report, bill, consultationId: consultation.id } );
        } ).catch( ex => {
            return false;
        } );
    };


    const removeImage = ( name ) => {
        setFiles( files.filter( f => f.name !== name ) );
    };


    /**
     * 
     * @param {array} f an array of files uploaded
     * @returns {void}
     */
    const uploadFiles = ( f ) => {
        if ( f.length > 3 ) {
            message.error( "Only 3 files are allowed" );
            return;
        }

        // const mimeTypes = [ 'jpg', 'jpeg', 'jfif', 'png', 'pdf' ];
        // files.map( f => {
        //     if ( !f.type.includes( mimeTypes ) ) {
        //         message.error( 'unknown file type included' );
        //         return;
        //     }
        // } );

        setFiles( f );

    };


    const previews = files.map( ( file, index ) => {
        const imageUrl = URL.createObjectURL( file );
        return (
            <div className="col-12 me-3 g-0 border" style={ {
                maxHeight: "150px",
                maxWidth: "150px",
                overflow: "hidden"
            } }
                title={ file.name }
            >
                <button className="app-btn btn-text text-danger p-1 d-flex"
                    onClick={ () => removeImage( file.name ) }
                >
                    <IconTrash size="sm" />
                    <small className="tag is-light" style={ { zIndex: "100" } }>{ file.name }</small>
                </button>
                {
                    file.type === 'application/pdf' ?
                        <Image src={ pdfIcon } height="120px" />
                        :
                        <Image
                            key={ index }
                            src={ imageUrl }
                            imageProps={ { onLoad: () => URL.revokeObjectURL( imageUrl ) } }
                        />
                }

            </div>
        );
    } );

    return (
        <div>
            <kbd className="bg-danger">NOTE: report submitted is not editable</kbd>
            <i className="d-block mt-1">Any previous reports will be replaced with new submissions!</i>
            <Divider />
            <div className="row">
                <div className="col-12">
                    <label className="mb-0" htmlFor="bill">
                        Laboratory Bill
                        {/* <RequiredIndicator /> */ }
                    </label>
                    <input
                        onChange={ ( e ) => setBill( e.target.value ) }
                        value={ bill }
                        placeholder="x.xx" min={ 0 } type="number" step="0.01"
                        className="input mb-3" />
                </div>
            </div>
            <div className="row">
                <div className="col-7">
                    <label className="mb-0" htmlFor="firstName">
                        Lab Report
                        <RequiredIndicator />
                    </label>
                    <textarea className="textarea"
                        ref={ reportRef }
                        value={ report }
                        rows={ 8 }
                        onChange={ e => setState( setReport, e.target.value ) }
                        required id="reportText" name="report" placeholder="type report here"></textarea>
                </div>
                {/* <input type="file" name="reports" id="lab_reports" onChange={ ( files ) => uploadFiles( files ) } multiple /> */ }

                <div className="col-5">
                    {/* <Dropzone
                        // onChange={ () => document.getElementById( "lab_reports" ).click() }
                        onDrop={ ( files ) => uploadFiles( files ) }
                        onReject={ ( files ) => message.error( `${ files.length } file(s) rejected` ) }
                        maxSize={ 3 * 1024 ** 2 }
                        className="mt-4"

                        accept={ {
                            "image/*": [ 'jpg', 'jpeg', 'jfif', 'png' ],
                            "application/pdf": [ 'pdf' ]
                        } }
                        { ...props }
                    >
                        <kbd>{ files.length } files uploaded</kbd>
                        <Group position="center" spacing="xl" style={ { minHeight: 50, pointerEvents: 'none' } }>
                            <Dropzone.Idle>
                                <IconPhoto size={ 40 } stroke={ 1.5 } />
                            </Dropzone.Idle>
                            <p className="my-1">
                                Attach jpg, png or pdf files (3 files max). Each file must not exceed <strong>5MB</strong>
                            </p>
                        </Group>
                    </Dropzone> */}
                </div>
            </div>
            <div className="row mx-auto mt-3">
                { previews }
            </div>
            <Divider />
            <button className={ `button is-link mt-3 ${ isLoading && ' is-loading' }` } onClick={ submitReport }>Submit</button>

        </div>
    );
}
