import {
  Space,
  Table,
  Select,
  message,
} from "antd";

import { format, isToday, isYesterday } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  deleteConsultation,
} from "../../../helpers/api";
import { consultationColorCoding, hasPermission, percentage, setState } from "../../../helpers/utilities";
import ConsultationDetail from "./consultation-detail";
import { Menu, Modal } from "@mantine/core";
import { action, resouces } from "../../../helpers/auth";
import smalltalk from 'smalltalk';
import { RatingDisplay } from "../../../components";
import { IconDotsCircleHorizontal, IconEye, IconTarget, IconTrash } from "@tabler/icons";
import CarePlansComponent from "./careplans";

const ConsultationComponent = ( { consultations, onReload, isloading } ) => {

  // states
  const [ selected, setSelected ] = useState( null );
  const [ filteredData, setFilteredData ] = useState( [] );
  const [ modal, setModal ] = useState( {
    isOpen: false,
    title: '',
    content: null,
    size: 500,
    zIndex: 50,
  } );


  // consts
  const { Option } = Select;


  // const handleDateFilter = ( data ) => {
  //   if ( data === null ) {
  //     handleReset();
  //     return;
  //   }

  //   const sdate = format( new Date( data[ 0 ]._d ), "yyy-MM-dd" );
  //   const edate = format( new Date( data[ 1 ]._d ), "yyy-MM-dd" );

  //   setFilteredData(
  //     consultations.filter( ( d ) => format( new Date( d.createdAt ), "yyy-MM-dd" ) >= sdate && format( new Date( d.createdAt ), "yyy-MM-dd" ) <= edate )
  //   );
  // };

  const handleReset = () => {
    setState(
      setFilteredData,
      consultations
    );
  };

  const handleDeleteConsultation = ( id ) => {
    smalltalk.confirm(
      "Delete Consultation", "Deleting this consultation cannot be undone. Continue?", {
      buttons: {
        ok: 'YES',
        cancel: 'NO',
      },
    }
    ).then( go => {
      message.info( 'deleting...' );
      deleteConsultation( id ).then( res => {
        if ( res.status === 204 ) {
          message.success( 'done!' );
          onReload();
          return;
        }
      } ).catch( ex => {
        message.error( ex.data.message );
      } );
    } )
      .catch( ex => {
        return false;
      } );
  };


  // consultation table
  const columns = [
    {
      title: <span className="bi bi-star-fill text-warning" />,
      sorter: ( a, b ) => a?.feedback?.rating > b?.feedback?.rating,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        <RatingDisplay rate={ record.feedback?.rating } />,
    },
    {
      title: "Date",
      sorter: ( a, b ) => a?.createdAt > b?.createdAt,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        <p>
          {
            isToday( new Date( record?.createdAt ) ) ? "Today " :
              isYesterday( new Date( record?.createdAt ) ) ? "Yesterday " :
                format( new Date( record?.createdAt ), "MMM dd, YYY" )
          }
        </p>,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: ( a, b ) => a.code > b.code,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        <p className="me-2">
          <kbd className={ consultationColorCoding( record?.completedTasks, record?.totalTasks ) }>
            { record?.code }
          </kbd>
          <span className="ms-1">- { percentage( record?.completedTasks, record?.totalTasks ) }%</span>
        </p>
    },
    {
      title: "Folder Number",
      sorter: ( a, b ) => a?.folderNumber > b?.folderNumber,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        record?.folderNumber || '-',
    },
    {
      title: "Patient",
      sorter: ( a, b ) => a?.patient?.firstName > b?.patient?.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        `${ record?.patient?.firstName }  ${ record?.patient?.lastName }`,
    },
    {
      title: "Contact",
      sorter: ( a, b ) => a?.patient?.contact > b?.patient?.contact,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        record?.patient?.contact,
    },
    // {
    //   title: "Doctor",
    //   dataIndex: "contact",
    //   sorter: ( a, b ) => a?.doctor?.firstName > b?.doctor?.lastName,
    //   sortDirections: [ "descend", "ascend" ],
    //   render: ( text, record, index ) =>
    //     `${ record?.doctor?.firstName }  ${ record?.doctor?.lastName }`,
    // },
    {
      title: "-",
      width: 70,
      render: ( text, record, index ) => (
        <Menu shadow="md" width={ 150 } >
          <Menu.Target>
            <button className="button app-btn is-ghost">
              <IconDotsCircleHorizontal />
            </button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={ () => {
                setSelected( record.id );
                setModal( {
                  content: <ConsultationDetail
                    refresh={ onReload }
                    consultationId={ record.id }
                  />,
                  isOpen: true,
                  title: 'Consultation',
                  size: 900,
                  zIndex: 50
                } );
              } }
              className="fw-bold"
              color="blue">
              <IconEye size={ 20 } color="teal" className="me-2" />
              Open
            </Menu.Item>
            <Menu.Item
              onClick={ () => setModal( {
                content: <CarePlansComponent consultationId={ record.id } />,
                isOpen: true,
                title: 'Care Plans',
                size: 700,
                zIndex: 50
              } ) }
            >
              <IconTarget size={ 20 } color="gray" className="me-2" />
              Care Plan
            </Menu.Item>
            {
              hasPermission( resouces.consultations, action.canDelete ) &&
              <Menu.Item
                onClick={ () => handleDeleteConsultation( record.id ) }
                color="red">
                <IconTrash size={ 20 } color="red" className="me-2" />
                Delete
              </Menu.Item>
            }
          </Menu.Dropdown>
        </Menu>
      ),
    },
  ];

  useEffect( () => {
    setFilteredData( consultations );
  }, [ consultations ] );

  return (
    <>
      <Modal
        onClose={ () => setModal( { ...modal, isOpen: false } ) }
        opened={ modal.isOpen }
        title={ modal.title }
        size={ modal.size }
        zIndex={ modal.zIndex }
      >
        { modal.content }
      </Modal>
      <div className="my-3 d-flex justify-content-between">
        <Space className="ms-0">
          <input
            className="input"
            onChange={ ( e ) =>
              setFilteredData(
                consultations.filter(
                  ( s ) =>
                    s?.patient?.firstName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    s?.patient?.lastName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    s?.code
                      ?.toLowerCase()
                      ?.includes( e.target.value.toLowerCase() ) ||
                    s?.patient?.contact
                      ?.toLowerCase()
                      ?.includes( e.target.value.toLowerCase() ) ||
                    s?.folderNumber
                      ?.toLowerCase()
                      ?.includes( e.target.value.toLowerCase() )
                )
              )
            }
            style={ { minWidth: "30vw" } }
            title="search by patient name, code, folder number or contact"
            placeholder="search by patient name, code, folder number or contact"
          />

          <Select
            style={ { width: "150px" } }
            size="large"
            name="byType"
            allowClear
            showSearch
            onChange={ ( v ) =>
              !v ?
                setFilteredData( consultations ) :
                setFilteredData(
                  filteredData.filter( ( d ) => d.type === v )
                )
            }
            defaultValue="Consultation type"
          >
            { [ ...new Set( filteredData.map( ( d ) => d.type ) ) ].map(
              ( v ) => (
                <Option value={ v } key={ v }>
                  { _.capitalize( v ) }
                </Option>
              )
            ) }
          </Select>
          {/* <RangePicker name="date-range" onChange={ handleDateFilter } /> */ }
        </Space>
        <div className="d-flex">
          <button
            onClick={ handleReset }
            className="app-btn btn-text ms-2 d-flex align-items-center"
          >
            <span className="bi bi-arrow-clockwise me-2"></span>
            <span className="d-none d-md-inline"> Reset filters</span>
          </button>
          <button className="app-btn"
            onClick={ onReload }
          >
            <span className="bi bi-cycle"></span>
            <span className="d-none d-md-inline">Refresh</span>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Table
            columns={ columns }
            rowClassName={ ( record, index ) =>
              `${ record.id === selected && " selected-item " }`
            }
            className="border"
            dataSource={ filteredData }
            rowKey={ ( record ) => record.id }
            loading={ isloading }
          />
        </div>
      </div >
    </>
  );
};

export default ConsultationComponent;;
