import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Modal,
} from "@mantine/core";
import { message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyRightTag, PageTitle } from "../../components";
import { postExtLogin } from "../../helpers/api";
import { appLinks, constants, termsandConditions } from "../../helpers/config";
import extAuth from "../../helpers/extAuth";
import logo from '../../static/img/riviaos-logo.png';

export function ExternalLoginPage () {
  const [ login, setLogin ] = useState( {
    email: "",
    password: "",
    tnc: false,
  } );
  const [ busy, setBusy ] = useState( false );
  const [ modelOpen, setOpenModal ] = useState( false );
  const nav = useNavigate();

  const handleLogin = ( e ) => {
    e.preventDefault();

    if ( !login.password || !login.email ) {
      message.info( "all fields are required" );
      return;
    }

    if ( !login.tnc ) {
      message.error( "You must accept the terms and conditions" );
      return;
    }

    setBusy( true );
    postExtLogin( login )
      .then( ( res ) => {

        // console.log( res );
        // console.log( res.data.data.role );

        if ( res.status === 200 ) {
          extAuth.authenticate( res.data.token, res.data.data );
          extAuth.setRole( res.data.data.role );
          // 

          if ( res.data.data.role === 'laboratory' ) {
            nav( appLinks.exterals.laboratory, { replace: true } );
          } else {
            nav( appLinks.exterals.pharmacy, { replace: true } );
          }
        };

        throw res;
      } )
      .catch( ( ex ) => {
        message.error( ex.response.data.message );
      } )
      .finally( () => {
        setBusy( false );
      } );
  };

  return (
    <Container size={ 420 } my={ 20 }>
      <PageTitle title="Login" />
      {/* <Title
        align="center"
        sx={ ( theme ) => ( {
          fontFamily: `Greycliff CF, ${ theme.fontFamily }`,
          fontWeight: 900,
        } ) }
      >
        { constants.siteTitle }
      </Title> */}
      <div className="text-center">
        <img src={ logo } alt="riviaos logo" width={ 200 } />
      </div>
      <p className="p-2 rounded text-center app-bg-default ">
        <strong>Service Provider Portal</strong>
      </p>
      <Text color="dimmed" size="sm" align="center" mt={ 5 }>
        Login to continue
        {/* <Anchor href="#" size="sm" onClick={ ( event ) => event.preventDefault() }>
                    Create account
                </Anchor> */}
      </Text>

      <Paper withBorder shadow="md" p={ 30 } mt={ 20 } radius="md">
        <form onSubmit={ handleLogin } method="POST">
          <TextInput
            autoFocus
            onChange={ ( e ) => setLogin( { ...login, email: e.target.value } ) }
            value={ login.email }
            label="Email"
            size="md"
            placeholder="email here"
            required
          />
          <PasswordInput
            onChange={ ( e ) => setLogin( { ...login, password: e.target.value } ) }
            value={ login.password }
            label="Password"
            size="md"
            placeholder="Your password"
            required
            mt="md"
          />
          <Group position="apart" mt="md">
            {/* <Checkbox label="Remember me" /> */ }
            <Anchor href={ appLinks.exterals.passwordReset } size="sm">
              Forgot password
            </Anchor>
          </Group>
          <button
            onClick={ handleLogin }
            className={ `button mt-3 h6 app-btn btn-prim py-3 px-2 w-100  ${ busy && "is-loading"
              }` }
          >
            Sign in
          </button>
        </form>
        <Checkbox
          value={ login.tnc }
          onChange={ () => setLogin( { ...login, tnc: !login.tnc } ) }
          className="mt-2" label={ <>
            I Accept the
            <a className="pt-2" onClick={ () => setOpenModal( true ) }> terms and conditions</a>
          </> } />
        {/* <a className="pt-2" onClick={ () => setOpenModal( true ) }>Terms and conditions</a> */ }
      </Paper>
      <div className="text-center">
        <CopyRightTag />
      </div>

      <Modal
        title={ <strong>Rivia Terms and Conditions</strong> }
        opened={ modelOpen }
        onClose={ () => setOpenModal( false ) }>
        <p>
          { termsandConditions }
        </p>
      </Modal>
    </Container>
  );
}
