import { Divider, Input, Select, Table } from "antd";
import { PageHeader } from "../../components";
import { useQuery } from "react-query";
import { getAllLogs } from "../../helpers/api";
import { useEffect, useState } from "react";
import { format, isToday, isYesterday } from "date-fns";
import { Chip, Space } from "@mantine/core";
import _ from "lodash";
import { getUser } from "../../helpers/auth";

const LogsPage = ( props ) => {

    const [ filteredData, setFilteredData ] = useState( [] );

    const { data: logs, isFetching, refetch } = useQuery( {
        queryFn: () => getAllLogs( getUser().branchId ),
        queryKey: [ 'activityLogs' ],
        onSuccess: data => setFilteredData( data )
    } );

    // const { Search } = Input;
    const { Option } = Select;

    // consultation table
    const columns = [
        {
            dataIndex: 'createdAt',
            title: 'Date',
            sortable: true,
            width: 150,
            render: ( text, record, index ) =>
                `${ isToday( new Date( record?.createdAt ) ) ? "Today " :
                    isYesterday( new Date( record?.createdAt ) ) ? "Yesterday " :
                        format( new Date( record?.createdAt ), "MMM dd, YYY" )
                }`,
        },
        {
            title: 'Time',
            sortable: true,
            width: 150,
            render: ( text, record, index ) => new Date( record?.createdAt ).toLocaleTimeString()
        },
        {
            title: 'User',
            sortable: true,
            width: 200,
            render: ( text, record, index ) => `${ record?.user?.staff?.firstName } ${ record?.user?.staff?.lastName }`
        },
        {
            dataIndex: 'description',
            title: 'Description',
            sortable: true,
            // width: 350
        },
        // {
        //     dataIndex: 'department',
        //     title: 'Department',
        //     sortable: true,
        //     width: 150
        // },
    ];


    return (
        <section className="logs-page">
            <PageHeader title="Logs" description="view all system level activity logs" hasBackButton />
            <Divider className="mt-2" />
            <>
                <Chip><strong>{ filteredData.length || 0 }</strong> Activity Logs</Chip>
                <div className="d-flex justify-content-between my-3">
                    <input
                        className="input w-50"
                        onChange={ ( e ) =>
                            setFilteredData(
                                !e.target.value ?
                                    logs :
                                    logs.filter(
                                        ( s ) =>
                                            s.user?.staff?.firstName
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            s.user?.staff?.lastName
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            s?.description
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() ) ||
                                            format( new Date( s?.createdAt ), "MMM dd, YYY" )
                                                .toLowerCase()
                                                .includes( e.target.value.toLowerCase() )
                                    )
                            )
                        }
                        title="search here"
                        placeholder="search by user, description or date"
                    />
                    <button className="button app-btn"
                        onClick={ () => refetch() }
                    >
                        <span className="bi bi-cycle"></span>
                        <span className="d-none d-md-inline">Refresh</span>
                    </button>
                </div>


                <div className="row">
                    <div className="col-12">
                        <Table
                            className="border"
                            bordered
                            columns={ columns }
                            dataSource={ filteredData }
                            rowKey={ ( record ) => record.id }
                            loading={ isFetching }
                        />
                    </div>
                </div >
            </>

        </section>
    );
};

export { LogsPage };;;;;