import { message, Select, Tabs } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { postNewPharmacy } from "../../../helpers/api";
import { Regions } from "../../../helpers/config";
import { dropDownSet } from "../../../helpers/utilities";
import { IndicatorInfo, RequiredIndicator } from "../../../components";
import _ from "lodash";

const NewPharmacyForm = ( { onSuccess, onError } ) => {
  const { handleSubmit, register, reset } = useForm();
  const [ dropValue, setDropValues ] = useState( {
    region: "",
  } );

  const { mutateAsync, isLoading } = useMutation( ( data ) => postNewPharmacy( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onSuccess();
        setDropValues( { region: "" } );
        reset();
        return;
      }


      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;

      if ( _.isArray( error.response.data.message ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      onError();
    },
  } );

  const handleCreate = async ( data ) => {
    mutateAsync( { ...data, ...dropValue } );
  };

  const { TabPane } = Tabs;


  return (
    <form onSubmit={ handleSubmit( handleCreate ) }>
      <IndicatorInfo className="p-0" />
      <div className="row p-3">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Basic" key="1">
            <div className="field col-12">
              <label className="mb-0" htmlFor="pharmacyName">
                Pharmacy Name
                <RequiredIndicator />
              </label>
              <input
                className="input"
                type="text"
                autoFocus
                { ...register( "pharmacyName", { required: true } ) }
                id="pharmacyName"
                placeholder="pharmacy name here"
              />
              <small>registered pharmacy name</small>
            </div>
            <div className="field col-12">
              <label className="mb-0" htmlFor="contactPerson">
                Contact Person
                <RequiredIndicator />
              </label>
              <input
                className="input"
                type="text"
                { ...register( "contactPerson", { required: true } ) }
                id="contactPerson"
                placeholder="name of person"
              />
              <small>who is the primary contact person</small>
            </div>
            <div className="field col-12">
              <label className="mb-0" htmlFor="contact">
                Contact
                <RequiredIndicator />
              </label>
              <input
                className="input"
                type="tel"
                { ...register( "contact", { required: true } ) }
                id="contact"
                placeholder="contact here"
              />
              <small>primary contact of pharmacy</small>
            </div>
            <div className="field col-12">
              <label className="mb-0" htmlFor="email">
                Email
              </label>
              <input
                className="input"
                type="email"
                { ...register( "email" ) }
                id="email"
                placeholder="email here"
              />
              <small>email address of pharmacy</small>
            </div>
          </TabPane>
          <TabPane tab="Location" key="2">
            <div className="field col-12">
              <label className="mb-0" htmlFor="location">
                Location
              </label>
              <input
                className="input"
                type="text"
                { ...register( "location" ) }
                id="location"
                placeholder="pharmacy location here"
              />
              <small>physical location of pharmacy</small>
            </div>
            <div className="field col-12">
              <label className="mb-0" htmlFor="region">
                Region
                <RequiredIndicator />
              </label>
              <Select
                value={ dropValue.region }
                required
                onChange={ ( v ) =>
                  dropDownSet( "region", v, dropValue, setDropValues )
                }
                autoFocus
                size="large"
                id="region"
                className="d-block"
                placeholder="select region"
              >
                { Regions }
              </Select>
              <small>region where pharmacy is located</small>
            </div>
          </TabPane>
        </Tabs>

        <button
          type="submit" id="submit" className={ `button app-btn h6 btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
          <span className="bi bi-save me-2" />
          Save
        </button>
      </div>
    </form>
  );
};

export default NewPharmacyForm;
