import { message, Select, Segmented } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { CharmBar } from "../../../components";
import { getAllLaboratories, getAllPharmacies, getAllStaffs, getFacilityBranches, postSignup } from "../../../helpers/api";
import { action, getUser, resouces } from "../../../helpers/auth";
import { dropDownSet, hasPermission } from "../../../helpers/utilities";
import { MultiSelect } from "@mantine/core";

const dropTemp = {
  staffId: null,
  pharmacyId: null,
  laboratoryId: null,
  branches: [],
  role: "",
  category: "internal",
};

const passwordMsg = "A random password will be auto generated and sent to the provided email address";


const NewUserForm = ( { onSuccess, onError, onExit } ) => {
  const { handleSubmit, register, reset } = useForm();
  const [ dropValue, setDropValues ] = useState( dropTemp );

  const [ shwPwd, setShwPwd ] = useState( false );

  const [ segment, setSeg ] = useState( 'staff' );
  const { Option } = Select;


  const { data: staffs = [] } = useQuery( {
    queryFn: getAllStaffs,
    queryKey: [ 'staffs' ],
  } );

  const { data: branches = [] } = useQuery( {
    queryFn: () => getFacilityBranches( getUser().facility.id ),
    queryKey: [ 'branches' ],
  } );

  const { data: pharms = [] } = useQuery( {
    queryFn: getAllPharmacies,
    queryKey: [ 'pharmacies' ],
  } );

  const { data: labs = [] } = useQuery( {
    queryFn: getAllLaboratories,
    queryKey: [ 'laboratories' ],
  } );




  const { mutateAsync: createUser, isLoading, reset: qryReset } = useMutation( ( data ) => postSignup( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onSuccess();
        setDropValues( dropTemp );
        reset();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      qryReset();
      const err = error.response.data.message;

      if ( _.isArray( error.response.data.message ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }

      onError();
    },
  } );


  const handleCreate = async ( data ) => {
    if ( segment === "staff" && dropValue.branches.length === 0 ) {
      message.error( 'assign a branch to user' );
      return;
    }

    createUser( { ...data, ...dropValue } );
  };


  const handleSegmentChange = ( value ) => {

    if ( value === 'staff' )
      setDropValues( {
        ...dropValue,
        role: 'nurse',
        category: 'internal'
      } );

    if ( value === 'pharm' )
      setDropValues( {
        ...dropValue,
        role: 'pharmacy',
        category: 'external'
      } );

    if ( value === 'lab' )
      setDropValues( {
        ...dropValue,
        role: 'laboratory',
        category: 'external'
      } );


    setSeg( value );
  };



  return (
    <div>
      <form onSubmit={ handleSubmit( handleCreate ) }>
        <CharmBar>
          <button className={ `button app-btn btn-prim me-2 
          ${ isLoading && 'is-loading' }` } type="submit"
          >
            Add Account
          </button>
          <button className="button app-btn" onClick={ onExit }>
            Cancel
          </button>
        </CharmBar>
        <div>
          <kbd className="bg-danger">All fields are required</kbd>
          {
            segment !== "staff" &&
            <kbd className="d-block mt-2 mb-0">{ passwordMsg }</kbd>
          }
        </div>
        <Segmented
          onChange={ handleSegmentChange }
          className="my-3"
          defaultValue="staff"
          options={ [
            {
              label: "Staff",
              value: "staff",
            },
            {
              label: "Pharmacy",
              value: "pharm",
            },
            {
              label: "Laboratory",
              value: "lab",
            },
          ] }
        />

        {
          segment === "staff" &&
          <div className="field col-md-6 col-12">
            <label className="mb-0" htmlFor="staffId">
              Staff
            </label>
            <Select
              required
              showSearch
              allowClear
              autoFocus
              onChange={ ( v ) =>
                dropDownSet( "staffId", v, dropValue, setDropValues )
              }
              size="large"
              id="staffId"
              className="d-block"
              placeholder="select staff"
            >
              { staffs.map( ( st ) => (
                <Option
                  value={ st.id }
                  key={ st.id }
                >{ `${ st.firstName } ${ st.lastName }` }</Option>
              ) ) }
            </Select>
            <small>staff associated with account</small>
          </div>
        }
        {
          segment === "pharm" &&
          <div className="field col-md-6 col-12">
            <label className="mb-0" htmlFor="pharmacyId">
              Pharmacy
            </label>
            <Select
              required
              showSearch
              allowClear
              // value={ dropValue.pharmacyId }
              onChange={ ( v ) =>
                dropDownSet( "pharmacyId", v, dropValue, setDropValues )
              }
              autoFocus
              size="large"
              id="pharmacyId"
              className="d-block"
              placeholder="select pharmacy"
            >
              { pharms.map( ( ph ) => (
                <Option
                  value={ ph.id }
                  key={ ph.id }
                >{ `${ ph.pharmacyName } - ${ ph.location }` }</Option>
              ) ) }
            </Select>
            <small>pharmacy associated with account</small>
          </div>
        }
        {
          segment === "lab" &&

          <div className="field col-md-6 col-12">
            <label className="mb-0" htmlFor="laboratoryId">
              Laboratory
            </label>
            <Select
              required
              showSearch
              allowClear
              // value={ dropValue.pharmacyId }
              onChange={ ( v ) =>
                dropDownSet( "laboratoryId", v, dropValue, setDropValues )
              }
              autoFocus
              size="large"
              id="laboratoryId"
              className="d-block"
              placeholder="select laboratory"
            >
              { labs.map( ( lab ) => (
                <Option
                  value={ lab.id }
                  key={ lab.id }
                >{ `${ lab.laboratoryName } - ${ lab.location }` }</Option>
              ) ) }
            </Select>
            <small>laboratory associated with account</small>
          </div>

        }
        {
          segment === "staff" &&
          <>
            <div className="field col-md-6 col-12">
              <label className="mb-0" htmlFor="role">
                Role
              </label>
              <Select
                required
                value={ dropValue.role }
                onChange={ ( v ) => dropDownSet( "role", v, dropValue, setDropValues ) }
                size="large"
                id="role"
                className="d-block"
                placeholder="select staff"
              >
                {
                  hasPermission( resouces.admin, action.canCreate ) &&
                  <Option value="admin" key="admin">
                    Admin
                  </Option>
                }
                <Option value="doctor" key="doctor">
                  Medical Doctor
                </Option>
                <Option value="nurse" key="nurse">
                  Nurse
                </Option>
                <Option value="frontdesk" key="frontdesk">
                  Front Desk
                </Option>
                {/* <Option value="agent" key="agent">
                Agent
              </Option> */}
                {/* <Option value="pharmacy" key="pharmacy">
                Pharmacy
              </Option>
              <Option value="laboratory" key="laboratory">
                Laboratory
              </Option> */}
                <Option value="partner" key="partner">
                  Partner
                </Option>
                {/* <Option value="Stakeholder" key='3-party'>Stakeholder</Option> */ }
              </Select>
              <small>Role for user</small>
            </div>
            <div className="field col-md-6 col-12">
              <label htmlFor="branches">
                Assigned Branches
              </label>
              <MultiSelect
                id='branches'
                size="md"
                required
                onChange={ ( v ) => dropDownSet( "branches", v, dropValue, setDropValues ) }
                value={ dropValue.branches }
                placeholder='select branches user will have access to'
                data={
                  branches.map( ol => {
                    return {
                      value: ol.id,
                      label: ol.branchName
                    };
                  } )
                }
              />
              <small>user will have access to the assigned branche's data</small>
            </div>
          </>
        }
        {/* <div className="field col-md-6 col-12">
          <label className="mb-0" htmlFor="username">
            Username
          </label>
          <input
            className="input"
            type="text"
            {...register("username", { required: true })}
            id="username"
            placeholder="username"
          />
          <small>username for account</small>
        </div> */}
        {
          segment !== "staff" &&

          <>
            <div className="field col-md-6 col-12">
              <label className="mb-0" htmlFor="fullname">
                Full Name
              </label>
              <input
                className="input"
                type="text"
                { ...register( "fullName", { required: true } ) }
                id="fullname"
                placeholder="fullname"
              />
              <small>Fullname of account owner (staff)</small>
            </div>
            <div className="field col-md-6 col-12">
              <label className="mb-0" htmlFor="contact">
                Phone Number
              </label>
              <input
                className="input"
                type="tel"
                { ...register( "contact", { required: true } ) }
                id="contact"
                placeholder="contact"
              />
              <small>phone number of staff without +233 & spaces (e.g. 0200000000)</small>
            </div>
          </>

        }
        <div className="row my-3">
          <div className="field col-md-6 col-12">
            <label className="mb-0" htmlFor="email">
              Account Email
            </label>
            <input
              className="input"
              type="email"
              { ...register( "email", { required: true } ) }
              id="email"
              placeholder="account email address"
            />
            <small>
              password reset, update and other details will be sent here
            </small>
          </div>

        </div>
        {
          segment === "staff" &&
          <div className="row my-3">
            <div className="field col-md-6 col-12">
              <label className="mb-0" htmlFor="password">
                Password
              </label>
              <input
                className="input"
                type={ shwPwd ? "text" : "password" }
                { ...register( "password", { required: true } ) }
                id="password"
                placeholder="password"
              />
              <small>account password here</small>
              <button className="button btn-text p-2 d-block"
                onClick={ ( e ) => {
                  e.preventDefault();
                  setShwPwd( !shwPwd );
                } }
              >show password</button>
            </div>
          </div>
        }
      </form>
    </div >
  );
};

export default NewUserForm;
