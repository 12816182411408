import { Divider, message } from 'antd';
import { Loader } from '@mantine/core';
import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { getExcuseDuty } from '../../helpers/api';
import { differenceInDays } from 'date-fns';
import { useReactToPrint } from "react-to-print";
import { getUser } from '../../helpers/auth';
import { usePDF } from "react-to-pdf";


export function ExcuseDutyDisplay ( { code, excuseDutyId } ) {

    const { data = {}, isFetching, isError } = useQuery( {
        queryFn: () => getExcuseDuty( code, excuseDutyId ),
        queryKey: [ 'excuse-duty', excuseDutyId ],
    } );

    const printArea = useRef();
    const { toPDF, targetRef } = usePDF( { filename: `excuse-duty-${ code }.pdf` } );


    const handlePrint = useReactToPrint( {
        content: () => printArea.current,
        documentTitle: "excuse duty - " + code,
        copyStyles: true,
        // bodyClass: "sale-receipt"
    } );



    return (
        <div>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        <>
                            <div className="buttons has-addons mt-3">
                                <button id="submit" className="button app-btn btn-prim h6"
                                    onClick={ () => {
                                        message.info( 'downloading...' );
                                        toPDF();
                                    } }
                                >
                                    <span className="bi bi-file-pdf me-2"></span>
                                    Download PDF
                                </button>
                                <button id="submit" className="button app-btn h6" onClick={ handlePrint }>
                                    <span className="bi bi-printer me-2"></span>
                                    Print
                                </button>
                            </div>
                            <Divider />
                            <div id="printRegion" ref={ printArea }>
                                <div ref={ targetRef }>
                                    <div className="row app-bg-default">
                                        <div className="col-12 p-5 pb-0">
                                            <h1 className='mb-0 pb-0'>{ getUser()?.facility?.facilityName }</h1>
                                            {/* <p className='mt-0 pt-1'>
                                            { getUser().facility?.address }
                                        </p> */}
                                            <Divider />
                                            <div className="mt-2">
                                                <h5 className='mb-0'>EXCUSE DUTY</h5>
                                                <p>Consultation tracking code is: <kbd>{ code }</kbd></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Divider className='mb-0' /> */ }
                                    <div className="p-5">
                                        <div className="row">
                                            <div className="col-3"><strong>Patient Name:</strong></div>
                                            <div className="col-7">{ `${ data?.patient?.firstName } ${ data?.patient?.lastName }` }</div>
                                        </div>
                                        <Divider />
                                        <div className="row">
                                            <div className="col-3"><strong>Duration:</strong></div>
                                            <div className="col-7">
                                                { new Date( data?.startDate ).toDateString() } -  { new Date( data?.endDate ).toDateString() }
                                                <span className="ms-2"> ({ differenceInDays( new Date( data?.endDate ), new Date( data?.startDate ) ) } days)</span>
                                            </div>
                                        </div>
                                        <Divider />

                                        <div className="row">
                                            <div className="col-3"><strong>Diagnosis:</strong></div>
                                            <div className="col-7">{ data?.diagnosis }</div>
                                        </div>
                                        <Divider />
                                        <div className="row">
                                            <div className="col-3"><strong>Doctor's Comments:</strong></div>
                                            <div className="col-7">{ data?.comment }</div>
                                        </div>
                                        <Divider />
                                        <div className="row">
                                            <div className="col-3"><strong>Doctor-In-Charge:</strong></div>
                                            <div className="col-7"><strong>{ `${ data?.doctor?.firstName } ${ data?.doctor?.lastName }` }</strong></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">Contact:</div>
                                            <div className="col-7">{ data?.doctor?.contact }</div>
                                        </div>
                                        {/* <Divider /> */ }
                                        <div className="row">
                                            <div className="col-3">Date Created:</div>
                                            <div className="col-7">{ new Date( data?.createdAt ).toDateString() }</div>
                                        </div>
                                        <Divider />
                                        <div className="mt-4">
                                            <small>This excuse duty was generated from the RiviaOS Telemedicine platform on: </small>
                                            <small>{ new Date().toUTCString() }</small>
                                            <small className="d-block">for more information, visit { process.env.REACT_APP_WEBSITE }</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
            }
        </div >
    );
}
