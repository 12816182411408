import { Select } from "antd";
import { useEffect, useState } from "react";
import { Regions } from "../../../helpers/config";
import { Skeleton } from "@mantine/core";
import _ from "lodash";

const AddPharmacyComponent = ( { data, allPharmacies, onUpdate, onDelete } ) => {
    const { Option } = Select;
    const [ region, setRegion ] = useState( "" );
    // list  is the list of pharmacies pulled from the server
    useEffect( () => {
        setRegion( data.region );
    }, [] );

    return (
        <div className="row">
            {/* <div className="col-4">
                <Select
                    required
                    allowClear
                    size="medium"
                    showSearch
                    optionFilterProp="children"
                    filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                    filterSort={ ( optionA, optionB ) =>
                        optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                    }
                    value={ region }
                    onChange={ ( v ) => setRegion( v ) }
                    id="region" className="w-100" placeholder="Region"
                >
                    { Regions }
                </Select>
            </div> */}
            <div className="col-10">
                {
                    _.isEmpty( allPharmacies ) ?
                        <Skeleton>loading</Skeleton> :
                        <Select
                            required
                            allowClear
                            size="medium"
                            showSearch
                            optionFilterProp="children"
                            filterOption={ ( input, option ) => option.children.toLowerCase().includes( input.toLowerCase() ) }
                            filterSort={ ( optionA, optionB ) =>
                                optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
                            }
                            value={ data.id }
                            onChange={ ( v ) => onUpdate( data.recordId, v ) }
                            id="pharmacyId" className="w-100" placeholder="select pharmacy"
                        >
                            {
                                allPharmacies
                                    // .filter( pharma => pharma.region === region )
                                    .map( ph =>
                                        <Option value={ ph.id } key={ ph.id }>{ `${ ph.pharmacyName }  (${ ph.location } - ${ ph.contact })` }</Option>
                                    )
                            }
                        </Select>
                }
            </div>
            {
                !data?.isComplete &&
                <div className="col-1 g-0">
                    <button className='button is-small text-danger'
                        onClick={ () => onDelete( data.recordId ) }
                    >
                        <span className="bi bi-trash"></span>
                    </button>
                </div>
            }
        </div>
    );
};

export default AddPharmacyComponent;
