import { Table, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { hasPermission, percentage, setState } from "../../../helpers/utilities";
import { format, isToday, isYesterday } from "date-fns";
import { Modal } from "@mantine/core";
import ConsultationDetail from "../../schedule/components/consultation-detail";
import { action, resouces } from "../../../helpers/auth";

const ConsultationsComponent = ( { data, isLoading } ) => {
  const [ consultations, setConsultations ] = useState( [] );
  const [ filteredData, setFilteredData ] = useState( [] );

  const { Search } = Input;
  const { Option } = Select;

  const [ modal, setModal ] = useState( {
    title: "",
    open: false,
    content: "",
    size: 900
  } );

  useEffect( () => {
    setConsultations( data );
    setFilteredData( data );
  }, [ data ] );

  // sales table
  const columns = [
    {
      title: "Date",
      sorter: ( a, b ) => a.createdAt > b.createdAt,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        isToday( new Date( record.createdAt ) ) ? "Today " :
          isYesterday( new Date( record.createdAt ) ) ? "Yesterday " :
            format( new Date( record.createdAt ), "MMM dd, YYY" ),
    },
    {
      title: "code",
      dataIndex: "code",
      sorter: ( a, b ) => a.code > b.code,
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Folder Number",
      sorter: ( a, b ) => a?.folderNumber > b?.folderNumber,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        record?.folderNumber || '-',
    },
    {
      title: "Patient",
      sorter: ( a, b ) => a.patient.firstName > b.patient.firstName,
      sortDirections: [ "descend", "ascend" ],
      render: ( text, record, index ) =>
        `${ record.patient.firstName } ${ record.patient.lastName } `,
    },
    {
      title: "Type",
      sorter: ( a, b ) => a.type > b.type,
      dataIndex: "type",
      sortDirections: [ "descend", "ascend" ],
    },
    {
      title: "Progress",
      sorter: ( a, b ) => a.completedTasks > b.completedTasks,
      sortDirections: [ "descend", "ascend" ],
      render: ( t, r, i ) =>
        `${ percentage( r.completedTasks, r.totalTasks ) }%`
    },
    {
      title: "-",
      width: 100,
      render: ( text, record, index ) => (
        <Space>
          {
            hasPermission( resouces.consultations, action.canRead ) &&
            <button
              className="button app-btn is-ghost p-2 fw-bold"
              onClick={ () => {
                setModal( {
                  title: "Consultation Detail",
                  open: true,
                  size: 900,
                  content: <ConsultationDetail consultationId={ record.id } />,
                } );
              } }>
              <span className="bi bi-eye me-2"></span>
              view
            </button>
          }
        </Space>
      ),
    },
  ];

  return (
    <section>
      <Modal
        title={ modal.title }
        opened={ modal.open }
        size={ modal.size }
        onClose={ () => setModal( { ...modal, open: false } ) }
      >
        { modal.content }
      </Modal>
      <>
        <p>
          Showing <strong>{ filteredData?.length }</strong>{ " " }
          { filteredData?.length > 1 ? " consultations" : "consultation" }
        </p>

        <div className="my-1 d-flex justify-content-between">
          <Search
            onChange={ ( e ) =>
              setFilteredData(
                consultations.filter(
                  ( p ) =>
                    p?.code
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    p?.type
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    p?.patient.firstName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() ) ||
                    p?.patient.lastName
                      .toLowerCase()
                      .includes( e.target.value.toLowerCase() )
                  // p?.folderNumber
                  //   .toLowerCase()
                  //   .includes( e.target.value.toLowerCase() )
                )
              )
            }
            title="search by code, type, folder number or patient"
            placeholder="search by code, type, folder number or patient"
          />
          <Select
            style={ { minWidth: "150px" } }
            name="call type"
            className="mx-2"
            onChange={ ( v ) =>
              setFilteredData( consultations.filter( ( d ) => d.type === v ) )
            }
            defaultValue="consultation type"
          >
            { [ ...new Set( consultations.map( ( d ) => d.type ) ) ].map( ( v ) => (
              <Option value={ v } key={ v }>
                { v }
              </Option>
            ) ) }
          </Select>
          <button
            onClick={ () => setState( setFilteredData, consultations ) }
            className="app-btn btn-text d-flex align-items-center"
          >
            <span className="bi bi-arrow-clockwise me-2"></span>
            Reset
          </button>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <Table
              className="border"
              rowKey={ ( record ) => record.id }
              dataSource={ filteredData }
              columns={ columns }
              loading={ isLoading }
              bordered
            />
          </div>
        </div>
      </>
    </section>
  );
};

export default ConsultationsComponent;
