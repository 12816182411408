import { Loader } from "@mantine/core";
import { message } from "antd";
import _ from "lodash";
import { RequiredIndicator } from "../../../components";
import { useQuery, useMutation } from 'react-query';
import { getCareplanById, postCareplan, putCarePlan } from "../../../helpers/api";
import { useAtom } from "jotai";
import { carePlanAtom, clearCarePlanAtom } from "../../../helpers/state";
import { useEffect } from "react";

const EditCarePlan = ( { carePlanId, isNewPlan = false, onRefresh, consultationId, onPost } ) => {
    const [ state, setState ] = useAtom( carePlanAtom );
    const [ , clearPlan ] = useAtom( clearCarePlanAtom );

    const { isError, isFetching } = useQuery( {
        queryFn: () => getCareplanById( carePlanId ),
        queryKey: [ 'careplan', carePlanId ],
        onSuccess: data => setState( data ),
        enabled: !isNewPlan
    } );

    const { mutateAsync: updateCarePlan, isLoading } = useMutation( ( data ) => putCarePlan( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error?.response?.data?.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    const { mutateAsync: createCarePlan, isLoading: creatingPlan } = useMutation( ( data ) => postCareplan( { ...data, consultationId } ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                onRefresh();
                setState( {} );
                onPost();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    useEffect( () => {
        return () => !isNewPlan && clearPlan();
    }, [] );


    return (
        <>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    <>
                        <div className="row mb-1">
                            <div className="field">
                                <label className="mb-0" htmlFor="assessment">
                                    Assessment
                                    <RequiredIndicator />
                                </label>
                                <textarea
                                    className="textarea"
                                    rows={ 3 }
                                    value={ state.assessment }
                                    onChange={ ( e ) => setState( { ...state, assessment: e.target.value } ) }
                                    id="assessment"
                                    placeholder="collect and analyze objective and subjective data."
                                ></textarea>
                            </div>
                            <div className="field">
                                <label className="mb-0" htmlFor="diagnosis">
                                    Diagnosis
                                </label>
                                <textarea
                                    className="textarea"
                                    id="diagnosis"
                                    value={ state.diagnosis }
                                    onChange={ ( e ) => setState( { ...state, diagnosis: e.target.value } ) }
                                    required
                                    rows={ 3 }
                                    placeholder="based on collected data, create a nursing diagnosis to direct care planning"
                                ></textarea>
                            </div>
                            <div className="field">
                                <label className="mb-0" htmlFor="plan">
                                    Plan
                                </label>
                                <textarea
                                    className="textarea"
                                    rows={ 3 }
                                    value={ state.plan }
                                    onChange={ ( e ) => setState( { ...state, plan: e.target.value } ) }
                                    id="plan"
                                    placeholder="in collaboration with the patient, create goals and outcomes of care based on the nursing diagnosis"
                                ></textarea>
                            </div>
                            <div className="field">
                                <label className="mb-0" htmlFor="interventions">
                                    Interventions
                                </label>
                                <textarea
                                    className="textarea"
                                    rows={ 3 }
                                    id="interventions"
                                    value={ state.interventions }
                                    onChange={ ( e ) => setState( { ...state, interventions: e.target.value } ) }
                                    placeholder="taking actions to perform the interventions described in the care plan to achieve the goals of care"
                                ></textarea>
                            </div>
                            <div className="field">
                                <label className="mb-0" htmlFor="evaluation">
                                    Evaluation
                                </label>
                                <textarea
                                    className="textarea"
                                    rows={ 3 }
                                    id="evaluation"
                                    value={ state.evaluation }
                                    onChange={ ( e ) => setState( { ...state, evaluation: e.target.value } ) }
                                    placeholder="reassessing the patient to determine if the intervention has the desired outcome."
                                ></textarea>
                            </div>
                        </div>

                        {
                            isNewPlan ?
                                <button onClick={ () => createCarePlan( state ) }
                                    id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ creatingPlan && ' is-loading ' }` }>
                                    <span className="bi bi-plus-circle me-2" />
                                    Save Plan
                                </button> :
                                <button onClick={ () => updateCarePlan( state ) }
                                    id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                                    <span className="bi bi-save me-2" />
                                    Update Plan
                                </button>

                        }
                    </>
            }
        </>
    );
};

export default EditCarePlan;
