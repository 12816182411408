import _ from 'lodash';
import React from 'react';

import { CopyRightTag, PageTitle } from '../../components';
import logo from '../../static/img/riviaos-logo.png';
import GuestAttendanceRegistration from '../../components/shared/guest-attendance';


export const ExternalAttendancePage = () => {

    return (
        <div className='row portal'>
            <PageTitle title="Guest Attendance" />
            <div className="mx-auto col-md-6 col-11">
                <div className="text-center py-3">
                    <img src={ logo } alt="riviaos logo" width={ 200 } />
                </div>
                <div className="inner-layer border p-md-5 p-3">
                    <h3 className='text-center mb-5'>Guest Attendance</h3>
                    <GuestAttendanceRegistration />
                </div >
                <div className="row text-center my-3">
                    <CopyRightTag />
                </div>
            </div >
        </div >
    );
};
