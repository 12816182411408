import { Modal } from "@mantine/core";
import { message, Space, Table, Tooltip } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import smalltalk from 'smalltalk';
import { deletePharmacy } from "../../../helpers/api";
import { action, resouces } from "../../../helpers/auth";
import { hasPermission } from "../../../helpers/utilities";
import EditPharmacyForm from './edit-pharmacy';

const Pharmacies = ( { data, onReload, isLoading } ) => {
    const [ modal, setModal ] = useState( {
        title: "",
        open: false,
        content: "",
    } );

    const handleDeletePharmacy = ( id ) => {
        smalltalk.confirm(
            "Delete Pharmacy", "This action cannot be undone. Delete Pharmacy?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            deletePharmacy( id ).then( res => {
                if ( res.status === 204 ) {
                    message.success( 'pharmacy deleted' );
                    onReload();
                }
            } );
        } ).catch( ex => {
            // console.log(ex);
            return false;
        } );
    };

    useEffect( () => { }, [ data ] );

    return (
        <div className="row">
            <Modal
                title={ modal.title }
                opened={ modal.open }
                onClose={ () => setModal( { ...modal, open: false } ) }
            >
                { modal.content }
            </Modal>
            <div className="col-12">
                <Table
                    columns={ [
                        {
                            title: "#",
                            sorter: ( a, b ) => a.index > b.index,
                            sortDirections: [ "descend", "ascend" ],
                            render: ( text, record, index ) => ++index,
                            width: '70px'
                        },
                        {
                            title: "Pharmacy",
                            dataIndex: 'pharmacyName',
                            sorter: ( a, b ) => a.providerName > b.providerName,
                            sortDirections: [ "descend", "ascend" ],
                        },
                        // {
                        //     title: "Contact Person",
                        //     dataIndex: 'contactPerson',
                        //     sorter: ( a, b ) => a.contactPerson > b.contactPerson,
                        //     sortDirections: [ "descend", "ascend" ],
                        // },
                        {
                            title: "Contact",
                            dataIndex: 'contact',
                            sorter: ( a, b ) => a.contact > b.contact,
                            sortDirections: [ "descend", "ascend" ],
                        },
                        {
                            title: "location",
                            dataIndex: 'location',
                            sorter: ( a, b ) => a.location > b.location,
                            sortDirections: [ "descend", "ascend" ],
                        },
                        {
                            title: "Consults",
                            dataIndex: 'totalConsultations',
                            sorter: ( a, b ) => a.totalConsultations > b.totalConsultations,
                            sortDirections: [ "descend", "ascend" ],
                        },
                        {
                            title: "Action",
                            render: ( text, record, index ) => (
                                <Space>
                                    <div class="buttons has-addons">
                                        {
                                            hasPermission( resouces.pharmacies, action.canUpdate ) &&
                                            <Tooltip title="Edit Pharmacy">
                                                <button
                                                    className="d-flex align-items-center button is-small"
                                                    onClick={ () => {
                                                        setModal( {
                                                            title: "Editing Pharmacy",
                                                            open: true,
                                                            content: <EditPharmacyForm pharmacyId={ record.id } onSuccess={ onReload } showCharm={ false } />,
                                                        } );
                                                    } }>
                                                    <span className="bi bi-pencil"></span>
                                                </button>
                                            </Tooltip>
                                        }
                                        {
                                            hasPermission( resouces.pharmacies, action.canDelete ) &&
                                            <Tooltip title="Delete patient">
                                                <button className="button is-small" onClick={ () => handleDeletePharmacy( record.id ) }>
                                                    <span className="bi bi-trash"></span>
                                                </button>
                                            </Tooltip>
                                        }
                                    </div>
                                </Space>
                            ),
                        },

                    ] }
                    className="border"
                    dataSource={ data }
                    rowKey={ ( record ) => record.id }
                    loading={ isLoading }
                    bordered
                />
            </div>
        </div>
    );
};

export default Pharmacies;